import React, { Component, Fragment } from "react";
import { Card, Image, Row, Button, Modal, Alert } from 'react-bootstrap';
//import { Media } from "reactstrap";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "react-loader-spinner";

import { FaTrashAlt } from 'react-icons/fa';

class Preview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            previewImages: [],
            dragId: "",
            showLoading: 'none'
        };
    }

    componentDidMount() {
        const { imagesPreviewUrls } = this.props;

        ////break out the data
        //var arr = [];
        //imagesPreviewUrls.map((image) => {
        //    arr.push(image.data);
        //})

        this.setState({
            previewImages: imagesPreviewUrls
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.imagesPreviewUrls !== state.previewImages) {
            return {
                previewImages: props.imagesPreviewUrls
            };
        }
        return null;
    }

    deleteImage = (id) => {
        const { deleteImage } = this.props;
        deleteImage(id);
    };

    handleOver = (ev) => {
        ev.preventDefault();
    };

    handleDrag = (ev) => {
        this.setState({
            dragId: ev.currentTarget.id
        });
    };

    handleDrop = (ev) => {

        this.setState({
            showLoading: 'block'
        })

        ev.preventDefault();
        const { previewImages } = this.state;
        const { dragId } = this.state;
        const dragImage = previewImages.find((image) => image.id == dragId);
        const dropImage = previewImages.find(
            (image) => image.id == ev.currentTarget.id
        );
        const arr = this.moveItem(dragImage.id - 1, dropImage.id - 1);

        this.setState({
            previewImages: arr
        });

        //pass array back to parent
        const { updateImageArray } = this.props;
        updateImageArray(arr);

        //this.setState({
        //    showLoading: 'none'
        //})
    };

    moveItem(from, to) {
        const { previewImages } = this.state;
        const f = previewImages.splice(from, 1)[0];
        previewImages.splice(to, 0, f);
        return previewImages;
    }

    renderPreview() {
        const { previewImages } = this.state;
        if (previewImages.length > 0) {
            previewImages.map((items, index) => {
                items.id = index + 1;
            });
        }
        return (
            /*<Fragment>*/
                <div
                    style={{ display: "flex", flexWrap: "wrap" }}
                    
                >
                {previewImages.length > 0 &&
                    previewImages.map((element, index) => {

                        {
                            if (index + 1 < previewImages.length && this.state.showLoading != 'none') {
                                this.setState({
                                    showLoading: 'none'
                                })
                            }

                        }

                        return (
                            <div className={(index < 1 ? "mediaPrimary" : "mediaStd")}
                                key={index}
                                id={element.id}
                                draggable
                                onDragOver={(e) => this.handleOver(e)}
                                onDragStart={(e) => this.handleDrag(e)}
                                onDrop={(e) => this.handleDrop(e)}
                            >

                                <p style={{ float: 'left', display: (index < 1 ? "block" : "none") }}><b>Primary Image</b></p>
                                <div style={{ textAlign: 'right' }}><Button variant='outline-danger' onClick={() => this.deleteImage(element.id)} ><FaTrashAlt /></Button></div>
                                <img key={index} src={URL.createObjectURL(element.data)} height="250px" style={{ minWidth: "175px", objectFit: "cover", cursor: "pointer" }} />
                            </div>
                        );
                    })}
                </div>
            /*</Fragment>*/
        );
    }

    render() {
        const { previewImages } = this.state;
        //console.log("image order", previewImages);
        return <div className="wrapper">
            <div class="loading-overlay" style={{ display: this.state.showLoading }}>
                <div class="loading-vertical">
                    <Grid
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={0}
                    />
                </div>
            </div>
            {this.renderPreview()}
        </div>;
    }
}

export default Preview;
