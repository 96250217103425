import React, { Component, useRef, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Button, Row, Col, Alert } from 'react-bootstrap';
import SideNav from "./../modals/SideNav";
import "./../styles/blog.css";

import { Input, Datepicker, SegmentedGroup , Segmented  } from '@mobiscroll/react';

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

// services
import Promo from './../functions/Promo';
import UserDetail from './../functions/User';
import Listing from './../functions/Listing';

import { Grid } from "react-loader-spinner";



export function withRouter(Children) {
    return (props) => {
        return <Children {...props} location={props} />
    }
}

class AddNewPromo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: this.props.location.state.email,
            loggedIn: this.props.location.state.loggedIn,
            key: '0',
            tabCount: '3',
            intro: '',
            body: '',

            // media
            primaryImage: null,
            images: [],
            showLoading: 'none',
            showResultMessage: 'none',
            input: {},
            errors: [],
            showErrors: [],
            startDate: null,
            endDate: null,
            //listGroupItems: [{ name: "fff", id: 5 }, { name: "www", id: 4 }],
            //hosts: [],
            hostMultiSelections: [],
            hostOptions: [],
            userMultiSelections: [],
            userOptions: [],
            stateMultiSelections: [],
            stateOptions: [{ name: "ACT" }, { name: "NSW" }, { name: "NT" }, { name: "QLD" }, { name: "SA" }, { name: "TAS" }, { name: "VIC" }, { name: "WA" }],
            listingMultiSelections: [],
            listingOptions: [],
            discountMultiSelections: [],
            discountOptions: [{ name: "All" }, { name: "Nightly" }, { name: "AHS Fee" }],
            type: true,
            typeSelected: "percent",
            isHost: true,
        }
    }

    validate() {
        let input = this.state.input;
        let errors = {};
        let showErrors = {};
        let isValid = true;

        if (!input["title"]) {
            isValid = false;
            errors["title"] = "Please enter title for listing";
            showErrors["title"] = true;
        }

        if (!input["code"]) {
            isValid = false;
            errors["code"] = "Please enter promo code for listing";
            showErrors["code"] = true;
        }

        if (!input["discount"]) {
            isValid = false;
            errors["discount"] = "Please enter discount amount for listing";
            showErrors["discount"] = true;
        }

        if (!this.state.startDate) {
            isValid = false;
            errors["start"] = "Please enter start date for listing";
            showErrors["start"] = true;
        }

        if (!this.state.endDate) {
            isValid = false;
            errors["end"] = "Please enter end date for listing";
            showErrors["end"] = true;
        }

        this.setState({
            errors: errors,
            showErrors: showErrors,
            //showResultMessage: 'inline',
            //resultMessageClass: css,
            //resultMessage: message,
        })

        return isValid;
    }

    componentDidMount() {
        //build in admin checks as final security

        //get user id by email
        UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {
            if (result.data.LoginType === "admin") {

                this.setState({
                    isHost: false,
                });

                //get list of users
                var hostArray = []
                var userArray = []
                UserDetail.getUsers().then((userResult) => {
                    //console.log(userResult.data);
                    userResult.data.map((user, index) => {
                        if (user.LoginType == "hosting") {
                            hostArray.push({
                                name: user.Firstname + " " + user.Lastname,
                                id: user.UUID
                            })
                        }
                        userArray.push({
                            name: user.Firstname + " " + user.Lastname,
                            id: user.UUID
                        })
                    })

                    this.setState({
                        hostOptions: hostArray,
                        userOptions: userArray
                    });

                })

                //get list of properties
                var listingArray = []
                Listing.getListings().then(result => {
                    //console.log(result.data);
                    result.data.map((owner, index) => {
                        owner.Listings.map((listing, index) => {
                            listingArray.push({
                                name: listing.Title,
                                id: listing.ListingID
                            })
                        })

                        this.setState({
                            listingOptions: listingArray
                        });

                    })

                })
            } else if (result.data.LoginType === "hosting") { //if host

                //remove options not allow for the host and define some defaults
                var hostArray = []
                hostArray.push({
                    name: result.data.Firstname + " " + result.data.Lastname,
                    id: result.data.UUID
                })

                this.setState({
                    discountOptions: [{ name: "All" }, { name: "Nightly" }],
                    isHost: true,
                    hostOptions: hostArray,
                    hostMultiSelections: hostArray,
                });

                Listing.getListingByOwnerEmail(result.data.Email).then(listingResult => {

                    var listingArray = []
                    listingResult.data.Listings.map((listing, index) => {
                        listingArray.push({
                            name: listing.Title,
                            id: listing.ListingID
                        })
                    })

                    this.setState({
                        listingOptions: listingArray
                    });

                });
            }
        })

    }

    onStartDateChange = (e) => {
        console.log(e)

        this.setState({
            startDate: e.value
        })
        if (e.value) {
            //remoev the errors fom array state
            let errors = {};
            let showErrors = {};

            Object.entries(this.state.errors).map((error, index) => {
                if (error[0] !== e.inst.props.name) {
                    errors[error[0]] = error[1];
                }
            })
            Object.entries(this.state.showErrors).map((error, index) => {
                if (error[0] !== e.inst.props.name) {
                    showErrors[error[0]] = error[1];
                }
            })

            this.setState({
                errors: errors,
                showErrors: showErrors
            })
        }
        return false;
    }

    onEndDateChange = (e) => {
        this.setState({
            endDate: e.value
        })

        if (e.value) {
            //remoev the errors fom array state
            let errors = {};
            let showErrors = {};

            Object.entries(this.state.errors).map((error, index) => {
                if (error[0] !== e.inst.props.name) {
                    errors[error[0]] = error[1];
                }
            })
            Object.entries(this.state.showErrors).map((error, index) => {
                if (error[0] !== e.inst.props.name) {
                    showErrors[error[0]] = error[1];
                }
            })

            this.setState({
                errors: errors,
                showErrors: showErrors
            })
        }

        return false;
    }

    onTextChange = (event) => {
        let input = this.state.input;
        input[event.target.name] = event.target.value;

        this.setState({
            input
        });

        if (event.target.value) {
            //remoev the errors fom array state
            let errors = {};
            let showErrors = {};

            Object.entries(this.state.errors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    errors[error[0]] = error[1];
                }
            })
            Object.entries(this.state.showErrors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    showErrors[error[0]] = error[1];
                }
            })

            this.setState({
                errors: errors,
                showErrors: showErrors
            })
        }
    }

    onTypeChange = (event) => {
        //console.log(event);

        //alert(event.target.value);

        this.setState({
            typeSelected: event.target.value
        })

    }

    savePromo = () => {
        //show page loading and disable save button
        this.setState({ showLoading: 'block' });

        //check if all fields have been validated
        if (this.validate()) {
            //get user ID
            UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {
                var userID = result.data.UUID;

                Promo.createPromo(
                    userID,
                    this.state.input["title"],
                    this.state.input["code"],
                    this.state.startDate,
                    this.state.endDate,
                    this.state.typeSelected,
                    this.state.input["discount"],
                    this.state.hostMultiSelections,
                    this.state.userMultiSelections,
                    this.state.discountMultiSelections,
                    this.state.stateMultiSelections,
                    this.state.listingMultiSelections
                        ).then(result => {

                        var css = '';
                        var message = '';

                        //if result is good
                        if (result && result.data && result.data.includes("promo created")) {
                            css = 'success';
                            message = 'Congratulations, your promo has been created, we will review and publish soon!';
                        } else {
                            css = 'danger';
                            message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                        }

                        this.setState({
                            showLoading: 'none',
                            showResultMessage: 'inline',
                            resultMessageClass: css,
                            resultMessage: message,
                            showButtons: 'none',
                            showDetail: 'none'
                        });

                    });

            });

        } else {
            this.setState({
                showLoading: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there are errors on the page please correct these!',
            });
        }
    }

    render() {

        return (
            <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
                <div class="loading-overlay" style={{ display: this.state.showLoading }}>
                    <div class="loading-vertical">
                        <Grid
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={0}
                        />
                    </div>
                </div>
                <Row>
                    <Col className="d-none d-md-block" md="3" lg="2" xl="1">
                        <SideNav
                            loggedIn={this.props.location.state.loggedIn}
                            loginType={this.props.location.state.loginType}
                            email={this.props.location.state.email}
                            activeTab='promos'
                        />
                    </Col>
                    <Col md="9" lg="10" xl="11">
                        <div style={{ width: '100%' }}>
                            <h1>Add New Promo</h1>
                            <hr />
                        </div>
                        <Row>
                            <Col sm={6} md={6}>
                                <h4>Title / Description*</h4>
                                <Input className="mbsc-remove-margins" type="text" name="title" placeholder="Title" id="title" errorMessage={this.state.errors.title} error={this.state.showErrors.title} onChange={this.onTextChange} />
                            </Col>
                            <Col sm={6} md={6}>
                                <h4>Promo Code*</h4>
                                <Input className="mbsc-remove-margins" type="text" name="code" placeholder="Promo Code" id="code" errorMessage={this.state.errors.code} error={this.state.showErrors.code} onChange={this.onTextChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} md={6} >
                                <h4>Start Date*</h4>
                                <Datepicker
                                    controls={['calendar']}
                                    theme="material"
                                    themeVariant='light'
                                    cssClass="mbsc-remove-margins"
                                    onChange={this.onStartDateChange}
                                    dateFormat="DD/MM/YYYY"
                                    labels={{
                                        cellCssClass: "mbsc-remove-margins"
                                    }}
                                    errorMessage={this.state.errors.start}
                                    error={this.state.showErrors.start}
                                    name="start"
                                />
                            </Col>
                            <Col sm={6} md={6}>
                                <h4>End Date*</h4>
                                <Datepicker
                                    controls={['calendar']}
                                    theme="material"
                                    themeVariant='light'
                                    cssClass="mbsc-remove-margins"
                                    onChange={this.onEndDateChange}
                                    dateFormat="DD/MM/YYYY"
                                    labels={{
                                        cellCssClass: "mbsc-remove-margins"
                                    }}
                                    errorMessage={this.state.errors.end}
                                    error={this.state.showErrors.end}
                                    name="end"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} md={6}>
                                <h4>Discount Type % or $*</h4>
                                <SegmentedGroup name="group" color="secondary" onChange={this.onTypeChange} >
                                    <Segmented label="%" defaultChecked={this.state.type} value="percent" style={{ height: 100 }}>%</Segmented>
                                    <Segmented label="$" defaultChecked={!this.state.type} value="dollar" style={{ height: 100 }}>$</Segmented>
                                </SegmentedGroup >
                            </Col>
                            <Col sm={6} md={6}>
                                <h4>Discount amount*</h4>
                                <Input className="mbsc-remove-margins" type="text" name="discount" placeholder="Discount Amount" id="discount" errorMessage={this.state.errors.discount} error={this.state.showErrors.discount} onChange={this.onTextChange} />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col sm={6} md={6} style={{ display: this.state.isHost ? "none" : "block" }}>
                                <h4>Hosts</h4>
                                <Typeahead
                                    id="basic-typeahead-multiple"
                                    labelKey="name"
                                    multiple
                                    onChange={(hostMultiSelections) => {
                                        this.setState({ hostMultiSelections });
                                    }}
                                    options={this.state.hostOptions}
                                    placeholder="Choose multiple hosts..."
                                    selected={this.state.hostMultiSelections}
                                />

                            </Col>
                            <Col sm={6} md={6} style={{ display: this.state.isHost ? "none" : "block" }}>
                                <h4>Users - this is specific to a select list of accounts only</h4>
                                <Typeahead
                                    id="basic-typeahead-multiple"
                                    labelKey="name"
                                    multiple
                                    onChange={(userMultiSelections) => {
                                        this.setState({ userMultiSelections });
                                    }}
                                    options={this.state.userOptions}
                                    placeholder="Choose multiple users..."
                                    selected={this.state.userMultiSelections}
                                />

                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} md={6}>
                                <h4>Discount off?</h4>
                                <Typeahead
                                    id="basic-typeahead-multiple"
                                    labelKey="name"
                                    multiple
                                    onChange={(discountMultiSelections) => {
                                        this.setState({ discountMultiSelections });
                                    }}
                                    options={this.state.discountOptions}
                                    placeholder="Choose multiple options..."
                                    selected={this.state.discountMultiSelections}
                                />
                            </Col>
                            <Col sm={6} md={6}>
                                <h4>States</h4>
                                <Typeahead
                                    id="basic-typeahead-multiple"
                                    labelKey="name"
                                    multiple
                                    onChange={(stateMultiSelections) => {
                                        this.setState({ stateMultiSelections });
                                    }}
                                    options={this.state.stateOptions}
                                    placeholder="Choose multiple states..."
                                    selected={this.state.stateMultiSelections}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12}>
                                <h4>Properties</h4>
                                <Typeahead
                                    id="basic-typeahead-multiple"
                                    labelKey="name"
                                    multiple
                                    onChange={(listingMultiSelections) => {
                                        this.setState({ listingMultiSelections });
                                    }}
                                    options={this.state.listingOptions}
                                    placeholder="Choose multiple listings..."
                                    selected={this.state.listingMultiSelections}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row style={{ display: this.state.showResultMessage }}>
                    <Col xs={{ span: 12 }} md={{ span: 9, offset: 3 }} lg={{ span: 10, offset: 2 }} xl={{ span: 11, offset: 1 }}>
                        <Alert variant={this.state.resultMessageClass} >
                            {this.state.resultMessage}
                        </Alert>
                    </Col>
                </Row>

                <Row style={{ display: this.state.showButtons }}>
                    <Col className="d-none d-sm-block" md="3" lg="2" xl="1">
                    </Col>
                    <Col className="d-none d-sm-block" md="9" lg="10" xl="11">
                        <Button style={{ width: "100%" }} variant="success" onClick={this.savePromo}>Save</Button>
                    </Col>
                    <Col className="d-block d-sm-none" xs="12">
                        <Button style={{ width: "100%" }}  variant="success" onClick={this.savePromo}>Save</Button>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withRouter(AddNewPromo)