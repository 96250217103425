import React from "react";
import "./../styles/footer.css";
import { FaFacebookF } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';

function Footer () {
    return (
        <div className="footer-wrap">
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        Aussie Holiday Stays
                    </div>
                    <div className="col-6 social-icons">
                    

                        <a target="_blank" href="https://www.facebook.com/aussieholidaystays"><FaFacebookF /></a>
                        <a target="_blank" href="https://twitter.com/aussieholidays/"><FaTwitter /></a>
                        <a target="_blank" href="https://www.linkedin.com/company/aussie-holiday-stays"><FaLinkedinIn /></a>
                        <a target="_blank" href="https://www.instagram.com/aussieholidaystays/"><FaInstagram /></a>
                        <a target="_blank" href="https://www.youtube.com/channel/UCx3OWl6DrqnkSScy7UBaITA"><FaYoutube /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer