import React, { useRef, useEffect, useState } from 'react';
import { Form, Card, Row, Col, Alert } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';

import "./../../styles/information.css";

import { Input } from '@mobiscroll/react';

function Information({ changeIntro, changeBody, isValid, blog}) {
    const introRef = useRef(null);
    const bodyRef = useRef(null);

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState({});
    const [pageValid, setPageValid] = useState('none');

    const logintro = () => {
        if (introRef.current) {
            console.log(introRef.current.getContent());
        }
    };
    const logbody = () => {
        if (bodyRef.current) {
            console.log(bodyRef.current.getContent());
        }
    };

    const handleIntroChange = (event) => {
        changeIntro(event);
    }

    const handleBodyChange = (event) => {
        changeBody(event);
    }

    const onTextChange = (event) => {

        let newinput = input;

        newinput[event.target.name] = event.target.value;

        setInput(newinput)

        if (event.target.value) {
            //remoev the errors fom array state
            let newerrors = {};
            let newshowErrors = {};

            Object.entries(errors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(showErrors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newshowErrors[error[0]] = error[1];
                }
            })

            setErrors(newerrors);
            setShowErrors(newshowErrors);

            setPageValid('none');
        }
    }

    const getvalid = () => {
        //let newinput = input;
        let newerrors = {};
        let newshowErrors = {};
        let isValid = true;

        if (!input["title"]) {
            //alert('no title');
            isValid = false;
            newerrors["title"] = "Please enter title for listing";
            newshowErrors["title"] = true;
        }

        setErrors(newerrors);
        setShowErrors(newshowErrors);
        if (isValid) {
            setPageValid('none');
        } else {
            setPageValid('block');
        }

        return isValid;
    }

    useEffect(() => {
        //alert('loaded');
        //console.log("blog: " + blog.Title);

        if (blog) {
            let newinput = input;

            if (blog.Title) {
                newinput["title"] = blog.Title;
                document.getElementById("informationtitle").value = blog.Title;
            }

            setInput(newinput)
        }

        isValid.current = getvalid;
    }, [])

    return (
            <Card style={{margin: "10px"}}>
                <Card.Body>                  
                <Row>
                    <h4>Title*</h4>
                    <Input className="mbsc-remove-margins" type="text" name="title" placeholder="Title" id="informationtitle" errorMessage={errors.title} error={showErrors.title} onChange={onTextChange} />
                </Row>
                <Row>
                        <Col sm={6} md={6}>
                        
                        <Row>
                            <h4>Introduction</h4>
                            <Editor
                                onInit={(evt, editor) => introRef.current = editor}
                                initialValue={(blog ? blog.Intro : "")}
                                init={{
                                    height: 450,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                                id="introdescription"
                                onEditorChange={event => handleIntroChange(event)}
                            />
                        </Row>
                        </Col>
                        <Col sm={6} md={6} RowSpan={6}>
                            <Row>
                            <h4>Body</h4>
                            <Editor
                                onInit={(evt, editorbody) => bodyRef.current = editorbody}
                                initialValue={(blog ? blog.Body : "")}
                                    init={{
                                        height: 450,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar: 'undo redo | formatselect | ' +
                                            'bold italic backcolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    id="bodydescription"
                                    onEditorChange={event => handleBodyChange(event)}
                                />
                            </Row>
                        </Col>
                    </Row>
                    
            </Card.Body>
            <div style={{ display: pageValid, width: '100%' }}>
                <Alert variant="danger" >
                    Unfortunately there are errors on the page!
                </Alert>
            </div>
            </Card>
        )
    }


export default Information