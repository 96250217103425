import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from 'react-bootstrap';
import SideNav from "./../modals/SideNav";

//import "./../styles/site.css";

import InvoiceDetail from './../functions/Invoice';
import UserDetail from './../functions/User';
//import ListingDetail from './../functions/Listing';


export function withRouter(Children) {
    return (props) => {
        return <Children {...props} location={props} />
    }
}

class Invoices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoices: [],
            statusClass: 'label'
        }
    }

    formatDate = (input) => {
        var date = new Date(input);
        return (('0' + (date.getDate())).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()).toString();
    }

    formatStatus = (input) => {
        switch (input.toLowerCase()) {
            case 'pending':
            case 'deposit paid':
                return 'label label-primary';
                break;
            case 'cancelled':
                return 'label label-secondary';
                break;
            case 'paid':
                return 'label label-success';
                break;
        }
    }

    componentDidMount() {
        //console.log(this.props.location.state.loginType);

        if (this.props.location.state.loginType === "admin") {
            //get all invoices
            InvoiceDetail.getInvoices().then((result) => {
                const sortInvoices = result.data.sort((a, b) => (a.InvoiceID < b.InvoiceID) ? 1 : -1);
                this.setState({ invoices: sortInvoices });
            })
        } else {

            var invoices = [];

            //get user id by email
            UserDetail.getUserByEmail(this.props.location.state.email).then((userResult) => {

                InvoiceDetail.getInvoiceByUserID(userResult.data.UUID).then((result) => {

                    //add invoices to array
                    if (result && result.data && result.data.length > 0 && typeof (result.data) != 'string') {
                        invoices = result.data;
                    }

                    //check if user is the host and get list of listings
                    if (userResult.data.LoginType === "hosting") {

                        InvoiceDetail.getInvoicesForAllListingIDsForUserID(userResult.data.UUID).then((result) => {
                            //console.log(result);
                            //loop through the invoices and add
                            if (result && result.data && result.data.length > 0 && typeof (result.data) != 'string') {
                                result.data.map((inv, index) => {
                                    //console.log(inv.InvoiceID);
                                    invoices.push(inv);
                                })
                            }

                            //console.log(invoices);
                            if (invoices) {
                                const sortInvoices = invoices.sort((a, b) => (a.InvoiceID < b.InvoiceID) ? 1 : -1);
                                this.setState({ invoices: sortInvoices });
                            }
                        })
                    } else {
                        const sortInvoices = invoices.sort((a, b) => (a.InvoiceID < b.InvoiceID) ? 1 : -1);
                        this.setState({ invoices: sortInvoices });
                    }
                })

            })
        }
        

    }

    render() {
        return (
            <div style={{ paddingTop: "80px" }}>
                <Row>
                    <Col className="d-none d-lg-block" lg="2" xl="1">
                        <SideNav
                            loggedIn={this.props.location.state.loggedIn}
                            loginType={this.props.location.state.loginType}
                            email={this.props.location.state.email}
                            activeTab='invoices'
                        />
                    </Col>
                    <Col md="12" lg="10" xl="11">
                        <div style={{ width: '100%' }}>
                            <h1>Invoices</h1>
                            <hr />
                        </div>
                        <Card>
                            <Card.Body style={{ paddingRight: "0px" }}>
                                <Row>
                                    <Row>
                                        <Col lg="2" xs="2"><h5>Invoice</h5></Col>
                                        <Col lg="2" xs="2"><h5>Date</h5></Col>
                                        <Col lg="3" xs="5"><h5>Reservation</h5></Col>
                                        <Col lg="2" className="d-none d-lg-block"><h5>Payment For</h5></Col>
                                        <Col lg="1" className="d-none d-lg-block"><h5>Status</h5></Col>
                                        <Col lg="1" className="d-none d-lg-block"><h5>Amount</h5></Col>
                                        <Col lg="1" xs="3"><h5>Actions</h5></Col>
                                    </Row>
                                    {
                                        this.state.invoices ?
                                            this.state.invoices.map((invoice, index) =>
                                                <div style={{ width: '100%' }}>
                                                    <div>
                                                        <Row md="12" style={{ height: '50px', borderBottom: '1px solid #808080', paddingTop: '6px' }} >
                                                            <Col lg="2" xs="2">
                                                                {invoice.InvoiceID}
                                                            </Col>
                                                            <Col lg="2" xs="2">
                                                                {this.formatDate(invoice.CreatedDate)}
                                                            </Col>
                                                            <Col lg="3" xs="5">
                                                                <Link to={{
                                                                    pathname: "/reservationdetail/" + invoice.ReservationID,
                                                                    //state: {
                                                                    //    reservation: reservation,
                                                                    //    email: this.props.location.state.email
                                                                    //    //    guests: props.guests,
                                                                    //    //    pets: props.pets,
                                                                    //    //    checkin: props.checkin
                                                                    //}
                                                                }}>Reservation: {invoice.ShortReservationID}
                                                                </Link>
                                                                
                                                            </Col>
                                                            <Col lg="2" className="d-none d-lg-block">
                                                                {invoice.PaymentFor}
                                                            </Col>
                                                            <Col lg="1" className="d-none d-lg-block">
                                                                <span className={this.formatStatus(invoice.Status)}>{invoice.Status}</span>
                                                            </Col>
                                                            <Col lg="1" className="d-none d-lg-block">
                                                                {invoice.Amount}
                                                            </Col>
                                                            <Col lg="1" xs="3">
                                                                <Link to={{
                                                                    pathname: "/invoice/" + invoice.UUID,
                                                                }}><Button>View</Button>
                                                                </Link>
                                                            </Col>
                                                        </Row>
                                                    </div>


                                                    {/*<div class="d-none d-sm-block d-md-none">*/}
                                                    {/*    <Row style={{ height: '50px', paddingtop: '6px' }} >*/}
                                                    {/*        <Col sm="3">*/}
                                                    {/*            */}{/*<span className={this.formatStatus(reservation.Reservation.Status)}>{reservation.Reservation.Status}</span>*/}
                                                    {/*        </Col>*/}
                                                    {/*        <Col sm="4">*/}
                                                    {/*            */}{/*<Link style={{ display: this.showAction(reservation.Reservation.Status) }} to={{*/}
                                                    {/*            */}{/*    pathname: "/blog",*/}
                                                    {/*            */}{/*    state: {*/}
                                                    {/*            */}{/*        reservation: reservation,*/}
                                                    {/*            */}{/*        email: this.props.location.state.email*/}
                                                    {/*            */}{/*    }*/}
                                                    {/*            */}{/*}}><Button>Details</Button>*/}
                                                    {/*            */}{/*</Link>*/}
                                                    {/*        </Col>*/}
                                                    {/*        <Col sm="3">*/}
                                                    {/*            {this.formatDate(invoice.CreatedDate)}*/}
                                                    {/*        </Col>*/}
                                                    {/*        <Col sm="3">*/}
                                                    {/*            {this.formatDate(invoice.CreatedDate)}*/}
                                                    {/*        </Col>*/}

                                                    {/*    </Row>*/}
                                                    {/*    <Row style={{ height: '40px', borderbottom: '2px solid #808080', paddingtop: '6px' }} sm="12" >*/}
                                                    {/*        */}{/*<Link to={{*/}
                                                    {/*        */}{/*    pathname: "/addnewblog",*/}
                                                    {/*        */}{/*    state: {*/}
                                                    {/*        */}{/*        id: reservation.Reservation.ListingID*/}
                                                    {/*        */}{/*    }*/}
                                                    {/*        */}{/*}}>*/}
                                                    {/*        */}{/*    <span>{blog.Title}{this.listingLink(reservation.Reservation.ListingID)}</span>*/}
                                                    {/*        */}{/*</Link>*/}
                                                    {/*        <hr />*/}
                                                    {/*    </Row>*/}
                                                    {/*</div>*/}




                                                </div>
                                            ) :
                                            <Row>
                                            </Row>
                                    }

                                </Row>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </div>
        )
    }
}

export default withRouter(Invoices)