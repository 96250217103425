import ListingService from "../services/ListingService";

/**
 * global functions to act on listing api actions
 */
const Listing = {
    async getListings() {
        const result = await ListingService.getListings();

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    /**
     * 
     * @param {*} email 
     * @param {*} kind 
     * @param {*} title 
     * @param {*} description 
     * @param {*} typeOfListing 
     * @param {*} numberOfBedrooms 
     * @param {*} numberOfBeds 
     * @param {*} numberOfBathrooms 
     */
    async createListing(email, 
        information,
        selectedFeatures, 
        primaryImage, cropArea, images, 
        pricing, fees, taxes, periods,
        location,
        rooms, termsAndCondtionsValues, blockedDates) {
        // create the form to send through to the api
        const listing = new FormData();
        listing.append("Email", email);
        listing.append("Information", JSON.stringify(information));
        listing.append("Features", selectedFeatures);
        listing.append("PrimaryImage", primaryImage);
        listing.append("CropArea", cropArea);
        for (const image of images) {
            listing.append("Images", image);
        }
        listing.append("Pricing", JSON.stringify(pricing));
        for (const fee of fees) {
            listing.append("Fees", JSON.stringify(fee));
        }
        for (const tax of taxes) {
            listing.append("Taxes", JSON.stringify(tax));
        }
        for (const period of periods) {
            listing.append("Periods", JSON.stringify(period));
        }
        listing.append("Location", JSON.stringify(location));
        for (const room of rooms) {
            listing.append("Rooms", JSON.stringify(room));
        }
        listing.append("TermsAndConditions", JSON.stringify(termsAndCondtionsValues));

        console.log("create blocked: " + JSON.stringify(blockedDates));
        for (const date of blockedDates) {
            listing.append("BlockedDates", JSON.stringify(date));
        }        

        // send it to the listing service
        const result = await ListingService.createListing(listing);

        if (result.data.length !== 0) {
            console.log(result);
            return result;
        }

        return undefined;
    },

    async createDraftListing(email,
        information,
        selectedFeatures,
        primaryImage, cropArea, images,
        pricing, fees, taxes, periods,
        location,
        rooms, termsAndCondtionsValues, blockedDates) {
        // create the form to send through to the api
        const listing = new FormData();
        listing.append("Email", email);
        listing.append("Information", JSON.stringify(information));
        listing.append("Features", selectedFeatures);
        listing.append("PrimaryImage", primaryImage);
        listing.append("CropArea", cropArea);
        for (const image of images) {
            listing.append("Images", image);
        }
        listing.append("Pricing", JSON.stringify(pricing));
        for (const fee of fees) {
            listing.append("Fees", JSON.stringify(fee));
        }
        for (const tax of taxes) {
            listing.append("Taxes", JSON.stringify(tax));
        }
        for (const period of periods) {
            listing.append("Periods", JSON.stringify(period));
        }
        listing.append("Location", JSON.stringify(location));
        for (const room of rooms) {
            listing.append("Rooms", JSON.stringify(room));
        }
        listing.append("TermsAndConditions", JSON.stringify(termsAndCondtionsValues));

        //console.log("create blocked: " + JSON.stringify(blockedDates));
        for (const date of blockedDates) {
            listing.append("BlockedDates", JSON.stringify(date));
        }

        // send it to the listing service
        const result = await ListingService.createDraftListing(listing);

        if (result.data.length !== 0) {
            console.log(result);
            return result;
        }

        return undefined;
    },

    async getListingSummary(listingID) {
        const result = await ListingService.getListingSummary(listingID);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getListingByLocation(centrePoint, radius) { // check in, checkout, guests count, pets, start count, end count
        const result = await ListingService.getListingByLocation(centrePoint, radius);

        if (result != null && result.data != null && result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getListingByCriteria(checkin, checkout, guests, pets, start, end, centerpoint, radius, featured) { // check in, checkout, guests count, pets, start count, end count, centerpoint, radius
        const result = await ListingService.getListingByCriteria(checkin, checkout, guests, pets, start, end, centerpoint, radius, featured);

        if (result != null && result.data != null && result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getListingByID(listingID) {
        const result = await ListingService.getListingById(listingID);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },
    
    async getListingByOwnerEmail(email) {
        const result = await ListingService.getListingByOwnerEmail(email);
        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async deleteListingByID(listingid) {
        const result = await ListingService.deleteListingByID(listingid);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getListingFeesByID(listingID) {
        const result = await ListingService.getListingPricingFees(listingID);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getListingTaxesByID(listingID) {
        const result = await ListingService.getListingPricingTaxes(listingID);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getListingPeriodsByID(listingID, startDate, endDate) {
        const result = await ListingService.getListingPricingPeriods(listingID, startDate, endDate);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getListingPricingPeriodsByID(listingID) {
        const result = await ListingService.getListingPricingPeriodsByID(listingID);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async createICalEntry(listingID, name, url) {   
        const icalentry = {
            ListingID: listingID,
            Name: name,
            URL: url
        }

        // send it to the reservation service
        const result =  await ListingService.createICalEntry(icalentry);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getCalendarEntriesByListingID(listingID) {
        // send it to the reservation service
        const result = await ListingService.getCalendarEntriesByListingID(listingID);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getICalEntriesByListingID(listingID) {
        // send it to the reservation service
        const result = await ListingService.getICalEntriesByListingID(listingID);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async deleteICalEntry(iCalID) {
        const result =  await ListingService.deleteICalEntry(iCalID);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getCalendarByListingID(listingID) {
        const result = await ListingService.getICalentriesByListingID(listingID);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async UpdateListing(owner,
        information,
        selectedFeatures,
        primaryImage, cropArea, images,
        pricing, fees, taxes, periods,
        location,
        rooms, termsAndCondtionsValues, blockedDates) {
        const listing = new FormData();
        listing.append("Owner", JSON.stringify(owner));
        listing.append("Information", JSON.stringify(information));

        listing.append("Features", selectedFeatures);

        // models.ListingMedia
        listing.append("PrimaryImage", primaryImage);
        listing.append("CropArea", cropArea);

        if (images !== null) {
            for (const image of images) {
                listing.append("Images", image);
            }
        }
        listing.append("Pricing", JSON.stringify(pricing));

        // models.ListingAddtionalFees
        if (fees !== null) {
            for (const fee of fees) {
                listing.append("Fees", JSON.stringify(fee));
            }
        }


        // models.ListingAdditionalTaxes
        if (taxes !== null) {
            for (const tax of taxes) {
                listing.append("Taxes", JSON.stringify(tax));
            }
        }


        // models.ListingAddtionalPeriods
        if (periods !== null) {
            for (const period of periods) {
                listing.append("Periods", JSON.stringify(period));
            }
        }


        // models.ListingLocation
        listing.append("Location", JSON.stringify(location));

        // models.ListingRooms
        if (rooms !== null) {
            for (const room of rooms) {
                listing.append("Rooms", JSON.stringify(room));
            }
        }


        // models.ListingTermsAndConditions
        listing.append("TermsAndConditions", JSON.stringify(termsAndCondtionsValues));

        // models.ListingCalendar
        if (blockedDates !== null) {
            for (const date of blockedDates) {
                listing.append("BlockedDates", JSON.stringify(date));
            }
        }


        // send it to the listing service
        const result = await ListingService.UpdateListing(listing);


        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async UpdateListingInformation(owner, information) {

        const listing = new FormData();
        listing.append("Owner", JSON.stringify(owner));

        // models.ListingInformation
        listing.append("Information", JSON.stringify(information));

        // send it to the listing service
        const result = await ListingService.UpdateListingInformation(listing);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async UpdateListingPricing(owner, pricing, fees, taxes, periods) {
        const listing = new FormData();
        listing.append("Owner", JSON.stringify(owner));

        // models.ListingPricing
        listing.append("Pricing", JSON.stringify(pricing));

        // models.ListingAddtionalFees
        if (fees !== null) {
            for (const fee of fees) {
                listing.append("Fees", JSON.stringify(fee));
            }
        }

        // models.ListingAdditionalTaxes
        if (taxes !== null) {
            for (const tax of taxes) {
                listing.append("Taxes", JSON.stringify(tax));
            }
        }

        // models.ListingAddtionalPeriods
        if (periods !== null) {
            for (const period of periods) {
                listing.append("Periods", JSON.stringify(period));
            }
        }

        // send it to the listing service
        const result = await ListingService.UpdateListingPricing(listing);


        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async UpdateListingMedia(owner, primaryImage, cropArea, images) {
        const listing = new FormData();
        listing.append("Owner", JSON.stringify(owner));

        // models.ListingMedia
        listing.append("PrimaryImage", primaryImage);
        //console.log("sending primary: " + primaryImage);
        listing.append("CropArea", cropArea);

        if (images !== null) {
            for (const image of images) {
                listing.append("Images", image);
            }
        }
        //console.log("images: " + images);

        // send it to the listing service
        const result = await ListingService.UpdateListingMedia(listing);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async UpdateListingFeatures(owner, selectedFeatures) {
        const listing = new FormData();
        listing.append("Owner", JSON.stringify(owner));
        
        listing.append("Features", selectedFeatures);

        // send it to the listing service
        const result = await ListingService.UpdateListingFeatures(listing);


        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async CreateListingLocation(owner, location) {
        const listing = new FormData();
        listing.append("Owner", JSON.stringify(owner));

        // models.ListingLocation
        listing.append("Location", JSON.stringify(location));

        // send it to the listing service
        const result = await ListingService.CreateListingLocation(listing);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async UpdateListingLocation(owner, location) {
        const listing = new FormData();
        listing.append("Owner", JSON.stringify(owner));

        // models.ListingLocation
        listing.append("Location", JSON.stringify(location));

        // send it to the listing service
        const result = await ListingService.UpdateListingLocation(listing);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async UpdateListingRooms(owner, rooms) {
        const listing = new FormData();
        listing.append("Owner", JSON.stringify(owner));

        // models.ListingRooms
        if (rooms !== null) {
            for (const room of rooms) {
                listing.append("Rooms", JSON.stringify(room));
            }
        }

        // send it to the listing service
        const result = await ListingService.UpdateListingRooms(listing);


        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async UpdateListingTermsAndConditions(owner, termsAndCondtionsValues) {
        const listing = new FormData();
        listing.append("Owner", JSON.stringify(owner));

        // models.ListingTermsAndConditions
        listing.append("TermsAndConditions", JSON.stringify(termsAndCondtionsValues));

        // send it to the listing service
        const result = await ListingService.UpdateListingTermsAndConditions(listing);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async UpdateListingCalendar(owner, blockedDates) {
        const listing = new FormData();
        listing.append("Owner", JSON.stringify(owner));

        // models.ListingCalendar
        if (blockedDates !== null) {
            for (const date of blockedDates) {
                listing.append("BlockedDates", JSON.stringify(date));
            }
        }

        // send it to the listing service
        const result = await ListingService.UpdateListingCalendar(listing);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async GetListingOtherImagesByID(listingid) {
        const result =  await ListingService.GetListingOtherImagesByID(listingid);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async GetListingAllImagesByID(listingid) {
        const result = await ListingService.GetListingAllImagesByID(listingid);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async GetListingPrimaryImageByID(listingid) {
        const result = await ListingService.GetListingPrimaryImageByID(listingid);

        if(result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    //async getListings() {
    //    const result = await ListingService.getListings();

    //    if (result.data.length !== 0) {
    //        return result;
    //    }

    //    return undefined;
    //},

    ////////////// states //////////////
    async GetListingStates() {
        const result = await ListingService.GetListingStates();
        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async GetListingStateByListingID(listingid) {
        const result = await ListingService.GetListingStateByListingID(listingid);
        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async GetListingStateByOwnerID(ownerid) {
        const result = await ListingService.GetListingStateByOwnerID(ownerid);
        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async GetListingStateByState(state, status) {
        const result = await ListingService.GetListingStateByState(state, status);
        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async UpdateListingState(listingid, state, status) {
        const listingState = {
            ListingID: listingid,
            State: state,
            Status: status
        }
        const result =  await ListingService.UpdateListingState(listingState);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async SyncICalendars(listingid) {
        const result = await ListingService.SyncICalendars(listingid)
        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    /**
     * update calendar and pricing from rms api
     * @param {string} listingid 
     * @param {string} datefrom 
     * @param {string} dateto
     * @returns {string} message
     */
     async UpdateRMSData(listingid, datefrom, dateto) {
        const result = await ListingService.UpdateRMSData(listingid, datefrom, dateto)

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    /**
     * update calendar and pricing from rms api
     * @param {string} listingid 
     * @param {string} datefrom 
     * @param {string} dateto
     * @returns {string} message
     */
    async UpdateBEDSData(listingid, datefrom, dateto) {
        const result = await ListingService.UpdateBEDSData(listingid, datefrom, dateto)

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    /**
     * update calendar and pricing from rms api
     * @param {string} listingid 
     * @param {string} datefrom 
     * @param {string} dateto
     * @returns {string} message
     */
    async UpdateESCAPIAData(listingid, datefrom, dateto) {
        const result = await ListingService.UpdateESCAPIAData(listingid, datefrom, dateto)

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    /**
     * update a single column entry with a value
     * currently only good for pricing deposit percentage entry
     * 
     * @param {string} listingid
     * @param {string} name - not used
     * @param {string} vale - new deposit percentage
     * 
     * @returns {string} message
     */
    async UpdateColumnEntryValue( listingid, name, value) {
        const result = await ListingService.UpdateColumnEntryValue(listingid, name, value)

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    }
}

export default Listing