import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button, Modal, Alert  } from 'react-bootstrap';
import SideNav from "./../modals/SideNav";

import { FaEdit } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { FaEyeSlash } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa';

import Promo from './../functions/Promo';
import UserDetail from './../functions/User';

export function withRouter(Children) {
    return (props) => {
        return <Children {...props} location={props} />
    }
}

class Promos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promos: [],
            statusClass: 'label',
            adminUser: false,
            showDeleteModal: false,
            showActivateModal: false,
            deleteUUID: "",
            showResultMessage: 'none',
            resultMessageClass: "",
            resultMessage: "",
        }
    }

    formatDate = (input) => {
        var date = new Date(input);
        return (('0' + (date.getDate())).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()).toString();
    }

    formatStatus = (input, date) => {
        //need to find a way to compare dates
        var endDate = new Date(date).getTime();
        var currentDate = new Date().getTime();

        if (input) {
            //true - check if expired or not
            if (endDate > currentDate) {
                return 'label label-success';
            } else {
                return 'label label-danger';
            }
        } else {
            if (endDate > currentDate) {
                return 'label label-secondary';
            } else {
                return 'label label-danger';
            }
        }
    }

    formatStatusText = (input, date) => {
        //need to find a way to compare dates
        var endDate = new Date(date).getTime();
        var currentDate = new Date().getTime();

        if (input) {
            if (endDate > currentDate) {
                return "Active";
            } else {
                return "Expired";
            }
        } else {
            return "Inactive";
        }
    }

    showDeletePromo = (title, uuid) => {
        this.setState({
            showDeleteModal: true,
            deleteUUID: uuid
        })

    }

    hideDeleteModal = () => {
        this.setState({
            showDeleteModal: false,
            deleteUUID: "",
        });
    }

    delete = () => {
        var css = '';
        var message = '';

        this.setState({
            showResultMessage: 'none',
            resultMessageClass: css,
            resultMessage: message,
        });

        Promo.deletePromo(this.state.deleteUUID).then((result) => {
            //console.log(result.data);
            if (result && typeof (result.data) == 'string' && result.data.includes("DeletePromo - promo deleted")) {
                //refresh grid view
                this.getPromos();

                css = 'success';
                message = 'Congratulations, your promo has been deleted!';
                this.setState({
                    showDeleteModal: false,
                    deleteUUID: "",
                    showResultMessage: 'inline',
                    resultMessageClass: css,
                    resultMessage: message,
                });
            } else {
                //show error message
                css = 'danger';
                message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';

                this.setState({
                    showResultMessage: 'inline',
                    resultMessageClass: css,
                    resultMessage: message,
                });
            }
        })
    }

    showActivatePromo = (title, uuid, active) => {
        this.setState({
            showActivateModal: true,
            activateUUID: uuid,
            activateActive: active
        })

    }

    hideActivateModal = () => {
        this.setState({
            showActivateModal: false,
            activateUUID: "",
            activateActive: ""
        });
    }

    activateUpdate = () => {
        var css = '';
        var message = '';

        this.setState({
            showResultMessage: 'none',
            resultMessageClass: css,
            resultMessage: message,
        });

        //console.log(this.state.activateActive);

        Promo.updatePromoField(this.state.activateUUID, "active", !this.state.activateActive).then((result) => {
            if (result && typeof (result.data) == 'string' && result.data.includes("UpdatePromoField - promo field updated")) {
                //refresh grid view
                this.getPromos();

                css = 'success';
                message = 'Congratulations, your promo has been deleted!';
                this.setState({
                    showActivateModal: false,
                    activateUUID: "",
                    showResultMessage: 'inline',
                    resultMessageClass: css,
                    resultMessage: message,
                });
            } else {
                //show error message
                css = 'danger';
                message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';

                this.setState({
                    showResultMessage: 'inline',
                    resultMessageClass: css,
                    resultMessage: message,
                });
            }
        })
    }

    getPromos() {
        //get user id by email
        UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {

            if (result.data.LoginType === "admin") {
                this.setState({ adminUser: true });
                //get all promos
                Promo.getPromos().then((result) => {
                    if (result && typeof (result.data) != 'string') {
                        this.setState({ promos: result.data });
                    }
                })
            } else if (result.data.LoginType === "hosting") {
                //get promo based on host
                Promo.getPromosDetailByKey("created_by", result.data.UUID).then((promoResult) => {
                //Promo.getPromosDetailByKey("UUID", "8513e1545a244efd853cf09370ce4b6b").then((promoResult) => {
                    if (promoResult && typeof (promoResult.data) != 'string') {
                        this.setState({ promos: promoResult.data });
                    }
                })

                this.setState({ adminUser: true });
            } else {
                //hide all details
                this.setState({ adminUser: false });
            }
        })
    }

    componentDidMount() {

        this.getPromos();

    }

    render() {
        return (this.state.adminUser ? 
            <div style={{ paddingTop: "80px" }}>
                <Row>
                    <Col className="d-none d-lg-block" lg="2" xl="1">
                        <SideNav
                            loggedIn={this.props.location.state.loggedIn}
                            loginType={this.props.location.state.loginType}
                            email={this.props.location.state.email}
                            activeTab='promos'
                        />
                    </Col>
                    <Col md="12" lg="10" xl="11">
                        <div style={{ width: '100%' }}>
                            <h1>Promos</h1>
                            <hr />
                        </div>
                        <Link to={{
                            pathname: "/addnewpromo",
                            state: {
                                loginType: this.props.location.state.loginType,
                                email: this.props.location.state.email,
                                loggedIn: this.props.location.state.loggedIn
                            }
                        }}>
                            <Button>New Promo</Button>
                        </Link>
                        <Row style={{ display: this.state.showResultMessage }}>
                            <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                <Alert variant={this.state.resultMessageClass} >
                                    {this.state.resultMessage}
                                </Alert>
                            </Col>
                        </Row>
                        <Card>
                            <Card.Body style={{ paddingRight: "0px" }}>
                                <Row>
                                    <Row>
                                        <Col md="2" sm="3" xs="3"><h5>Status</h5></Col>
                                        <Col md="2" sm="3" xs="3"><h5>Title</h5></Col>
                                        <Col md="2" sm="2" xs="2"><h5>Code</h5></Col>
                                        <Col md="2" className="d-none d-md-block"><h5>Created</h5></Col>
                                        <Col md="2" sm="2" xs="2"><h5>Expires</h5></Col>
                                        <Col md="2" sm="2" xs="2"><h5>Actions</h5></Col>
                                    </Row>
                                    {
                                        this.state.promos && this.state.promos.length > 0 ?
                                            this.state.promos.map((promo, index) =>
                                                <div style={{ width: '100%' }}>
                                                    <div>
                                                        <Row md="12" sm="12" xs="12" style={{ height: '50px', borderBottom: '1px solid #808080', paddingTop: '6px' }} >
                                                            <Col md="2" sm="3" xs="3">
                                                                <span className={this.formatStatus(promo.Active, promo.EndDate)}>{this.formatStatusText(promo.Active, promo.EndDate)}</span>
                                                            </Col>
                                                            <Col md="2" sm="3" xs="3">
                                                                <span>{promo.Title}</span>
                                                            </Col>
                                                            <Col md="2" sm="2" xs="2">
                                                                {promo.Code}
                                                            </Col>

                                                            <Col md="2" className="d-none d-md-block">
                                                                {this.formatDate(promo.CreatedDate)}
                                                            </Col>
                                                            <Col md="2" sm="2" xs="2">
                                                                {this.formatDate(promo.EndDate)}
                                                            </Col>
                                                            <Col md="2" sm="2" xs="2">
                                                                <Link to={"/editpromo"}
                                                                    state={{
                                                                        loginType: this.props.location.state.loginType,
                                                                        email: this.props.location.state.email,
                                                                        loggedIn: this.props.location.state.loggedIn,
                                                                        promo: promo
                                                                }}><Button variant="link" title="Edit Promo" className="icon-button" > <FaEdit size={28} color="lightblue" /> </Button>
                                                                </Link>
                                                                <Button variant="link" title="Delete Promo" className="icon-button" onClick={() => this.showDeletePromo(promo.Title, promo.UUID)} ><FaTrashAlt id="delete" size={28} color="lightblue" /></Button>
                                                                <Button style={{ display: (promo.Active ? "inline" : "none") }} variant="link" title="Deactivate Promo" className="icon-button" onClick={() => this.showActivatePromo(promo.Title, promo.UUID, promo.Active)} ><FaEyeSlash size={28} color="lightblue" /></Button>
                                                                <Button style={{ display: (promo.Active ? "none" : "inline") }} variant="link" title="Activate Promo" className="icon-button" onClick={() => this.showActivatePromo(promo.Title, promo.UUID, promo.Active)} ><FaEye size={28} color="lightblue" /></Button>

                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            ) :
                                            <Row>
                                                <h4>There are no Promos to list</h4>
                                            </Row>
                                    }

                                </Row>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>

                <Modal show={this.state.showDeleteModal} size="md" centered>
                    <Modal.Header closeButton onClick={() => this.hideDeleteModal()}>
                        <Modal.Title>Delete Promo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ wordWrap: 'break-word' }}>
                            <h4>You cannot undo this change once deleted.</h4>
                        </div>
                        <hr />
                        <Button variant="success" onClick={() => this.delete()} style={{ width: "100%" }}>Confirm Delete</Button>
                        <br />
                        <br />
                        <Button variant="secondary" onClick={() => this.hideDeleteModal()} style={{ width: "100%" }}>Close</Button>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showActivateModal} size="md" centered>
                    <Modal.Header closeButton onClick={() => this.hideActivateModal()}>
                        <Modal.Title>Activate/Deactivate Promo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <hr />
                        <Button variant="success" onClick={() => this.activateUpdate()} style={{ width: "100%" }}>Confirm {(this.state.activateActive ? "Deactivate" : "Activate")}</Button>
                        <br />
                        <br />
                        <Button variant="secondary" onClick={() => this.hideActivateModal()} style={{ width: "100%" }}>Close</Button>
                    </Modal.Body>
                </Modal>

            </div>
            :
            <div>
                You do not have permissions for this page!
            </div>
        )
    }
}

export default withRouter(Promos)