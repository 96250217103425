import React, { Component } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, Button } from 'react-bootstrap';

import "./../styles/invoice.css";

import SideNav from "./../modals/SideNav";

/*import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';*/

import InvoiceDetail from './../functions/Invoice';
import ReservationDetail from './../functions/Reservation';
import PricingDetail from './../functions/Pricing';
import UserDetail from './../functions/User';
import ListingDetail from './../functions/Listing';

import { Grid } from "react-loader-spinner";
import { Link } from "react-router-dom";

export function withRouter(Children) {
    return (props) => {

        const match = { params: useParams() };
        const location = { location: useLocation() };

        return <Children {...props} match={match} location={location.location} />
    }
}

class Invoice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoiceID: '', //this.props.location.state.id
            spinner: 'none',
            invoiceShow: 'block',
            invoice: Invoice,
            customer: null,
            reservation: null,
            isCustomer: true,
            hostFees: "$0.00",
            shortReservationID: 0,
            listingTitle: ""
        }
    }

    formatDate = (input) => {
        var date = new Date(input);
        return (('0' + (date.getDate())).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()).toString();
    }

    formatStatus = (input) => {
        if (input) {
            switch (input.toLowerCase()) {
                case 'pending':
                    return 'label label-primary';
                    break;
                case 'cancelled':
                    return 'label label-secondary';
                    break;
                case 'deposit-paid':
                    return 'label label-success';
                    break;
                case 'paid':
                    return 'label label-success';
                    break;
            }
        }
    }

    printInvoice = () => {
        //alert("print");

        var printContents = document.getElementById("printArea").innerHTML;
        var originalContents = document.body.innerHTML;

        var style = '<style type="text/css" media="print">@page{size: auto; margin: 0mm;  } html { background - color: #FFFFFF; margin: 0px;  } body {margin: 10mm 15mm 10mm 15mm;}</style >'

        document.body.innerHTML = style + printContents;

        window.print();

        window.location.reload();
        //document.body.innerHTML = originalContents;

        //window.close();

        //let w = window.open();
        //w.document.write(style + printContents);
        //w.print();
        //w.close();


    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({ invoiceID: id });

        InvoiceDetail.getInvoiceByID(id).then(result => {
            this.setState({ invoice: result.data });

            //get the customer for name and email
            UserDetail.getUserByID(result.data.CustomerID).then(result => {
                if (result) {
                    this.setState({ customer: result.data });

                    UserDetail.userEmailCheck(result.data.Email).then(result => {
                        if (!result) {
                            //hide results and show login

                            //this is not the customer
                            this.setState({ isCustomer: false });

                        } else {
                            //this is the customer
                            this.setState({ isCustomer: true });
                        }
                    })
                }
            });

            //get the reservation for the pricing details
            ReservationDetail.getReservationObjectsByReservationID(result.data.ReservationID).then(res => {
                this.setState({
                    reservation: res.data,
                    shortReservationID: res.data.Reservation.ShortReservationID
                });

                //get the listing details to add the title to the invoice
                ListingDetail.getListingSummary(res.data.Reservation.ListingID).then(listing => {
                    this.setState({ listingTitle: listing.data.Title });
                });

                PricingDetail.formatPrice(+res.data.Reservation.GuestGST).then(value => {
                    this.setState({
                        guestGST: value
                    });
                })

                PricingDetail.formatPrice(+res.data.Reservation.HostGST).then(value => {
                    this.setState({
                        hostGST: value
                    });
                })

                var dailyRate = [];
                res.data.DailyRates.map((daily, index) => {
                    dailyRate = [...dailyRate, {
                        date: new Date(daily.Date),
                        price: daily.Rate
                    }];
                });

                PricingDetail.buildDailyRateAccordion(dailyRate, res.data.Reservation.AmountSaved).then(res => {
                    if (result) {
                        this.setState({
                            NightlyTotal: res[0],
                            Nights: res[2]
                        });
                        this.refs.dailyratesdetail.innerHTML = res[1];
                    }
                })

                PricingDetail.buildPricingTable(
                    //this.state.reservation.DailyRates,
                    null, //res.data.TaxesInc,
                    res.data.TaxesEx,
                    res.data.TaxesCond,
                    null, //res.data.TaxesArriveEx,
                    null, //res.data.TaxesArriveCond,
                    null, //res.data.FeesInc,
                    res.data.FeesEx,
                    res.data.FeesCond,
                    null, //res.data.FeesArriveEx,
                    null, //res.data.FeesArriveCond,
                    res.data.Reservation.TotalFees,
                    res.data.Reservation.Deposit,
                    res.data.Reservation.SecurityDeposit,
                    (+res.data.Reservation.BalanceRemaining > 1 ? false : true),
                    null,
                    null,
                    res.data.Reservation.TotalGST
                ).then(table => {

                    if (table) {
                        this.refs.pricingdetail.innerHTML = table;
                    }

                });

                PricingDetail.getHostFees(
                    res.data.Reservation.TotalFees,
                    res.data.Reservation.SecurityDeposit,
                    res.data.Reservation.ServiceFee
                ).then(result => {
                    this.setState({ hostFees: result });
                });

            })

            this.setState({ showLoading: 'none' });

        })
    }

    render() {

        return (

            <div>
                <div class="loading-overlay" style={{ display: this.state.showLoading }}>
                    <div class="loading-vertical">
                        <Grid
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={0}
                        />
                    </div>
                </div>

                <div style={{ paddingTop: "80px" }}>
                    <Row >
                        <Col className="d-none d-md-block" md="3" lg="2" xl="1">
                            <SideNav
                                loggedIn={this.props.loggedIn}
                                loginType={this.props.loginType}
                                email={this.props.email}
                                activeTab='invoices'
                            />
                        </Col>
                        <Col md="9" lg="10" xl="11" style={{ paddingRight: "0px" }}>
                            <Row style={{ marginLeft: "0px" }}>
                                <div style={{ width: '100%' }}>
                                    <h1>Invoice</h1>
                                    <hr />
                                </div>
                            </Row>

                            <Row style={{ marginLeft: "0px" }}>
                                <Col md="9" lg="10" xl="10" style={{ paddingRight: "0px" }}>
                                    <Card id="printArea">
                                        <Card.Title>
                                            <span className={this.formatStatus(this.state.invoice.Status)}>{this.state.invoice.Status}</span>
                                        </Card.Title>
                                        <Card.Body>
                                            <Row>
                                                <div className="invoice-header">
                                                    <div className="block-left">
                                                        <img
                                                            alt=""
                                                            src="/logo.png"
                                                            width="150"
                                                            height="150"
                                                            className="d-inline-block align-top"
                                                        />
                                                        <br />
                                                    </div>
                                                    <div className="block-right">
                                                        <h4>{this.state.listingTitle}</h4>
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="invoice-header">
                                                    <div className="block-left">
                                                        <h4>Aussie Holiday Stays</h4>
                                                        9 Cavella Court <br />
                                                        Willetton <br />
                                                        WA 6155 <br />
                                                        ACN: 655 350 333 <br />
                                                    </div>
                                                    <div className="block-right">
                                                        <b>Reservation: </b> {this.state.shortReservationID}<br />
                                                        <b>Invoice: </b> {this.state.invoice.InvoiceID}<br />
                                                        <b>Date: </b> {this.formatDate(this.state.invoice.CreatedDate)}<br />
                                                    </div>
                                                </div>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <div>
                                                    <h5>To:</h5>
                                                    {this.state.customer ? this.state.customer.Firstname : ""} {this.state.customer ? this.state.customer.Lastname : ""}<br />
                                                    {this.state.customer ? this.state.customer.Email : ""}
                                                </div>

                                                <div>
                                                    <h5>Details:</h5>

                                                    <div style={{ fontStyle: 'italic' }} ref="dailyratesdetail"></div>
                                                    <div style={{ width: '100%' }}><div style={{ width: '50%', float: 'left' }}><b>Total Nights: {this.state.Nights}</b></div><div style={{ width: '50%', textAlign: 'right', float: 'left' }}><b>{this.state.NightlyTotal}</b></div></div>
                                                    <br /><br />
                                                    <div ref="pricingdetail"></div>
                                                </div>

                                                <hr />
                                                <div style={{ display: this.state.isCustomer ? "inline" : "none" }}>
                                                    <h5>Additional Information:</h5>
                                                    <div class="payment-list-price-detail">
                                                        <ul>
                                                            <li class="payment-due">GST Claimable<span>{this.state.guestGST}</span></li>
                                                        </ul>
                                                    </div>
                                                    Thank you for supporting a local WA company and booking your holiday with Aussie Holiday Stays. We hope you enjoyed your stay and welcome any feedback you may have about this invoice or our site.
                                                </div>
                                                <div style={{ display: this.state.isCustomer ? "none" : "inline" }}>
                                                    <h5>Additional Information:</h5>
                                                    <div class="payment-list-price-detail">
                                                        <ul>
                                                            <li class="payment-due">You Receive<span>{this.state.hostFees}</span></li>
                                                            <li class="payment-due">GST Claimable<span>{this.state.hostGST}</span></li>
                                                        </ul>
                                                    </div>
                                                    Thank you for supporting a local WA company and listing your property with us. We welcome any feedback you may have about this invoice or our site.
                                                </div>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    <Row style={{ marginLeft: "0px" }}>
                                        <Col>
                                            <Button className="action-button" variant="secondary" onClick={() => this.printInvoice()} style={{ width: '100%' }}>Print</Button>
                                        </Col>
                                        <Col>
                                            <Link to={{
                                                pathname: "/reservationdetail",
                                                state: {
                                                    reservation: this.state.reservation,
                                                    email: this.state.customer ? this.state.customer.Email : ""
                                                }
                                            }}>
                                                <Button className="action-button" variant="success" style={{ width: '100%' }}>View Reservation</Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="d-none d-md-block" md="3" lg="2" xl="2" >
                                    <div style={{ width: '100%' }}>
                                        <Button className="action-button" variant="secondary" onClick={() => this.printInvoice()} style={{ width: '100%' }}>Print</Button>
                                        <Link to={{
                                            pathname: "/reservationdetail",
                                            state: {
                                                reservation: this.state.reservation,
                                                email: this.state.customer ? this.state.customer.Email : ""
                                            }
                                        }}>
                                            <Button className="action-button" variant="success" style={{ width: '100%' }}>View Reservation</Button>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

            </div>

        )
    }
}

export default withRouter(Invoice)