import CreditCardFeeService from "../services/CreditCardFeeService";

const CCFee = {
    async getCCFees() {
        const result = await CreditCardFeeService.getCCFees();

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },
   
    async createCCFee(scheme, amount, domestic) {
        const result = await CreditCardFeeService.createCCFee(scheme, amount, domestic);

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },

    async updateCCFee(uuid, scheme, amount, domestic) {
        const result = await CreditCardFeeService.updateCCFee(uuid, scheme, amount, domestic);

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },

    async deleteCCFee(uuid) {

        const result = await CreditCardFeeService.deleteCCFee(uuid);

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },
}

export default CCFee