import InvoiceService from "../services/InvoiceService";

/**
 * global functions to act on invoice api actions
 */
const Invoice = {

    async getInvoiceByID(id) {
        const result = await InvoiceService.getInvoiceByID(id);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async getInvoices() {
        const result = await InvoiceService.getInvoices();

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async createInvoice(reservationID, paymentFor, amount) {
        const invoice = new FormData();
        invoice.append("ReservationID", reservationID);
        invoice.append("PaymentFor", paymentFor);
        invoice.append("Status", "Pending");
        invoice.append("Amount", amount);

        // send it to the invoice service
        const result = await InvoiceService.createInvoice(invoice);

        if (result.data.length !== 0) {
            return result;
        }

        return false;

    },

    async deleteInvoice(id) {
        const result = await InvoiceService.deleteInvoice(id);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async getInvoiceByUserID(id) {
        const result = await InvoiceService.getInvoiceByUserID(id);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async getInvoiceByListingID(id) {
        const result = await InvoiceService.getInvoiceByListingID(id);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async getInvoicesForAllListingIDsForUserID(id) {
        const result = await InvoiceService.getInvoicesForAllListingIDsForUserID(id);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    }

    /*async updateInvoiceByID(id, status) {
        const state = {
            InvoiceID: id,
            Status: status
        }
        const result = await InvoiceService.updateInvoiceByID(state);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },*/
}

export default Invoice