import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Alert } from 'react-bootstrap';

import { Input, Dropdown, RadioGroup, Radio, Textarea } from '@mobiscroll/react';

function TermsAndConditions({ isValid, listingData, depositPercentage, securityDepositPercentage }) {
    const createCheckInAfterItems = (key) => {
        let items = [];
        items.push(<option selected>Select</option>);
        for (let hour = 8; hour < 12; hour++) {
            for (let minutes = 0; minutes < 6; minutes += 3) {
                items.push(<option value={hour + ":" + minutes + "0 AM"}>{hour}:{minutes}0 AM</option>);
            }
        }
        items.push(<option value={"12:00 PM"}>12:00 PM</option>);
        items.push(<option value={"12:30 PM"}>12:30 PM</option>);
        for (let hour = 1; hour < 10; hour++) {
            for (let minutes = 0; minutes < 6; minutes += 3) {
                items.push(<option value={hour + ":" + minutes + "0 PM"}>{hour}:{minutes}0 PM</option>);
            }
        }
        items.pop();
        return items;
    }

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState({});
    const [pageValid, setPageValid] = useState('none');

    const [loadData, setLoadData] = useState(true);

    const [smoking, setSmoking] = useState();
    const [pets, setPets] = useState();
    const [party, setParty] = useState();
    const [children, setChildren] = useState();

    const onTextChange = (event) => {

        let newinput = input;

        newinput[event.target.name] = event.target.value;
        setInput(newinput)

        if (event.target.value) {
            //remoev the errors fom array state
            let newerrors = {};
            let newshowErrors = {};

            //manual check pricingDeposit
            if (event.target.name == "tacDeposit") {
                var value = event.target.value.replace('.', '');
                event.target.value = value;

                if (value < 20 || value > 100) {
                    newerrors["tacDeposit"] = "Please enter a whole number between 20 and 100";
                    newshowErrors["tacDeposit"] = true;
                }
            }

            Object.entries(errors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(showErrors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newshowErrors[error[0]] = error[1];
                }
            })

            setErrors(newerrors);
            setShowErrors(newshowErrors);

            setPageValid('none');
        }
    }

    const checkArriveDepart = () => {

        let isValid = true;
        //get both the arrive and depart
        var checkIn = document.getElementById('checkInAfter').value;
        var checkOut = document.getElementById('checkOutBefore').value;

        //check both have value before processing
        if (checkIn != "Select" && checkOut != "Select") {

            //split the time into time plus AM/PM
            var splitobjectcheckIn = checkIn.split(" ");
            var splitTimecheckIn = splitobjectcheckIn[0].split(":");

            var splitobjectcheckOut = checkOut.split(" ");
            var splitTimecheckOut = splitobjectcheckOut[0].split(":");

            //if both are either AM or PM
            if (splitobjectcheckIn[1] == splitobjectcheckOut[1]) {
                //if Checkin is before checkout then error
                if (+splitTimecheckIn[0] < +splitTimecheckOut[0]) {
                    isValid = false;
                } else if (+splitTimecheckIn[0] == +splitTimecheckOut[0] && +splitTimecheckIn[1] <= +splitTimecheckOut[1]) { //if the hour is the same but minutes then error
                    //alert('issue');
                    isValid = false;
                }

            } else if (splitobjectcheckOut[1] == "PM") { //this would be an error as checkin is AM so error
                //alert('issue');
                isValid = false;
            }
        }

        return isValid;
    }

    const onCheckInOutChange = (event) => {
        if (checkArriveDepart()) { //if is valid then clear errors
            let newerrors = {};
            let newshowErrors = {};

            Object.entries(errors).map((error, index) => {
                if (error[0] !== "checkOutBefore" && error[0] !== "checkInAfter") {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(showErrors).map((error, index) => {
                if (error[0] !== "checkOutBefore" && error[0] !== "checkInAfter") {
                    newshowErrors[error[0]] = error[1];
                }
            })

            setErrors(newerrors);
            setShowErrors(newshowErrors);
        }

    }

    const onTextSet = (event) => {
        let newinput = input;

        newinput[event.name] = event.value;

        setInput(newinput)

        if (event.value) {
            //remoev the errors fom array state
            let newerrors = {};
            let newshowErrors = {};

            Object.entries(errors).map((error, index) => {
                if (error[0] !== event.name) {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(showErrors).map((error, index) => {
                if (error[0] !== event.name) {
                    newshowErrors[error[0]] = error[1];
                }
            })

            setErrors(newerrors);
            setShowErrors(newshowErrors);
            setPageValid('none');
        }
    }

    const getvalid = () => {

        //let newinput = input;
        let newerrors = {};
        let newshowErrors = {};
        let isValid = true;

        if (!input["minimumNotice"]) {
            isValid = false;
            newerrors["minimumNotice"] = "Please enter minimum notice period for listing";
            newshowErrors["minimumNotice"] = true;
        }

        if (!input["mincancellation"]) {
            isValid = false;
            newerrors["mincancellation"] = "Please enter final payment due for listing";
            newshowErrors["mincancellation"] = true;
        } else {
            //check the min days > 7
            var days = document.getElementById('mincancellation').value;
            if (days < 7) {
                isValid = false;
                newerrors["mincancellation"] = "Please enter a value above 7 days";
                newshowErrors["mincancellation"] = true;
            }
        }

        if (!input["tacDeposit"] || document.getElementById("tacDeposit").value < 20 || document.getElementById("tacDeposit").value > 100) {
            isValid = false;
            newerrors["tacDeposit"] = "Please enter a whole number between 20 and 100";
            newshowErrors["tacDeposit"] = true;
        }

        //check the min days < 90
        var maxbooking = document.getElementById('maxbooking').value;
        if (maxbooking > 90) {
            isValid = false;
            newerrors["maxbooking"] = "Please enter a value below 90 days";
            newshowErrors["maxbooking"] = true;
        }


        if (!input["maximumWindow"]) {
            isValid = false;
            newerrors["maximumWindow"] = "Please enter maximum booking window for listing";
            newshowErrors["maximumWindow"] = true;
        }

        //check the checkin and checkout time
        if (!checkArriveDepart()) {
            isValid = false;
            newerrors["checkInAfter"] = "Please enter time later than Check-out Time";
            newshowErrors["checkInAfter"] = true;

            newerrors["checkOutBefore"] = "Please enter time earlier than the Check-in Time";
            newshowErrors["checkOutBefore"] = true;
        }

        setErrors(newerrors);
        setShowErrors(newshowErrors);
        if (isValid) {
            setPageValid('none');
        } else {
            setPageValid('block');
        }

        return isValid;
    }

    useEffect(() => {
        isValid.current = getvalid;

        if (loadData && listingData) {

            document.getElementById('mincancellation').value = listingData.Cancellation;
            document.getElementById('minimumNotice').value = listingData.MinimumNotice;
            document.getElementById('maximumWindow').value = listingData.MaxBookingWindows;
            document.getElementById('minbooking').value = listingData.MinimumBooking;
            document.getElementById('maxbooking').value = listingData.MaximumBooking;
            document.getElementById('checkInAfter').value = listingData.CheckInAfter;
            document.getElementById('checkOutBefore').value = listingData.CheckOutBefore;
            document.getElementById('registrationNumber').value = listingData.RegistrationNumber;
            document.getElementById('optional').value = listingData.Optional;

            document.getElementById('tacDeposit').value = depositPercentage;
            document.getElementById('tacSecurityDeposit').value = securityDepositPercentage;

            if (listingData.Smoking == "Yes") {
                setSmoking(true);
                //var doc = document.getElementById('smokingyes').parentElement;
                //for (var i = 0; i < doc.childNodes.length; i++) {
                //    if (doc.childNodes[i].className.includes("mbsc-radio-box-left")) {
                //        doc.childNodes[i].className += " mbsc-checked";
                //        break;
                //    }
                //}
            } else {
                setSmoking(false);
            }

            if (listingData.Pets == "Yes") {
                setPets(true);
            } else {
                setPets(false);
            }

            if (listingData.Party == "Yes") {
                setParty(true);
            } else {
                setParty(false);
            }

            if (listingData.Children == "Yes") {
                setChildren(true);
            } else {
                setChildren(false);
            }

            onTextSet(document.getElementById('minimumNotice'));
            onTextSet(document.getElementById('maximumWindow'));
            onTextSet(document.getElementById('mincancellation'));
            onTextSet(document.getElementById('tacDeposit'));

            getvalid();
            setLoadData(false);
        }
    }, [])

    return (
        /*<div style={{ backgroundColor:'#f7f8f9' }}>*/
        <Card style={{ margin: "10px" }}>
            <Card.Body>
                <Row>
                    <Col sm={12} md={6}>
                        <h5>Cancellation Policy</h5>
                        A non refundable deposit is required for bookings before the final payment period. Monies are provided to you once funds are cleared. You can decide when final payment is required below.
                    </Col>
                    <Col sm={12} md={6}>
                        <h5>Booking minimum notice period*</h5>
                        <Dropdown className="mbsc-remove-margins" id="minimumNotice" name="minimumNotice" error={showErrors.minimumNotice} errorMessage={errors.minimumNotice} onChange={onTextChange} >
                            <option value="" selected>Select Booking Period</option>
                            <option value="0">Same Day</option>
                            <option value="1">1 Day</option>
                            <option value="2">2 Days</option>
                            <option value="3">3 Days</option>
                            <option value="7">7 Days</option>
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        <h5>Final Payment Due (days before stay)*</h5>
                        <Input className="mbsc-remove-margins" min="7" type="number" id="mincancellation" name="mincancellation" placeholder="Enter minimum number of days allowed for cancellation, between 7 and 60" errorMessage={errors.mincancellation} error={showErrors.mincancellation} onChange={onTextChange} />
                    </Col>
                    <Col sm={12} md={6}>
                        <h5>Maximum booking window*</h5>
                        <Dropdown className="mbsc-remove-margins" id="maximumWindow" name="maximumWindow" error={showErrors.maximumWindow} errorMessage={errors.maximumWindow} onChange={onTextChange} >
                            <option value="" selected>Select Maximum Window</option>
                            <option value="3">3 Months Into the Future</option>
                            <option value="6">6 Months Into the Future</option>
                            <option value="9">9 Months Into the Future</option>
                            <option value="12">12 Months Into the Future</option>
                            <option value="24">24 Months Into the Future</option>
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        <h5>Minimum days of a booking</h5>
                        <Input className="mbsc-remove-margins" min="0" type="number" id="minbooking" name="minbooking" placeholder="Enter The Minimum Days Of A Booking" />
                    </Col>
                    <Col sm={12} md={6}>
                        <h5>Maximum days of a booking</h5>
                        <Input className="mbsc-remove-margins" min="1" max="90" type="number" id="maxbooking" name="maxbooking" error={showErrors.maxbooking} errorMessage={errors.maxbooking} onChange={onTextChange} placeholder="Enter The Maximum Days Of A Booking" />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} md={6}>
                        <h5>Deposit Required</h5>
                        <Input className="mbsc-remove-margins" placeholder="Enter the Percentage for Deposit - Whole number only" min="20" max="100" type="number" id="tacDeposit" name="tacDeposit" errorMessage={errors.tacDeposit} error={showErrors.tacDeposit} onChange={onTextChange} />
                        <small>*Default value is 20%</small>
                    </Col>
                    <Col sm={6} md={6}>
                        <h5>Bond</h5>
                        <Input className="mbsc-remove-margins" placeholder="Enter the value of the security deposit" type="number" id="tacSecurityDeposit" name="tacSecurityDeposit" />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        <h5>Check-in After</h5>
                        <Dropdown className="mbsc-remove-margins" name="checkInAfter" id="checkInAfter" error={showErrors.checkInAfter} errorMessage={errors.checkInAfter} onChange={onCheckInOutChange} >
                            {createCheckInAfterItems("checkInAfter")}
                        </Dropdown>
                    </Col>
                    <Col sm={12} md={6}>
                        <h5>Check-out Before</h5>
                        <Dropdown className="mbsc-remove-margins" name="checkOutBefore" id="checkOutBefore" error={showErrors.checkOutBefore} errorMessage={errors.checkOutBefore} onChange={onCheckInOutChange} >
                            {createCheckInAfterItems("checkOutBefore")}
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4} md={4}>
                        <h5>Smoking allowed?</h5>
                    </Col>
                    <Col sm={4} md={4}>
                        <RadioGroup name="smokinggroup">
                            <Radio label="Yes" defaultChecked={smoking} value="true" id="smokingyes" />
                        </RadioGroup>
                    </Col>
                    <Col sm={4} md={4}>
                        <RadioGroup name="smokinggroup">
                            <Radio label="No" defaultChecked={!smoking} value="false" id="smokingno" />
                        </RadioGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4} md={4}>
                        <h5>Pets allowed?</h5>
                    </Col>
                    <Col sm={4} md={4}>
                        <RadioGroup name="petsgroup">
                            <Radio label="Yes" defaultChecked={pets} value="true" id="petsyes" />
                        </RadioGroup>
                    </Col>
                    <Col sm={4} md={4}>
                        <RadioGroup name="petsgroup">
                            <Radio label="No" defaultChecked={!pets} value="false" id="petsno" />
                        </RadioGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4} md={4}>
                        <h5>Party allowed?</h5>
                    </Col>
                    <Col sm={4} md={4}>
                        <RadioGroup name="partygroup">
                            <Radio label="Yes" defaultChecked={party} value="true" id="partyyes" />
                        </RadioGroup>
                    </Col>
                    <Col sm={4} md={4}>
                        <RadioGroup name="partygroup">
                            <Radio label="No" defaultChecked={!party} value="false" id="partyno" />
                        </RadioGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4} md={4}>
                        <h5>Children allowed?</h5>
                    </Col>
                    <Col sm={4} md={4}>
                        <RadioGroup name="childrengroup">
                            <Radio label="Yes" defaultChecked={children} value="true" id="childrenyes" />
                        </RadioGroup>
                    </Col>
                    <Col sm={4} md={4}>
                        <RadioGroup name="childrengroup">
                            <Radio label="No" defaultChecked={!children} value="false" id="childrenno" />
                        </RadioGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={12}>
                        <h5>Development Approval Number / Holiday Home Registration Number (Optional)</h5>
                        <Input className="mbsc-remove-margins" id="registrationNumber" name="registrationNumber" />
                        <small>NOTE: Aussie Holiday Stays reserves the right to use this number for verification purposes with your relevant local or state government.</small>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={12}>
                        <h5>Additional rules information (Optional)</h5>
                        <Textarea style={{height: "120px", overflow: 'auto'}}  className="mbsc-remove-margins" id="optional" name="optional"  />
                    </Col>
                </Row>
                
            </Card.Body>
            <div style={{ display: pageValid, width: '100%' }}>
                <Alert variant="danger" >
                    Unfortunately there are errors on the page!
                </Alert>
            </div>
        </Card>
    )
}


export default TermsAndConditions