import FeatureService from "../services/FeatureService";

const Feature = {
    async getFeatures() {
        const result = await FeatureService.getFeatures();

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },

    async getFeaturesByCategory(categoryID) {
        const result = await FeatureService.getFeaturesByCategory(categoryID);

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },    
}

export default Feature