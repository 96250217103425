import PricingService from "../services/PricingService";
import ListingService from "../services/ListingService";
import PMSService from "../services/PMSService";
import CCFeeDetail from '../services/CreditCardFeeService';

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const Pricing = {
    //This function will return only nightly pricing for a range to build the calendar on a listing
    async getPricingForCalendar(listingID, start, end) {
        const result = await PricingService.getPricingForCalendar(listingID, start, end);
        return result;
    },

    async getPricingForBooking(listingID, arrive, depart, guests, pets, selectedFees, selectedTaxes, gst, promo) {
        //how many days until checkin
        const today = new Date();
        const oneDay = 1000 * 60 * 60 * 24;
        const diffInTime = arrive.getTime() - today.getTime();
        const diffInDays = (Math.round(diffInTime / oneDay) + 1);

        var start = (('0' + (arrive.getMonth() + 1)).slice(-2) + '/' + ('0' + arrive.getDate()).slice(-2) + '/' + arrive.getFullYear()).toString();
        var end = (('0' + (depart.getMonth() + 1)).slice(-2) + '/' + ('0' + depart.getDate()).slice(-2) + '/' + depart.getFullYear()).toString();

        var daysStay = Math.round((depart - arrive) / (1000 * 60 * 60 * 24));

        //get the standard pricing
        const listing = await ListingService.getListingById(listingID);

        //console.log(listing);

        const nightly = listing.data.Pricing.Nightly;
        var weekend = listing.data.Pricing.Weekend;

        if (!weekend) {
            weekend = nightly;
        }

        const additionalGuestCost = (listing.data.Pricing.AdditionalGuestCost != "" ? listing.data.Pricing.AdditionalGuestCost : 0);
        const stdGuests = listing.data.Pricing.StandardNumberOfGuests;
        var additionalGuest = guests - stdGuests;

        if (additionalGuest < 0) {
            additionalGuest = 0;
        }

        const weekendDays = listing.data.Pricing.WeekendRate;
        const securityDeposit = listing.data.Pricing.SecurityDeposit;

        //get the custom rates
        const periods = await ListingService.getListingPricingPeriods(listingID, start, end);
        var periodArray = [];
        var periodMinDays = 0;
        if (periods && typeof (periods.data) != 'string') {
            periods.data.map(function (item, i) {
                var date = item.Date.toString().split('T');
                periodArray = [...periodArray, date[0]];

                if (+item.MinimumDays > +periodMinDays) {
                    periodMinDays = item.MinimumDays;
                }

            });
        }

        //check min days against custom periods minimum
        if (+daysStay < +periodMinDays) {
            return "Minimum days during this period is: " + periodMinDays;
        }

        //get the taxes
        const taxes = await ListingService.getListingPricingTaxes(listingID);

        //get the fees
        const fees = await ListingService.getListingPricingFees(listingID);

        //loop through days to build daily rates
        var dailyRates = [];
        var totalNightly = 0;
        var totalDays = 0;
        var totalPrice = 0;

        for (let i = arrive; i < depart; (i.setDate(i.getDate() + 1))) {
            totalDays++;

            //Get the date formatted to compare to period
            var periodCompare = (i.getFullYear() + '-' + ('0' + (i.getMonth() + 1)).slice(-2) + '-' + ('0' + i.getDate()).slice(-2)).toString()

            //Reset the nightly and weekend rates each loop
            var nightlyRate = nightly;
            var weekendRate = weekend;

            //Compare and change rates if exists
            if (periodArray.indexOf(periodCompare) > -1) {
                //check if the nightly and weekend exist before setting

                if (periods.data[periodArray.indexOf(periodCompare)].Nightly && periods.data[periodArray.indexOf(periodCompare)].Nightly != 0)
                    nightlyRate = periods.data[periodArray.indexOf(periodCompare)].Nightly;

                if (periods.data[periodArray.indexOf(periodCompare)].Weekend && periods.data[periodArray.indexOf(periodCompare)].Weekend != 0)
                    weekendRate = periods.data[periodArray.indexOf(periodCompare)].Weekend;

            }

            if (i.getDay() == 5 && weekendDays.includes('Friday')) {
                dailyRates = [...dailyRates, {
                    date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                    price: weekendRate
                }];
                totalNightly = (+totalNightly + +weekendRate);
            } else if (i.getDay() == 6 && weekendDays.includes('Saturday')) {
                dailyRates = [...dailyRates, {
                    date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                    price: weekendRate
                }];
                totalNightly = (+totalNightly + +weekendRate);
            } else if (i.getDay() == 0 && weekendDays.includes('Sunday')) {
                dailyRates = [...dailyRates, {
                    date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                    price: weekendRate
                }];
                totalNightly = (+totalNightly + +weekendRate);
            } else {
                dailyRates = [...dailyRates, {
                    date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                    price: nightlyRate
                }];
                totalNightly = (+totalNightly + +nightlyRate);
            }
        }

        totalPrice = +totalPrice + +totalNightly;

        var saved = 0;
        //apply 7 and 30 day discounts
        if (totalDays >= 30 && listing.data.Pricing.MonthlyRate != '' ) {
            var discount = (+listing.data.Pricing.MonthlyRate / 100);
            saved = (+totalNightly * +discount);
            totalPrice = +totalPrice - +saved;
        } else if (totalDays >= 7 && listing.data.Pricing.WeeklyRate != '' ) {
            var discount = (+listing.data.Pricing.WeeklyRate / 100);
            saved = (+totalNightly * +discount);
            totalPrice = +totalPrice - +saved;
        }        

        //add standard fees such as cleaning
        var incfeesArray = [];
        var exfeesArray = [];
        var condfeesArray = [];

        var exArrivefeesArray = [];
        var condArrivefeesArray = [];

        //loop through for fees
        if (fees && typeof (fees.data) != 'string')
            fees.data.map(function (fee, i) {
                //switch statement for type of tax to calculate the total
                var calcFee = 0;
                switch (fee.Frequency) {
                    case "Per Stay":
                        calcFee = fee.Amount;
                        break;
                    case "Per Night":
                        calcFee = (totalDays) * fee.Amount;
                        break;
                    case "Per Guest":
                        calcFee = guests * fee.Amount;
                        break;
                    case "Per Guest Per Night":
                        calcFee = (guests * totalDays) * fee.Amount;
                        break;
                    case "Per Guest Extra":
                        if (additionalGuest > 0) {
                            calcFee = (additionalGuest) * fee.Amount;
                        }
                        break;
                    case "Per Guest Per Night Extra":
                        if (additionalGuest > 0) {
                            calcFee = (additionalGuest * totalDays) * fee.Amount;
                        }
                        break;
                }
                //add fees to arrays
                if (calcFee > 0) {
                    if (fee.ChargeType == "Included") {
                        incfeesArray = [...incfeesArray, {
                            title: fee.Description,
                            price: calcFee
                        }]
                    } else if (fee.ChargeType == "Excluded") {
                        //check if on booking or arrival
                        if (fee.ChargedAt == "Booking") {
                            exfeesArray = [...exfeesArray, {
                                title: fee.Description,
                                price: calcFee
                            }]
                            totalPrice = +totalPrice + +calcFee;
                        } else if (fee.ChargedAt == "Arrival") {
                            exArrivefeesArray = [...exArrivefeesArray, {
                                title: fee.Description,
                                price: calcFee
                            }]
                        }
                    } else if (fee.ChargeType == "Conditional") {
                        if (fee.ChargedAt == "Booking") {
                            condfeesArray = [...condfeesArray, {
                                title: fee.Description,
                                price: calcFee
                            }]
                            //check if checked
                            if (selectedFees.length > 0 && selectedFees.includes(fee.Description)) {
                                totalPrice = +totalPrice + +calcFee;
                            }
                        } else if (fee.ChargedAt == "Arrival") {
                            condArrivefeesArray = [...condArrivefeesArray, {
                                title: fee.Description,
                                price: calcFee
                            }]
                        }
                    }
                }
            });

        //guest entered - the standard number of guests allowed
        if (+additionalGuest > 0 && +additionalGuestCost > 0) {
            var guestFee = +additionalGuestCost * +additionalGuest * +totalDays;

            exfeesArray = [...exfeesArray, {
                title: ("Additional Guest Fee x " + additionalGuest),
                price: +guestFee
            }]
            totalPrice = +totalPrice + +guestFee;
        }

        //process the promotion discounts
        var totalDiscount = 0;
        var tempDiscount = 0;

        var calcServiceFee = +totalPrice * (5 / 100);

        var totalFeeBeforePromo = +totalPrice + +calcServiceFee

        //need to add the service fee discount amount to the total if there is a discount to All or nightly
        if (promo) {
            if (promo.Discounts && promo.Discounts.length > 0) {
                promo.Discounts.map((discount, index) => {
                    tempDiscount = 0;

                    switch (discount.Name) {
                        case "All":
                            switch (promo.Type) {
                                case "percent":
                                    //totalDiscount += totalPrice * (promo.Amount / 100);
                                    tempDiscount = totalPrice * (promo.Amount / 100);
                                    //totalDiscount += tempDiscount;
                                    totalPrice = +totalPrice - +tempDiscount;
                                    break;
                                case "dollar":
                                    tempDiscount = promo.Amount;
                                    //totalDiscount += tempDiscount;
                                    totalPrice = +totalPrice - +tempDiscount;
                                    break;
                            }
                            break;
                        case "Nightly":
                            //console.log("into discount space")
                            switch (promo.Type) {
                                case "percent":
                                    //console.log("into discount space")
                                    //console.log(totalDiscount)
                                    //console.log(totalNightly)
                                    //console.log(promo.Amount)

                                    tempDiscount = totalNightly * (promo.Amount / 100);
                                    //totalDiscount += tempDiscount;
                                    totalPrice = +totalPrice - +tempDiscount;

                                    break;
                                case "dollar":

                                    tempDiscount = promo.Amount;
                                    //totalDiscount += tempDiscount;
                                    totalPrice = +totalPrice - +tempDiscount;

                                    //totalDiscount += promo.Amount;
                                    break;
                            }
                            break;
                    }

                })
            } else {
                //console.log("into the else")
                //assume take off total
                if (promo.Type == "percent") {
                    tempDiscount = totalPrice * (promo.Amount / 100);
                    //totalDiscount += tempDiscount;
                    totalPrice = +totalPrice - +tempDiscount;
                }
                if (promo.Type == "dollar") {
                    tempDiscount = promo.Amount;
                    //totalDiscount += tempDiscount;
                    totalPrice = +totalPrice - +tempDiscount;
                }
            }
        }

        //discount needs to come off the Total Fees and Deposit

        //Total price including all fees for service fee
        //Taxes to be calculated post all fees
        //Get the security percentage from the WAHS DB

        var tempServiceFee = +totalPrice * (5 / 100);

        if (calcServiceFee > tempServiceFee) {
            //totalDiscount += (calcServiceFee - tempServiceFee)
            calcServiceFee = tempServiceFee;
        } 

        //var calcServiceFee = +totalPrice * (5 / 100);

        

        //check if promo has a AHS fee discount
        if (promo) {
            if (promo.Discounts && promo.Discounts.length > 0) {
                promo.Discounts.map((discount, index) => {
                    tempDiscount = 0;
                    switch (discount.Name) {
                        case "AHS Fee":
                            switch (promo.Type) {
                                case "percent":
                                    tempDiscount = calcServiceFee * (promo.Amount / 100);
                                    calcServiceFee = +calcServiceFee - +tempDiscount;
                                    break;
                                case "dollar":
                                    tempDiscount = promo.Amount;
                                    if (calcServiceFee < tempDiscount) {
                                        calcServiceFee = 0;
                                    } else {
                                        calcServiceFee = +calcServiceFee - +tempDiscount;
                                    }
                                    
                                    break;
                            }
                            break;
                    }
                })
            }
        }

        //calculate the AHS GST component
        var ahsGST = (+totalPrice * (10 / 100)) * 0.1;

        exfeesArray = [...exfeesArray, {
            title: "Service Fee",
            price: calcServiceFee
        }]
        totalPrice = +totalPrice + +calcServiceFee;

        //calculate full gst if required
        var totalGST = ahsGST;
        //halve the ahsGST for host and guest - this is to allow the host paying a service fee and the guest
        var guestGST = +ahsGST / 2;
        var hostGST = +ahsGST / 2;

        if (gst) {
            totalGST = (+totalPrice * (10 / 100));
            //set the host GST based on the total minus the Guest
            hostGST = +totalGST - +guestGST;
        } 

        //loop through for taxes
        var totalFeeBeforeTaxes = totalPrice;
        //var taxesArray = [];
        var inctaxesArray = [];
        var extaxesArray = [];
        var condtaxesArray = [];

        var exArrivetaxesArray = [];
        var condArrivetaxesArray = [];

        if (taxes && typeof (taxes.data) != 'string')
            taxes.data.map(function (tax, i) {
                //switch statement for type of tax to calculate the total
                switch (tax.Frequency) {
                    case "Per Stay":
                        var calcPrice = (totalFeeBeforeTaxes) * (tax.Percentage / 100);
                        break;
                    case "Per Night":
                        var calcPrice = (totalFeeBeforeTaxes * totalDays) * (tax.Percentage / 100);
                        break;
                    case "Per Guest":
                        var calcPrice = (totalFeeBeforeTaxes * guests) * (tax.Percentage / 100);
                        break;
                    case "Per Guest Per Night":
                        var calcPrice = (totalFeeBeforeTaxes * guests * totalDays) * (tax.Percentage / 100);
                        break;
                    case "Per Guest Extra":
                        if (additionalGuest > 0) {
                            var calcPrice = (totalFeeBeforeTaxes * additionalGuest) * (tax.Percentage / 100);
                        }
                        break;
                    case "Per Guest Per Night Extra":
                        if (additionalGuest > 0) {
                            var calcPrice = (totalFeeBeforeTaxes * additionalGuest * totalDays) * (tax.Percentage / 100);
                        }
                        break;
                }
                //add fees to arrays
                if (calcPrice > 0) {
                    if (tax.ChargeType == "Included") {
                        inctaxesArray = [...inctaxesArray, {
                            title: tax.Description,
                            price: calcPrice
                        }]
                    } else if (tax.ChargeType == "Excluded") {
                        if (tax.ChargedAt == "Booking") {
                            extaxesArray = [...extaxesArray, {
                                title: tax.Description,
                                price: calcPrice
                            }]
                            totalPrice = +totalPrice + +calcPrice;

                        } else if (tax.ChargedAt == "Arrival") {
                            exArrivetaxesArray = [...exArrivetaxesArray, {
                                title: tax.Description,
                                price: calcPrice
                            }]
                        }
                    } else if (tax.ChargeType == "Conditional") {
                        if (tax.ChargedAt == "Booking") {
                            condtaxesArray = [...condtaxesArray, {
                                title: tax.Description,
                                price: calcPrice
                            }]
                            //check if checked
                            if (selectedTaxes.length > 0 && selectedTaxes.includes(tax.Description)) {
                                totalPrice = +totalPrice + +calcPrice;
                                //need to add the discount amount here

                            }
                        } else if (tax.ChargedAt == "Arrival") {
                            condArrivetaxesArray = [...condArrivetaxesArray, {
                                title: tax.Description,
                                price: calcPrice
                            }]
                        }
                    }
                }

            });
        
        var totalPriceBeforePromo = +totalFeeBeforePromo;

        //if there is a difference in before and after calc the taxes for the difference in savings
        if (totalFeeBeforeTaxes < totalPrice && taxes && typeof (taxes.data) != 'string')
            taxes.data.map(function (tax, i) {
                //switch statement for type of tax to calculate the total
                switch (tax.Frequency) {
                    case "Per Stay":
                        var calcPrice = (totalFeeBeforePromo) * (tax.Percentage / 100);
                        break;
                    case "Per Night":
                        var calcPrice = (totalFeeBeforePromo * totalDays) * (tax.Percentage / 100);
                        break;
                    case "Per Guest":
                        var calcPrice = (totalFeeBeforePromo * guests) * (tax.Percentage / 100);
                        break;
                    case "Per Guest Per Night":
                        var calcPrice = (totalFeeBeforePromo * guests * totalDays) * (tax.Percentage / 100);
                        break;
                    case "Per Guest Extra":
                        if (additionalGuest > 0) {
                            var calcPrice = (totalFeeBeforePromo * additionalGuest) * (tax.Percentage / 100);
                        }
                        break;
                    case "Per Guest Per Night Extra":
                        if (additionalGuest > 0) {
                            var calcPrice = (totalFeeBeforePromo * additionalGuest * totalDays) * (tax.Percentage / 100);
                        }
                        break;
                }
                //add fees to arrays
                if (calcPrice > 0) {
                    if (tax.ChargeType == "Excluded") {
                        if (tax.ChargedAt == "Booking") {
                            totalPriceBeforePromo = +totalPriceBeforePromo + +calcPrice;
                        } 
                    } else if (tax.ChargeType == "Conditional") {
                        if (tax.ChargedAt == "Booking") {
                            //check if checked
                            if (selectedTaxes.length > 0 && selectedTaxes.includes(tax.Description)) {
                                totalPriceBeforePromo = +totalPriceBeforePromo + +calcPrice;
                            }
                        } 
                    }
                }

            });

        if (totalPrice < totalPriceBeforePromo) {
            totalDiscount = +totalPriceBeforePromo - +totalPrice;
        }

        //process PMS Fees
        var pmsFees = 0.00;
        const pmsFee = await PMSService.getPMSFees();

        if (pmsFee && pmsFee.data) {
            switch (listing.data.Owner.RemoteToken) {
                case "BEDS":
                    var pmsMulti = pmsFee.data.find((fees) => fees.Code == "BEDS");
                    if (pmsMulti && +pmsMulti.Percentage > 0) {
                        pmsFees = +totalPrice * (+pmsMulti.Percentage / 100);
                        totalPrice = +totalPrice + +pmsFees;
                    }
                    break;
                case "RMS":
                    var pmsMulti = pmsFee.data.find((fees) => fees.Code == "RMS");
                    if (pmsMulti && +pmsMulti.Percentage > 0) {
                        pmsFees = +totalPrice * (+pmsMulti.Percentage / 100);
                        totalPrice = +totalPrice + +pmsFees;
                    }
                    break;
                case "ESCAPIA":
                    var pmsMulti = pmsFee.data.find((fees) => fees.Code == "ESCAPIA");
                    if (pmsMulti && +pmsMulti.Percentage > 0) {
                        pmsFees = +totalPrice * (+pmsMulti.Percentage / 100);
                        totalPrice = +totalPrice + +pmsFees;
                    }
                    break;
            }
        }

        //calculate if deposit or full payment required based on cancellation period
        const cancellation = +listing.data.TermsAndConditions.Cancellation + 7;

        var deposit = 0;
        var depositPercentage = 20;
        //Booking Percent = 20%
        //check listing for a deposit 
        if (listing.data.Pricing.DepositPercentage > 0) {
            depositPercentage = listing.data.Pricing.DepositPercentage;
        }

        if (diffInDays > cancellation) {
            deposit = +totalPrice * (+depositPercentage / 100);
        }

        //add security
        totalPrice = +totalPrice + +securityDeposit;

        
        var result = [{
            DailyRates: dailyRates,
            IncFees: incfeesArray,
            ExFees: exfeesArray,
            CondFees: condfeesArray,
            ExArriveFees: exArrivefeesArray,
            CondArriveFees: condArrivefeesArray,
            IncTaxes: inctaxesArray,
            ExTaxes: extaxesArray,
            CondTaxes: condtaxesArray,
            ExArriveTaxes: exArrivetaxesArray,
            CondArriveTaxes: condArrivetaxesArray,
            Total: totalPrice,
            Deposit: deposit,
            ServiceFee: calcServiceFee,
            Saved: saved,
            AHSgst: ahsGST,
            TotalGST: totalGST,
            GuestGST: guestGST,
            HostGST: hostGST,
            PromoTotal: totalDiscount,
            PMSFees: pmsFees
        }];

        return result;
    },

    async buildDailyRateAccordion(dailyPrice, saved) {

        var totalDaily = 0;
        var pricing = '';
        var nights = 0;
        //build daily pricing
        if (dailyPrice) {

            pricing += '<div class="payment-list-price-detail"><ul>';


            dailyPrice.map(function (d) {
                pricing += '<li>' + d.date.getDate() + '/' + (d.date.getMonth() + 1) + '/' + d.date.getFullYear() + '<span>' + formatter.format(d.price) + '</span></li>';
                totalDaily = +totalDaily + +d.price;
                nights++;
            });

            //add in the saved amount
            if (saved != "" && saved != "0") {
                pricing += '<li style="color:Red">Long Stay Saved<span>' + formatter.format(saved) + '</span></li>';
                totalDaily = +totalDaily - +saved;
            }

            pricing += '</ul></div>';

        }

        //build out the nightly to match
        var total = '<div class="payment-list-price-detail"><ul>';
        total += '<li>Total Nights<span>' + formatter.format(totalDaily) + '</span></li>';
        total += '</ul></div>';

        var result = [formatter.format(totalDaily), pricing, nights];

        return result;

    },

    //As the pricing table can be using in many locations this will keep the same format
    async buildPricingTable(incTaxes, exTaxes, condTaxes, exArriveTaxes, condArriveTaxes, incFees, exFees, condFees, exArriveFees, condArriveFees, total, deposit, security, fullyPaid, selectedFees, selectedTaxes, totalGST, pmsFee) {

        let pricing = '';
        
        //build taxes and fees
        if (incFees && incFees.length > 0) {
            pricing += '<div class="payment-list-price-detail"><ul>';
            pricing += '<li class="payment-due">' + 'Fee Included' + '<span></span></li>';

            incFees.map(d => (
                pricing += '<li>' + (d.title ? d.title : d.Fee) + '<span>' + formatter.format((d.price ? d.price : d.Rate)) + '</span></li>'
            ));

            pricing += '</ul></div>';
        }

        if (exFees && exFees.length > 0) {
            pricing += '<div class="payment-list-price-detail"><ul>';
            pricing += '<li class="payment-due">' + 'Additional Fee' + '<span></span></li>';

            exFees.map(d => (
                //pricing += '<li>' + (d.title ? d.title : d.Fee) + '<span>' + (d.price > 0 ? formatter.format((d.price ? d.price : d.Rate)) : '$0.00') + '</span></li>'
                pricing += '<li>' + (d.title ? d.title : d.Fee) + '<span>' + this.priceFormat((d.price ? d.price : d.Rate)) + '</span></li>'
            ));

            pricing += '</ul></div>';
        }

        if (condFees && condFees.length > 0) {
            pricing += '<div class="payment-list-price-detail"><ul>';
            pricing += '<li class="payment-due">' + 'Optional Fees' + '<span></span></li>';

            //console.log("price fee: " + selectedFees);
            if (selectedFees) {
                condFees.map(d => (
                    //check if selected or not
                    pricing += '<li><input ' + (selectedFees.includes(d.title) ? "checked" : "") + ' name="fee" class="checkbox" type="checkbox" value="' + d.title + '" />' + (d.title ? d.title : d.Fee) + '<span>' + formatter.format((d.price ? d.price : d.Rate)) + '</span></li>'
                ));
            } else {
                condFees.map(d => (
                    //check if selected or not
                    pricing += '<li>' + (d.title ? d.title : d.Fee) + '<span>' + formatter.format((d.price ? d.price : d.Rate)) + '</span></li>'
                ));
            }

            pricing += '</ul></div>';
        }


        if (incTaxes && incTaxes.length > 0) {
            pricing += '<div class="payment-list-price-detail"><ul>';
            pricing += '<li class="payment-due">' + 'Taxes Included' + '<span></span></li>';

            incTaxes.map(d => (
                pricing += '<li>' + (d.title ? d.title : d.Tax) + '<span>' + formatter.format((d.price ? d.price : d.Rate)) + '</span></li>'
            ));

            pricing += '</ul></div>';
        }

        if (exTaxes && exTaxes.length > 0) {
            pricing += '<div class="payment-list-price-detail"><ul>';
            pricing += '<li class="payment-due">' + 'Additional Taxes' + '<span></span></li>';

            exTaxes.map(d => (
                pricing += '<li>' + (d.title ? d.title : d.Tax) + '<span>' + formatter.format((d.price ? d.price : d.Rate)) + '</span></li>'
            ));

            pricing += '</ul></div>';
        }

        if (condTaxes && condTaxes.length > 0) {
            pricing += '<div class="payment-list-price-detail"><ul>';
            pricing += '<li class="payment-due">' + 'Optional Taxes' + '<span></span></li>';

            condTaxes.map(d => (
                pricing += '<li><input ' + (selectedTaxes.includes(d.title) ? "checked" : "") + ' name="tax" class="checkbox" type="checkbox" value="' + d.title + '" />' + (d.title ? d.title : d.Tax) + '<span>' + formatter.format((d.price ? d.price : d.Rate)) + '</span></li>'
            ));

            pricing += '</ul></div>';
        }

        //security deposit 
        pricing += '<div class="payment-list-price-detail"><ul><li></li>';
        pricing += '<li>Security Bond<span>' + formatter.format(security) + '</span></li>'
        pricing += '</ul></div>';

        //PMS Fees - only add if there is fees
        if (pmsFee > 0) {
            pricing += '<div class="payment-list-price-detail"><ul><li></li>';
            pricing += '<li>PMS Fees<span>' + formatter.format(pmsFee) + '</span></li>'
            pricing += '</ul></div>';
        }

        pricing += '<div class="payment-list-price-detail">';

        //total and deposit due - need to work out the days until check in
        pricing += '<li></li>';
        if (deposit == 0 || deposit == total) {
            pricing += '<li class="payment-due">' + 'Total Fees Due' + '<span>' + formatter.format(total) + '</span></li>';

            if (totalGST && totalGST != 0) {
                pricing += '<li>' + 'GST <small>inc.</small>' + '<span>' + formatter.format(totalGST) + '</span></li>';
            }

        } else {

            pricing += '<li class="payment-due">' + 'Total Fees' + '<span>' + formatter.format(total) + '</span></li>';

            if (totalGST && totalGST != 0) {
                pricing += '<li>' + 'GST <small>inc.</small>' + '<span>' + formatter.format(totalGST) + '</span></li>';
            }

            //if balance is 0 (fully paid) do not show deposit
            if (!fullyPaid) {
                pricing += '<li></li>';
                pricing += '<li class="payment-due">' + 'Deposit Due' + '<span>' + formatter.format(deposit) + '</span></li>';
            }
        }

        pricing += '<li></li>';
        pricing += '</ul>';
        pricing += '</div>';

        //build out fee and taxes that are paid on arrival
        if (exArriveFees && exArriveFees.length > 0) {
            pricing += '<div class="payment-list-price-detail"><ul>';
            pricing += '<li class="payment-due">' + 'Additional Fee - on Arrival' + '<span></span></li>';

            exArriveFees.map(d => (
                pricing += '<li>' + (d.title ? d.title : d.Fee) + '<span>' + d.price > 0 ? this.priceFormat((d.price ? d.price : d.Rate)) : "$0.00" + '</span></li>'
            ));

            pricing += '</ul></div>';
        }

        if (condArriveFees && condArriveFees.length > 0) {
            pricing += '<div class="payment-list-price-detail"><ul>';
            pricing += '<li class="payment-due">' + 'Optional Fees - on Arrival' + '<span></span></li>';

            condArriveFees.map(d => (
                //check if selected or not
                pricing += '<li>' + (d.title ? d.title : d.Fee) + '<span>' + formatter.format((d.price ? d.price : d.Rate)) + '</span></li>'
            ));

            pricing += '</ul></div>';
        }

        if (exArriveTaxes && exArriveTaxes.length > 0) {
            pricing += '<div class="payment-list-price-detail"><ul>';
            pricing += '<li class="payment-due">' + 'Additional Taxes - on Arrival' + '<span></span></li>';

            exArriveTaxes.map(d => (
                pricing += '<li>' + (d.title ? d.title : d.Tax) + '<span>' + formatter.format((d.price ? d.price : d.Rate)) + '</span></li>'
            ));

            pricing += '</ul></div>';
        }

        if (condArriveTaxes && condArriveTaxes.length > 0) {
            pricing += '<div class="payment-list-price-detail"><ul>';
            pricing += '<li class="payment-due">' + 'Optional Taxes - on Arrival' + '<span></span></li>';

            condArriveTaxes.map(d => (
                pricing += '<li>' + (d.title ? d.title : d.Tax) + '<span>' + formatter.format((d.price ? d.price : d.Rate)) + '</span></li>'
            ));

            pricing += '</ul></div>';
        }

        return pricing;

    },

    async formatPrice(value) {
        return formatter.format(value)
    },

    priceFormat(value) {
        //console.log("value: " + value)
        if (value > 0) {
            return formatter.format(value)
        } else {
            return "$0.00"
        }

    },

    async getHostFees(totalFee, bond, serviceFee) {

        var pinPaymentFee = (+totalFee * 0.0175) + 0.60;
        var ahsFee = ((((+totalFee - +bond) - +serviceFee) * 0.05) + +serviceFee) - +pinPaymentFee

        return this.formatPrice(+totalFee - +ahsFee - +bond - +pinPaymentFee)
    },

    async buildCreditCardFeeTable() {
        const fees = await CCFeeDetail.getCCFees();

        var feestable = "<div>No Fees Charged</div>";
        //cycle through data
        if (fees && fees.data && fees.data.length > 0) {
            feestable = "<div>"
            //do domestic first - check if any exist
            var tempDomestic = fees.data.find((fees) => fees.Domestic == true);
            if (tempDomestic) {
                feestable += '<div class="payment-list-price-detail"><ul>';
                feestable += '<li class="payment-due">' + 'Domestic Cards:' + '<span></span></li>';
                fees.data.map(function (fee, index) {
                    if (fee.Domestic) {
                        feestable += '<li>' + fee.Scheme + '<span>' + fee.Amount + '%</span></li>';
                    }
                })
            }

            //do international 
            var tempInternational = fees.data.find((fees) => fees.Domestic == false);
            if (tempInternational) {
                feestable += '</ul></div><div class="payment-list-price-detail"><ul>';
                feestable += '<li class="payment-due">' + 'International Cards:' + '<span></span></li>';
                fees.data.map(function (fee, index) {
                    if (!fee.Domestic) {
                        feestable += '<li>' + fee.Scheme + '<span>' + fee.Amount + '%</span></li>';
                    }
                })
            }
            feestable += "</ul></div></div>"
        }

        return feestable;
    }

}

export default Pricing