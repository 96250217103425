import React, { useRef, useState, useEffect } from 'react';
import { Form, Alert } from 'react-bootstrap';

import "./../../styles/blog.css";

import { Input } from '@mobiscroll/react';

function Location({ isValid, blog, google }) {
    const editorRef = useRef(null);

    const mapStyles = {
        left: '0',
        top: '0',
        height: '400px',
        width: '100%',
        position: 'relative'
    };

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState({});
    const [pageValid, setPageValid] = useState('none');

    const [uluru, setUluru] = useState({ lat: -25.344, lng: 131.036 });
    const [center, setCenter] = useState({ lat: -25.344, lng: 131.036 });

    const [runAutocomplete, setRunAutocomplete] = useState(true);

    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    const onTextChange = (event) => {

        let newinput = input;

        newinput[event.target.name] = event.target.value;
        setInput(newinput)

        if (event.target.value) {
            //remove the errors fom array state
            let newerrors = {};
            let newshowErrors = {};

            Object.entries(errors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(showErrors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newshowErrors[error[0]] = error[1];
                }
            })

            setErrors(newerrors);
            setShowErrors(newshowErrors);

            setPageValid('none');
        }
    }

    const onTextSet = (name, value) => {
        let newinput = input;

        newinput[name] = value;
        setInput(newinput)

        if (value) {
            //remove the errors fom array state
            let newerrors = {};
            let newshowErrors = {};

            Object.entries(errors).map((error, index) => {
                if (error[0] !== name) {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(showErrors).map((error, index) => {
                if (error[0] !== name) {
                    newshowErrors[error[0]] = error[1];
                }
            })

            setErrors(newerrors);
            setShowErrors(newshowErrors);

            setPageValid('none');
        }
    }

    const getvalid = () => {

        //let newinput = input;
        let newerrors = {};
        let newshowErrors = {};
        let isValid = true;

        if (!input["blogCity"]) {
            isValid = false;
            newerrors["blogCity"] = "Please select a city from the list";
            newshowErrors["blogCity"] = true;
        }

        if (!document.getElementById('bloggeoLocation').value) {
            isValid = false;
            newerrors["blogCity"] = "Please select a city from the list";
            newshowErrors["blogCity"] = true;
        }

        if (!document.getElementById('blogplaceID').value) {
            isValid = false;
            newerrors["blogCity"] = "Please select a city from the list";
            newshowErrors["blogCity"] = true;
        }

        setErrors(newerrors);
        setShowErrors(newshowErrors);
        if (isValid) {
            setPageValid('none');
        } else {
            setPageValid('block');
        }

        return isValid;
    }

    window.googleCallBlog = () => {
        const google = window.google;
        let autocomplete;

        // The map, centered at Uluru
        const map = new google.maps.Map(document.getElementById("map"), {
            zoom: 8,
            center: uluru,
            maxZoom: 14
        });

        autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('blogCity'),
            {
                types: ['(cities)'],
                componentRestrictions: { 'country': ['AU'] },
                fields: ['place_id', 'geometry', 'name', 'address_components']
            });

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();

            if (!place.geometry) return;

            document.getElementById('blogCity').setAttribute('data-geo', place.geometry.location);

                   document.getElementById('bloggeoLocation').value = place.geometry.location;
                document.getElementById('blogplaceID').value = place.place_id;



            for (var i = 0; i < place.address_components.length; i++) {
                switch (String(place.address_components[i]['types'])) {
                    case "locality,political":
                        document.getElementById('blogCity').value = place.address_components[i]['short_name'];
                        break;
                    case "administrative_area_level_1,political":
                        document.getElementById('blogState').value = place.address_components[i]['short_name'];
                        break;
                    case "postal_code":
                        document.getElementById('blogPostCode').value = place.address_components[i]['short_name'];
                        break;
                    case "country,political":
                        document.getElementById('blogCountry').value = place.address_components[i]['short_name'];
                        break;
                };
            }

            onTextSet("blogCity", document.getElementById('blogCity').value);

            const map = new google.maps.Map(document.getElementById("map"), {
                zoom: 8,
                center: place.geometry.location,
                maxZoom: 14
            });
            // The marker, positioned at Uluru
            const marker = new google.maps.Marker({
                position: place.geometry.location,
                map: map,
            });

        });
    }

    const renderAutoComplete = () => {
        const script = document.createElement("script");

        script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCokK7P3SJHrW4MRbQjQTaJ7_VFkCkiBFc&libraries=places&callback=googleCallBlog";
        script.async = true;

        document.body.appendChild(script);
    };

    useEffect(() => {
        const google = window.google;
        if (runAutocomplete) {
            setRunAutocomplete(false);
            renderAutoComplete();
        }

        if (blog) {
            let newinput = input;
            

            if (blog.Location && blog.Location.City) {

                //console.log("location: " + JSON.stringify(blog.Location.City));

                newinput["blogCity"] = blog.Location.City;
                document.getElementById("blogCity").value = blog.Location.City;

                let location = { lat: blog.Location.Latitude, lng: blog.Location.Longitude };

                //const map = new google.maps.Map(document.getElementById("map"), {
                //    zoom: 8,
                //    center: location,
                //    maxZoom: 14
                //});
                //// The marker, positioned at Uluru
                //const marker = new google.maps.Marker({
                //    position: location,
                //    map: map,
                //});

                document.getElementById("bloggeoLocation").value = blog.Location.Location;
                document.getElementById("blogplaceID").value = blog.Location.PlaceID;

                document.getElementById('blogState').value = blog.Location.State;
                document.getElementById('blogPostCode').value = blog.Location.PostCode;
                document.getElementById('blogCountry').value = blog.Location.Country;

            }

            setInput(newinput)
        }

        isValid.current = getvalid;
    }, []);
    
    return (
        <div style={{ backgroundColor:'#f7f8f9' }}>
            <div className="row">
                <div className="col-md-12">
                    <h4>City*</h4>
                    {/*<Form.Control id="blogCity" required type="text" onClick={window["initAutocompleteBlog"]} placeholder="Enter the City" />*/}
                    {/*<Input className="mbsc-remove-margins" onClick={window["initAutocompleteBlog"]} placeholder="Enter the City" type="text" id="blogCity" name="blogCity" errorMessage={errors.blogCity} error={showErrors.blogCity} onChange={onTextChange} />*/}
                    <Input className="mbsc-remove-margins"  placeholder="Enter the City" type="text" id="blogCity" name="blogCity" errorMessage={errors.blogCity} error={showErrors.blogCity} onChange={onTextChange} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Form.Control id="bloggeoLocation" type="text" readonly hidden />

                    <Form.Control id="blogCity" type="text" readonly hidden />
                    <Form.Control id="blogState" type="text" readonly hidden />
                    <Form.Control id="blogPostCode" type="text" readonly hidden />
                    <Form.Control id="blogCountry" type="text" readonly hidden />
                </div>
                <div className="col-md-6">
                    <Form.Control id="blogplaceID" type="text" readonly hidden />
                    <Form.Control id="mapdefaultlat" type="text" readonly hidden />
                    <Form.Control id="mapdefaultlong" type="text" readonly hidden />
                </div>
            </div>
            <div className="row">
                <div id="map"></div>
            </div>
            <div style={{ display: pageValid, width: '100%' }}>
                <Alert variant="danger" >
                    Unfortunately there are errors on the page!
                </Alert>
            </div>
        </div>
        )
    }

export default Location