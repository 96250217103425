import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBed, FaUser, FaHotel, FaStar, FaRegHeart, FaHeart } from 'react-icons/fa';

import Favourites from './../functions/Favourites';
import Listing from './../functions/Listing';

import "./../styles/search.css";

function SearchTile (props) {

    const [fav, setFav] = useState(false);
    const [loadFav, setLoadFav] = useState(true);
    const [image, setImage] = useState(null);

    const switchFavourite = (e) => {
        e.preventDefault();

        if (props.favourites && props.favourites.length > 0 && props.favourites.some(fav => props.id === fav.ListingID)) {
            props.favourites.map((fav, index) => {
                if (fav.ListingID == props.id) {
                    Favourites.deleteFavourite(fav.UUID).then((result) => {
                        setFav(false);
                    })
                }
            })
            
        } else {
            Favourites.createFavourite(props.userID, props.id).then((result) => {
                setFav(true);
            })
        }
    }

    const checkFavourite = () => {
        if (props.favourites && props.favourites.length > 0) {
            setFav(props.favourites.some(fav => props.id === fav.ListingID));
        }
    }

    const onMouseOver = () => {
        //check if mouseover prop exists as home page will not have map
        if (props.mouseover) {
            props.mouseover(props.id);
        } 
    }

    useEffect(() => {
        if (loadFav) {
            checkFavourite();

            //load the primary image
            //setImage(props.image);
            Listing.GetListingPrimaryImageByID(props.id).then(result => {
                //console.log(result);

                if (result && typeof (result.data) != 'string') {
                    setImage(result.data.PrimaryImage);
                }
            })
            

            setLoadFav(false);
        }
    })

    return (
        <Link to={{
            pathname: (props.checkin ? ("/listing/" + props.id + "/" + (props.checkin ? props.checkin : "-") + "/" + (props.guests ? props.guests : "0") + "/" + (props.pets ? props.pets : "-")) : ("/listing/" + props.id)),
            state: {
                id: props.id,
                guests: props.guests,
                pets: props.pets,
                checkin: props.checkin
            }
        }}>
            <div className="search-item" style={{ backgroundColor: props.featured ? "#cccccc" : "white" }}>
                <div className="search-featured" style={{ display: props.featured ? "block" : "none" }}>
                    <FaStar />
                </div>
                <div className="search-favourite" style={{ display: props.showFavourite ? "block" : "none" }}>
                    <FaRegHeart style={{ color: "red", display: fav ? "none" : "block" }} onClick={switchFavourite} />
                    <FaHeart style={{ color: "red", display: fav ? "block" : "none" }} onClick={switchFavourite} />
                </div>
                <div className="search-price">
                    ${Math.round(props.price)}<span style={{fontSize: "15px"}}>/night</span>
                </div>

                <img onMouseOver={onMouseOver} className="search-img" alt={props.title + " - Listing Primary Image"} height="240px" src={image ? "data:image/png;base64," + image : "logo.png"} width="100%" style={{ objectFit: "cover" }}></img>

                <div className="search-title"><b>{props.title}</b></div> 
                <div className="row search-details">
                    <div className="col-sm-4 detail-padding" >
                        <FaBed style={{fontSize: "medium"}} />  Bedrooms: {props.bedroom}
                    </div>
                    <div className="col-sm-4 detail-padding" >
                        <FaUser />  Guests: {props.maxGuest ? props.maxGuest : props.guest}
                    </div>
                    <div className="col-sm-4 detail-padding" >
                        <FaHotel />  {props.accomodation}
                    </div>
                </div>
            </div>
        
        </Link>

        

    )
}

export default SearchTile