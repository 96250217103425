/*global Pin*/
import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Button, Col, Form, ListGroup, ListGroupItem, Modal, Row } from 'react-bootstrap';
/*import '@mobiscroll/react/dist/css/mobiscroll.min.css';*/
import { Input } from '@mobiscroll/react';
/*import Pin from 'https://cdn.pinpayments.com/pin.v2.js';*/

import ReactTooltip from "react-tooltip";

import Globals from '../functions/globals';
import User from "../functions/User";
import Pricing from "../functions/Pricing";
import CCFee from "../functions/CCFee";

export function withRouter(Children) {
    return (props) => {

        const match = { params: useParams() };
        const location = { location: useLocation() };

        return <Children {...props} match={match.params} location={location.location} />
    }
}

class CardEntry extends Component {

    constructor(props) {
        super(props);
        //creating ref
        //this.errors = React.createRef();
        //this.errorList = "something";
        //this.errorHeading = React.createRef();

        this.state = {
            errorList: '',
            errorHeading: '',
            errors: 'none',
            showForm: 'block',
            showSuccess: 'none',
            submitButton: false,
            firstnameError: false,
            lastnameError: false,
            emailError: false,
            addrError: false,
            cityError: false,
            countryError: false,
            numberError: false,
            nameErrorCard: false,
            monthError: false,
            yearError: false,
            cvcError: false,
            userEmail: this.props.email,
            showUserDetails: 'block',
            showAddress: 'none',
            showCard: 'none',
            reservationUUID: this.props.reservationUUID,
            showTotal: 'none',
            errorRegister: 'none',
            showCardFees: 'none',
            cardFees: 0,
            cardFeesDisplay: "$0.00",
            cardToken: "",
            creditCardFeesTip: "No Fees Charged"
        }

        this.payment = this.payment.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    createAccount = (event) => {
        event.preventDefault();

        var isValid = true;

        var firstname = document.getElementById('firstname').value,
            lastname = document.getElementById('lastname').value,
            email = document.getElementById('email').value;

        if (!this.state.userEmail) {

            if (firstname === "") {
                this.setState({
                    firstnameError: true
                });
                isValid = false;
            };
            if (lastname === "") {
                this.setState({
                    lastnameError: true
                });
                isValid = false;
            };
            if (email === "") {
                this.setState({
                    emailError: true
                });
                isValid = false;
            };

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                this.setState({
                    emailError: true
                });
                isValid = false;
            }

        };

        if (isValid) {
            //default registration type to guest
            //password is blank at this stage
            User.createUser(email, firstname, lastname, "", "booking").then(result => {
                //check the results and only close on success and clear
                if (typeof result != "undefined" && !result.data.includes("not created")) {

                    //need to send user email to create password
                    this.setState({ userEmail: email });

                    this.setState({ showUserDetails: 'none' });
                    this.setState({ showAddress: 'block' });
                    this.setState({ showCard: 'block' });
                    this.setState({ errorRegister: 'none' });
                } else {
                    //else show error message to user
                    this.setState({ errorRegister: 'block' });
                    this.setState({
                        showForm: 'block',
                        showLoading: 'none'
                    })
                }

            });

        }
    }

    componentDidMount() {
        
        if (typeof this.props.totalFees != 'undefined') {
            this.setState({ showTotal: 'block' });
        }

        if (this.props.email) {
            this.setState({
                userEmail: this.props.email,
                showUserDetails: 'none',
                showAddress: 'block',
                showCard: 'block',
                showCardFees: "none"
            });
        }

        //build credit card fees table
        Pricing.buildCreditCardFeeTable().then(fees => {
            this.setState({
                creditCardFeesTip: fees
            })
        })
    }

    componentDidUpdate() {

        if (this.state.userEmail != this.props.email) {
            //alert('email changed');

            this.setState({ userEmail: this.props.email });

            this.setState({ showUserDetails: 'none' });
            this.setState({ showAddress: 'block' });
            this.setState({ showCard: 'block' });

        }

        if (this.state.reservationUUID != this.props.reservationUUID) {
            

        }

    }

    closeCardEntry = (event) => {
        //reset data
        this.setState({
            showSuccess: 'none',
            showForm: 'block',
            reservationUUID: '',
            showCardFees: "none"
        });

        this.props.onClose && this.props.onClose(event);
    }

    onTextChange = (event) => {
        if (event.target.value) {
            this.setState({
                [event.target.getAttribute('data-error')]: false
            });
        }
        //re-enable the process button as text would be validated
        this.setState({
            submitButton: false
        });
    }

    loginModal = () => {
        //alert('login');
        this.props.showLoginModal && this.props.showLoginModal();
    }

    forgotPassword = () => {
        //alert('login');
        this.props.showForgotPassword && this.props.showForgotPassword();
    }

    payment(event) {
        event.preventDefault();

        //alert('processing: ' + this.props.reservationUUID);

        var pinApi = new Pin.Api(Globals.pinPaymentPublicKey, Globals.pinPaymentAPI);

        //Clear previous errors
        this.setState({
            errorList: '',
            errorHeading: '',
            errors: 'none',
            submitButton: true
        });

        //Validation check
        //var name = document.getElementById('name').value,
        //    phone = "",//document.getElementById('phone').value,
        //    email = document.getElementById('email').value,
        var number = document.getElementById('cc-number').value,
            cc_name = document.getElementById('cc-name').value,
            expiry_month = document.getElementById('cc-expiry-month').value,
            expiry_year = document.getElementById('cc-expiry-year').value,
            cvc = document.getElementById('cc-cvc').value,
            address_line1 = document.getElementById('address-line1').value,
            address_line2 = document.getElementById('address-line2').value,
            address_city = document.getElementById('address-city').value,
            address_state = document.getElementById('address-state').value,
            address_postcode = document.getElementById('address-postcode').value,
            address_country = document.getElementById('address-country').value;
        
        if (address_line1 === "") {
            this.setState({
                addrError: true
            });
        };
        if (address_city === "") {
            this.setState({
                cityError: true
            });
        };
        if (address_country === "") {
            this.setState({
                countryError: true
            });
        };
        if (number === "") {
            this.setState({
                numberError: true
            });
        };
        if (cc_name === "") {
            this.setState({
                nameErrorCard: true
            });
        };
        if (expiry_month === "") {
            this.setState({
                monthError: true
            });
        };
        if (expiry_year === "") {
            this.setState({
                yearError: true
            });
        };
        if (cvc === "") {
            this.setState({
                cvcError: true
            });
        };

        // Fetch details required for the createToken call to Pin Payments
        var card = {
            number: document.getElementById('cc-number').value,
            name: document.getElementById('cc-name').value,
            expiry_month: document.getElementById('cc-expiry-month').value,
            expiry_year: document.getElementById('cc-expiry-year').value,
            cvc: document.getElementById('cc-cvc').value,
            address_line1: document.getElementById('address-line1').value,
            address_line2: document.getElementById('address-line2').value,
            address_city: document.getElementById('address-city').value,
            address_state: document.getElementById('address-state').value,
            address_postcode: document.getElementById('address-postcode').value,
            address_country: document.getElementById('address-country').value
        };

        // Request a token for the card from Pin Payments
        pinApi.createCardToken(card).then(this.handleSuccess, this.handleError).done();
        //pinApi.createCardToken(card).then(this.handleSuccess, this.handleSuccess).done();
        //});


        //alert('process payment end');
    }

    handleSuccess(card) {

        var userEmail = this.props.email;
        if (userEmail == "") {
            userEmail = document.getElementById('email').value;
        }

        var cardFees = false;
        var cardFee = 0;

        CCFee.getCCFees().then(ccFees => {

            if (ccFees && ccFees.data) {
                //check the card type and if there is fees advise the user
                //if (card.scheme === "visa" && card.issuing_country === "AU") {
                var ccMulti = ccFees.data.find((ccFee) => (ccFee.Scheme.toLowerCase() == card.scheme.toLowerCase() && ccFee.Domestic == (card.issuing_country === "AU")));
                if (ccMulti && +ccMulti.Amount > 0) {
                    cardFees = true;
                    cardFee = (+ccMulti.Amount / 100);
                } else {
                    //check for "all" as a default
                    ccMulti = ccFees.data.find((ccFee) => (ccFee.Scheme.toLowerCase()== "all" && ccFee.Domestic == (card.issuing_country === "AU")));
                    if (ccMulti && +ccMulti.Amount > 0) {
                        cardFees = true;
                        cardFee = (+ccMulti.Amount / 100);
                    }
                }
                //}
            }

            this.setState({
                errorList: '',
                errorHeading: '',
                errors: 'none',
                submitButton: false
            });

            if (cardFees) {
                var tempPayment = this.props.payable.replace("$", "").replace(",", "");
                var tempFee = +tempPayment * +cardFee;

                this.setState({
                    showCardFees: 'block',
                    cardFees: tempFee,
                    cardFeesDisplay: Pricing.priceFormat(tempFee),
                    showForm: "none",
                    cardToken: card.token
                });
            } else {
                if (this.props.reservationUUID != '' && typeof (this.props.reservationUUID) != 'undefined') {
                    //this is where we will save the card token and call a function to process the payment similar to approved flow
                    this.props.processPayment({ token: card.token, cardFees: this.state.cardFees });

                } else {
                    //this is a new reservation that will be created
                    this.props.createReservation({ token: card.token, email: userEmail, cardFees: this.state.cardFees });
                }
            }
        });
    }

    handleError(response) {

        //alert('error' + response);
        //console.log('response: ' + JSON.stringify(response));

        //this.createReservation();
        //errorHeading.text(response.error_description);

        if (response.messages) {
            //$.each(response.messages, function (index, paramError) {
            //    $('<li>')
            //        .text(paramError.param + ": " + paramError.message)
            //        .appendTo(errorList);
            //});

            var errormessages = [];

            response.messages.map((paramError, index) => {
                /*errormessages.push(<li>{paramError.param}</li>);*/
                /*errormessages.push(<ListGroupItem>{paramError.param}</ListGroupItem>);*/
                errormessages.push(<ListGroupItem>{paramError.message}</ListGroupItem>);
            });

            this.setState({
                errorList: errormessages,
                errorHeading: 'Credit Card Process Error',
                errors: 'block',
                submitButton: true
            });

        }

        //errorContainer.show();

        //// Re-enable the submit button
        //submitButton.removeAttr('disabled');
    };

    processFees() {

        var userEmail = this.props.email;
        if (userEmail == "") {
            userEmail = document.getElementById('email').value;
        }

        if (this.props.reservationUUID != '' && typeof (this.props.reservationUUID) != 'undefined') {
            //this is where we will save the card token and call a function to process the payment similar to approved flow
            this.props.processPayment({ token: this.state.cardToken, cardFees: this.state.cardFees });

        } else {
            //this is a new reservation that will be created
            this.props.createReservation({ token: this.state.cardToken, email: userEmail, cardFees: this.state.cardFees });
        }
    }

    exit = () => {
        //reset data
        this.setState({
            showSuccess: 'none',
            showForm: 'block',
            reservationUUID: ''
        });
    }

    hideCardEntry = (event) => {

        //reset data
        this.setState({
            showSuccess: 'none',
            showForm: 'block',
            reservationUUID: '',
            showCardFees: "none"
        });

        this.props.hideCardEntry && this.props.hideCardEntry(event);
    }

    render() {
        //if (!this.props.showCardEntry) {
        //    return null;
        //}

        return (

            <Modal show={this.props.showCardEntry} onClose={event => this.closeCardEntry(event)} onExit={() => this.exit() } size="lg">
                <Modal.Header closeButton onClick={() => this.hideCardEntry()}>
                    <Modal.Title>Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.payment} style={{ display: this.state.showForm }}>
                        <fieldset>
                            <legend>Price</legend>
                            <div style={{ display: this.state.showTotal }}>
                                <Row>
                                    <Col>
                                        <b>Total Price:</b>
                                    </Col>
                                    <Col>
                                        {this.props.totalFees}
                                    </Col>
                                    <Col>
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <Col>
                                    <b>Amount Payable:</b>
                                </Col>
                                <Col>
                                    {this.props.payable}
                                </Col>
                                <Col>
                                </Col>
                            </Row>

                            <div style={{ display: this.state.showUserDetails }}>
                            <legend>Your Details</legend>
                                        <Button variant="primary" onClick={this.loginModal} style={{ width: "100%" }}>Login</Button>
                                <Row>
                                    <Col style={{ textAlign: 'center', width: '100%' }}>
                                        <br/>
                                        <h4>OR</h4>
                                    </Col>
                                </Row>
                            {/*The lack of 'name' attributes on these inputs prevents*/}
                                {/*the browser from submitting them to your server*/}
                                <Row>
                                    <Col>
                                        <Input label="Email" type="email" error={this.state.emailError} onChange={this.onTextChange} data-error='emailError' errorMessage="Valid Email Required" inputStyle="box" labelStyle="floating" id='email' />
                                        <Input label="First Name" error={this.state.firstnameError} onChange={this.onTextChange} data-error='firstnameError' errorMessage="First Name Required" inputStyle="box" labelStyle="floating" id='firstname' />
                                        <Input label="Last Name" error={this.state.lastnameError} onChange={this.onTextChange} data-error='lastnameError' errorMessage="Last Name Required" inputStyle="box" labelStyle="floating" id='lastname' />
                                </Col>
                            </Row>
                            <Row>
                                    <Button variant="primary" type="submit" onClick={this.createAccount} style={{ width: "100%" }}>Create Account</Button>
                                    <div style={{ display: this.state.errorRegister, color: "#e96852" }}>
                                        It appears this email already exists, please do <a href="#" onClick={this.forgotPassword}>Reset Password</a> to reset account or use different email
                                    </div>
                            </Row>
                            </div>
                            <div style={{ display: this.state.showAddress }}>
                            <legend>Billing Address</legend>

                            {/*The lack of 'name' attributes on these inputs prevents*/}
                            {/*the browser from submitting them to your server*/}
                            <Row>
                                <Col className="sm-input">
                                    <Input label="Address 1" error={this.state.addrError} onChange={this.onTextChange} data-error='addrError' errorMessage="Address Required" inputStyle="box" labelStyle="floating" id='address-line1' />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="sm-input">
                                    <Input label="Address 2" inputStyle="box" labelStyle="floating" id='address-line2' />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="sm-input">
                                    <Input label="City" error={this.state.cityError} onChange={this.onTextChange} data-error='cityError' errorMessage="City Required" inputStyle="box" labelStyle="floating" id='address-city' />
                                </Col>
                                <Col className="sm-input">
                                    <Input label="State" inputStyle="box" labelStyle="floating" id='address-state' />
                                </Col>
                            </Row>
                            <Row>
                                <Col >
                                    <Input label="Postcode" inputStyle="box" labelStyle="floating" id='address-postcode' />
                                </Col>
                                <Col >
                                    <Input label="Country" error={this.state.countryError} onChange={this.onTextChange} data-error='countryError' errorMessage="Country Required" inputStyle="box" labelStyle="floating" id='address-country' />
                                </Col>
                                </Row>
                            </div>

                        </fieldset>
                        <fieldset style={{ display: this.state.showCard }}>
                            <legend>Card Details
                                <a data-tip data-for="creditCardFees" style={{ fontSize: "small", paddingLeft: "10px" }} >
                                    Fees
                                </a>
                            </legend>
                            <ReactTooltip id="creditCardFees" place="right" effect="solid">
                                <div dangerouslySetInnerHTML={{ __html: this.state.creditCardFeesTip }} />
                                {/*<span>{this.state.creditCardFeesTip}</span>*/}
                                {/*<div ref="creditCardFeeshtml"></div>*/}
                            </ReactTooltip>

                            {/*The lack of 'name' attributes on these inputs prevents*/}
                            {/*the browser from submitting them to your server*/}
                            <Row>
                                <Col className="sm-input">
                                    <Input label="Credit Card Number" error={this.state.numberError} onChange={this.onTextChange} data-error='numberError' errorMessage="Credit Card Number Required" inputStyle="box" labelStyle="floating" id='cc-number' type='text' />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="sm-input">
                                    <Input label="Name on Card" error={this.state.nameErrorCard} onChange={this.onTextChange} data-error='nameErrorCard' errorMessage="Name on Card Required" inputStyle="box" labelStyle="floating" id='cc-name' type='text' />
                                </Col>
                            </Row>
                            <Row>
                                <Col >
                                    <Input label="Expiry Month" error={this.state.monthError} onChange={this.onTextChange} data-error='monthError' errorMessage="Expiry Month Required" inputStyle="box" labelStyle="floating" id='cc-expiry-month' />
                                </Col>
                                <Col >
                                    <Input label="Expiry Year" error={this.state.yearError} onChange={this.onTextChange} data-error='yearError' errorMessage="Expiry Year Required" inputStyle="box" labelStyle="floating" id='cc-expiry-year' />
                                </Col>
                                <Col >
                                    <Input label="CVC" error={this.state.cvcError} onChange={this.onTextChange} data-error='cvcError' errorMessage="CVC Required" inputStyle="box" labelStyle="floating" id='cc-cvc' />
                                </Col>
                            </Row>

                            <div style={{ display: this.state.errors}}>
                                <p style={{ fontSize: "125%", color: "red" }}> {this.state.errorHeading} </p>
                                {/*<ul>{this.state.errorList}</ul>*/}
                                <ListGroup>{this.state.errorList}</ListGroup>
                            </div>

                            <Button id="processpayment" data-bookingvalue={this.props.totalFees ? this.props.totalFees.replace('$', '') : ""} data-listingid={this.props.listingID} variant="secondary" type="submit" disabled={this.state.submitButton} style={{ width: "100%" }}>Process Payment</Button>

                        </fieldset>

                        

                    </Form>

                    <div style={{ display: this.state.showSuccess }}>
                        <legend>Success</legend>
                        Thank you for your payment an email confirmation will be sent shortly!
                    </div>

                    <div style={{ display: this.state.showCardFees }}>
                        <legend>Credit Card Fees Payable</legend>
                        <b>{this.state.cardFeesDisplay}</b> to be added to reservation total.
                        <Button variant="secondary" type="submit" style={{ width: "100%" }} onClick={() => this.processFees()}>Accept Fees</Button>
                        <br />
                        <br />
                        <Button variant="danger" type="submit" style={{ width: "100%" }} onClick={() => this.hideCardEntry()}>Cancel Booking</Button>
                    </div>

                </Modal.Body>
            </Modal>

        )
    }
}

export default withRouter(CardEntry)