import React, { Component, useRef } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Tabs, Tab, Button, Row, Col, Alert } from 'react-bootstrap';
import SideNav from "./../modals/SideNav";
import Information from '../items/addblog/information';
import Location from '../items/addblog/location';
import Media from './../items/addblog/media';

// services
import Blog from './../functions/Blog';
import UserDetail from './../functions/User';

import { Grid } from "react-loader-spinner";
//import BlogService from '../services/BlogService';

export function withRouter(Children) {
    return (props) => {
        const location = { location: useLocation() };

        return <Children {...props} location={location.location} />
    }
}

class EditNewBlog extends Component {
    constructor(props) {
        super(props);

        this.inforef = React.createRef();
        this.locationref = React.createRef();

        this.state = {
            email: this.props.location.state.email,
            loggedIn: this.props.location.state.loggedIn,
            key: '0',
            tabCount: '3',
            intro: this.props.location.state.blog.Intro,
            body: '',

            // media
            primaryImage: null,
            images: [],
            showLoading: 'none',
            showResultMessage: 'none'
        }
    }

    componentDidMount() {
        //console.log(this.props.location.state.blog)

        //check the logged in user matches the blog owner or is admin
        if (this.props.location.state.loginType != "admin") {
            //get user ID
            UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {
                var userID = result.data.UUID;

                if (this.props.location.state.blog.CreatedBy != userID) {
                    //redirect to home page
                    window.location.href = "/";
                }
            })
        } else {

            //console.log("primary load: " + this.props.location.state.blog.PrimaryImage);

            ////is admin so need to set images
            //this.setState({
            //    primaryImage: this.props.location.state.blog.PrimaryImage,
            //})

            //if (this.props.location.state.blog.OtherImages) {
            //    this.setState({
            //        images: this.props.location.state.blog.OtherImages
            //    })
            //}
        }
    }

    setKey = (event) => {

        if (this.state.key == 0) {
            //call a validate function before changing key
            if (this.inforef.current()) {
                this.setState({ key: event });
            }
        } else if (this.state.key == 2) {
            //call a validate function before changing key
            if (this.locationref.current()) {
                this.setState({ key: event });
            }
        } else {
            this.setState({ key: event });
        }
    }

    saveBlog = () => {

        //show page loading and disable save button
        this.setState({ showLoading: 'block' });

        //check if all fields have been validated
        if (this.inforef.current() && this.locationref.current()) {
            //get user ID
            UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {
                var userID = result.data.UUID;
                //console.log("user ID: " + userID);

                //console.log("primary: " + this.state.primaryImage);
                //console.log("images: " + this.state.images);

                var primaryImage = this.state.primaryImage;
                if (!this.state.primaryImage) {
                    primaryImage = this.state.images[0];
                }

                //console.log("prim name: " + primaryImage.name)

                //this.state.images.map((image, index) => {
                //    console.log(image.name)
                //})

                var spliceImages = this.state.images.filter(filter => filter.name !== primaryImage.name);
                this.setState({ images: spliceImages });

                // location data
                const location = this.getLocationData();

                Blog.updateBlog(
                    this.props.location.state.blog.UUID,
                    document.getElementById('informationtitle').value,
                    this.state.intro,
                    this.state.body,
                    userID,
                    primaryImage,
                    spliceImages, //this.state.images,
                    location,
                    this.props.location.state.blog.Status
                ).then(result => {

                        var css = '';
                        var message = '';

                        //if result is good
                        if (result) {
                            css = 'success';
                            message = 'Congratulations, your blog has been saved, we will review and publish soon!';
                        } else {
                            css = 'danger';
                            message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                        }

                        this.setState({
                            showLoading: 'none',
                            showResultMessage: 'inline',
                            resultMessageClass: css,
                            resultMessage: message,
                            showButtons: 'none',
                            showDetail: 'none'
                        });

                    });

            });

        } else {
            this.setState({ showLoading: 'none' });
        }
    }

    publishBlog = () => {

        //show page loading and disable save button
        this.setState({ showLoading: 'block' });

        //check if all fields have been validated
        if (this.inforef.current() && this.locationref.current()) {
            //get user ID
            UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {
                var userID = result.data.UUID;
                //console.log("user ID: " + userID);

                var primaryImage = this.state.primaryImage;
                if (!this.state.primaryImage) {
                    primaryImage = this.state.images[0];
                }

                // location data
                const location = this.getLocationData();

                Blog.updateBlog(
                    this.props.location.state.blog.UUID,
                    document.getElementById('informationtitle').value,
                    this.state.intro,
                    this.state.body,
                    userID,
                    primaryImage,
                    this.state.images,
                    location,
                    "Published"
                ).then(result => {

                    var css = '';
                    var message = '';

                    //if result is good
                    if (result) {
                        css = 'success';
                        message = 'Congratulations, your blog has been saved, we will review and publish soon!';
                    } else {
                        css = 'danger';
                        message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                    }

                    this.setState({
                        showLoading: 'none',
                        showResultMessage: 'inline',
                        resultMessageClass: css,
                        resultMessage: message,
                        showButtons: 'none',
                        showDetail: 'none'
                    });
                });
            });

        } else {
            this.setState({ showLoading: 'none' });
        }
    }

    hideBlog = () => {

        //show page loading and disable save button
        this.setState({ showLoading: 'block' });

        //check if all fields have been validated
        if (this.inforef.current() && this.locationref.current()) {
            //get user ID
            UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {
                var userID = result.data.UUID;
                //console.log("user ID: " + userID);

                var primaryImage = this.state.primaryImage;
                if (!this.state.primaryImage) {
                    primaryImage = this.state.images[0];
                }

                // location data
                const location = this.getLocationData();

                Blog.updateBlog(
                    this.props.location.state.blog.UUID,
                    document.getElementById('informationtitle').value,
                    this.state.intro,
                    this.state.body,
                    userID,
                    primaryImage,
                    this.state.images,
                    location,
                    "Hidden"
                ).then(result => {

                        var css = '';
                        var message = '';

                        //if result is good
                        if (result) {
                            css = 'success';
                            message = 'Congratulations, your blog has been saved, we will review and publish soon!';
                        } else {
                            css = 'danger';
                            message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                        }

                        this.setState({
                            showLoading: 'none',
                            showResultMessage: 'inline',
                            resultMessageClass: css,
                            resultMessage: message,
                            showButtons: 'none',
                            showDetail: 'none'
                        });
                    });
            });

        } else {
            this.setState({ showLoading: 'none' });
        }
    }

    getInformation = () => {

        const information = {
            Title: document.getElementById('informationtitle').value,
            Introduction: this.state.intro,
            Body: this.state.body,
        }

        return information;
    }

    addImage = (image) => {
        this.state.images.push(image);
    }

    deleteImage = (image) => {
        var tempImages = this.state.images.filter(filter => filter.name !== image.data.name);

        this.setState({
            images: tempImages //this.state.images.filter(filter => filter.name !== image.data.name)
        })

        //check if primary image and clean up
        if (this.state.primaryImage && this.state.primaryImage.name == image.data.name) {
            this.setState({
                primaryImage: null //this.state.images.filter(filter => filter.name !== image.data.name)
            })
        }

        if (image.ID) {
            Blog.deleteBlogMediaByID(image.ID).then(result => {
            })
        }
    }

    onSelectImage = (image) => {
        this.setState({ primaryImage: image });
    }

    getLocationData = () => {
        const geoLocation = document.getElementById('bloggeoLocation').value;
        const attempt = 0;

        while (geoLocation === null && attempt < 5) {
            geoLocation = document.getElementById('bloggeoLocation').value;
            attempt++;
        }

        const location = {
            City: document.getElementById('blogCity').value,
            Location: geoLocation,
            PlaceID: document.getElementById('blogplaceID').value
        }

        return location;
    }

    changeIntro = (event) => {
        this.setState({ intro: event });
    }

    changeBody = (event) => {
        this.setState({ body: event });
    }

    render() {

        return (
            <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
                <div class="loading-overlay" style={{ display: this.state.showLoading }}>
                    <div class="loading-vertical">
                        <Grid
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={0}
                        />
                    </div>
                </div>
                <Row>
                    <Col className="d-none d-md-block" md="3" lg="2" xl="1">
                        <SideNav
                            loggedIn={this.props.location.state.loggedIn}
                            loginType={this.props.location.state.loginType}
                            email={this.props.location.state.email}
                            activeTab='blogs'
                        />
                    </Col>
                    <Col md="9" lg="10" xl="11">
                        <div style={{ width: '100%' }}>
                            <h1>Edit Blog</h1>
                            <hr />
                        </div>
                        {/*<div className="row" >*/}
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={this.state.key}
                            onSelect={(k) => this.setKey(k)}
                            style={{ background: "#f7f8f9" }}
                        >
                            <Tab eventKey="0" title="Information">
                                <br />
                                <Information
                                    changeIntro={this.changeIntro}
                                    changeBody={this.changeBody}
                                    isValid={this.inforef}
                                    blog={this.props.location.state.blog}
                                />

                            </Tab>
                            <Tab eventKey="1" title="Media">
                                <br />
                                <Media
                                    images={this.state.images}
                                    addImage={this.addImage}
                                    onSelectImage={this.onSelectImage}
                                    deleteImage={this.deleteImage}
                                    blog={this.props.location.state.blog}
                                />
                            </Tab>
                            <Tab eventKey="2" title="Location" >
                                <br />
                                <Location
                                    isValid={this.locationref}
                                    blog={this.props.location.state.blog}
                                />
                            </Tab>
                        </Tabs>


                    </Col>
                </Row>

                <Row style={{ display: this.state.showResultMessage }}>
                    <Col md={{ span: 9, offset: 3 }} lg={{ span: 10, offset: 2 }} xl={{ span: 11, offset: 1 }}>
                        <Alert variant={this.state.resultMessageClass} >
                            {this.state.resultMessage}
                        </Alert>
                    </Col>
                </Row>

                <Row style={{ display: this.state.showButtons }}>
                    <Col className="d-none d-md-block" md="3" lg="2" xl="1">
                    </Col>
                    <Col md="9" lg="10" xl="11">

                        {(this.state.key > 0) &&
                            <Button className="col-md-6" onClick={() => this.setKey((parseInt(this.state.key) + 3 - 1) % 3)} variant="secondary">Back</Button>
                        }
                        {(this.state.key == 0) &&
                            <div className="col-md-6"></div>
                        }

                        {(this.state.key != this.state.tabCount - 1) &&
                            <Button className="col-md-6" onClick={() => this.setKey((parseInt(this.state.key) + 1) % 3)} variant="primary">Next</Button>
                        }

                        <Button className="col-md-6" variant="success" onClick={this.saveBlog}>Save</Button>

                        <Button className="col-md-6" variant="outline-primary" style={{ display: (this.props.location.state.loginType =="admin" && this.props.location.state.blog.Status != "Published" ? 'inline' : 'none') }} onClick={this.publishBlog}>Publish</Button>
                        <Button className="col-md-6" variant="outline-danger" style={{ display: (this.props.location.state.loginType == "admin" && this.props.location.state.blog.Status != "Hidden" ? 'inline' : 'none') }} onClick={this.hideBlog}>Hide</Button>
                        {/*</div>*/}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withRouter(EditNewBlog)