import ErrorService from "../services/ErrorService";

/**
 * global functions to act on user api actions
 */
const ErrorHandler = {

    async postErrorMessage(page, errorMessage) {
        const errorconst = new FormData();
        errorconst.append("Page", page);
        errorconst.append("Error", errorMessage);

        const result = await ErrorService.postErrorMessage(errorconst);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    
}

export default ErrorHandler