// interface to the backend database handlers to act on the Reservation database
import axios from 'axios';

import { BASE_URL } from './GlobalDefinitions';
//import { AUTH, ORIGIN, METHODS_GET, METHODS_POST, METHODS_PUT, METHODS_DELETE, CONTENT_TYPE } from './Headers';
import { AUTH, METHODS_DELETE, METHODS_GET, METHODS_POST, ORIGIN } from './Headers';

///////////// API ENDPOINTS
// Favourite object
const FAVOURITEOBJECT_API_BASE_URL = "/favourites";
const FAVOURITEOBJECT_BY_USER_ID_API_BASE_URL = "/favouritesbyuser";
const FAVOURITEOBJECT_BY_LISTING_ID_API_BASE_URL = "/favouritesbylistingid";
const FAVOURITEOBJECT_BY_DATE_API_BASE_URL = "/favouritesbydate";


class FavouriteService {
    // get all reservation objects
    getFavourites() {
        return axios.get(BASE_URL + FAVOURITEOBJECT_API_BASE_URL, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get all favourite objects based on user id
    getFavouritesByUser(userid) {
        return axios.get(BASE_URL + FAVOURITEOBJECT_BY_USER_ID_API_BASE_URL, { 
            params: {
                UserID: userid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get all favourite objects based on listing id
    getFavouritesByListing(listingid) {
        return axios.get(BASE_URL + FAVOURITEOBJECT_BY_LISTING_ID_API_BASE_URL, { 
            params: {
                ListingID: listingid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get all reservation objects based on listing id
    getFavouritesByDate(date) {
        return axios.get(BASE_URL + FAVOURITEOBJECT_BY_DATE_API_BASE_URL, { 
            params: {
                Date: date
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // create a new favourite
    createFavourite(userid, listingid) {
        return axios.post(BASE_URL + FAVOURITEOBJECT_API_BASE_URL, null, { 
            params: {
                UserID: userid,
                ListingID: listingid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // delete an existing favourite
    deleteFavourite(favouriteID) {
        return axios.delete(BASE_URL + FAVOURITEOBJECT_API_BASE_URL, { 
            params: {
                FavouriteID: favouriteID
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }
}

export default new FavouriteService()