import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Button, Modal, Form, InputGroup } from 'react-bootstrap';

import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { Input } from '@mobiscroll/react';

export function withRouter(Children) {
    return (props) => {

        //const match = { params: useParams() };
        //const location = { location: useLocation() };

        //return <Children {...props} match={match.params} location={location.location} />
        return <Children {...props} />
    }
}

class LoginModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            input: {},
            errors: {},
            showErrors: {},
            regOptions: []
        }

        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.validate()) {
            let input = this.state.input;

            let email = input["email"];
            let password = input["password"];

            this.props.onClose(email, password) && this.props.onClose();
        }
    }

    validate() {
        let input = this.state.input;
        let errors = {};
        let showErrors = {};
        let isValid = true;

        if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your email address.";
            showErrors["email"] = true;
        }

        if (!input["password"]) {
            isValid = false;
            errors["password"] = "Please enter your password.";
            showErrors["password"] = true;
        }

        this.setState({
            errors: errors,
            showErrors: showErrors
        });

        return isValid;
    }

    onTextChange = (event) => {

        let input = this.state.input;
        input[event.target.name] = event.target.value;

        this.setState({
            input
        });

        if (event.target.value) {
            //remoev the errors fom array state
            let errors = {};
            let showErrors = {};

            Object.entries(this.state.errors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    errors[error[0]] = error[1];
                }
            })
            Object.entries(this.state.showErrors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    showErrors[error[0]] = error[1];
                }
            })

            this.setState({
                errors: errors,
                showErrors: showErrors
            })
        }
    }

    dontHaveAccount = () => {
        this.props.dontHaveAccount && this.props.dontHaveAccount();
    }

    forgotPassword = () => {
        this.props.forgotPassword && this.props.forgotPassword();
    }

    sendVerifyEmail = () => {
        //let input = this.state.input;
        //let email = input["email"];
        this.props.sendVerifyEmail && this.props.sendVerifyEmail();
    }

    hideLogin = (event) => {
        this.props.hideLogin && this.props.hideLogin(event);
    }

    render() {
        if (!this.props.showLogin) {
            return null;
        }

        return (
            <Modal show={this.props.showLogin} onClose={event => this.closeLogin(event)}>
                <Modal.Header closeButton onClick={() => this.hideLogin()}>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*<Button variant="info" style={{width: "100%"}}>Login with Facebook</Button>*/}
                    {/*<br />*/}

                    <form onSubmit={this.handleSubmit}>

                        <Input inputStyle="box" labelStyle="floating" type="email" name="email" label="Email" errorMessage={this.state.errors.email} error={this.state.showErrors.email} onChange={this.onTextChange} />
                        <Input inputStyle="box" labelStyle="floating" name="password" type="password" label="Password" passwordToggle="true" iconShow="eye" iconHide="eye-blocked" autoComplete="new-password" errorMessage={this.state.errors.password} error={this.state.showErrors.password} onChange={this.onTextChange} />

                        <div style={{ display: this.props.errorLogin, color: "#e96852" }}>
                            Login error: please confirm your email and password are correct and try again!
                        </div>

                        <div style={{ display: this.props.invalidLogin, color: "#e96852" }}>
                            Please validate your email before continuing. If you need your validation email resent - <a href="#" onClick={this.sendVerifyEmail}>click here</a>
                        </div>

                        <Button type="submit" variant="secondary" style={{ width: "100%" }}>Login</Button>

                    </form>
                    <Form.Label className="text-center" style={{ width: "100%" }} >Forgot your password? <a href="#" onClick={this.forgotPassword}>Reset</a></Form.Label>
                    <Form.Label className="text-center" style={{ width: "100%" }} >Don't have an account? <a href="#" onClick={this.dontHaveAccount}>Register</a></Form.Label>
                </Modal.Body>
                                 
            </Modal>
        )
    }
}

export default withRouter(LoginModal)