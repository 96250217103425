import React from 'react';

import { Button, Modal } from 'react-bootstrap';

function PublishReview({ title, showPublishReview, publish, unpublish}) {

    return (
        <div>
            <Modal show={showPublishReview} style={{textAlign: "center"}}>
                <br/>
                <h3>Change review status for: {title} </h3>
                <br/>
                <Button variant="success" onClick={() => publish()}>Publish</Button>
                <br />
                <Button variant="outline-warning" onClick={() => unpublish()}>Unpublish</Button>
                <br/>
        </Modal>
        </div>
    )
}

export default PublishReview