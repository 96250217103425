import React, { useRef, useState, useEffect } from 'react';
import { Button, InputGroup, Form, Card, Col, Row, Alert, OverlayTrigger, Popover } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';

import ListingDetail from '../../functions/Listing';

import "./../../styles/information.css"
//import "react-day-picker/lib/style.css";
import { sectionFooterSecondaryContent } from 'aws-amplify';
import { FaQuestionCircle } from 'react-icons/fa';

import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { isNotEmittedStatement } from 'typescript';
//import Moment from 'react-moment';
import moment from 'moment';

import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { Input, Dropdown, RadioGroup, Radio } from '@mobiscroll/react';

function Pricing({ setInstantBooking, setNightlyRate, setWeekendRate, setFees, setTaxes, setPeriods, isValid, listingData, listingUUID }) {
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    // set up our arrays to take care of the dynamic stuff
    const [fees, addFee] = useState([]);
    const [taxes, addTax] = useState([]);
    //const [additonalPeriods, addAdditonalPeriods] = useState([]);
    const [periods, addPeriod] = useState([]);
    const [focusedInput, setFocusedInput] = useState([]);

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState({});
    const [pageValid, setPageValid] = useState('none');

    const [additionalGuest, setAdditionalGuest] = useState(true);
    const [chargeGST, setChargeGST] = useState(false);
    const [instant, setInstant] = useState(false);

    const [loadData, setLoadData] = useState(true);

    const onTextChange = (event) => {

        let newinput = input;

        if (event.target.name == "pricingOneNightNightly") {
            //check if below 0 and set to 0
            if (event.target.value < 0) {
                event.target.value = "";
            }
            setNightlyRate(event.target.value);
        }

        newinput[event.target.name] = event.target.value;
        setInput(newinput)

        if (event.target.value) {
            //remoev the errors fom array state
            let newerrors = {};
            let newshowErrors = {};

            ////manual check pricingDeposit
            //if (event.target.name == "pricingDeposit") {
            //    var value = event.target.value.replace('.', '');
            //    event.target.value = value;

            //    if (value < 20 || value > 100) {
            //        newerrors["pricingDeposit"] = "Please enter a whole number between 20 and 100";
            //        newshowErrors["pricingDeposit"] = true;
            //    }
            //}

            Object.entries(errors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(showErrors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newshowErrors[error[0]] = error[1];
                }
            })

            setErrors(newerrors);
            setShowErrors(newshowErrors);

            setPageValid('none');
        }
    }

    const onTextSet = (event) => {
        let newinput = input;

        newinput[event.name] = event.value;

        setInput(newinput)

        if (event.value) {
            //remoev the errors fom array state
            let newerrors = {};
            let newshowErrors = {};

            Object.entries(errors).map((error, index) => {
                if (error[0] !== event.name) {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(showErrors).map((error, index) => {
                if (error[0] !== event.name) {
                    newshowErrors[error[0]] = error[1];
                }
            })

            setErrors(newerrors);
            setShowErrors(newshowErrors);
            setPageValid('none');
        }
    }

    const getvalid = () => {

        //let newinput = input;
        let newerrors = {};
        let newshowErrors = {};
        let isValid = true;

        if (!input["pricingOneNightNightly"]) {
            isValid = false;
            newerrors["pricingOneNightNightly"] = "Please enter price per night for listing";
            newshowErrors["pricingOneNightNightly"] = true;
        }

        //if (!input["pricingDeposit"] || document.getElementById("pricingDeposit").value < 20 || document.getElementById("pricingDeposit").value > 100) {
        //    isValid = false;
        //    newerrors["pricingDeposit"] = "Please enter a whole number between 20 and 100";
        //    newshowErrors["pricingDeposit"] = true;
        //}

        setErrors(newerrors);
        setShowErrors(newshowErrors);
        if (isValid) {
            setPageValid('none');
        } else {
            setPageValid('block');
        }

        return isValid;
    }

    const handleInstantBookingChange = (event) => {
        setInstantBooking(event.target.value);
    }

    //const handleNightlyRateChange = (event) => {
    //    setNightlyRate(event.target.value);
    //}

    const handleNWeekendRateChange = (event) => {
        setWeekendRate(event.target.value);
    }

    const newFee = () => {
        addFee([...fees, { description: "", amount: "", frequency: "", chargeType: "", chargedAt: "" }]);
        //setFees(fees);
    }

    const newTax = () => {
        addTax([...taxes, { description: "", percentage: "", frequency: "", chargeType: "", chargedAt: "" }]);
        //setTaxes(taxes);
    }

    const newPeriod = () => {
        addPeriod([...periods, { startDate: null, endDate: null, startDateShort: null, endDateShort: null, days: [], nightly: "", weekends: "", minimumBooking: "" }])
    }

    const removeFee = (f, index) => {
        console.log(f);
        console.log(fees);
        const list = fees.filter(function (fee) { return fee !== f; });
        addFee([...list]);
        console.log(list);
        setFees(list);
    }

    const removeTax = (t, index) => {
        const list = taxes.filter(function (tax) { return tax !== t; });
        addTax([...list]);
        setTaxes(list);
    }

    const handleFeeChange = (event, index) => {
        const { name, value } = event.target;
        const list = [...fees];
        list[index][name] = value;
        setFees(fees);
    }

    const handleTaxChange = (event, index) => {
        const { name, value } = event.target;
        const list = [...taxes];
        list[index][name] = value;
        setTaxes(taxes);
    }

    const removePeriod = (period) => {
        const list = periods.filter(function (p) { return p !== period; });
        addPeriod(list);
        setPeriods(list);
    }

    const getDatesBetween = (startDate, endDate) => {
        if (startDate !== null) {
            var start = startDate.clone();
        }

        if (endDate !== null) {
            var end = endDate.clone();
        }

        var dates = [];

        if (start !== undefined && end !== undefined) {
            while (start.isSameOrBefore(end)) {
                dates.push(start.format('MM/DD/YYYY'))
                start.add(1, 'days');
            }
        }

        return dates;
    }

    const handlePeriodChange = (event, index) => {
        const { name, value } = event.target;
        const list = [...periods];
        list[index][name] = value;
        addPeriod(list);
        setPeriods(list);
    }

    const onDatesChange = (index, { startDate, endDate }) => {
        periods[index].startDate = startDate;
        periods[index].endDate = endDate;

        let days = getDatesBetween(periods[index].startDate, periods[index].endDate);

        periods[index].days = days;

        periods[index].startDateShort = days[0];
        periods[index].endDateShort = days[days.length - 1];

        setPeriods(periods);
    }

    const onFocusChange = (index, focus) => {
        const focused = [...focusedInput];
        focused[index] = focus;
        setFocusedInput(focused);
    }

    const popoverChargedAt = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Charged At</Popover.Header>
            <Popover.Body>
                <strong>Booking</strong> - Guests will be charged this through the site on booking
                <br />
                <br />
                <strong>Arrival</strong> - Guests won't be charged through the site, you will need to charge them when they arrive at the property
            </Popover.Body>
        </Popover>
    );
    
    const popoverChargeType = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Charge Type</Popover.Header>
            <Popover.Body>
                <strong>Included</strong> - No extra charge will be added. You have included this in the nightly rates
                <br />
                <br />
                <strong>Excluded</strong> - Extra charge added for guests at time of booking
                <br />
                <br />
                <strong>Conditional</strong> - Only added for guests if they choose this option at time of booking
            </Popover.Body>
        </Popover>
    );

    useEffect(() => {
        //setPeriods(periods);

        isValid.current = getvalid;

        //alert("pricing effect");
        if (loadData && listingData) {


            document.getElementById('pricingOneNightNightly').value = listingData.Nightly;
            document.getElementById('pricingOneNightWeekend').value = listingData.Weekend;
            document.getElementById('pricingOneNightSevenPlus').value = listingData.WeeklyRate;
            document.getElementById('pricingOneNightThirtyPlus').value = listingData.MonthlyRate;
            document.getElementById('pricingPneAdditionalGuest').value = listingData.AdditionalGuestCost;
            document.getElementById('pricingStandardNumberOfGuests').value = listingData.StandardNumberOfGuests;
            document.getElementById('pricingMaximumNumberOfGuests').value = listingData.MaximumNumberOfGuests;
            //document.getElementById('pricingSecurityDeposit').value = listingData.SecurityDeposit;
            //document.getElementById('pricingDeposit').value = listingData.DepositPercentage;

            document.getElementById('weekendRate').value = listingData.WeekendRate;
            setWeekendRate(listingData.WeekendRate);

            if (listingData.AllowAdditionalGuests == "Yes") {
                setAdditionalGuest(true);
            } else {
                setAdditionalGuest(false);
            }

            if (listingData.GSTFlag) {
                setChargeGST(true);
            } else {
                setChargeGST(false);
            }
            if (listingData.InstantBooking) {
                setInstant(true);
            } else {
                setInstant(false);
            }

            //Fees
            ListingDetail.getListingFeesByID(listingUUID).then((result) => {
                if (result.data && typeof (result.data) != 'string') {
                    var feesTemp = [];
                    result.data.map((fee, index) => {
                        feesTemp.push({ description: fee.Description, amount: fee.Amount, frequency: fee.Frequency, chargeType: fee.ChargeType, chargedAt: fee.ChargedAt });
                        //addFee([...fees, { description: fee.Description, amount: fee.Amount, frequency: fee.Frequency, chargeType: fee.ChargeType, chargedAt: fee.ChargedAt }]);
                    })
                    addFee(feesTemp);
                    setFees(feesTemp);
                }
            })

            //Taxes
            ListingDetail.getListingTaxesByID(listingUUID).then((result) => {
                //console.log(result.data);
                if (result.data && typeof (result.data) != 'string') {
                    var taxesTemp = [];
                    result.data.map((tax, index) => {
                        taxesTemp.push({ description: tax.Description, percentage: tax.Percentage, frequency: tax.Frequency, chargeType: tax.ChargeType, chargedAt: tax.ChargedAt });
                        //addTax([...taxes, { description: tax.Description, percentage: tax.Percentage, frequency: tax.Frequency, chargeType: tax.ChargeType, chargedAt: tax.ChargedAt }]);
                    })
                    addTax(taxesTemp);
                    setTaxes(taxesTemp);
                }
            })

            //Periods

            //ListingDetail.getListingPeriodsByID(
            //    listingUUID,
            //    (('0' + (today.getMonth() + 1)).slice(-2) + '/' + ('0' + today.getDate()).slice(-2) + '/' + today.getFullYear()).toString(),
            //    (('0' + (max.getMonth() + 1)).slice(-2) + '/' + ('0' + max.getDate()).slice(-2) + '/' + max.getFullYear()).toString()
            //).then((result) => {
            ListingDetail.getListingPricingPeriodsByID(listingUUID).then((result) => {

                if (result.data && typeof (result.data) != 'string') {
                    var periodsTemp = [];
                    result.data.map((period, index) => {
                        //console.log(period.StartDate);
                        var startDate = new Date(period.StartDate);
                        var startMoment = moment(startDate);
                        var endDate = new Date(period.EndDate);
                        var endMoment = moment(endDate);

                        //console.log("dates betweem: " + getDatesBetween(startMoment, endMoment));
                        let days = getDatesBetween(startMoment, endMoment);

                        //only add the date if the start date matches the date
                        if (period.StartDateShort == period.Date) {
                            periodsTemp.push({ startDate: startMoment, endDate: endMoment, startDateShort: days[0], endDateShort: days[days.length - 1], days: days, nightly: period.Nightly, weekends: period.Weekend, minimumBooking: period.MinimumDays });
                        }
                        //addPeriod([...periods, { startDate: period.StartDate, endDate: period.EndDate, startDateShort: null, endDateShort: null, days: [], nightly: period.Nightly, weekends: period.Weekend, minimumBooking: period.MinimumDays }]);
                        //addPeriod([...periods, { startDate: startMoment, endDate: endMoment, startDateShort: null, endDateShort: null, days: [], nightly: period.Nightly, weekends: period.Weekend, minimumBooking: period.MinimumDays }]);
                    })
                    addPeriod(periodsTemp);
                    setPeriods(periodsTemp);
                    //console.log("periods added: " + JSON.stringify(periodsTemp));
                }
            })

            onTextSet(document.getElementById('pricingOneNightNightly'));
            //onTextSet(document.getElementById('pricingDeposit'));


            getvalid();
            setLoadData(false);
        }
    });
    //}, [periods]);

    return (
        <div style={{ backgroundColor: '#f7f8f9' }}>
            <Col sm={9} md={9}>
                <Card style={{ margin: "10px" }}>
                    <Card.Body>
                        <Row>
                            <Col sm={12} md={6}>
                                <h5>Allow Instant Booking</h5>
                                <Row>
                                    <Col sm={6} md={6}>
                                        <RadioGroup name="instantbookgroup">
                                            <Radio label="Yes" value="true" defaultChecked={instant} id="instantbookYes" onChange={event => handleInstantBookingChange(event)} />
                                        </RadioGroup>
                                    </Col>
                                    <Col sm={6} md={6}>
                                        <RadioGroup name="instantbookgroup">
                                            <Radio label="No" defaultChecked={!instant} value="false" id="instantbookNo" onChange={event => handleInstantBookingChange(event)} />
                                        </RadioGroup>
                                    </Col>
                                </Row>
                                {/*<select className="form-control" name="instantBooking" onChange={event => handleInstantBookingChange(event)}>*/}
                                {/*    <option selected>Allow instant booking</option>*/}
                                {/*    <option value="true">True</option>*/}
                                {/*    <option value="false">False</option>*/}
                                {/*</select>*/}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card style={{ margin: "10px" }}>
                    <Card.Title>Pricing</Card.Title>
                    <Card.Body>
                        <Row>
                            <Col sm={6} md={6}>
                                <h5>Nightly*</h5>
                                <Input className="mbsc-remove-margins" placeholder="Enter the Unit Price for 1 night" type="number" id="pricingOneNightNightly" name="pricingOneNightNightly" errorMessage={errors.pricingOneNightNightly} error={showErrors.pricingOneNightNightly} onChange={onTextChange} />
                            </Col>
                            {/*<Col sm={6} md={6}>*/}
                            {/*    <h5>Deposit Required</h5>*/}
                            {/*    <Input className="mbsc-remove-margins" placeholder="Enter the Percentage for Deposit - Whole number only" min="20" max="100" type="number" id="pricingDeposit" name="pricingDeposit" errorMessage={errors.pricingDeposit} error={showErrors.pricingDeposit} onChange={onTextChange} />*/}
                            {/*    <small>*Default value is 20%</small>*/}
                            {/*</Col>*/}
                        </Row>
                        <Row>
                            <Col sm={6} md={6}>
                                <h5>Weekend</h5>
                                <Input className="mbsc-remove-margins" placeholder="Enter the Unit Price for a Single Day" type="number" id="pricingOneNightWeekend" name="pricingOneNightWeekend" />
                            </Col>
                            <Col sm={6} md={6}>
                                <h5>Select the days to Apply Weekend Pricing</h5>

                                <Dropdown className="mbsc-remove-margins" name="weekendRate" id="weekendRate" onChange={event => handleNWeekendRateChange(event)} >
                                    <option selected value="Saturday and Sunday">Saturday and Sunday</option>
                                    <option value="Friday and Saturday">Friday and Saturday</option>
                                    <option value="Friday, Saturday and Sunday">Friday, Saturday and Sunday</option>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} md={6}>

                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card style={{ margin: "10px" }}>
                    <Card.Title>Long Term Pricing</Card.Title>
                    <Card.Body>
                        <Row>
                            <Col sm={6} md={6}>
                                <h5>Weekly - 7+ Nights</h5>
                                <Input className="mbsc-remove-margins" placeholder="Enter the Percentage Discount on Nightly Rates" type="number" min="0" max="100" id="pricingOneNightSevenPlus" name="pricingOneNightSevenPlus" />
                            </Col>
                            <Col sm={6} md={6}>
                                <h5>Monthly - 30+ Nights</h5>
                                <Input className="mbsc-remove-margins" placeholder="Enter the Percentage Discount on Nightly Rates" type="number" min="0" max="100" id="pricingOneNightThirtyPlus" name="pricingOneNightThirtyPlus" />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card style={{ margin: "10px" }}>
                    <Card.Title>Additional Options</Card.Title>
                    <Card.Body>
                        <Row>
                            <Col sm={6} md={6}>
                                <h5>Allow Additional Guests</h5>
                            </Col>
                            <Col sm={6} md={6}>
                                <h5>Charge GST</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3} md={3}>
                                <RadioGroup name="additionalguestgroup">
                                    <Radio label="Yes" defaultChecked={additionalGuest} id="allowAdditionalGuestsTrue" value="Yes" />
                                </RadioGroup>
                            </Col>
                            <Col sm={3} md={3}>
                                <RadioGroup name="additionalguestgroup">
                                    <Radio label="No" defaultChecked={!additionalGuest} id="allowAdditionalGuestsFalse" value="No" />
                                </RadioGroup>
                            </Col>
                            <Col sm={3} md={3}>
                                <RadioGroup name="chargegstgroup">
                                    <Radio label="Yes" defaultChecked={chargeGST} id="chargeGSTTrue" value="Yes" />
                                </RadioGroup>
                            </Col>
                            <Col sm={3} md={3}>
                                <RadioGroup name="chargegstgroup">
                                    <Radio label="No" defaultChecked={!chargeGST} id="chargeGSTFalse" value="No" />
                                </RadioGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} md={6}>
                                <h5>Standard Number of Guests</h5>
                                <Input className="mbsc-remove-margins" placeholder="Standard Number of Guests" type="number" id="pricingStandardNumberOfGuests" name="pricingStandardNumberOfGuests" />
                            </Col>
                            <Col sm={6} md={6}>
                                <h5>Maximum Number of Guests</h5>
                                <Input className="mbsc-remove-margins" placeholder="Maximum Number of Guests" type="number" id="pricingMaximumNumberOfGuests" name="pricingMaximumNumberOfGuests" />
                            </Col>
                        </Row>
                        <Row>
                            {/*<Col sm={6} md={6}>*/}
                            {/*    <h5>Bond</h5>*/}
                            {/*    <Input className="mbsc-remove-margins" placeholder="Enter the value of the security deposit" type="number" id="pricingSecurityDeposit" name="pricingSecurityDeposit" />*/}
                            {/*</Col>*/}
                            <Col sm={6} md={6}>
                                <h5>Additional Guest Cost</h5>
                                <Input className="mbsc-remove-margins" placeholder="Enter the Unit Price for 1 Additional Guest" type="number" id="pricingPneAdditionalGuest" name="pricingPneAdditionalGuest" />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {fees.map((fee, index) => {
                    return (
                        <Card style={{ margin: "10px" }}>
                            <Card.Title>Additional Fees</Card.Title>
                            <Card.Body>
                                <Form>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlID="formGridState">
                                            <Form.Label><h5>Description</h5></Form.Label>
                                            <Form.Control id="description" type="text" placeholder="Enter Fee Description" name="description" value={fee.description} onChange={event => handleFeeChange(event, index)} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlID="formGridState">
                                            <Form.Label><h5>Amount</h5></Form.Label>
                                            <InputGroup>
                                                <Form.Control type="number" placeholder="Enter Fee Amount in Dollars" name="amount" value={fee.amount} onChange={event => handleFeeChange(event, index)} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlID="formGridState">
                                            <Form.Label><h5>Frequency</h5></Form.Label>
                                            <select className="form-control" name="frequency" value={fee.frequency} onChange={event => handleFeeChange(event, index)}>
                                                <option selected>Select</option>
                                                <option value="Per Stay">Per Stay</option>
                                                <option value="Per Night">Per Night</option>
                                                <option value="Per Guest">Per Guest</option>
                                                <option value="Per Guest Per Night">Per Guest Per Night</option>
                                                <option value="Per Guest Extra">Per Guest Extra</option>
                                                <option value="Per Guest Per Night Extra">Per Guest Per Night Extra</option>
                                            </select>
                                        </Form.Group>
                                        <Form.Group as={Col} controlID="formGridState">
                                            <Form.Label><h5 style={{ display: 'inline' }}>Charge Type</h5>
                                                &nbsp;&nbsp;
                                                <OverlayTrigger trigger="click" placement="auto" overlay={popoverChargeType}>
                                                    <Button style={{ paddingTop: '0px' }} variant="link"><FaQuestionCircle /></Button>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <select className="form-control" name="chargeType" value={fee.chargeType} onChange={event => handleFeeChange(event, index)}>
                                                <option selected>Select</option>
                                                <option value="Included">Included</option>
                                                <option value="Excluded">Excluded</option>
                                                <option value="Conditional">Conditional</option>
                                            </select>
                                        </Form.Group>
                                        <Form.Group as={Col} controlID="formGridState">
                                            <Form.Label><h5 style={{display: 'inline'}}>Charged At</h5>
                                                &nbsp;&nbsp;
                                                <OverlayTrigger trigger="click" placement="auto" overlay={popoverChargedAt}>
                                                    <Button style={{ paddingTop: '0px' }} variant="link"><FaQuestionCircle /></Button>
                                                </OverlayTrigger>

                                            </Form.Label>
                                            <select className="form-control" name="chargedAt" value={fee.chargedAt} onChange={event => handleFeeChange(event, index)}>
                                                <option selected>Select</option>
                                                <option value="Booking">Booking</option>
                                                <option value="Arrival">Arrival</option>
                                            </select>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <div style={{ display: "flex" }}>
                                            <Button variant="info" style={{ width: "150px", fontSize: "12px" }} onClick={() => removeFee(fee, index)} >Remove This Fee</Button>
                                        </div>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    )
                })}
                <Card style={{ margin: "10px", backgroundColor: '#f7f8f9', border: "none" }}>
                    <div style={{ display: "flex" }}>
                        <Button variant="info" style={{ marginLeft: "auto", width: "150px", fontSize: "12px" }} onClick={newFee}>Add Additional Fee</Button>
                    </div>
                </Card>
                {taxes.map((tax, index) => {
                    return (
                        <Card style={{ margin: "10px" }}>
                            <Card.Title>Additional Taxes</Card.Title>
                            <Card.Body>
                                <Form>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlID="formGridState">
                                            <Form.Label><h5>Description</h5></Form.Label>
                                            <Form.Control id="description" type="text" placeholder="Enter Tax Description" name="description" value={tax.description} onChange={event => handleTaxChange(event, index)} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlID="formGridState">
                                            <Form.Label><h5>Percentage</h5></Form.Label>
                                            <InputGroup>
                                                <Form.Control type="number" placeholder="Enter Tax As A Percentage" name="percentage" value={tax.percentage} onChange={event => handleTaxChange(event, index)} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlID="formGridState">
                                            <Form.Label><h5>Frequency</h5></Form.Label>
                                            <select className="form-control" name="frequency" onChange={event => handleTaxChange(event, index)}>
                                                <option selected>Select</option>
                                                <option value="Per Stay">Per Stay</option>
                                                <option value="Per Night">Per Night</option>
                                                <option value="Per Guest">Per Guest</option>
                                                <option value="Per Guest Per Night">Per Guest Per Night</option>
                                                <option value="Per Guest Extra">Per Guest Extra</option>
                                                <option value="Per Guest Per Night Extra">Per Guest Per Night Extra</option>
                                            </select>
                                        </Form.Group>
                                        <Form.Group as={Col} controlID="formGridState">
                                            <Form.Label><h5 style={{ display: 'inline' }}>Charge Type</h5>
                                                &nbsp;&nbsp;
                                                <OverlayTrigger trigger="click" placement="auto" overlay={popoverChargeType}>
                                                    <Button style={{ paddingTop: '0px' }} variant="link"><FaQuestionCircle /></Button>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <select className="form-control" name="chargeType" onChange={event => handleTaxChange(event, index)}>
                                                <option selected>Select</option>
                                                <option value="Included">Included</option>
                                                <option value="Excluded">Excluded</option>
                                                <option value="Conditional">Conditional</option>
                                            </select>
                                        </Form.Group>
                                        <Form.Group as={Col} controlID="formGridState">
                                            <Form.Label><h5 style={{ display: 'inline' }}>Charged At</h5>
                                                &nbsp;&nbsp;
                                                <OverlayTrigger trigger="click" placement="auto" overlay={popoverChargedAt}>
                                                    <Button style={{ paddingTop: '0px' }} variant="link"><FaQuestionCircle /></Button>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <select className="form-control" name="chargedAt" onChange={event => handleTaxChange(event, index)}>
                                                <option selected>Select</option>
                                                <option value="Booking">Booking</option>
                                                <option value="Arrival">Arrival</option>
                                            </select>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <div style={{ display: "flex" }}>
                                            <Button variant="info" style={{ width: "150px", fontSize: "12px" }} onClick={() => removeTax(tax, index)} >Remove This Tax</Button>
                                        </div>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    )
                })}
                <Card style={{ margin: "10px", backgroundColor: '#f7f8f9', border: "none" }}>
                    <div style={{ display: "flex" }}>
                        <Button variant="info" style={{ marginLeft: "auto", width: "150px", fontSize: "12px" }} onClick={newTax}>Add Additional Tax</Button>
                    </div>
                </Card>
                {periods.map((period, index) => {
                    return (
                        <Card style={{ margin: "10px" }}>
                            <Card.Title>Custom Period</Card.Title>
                            <Card.Body>
                                <Form>
                                    <Row className="mb-3" style={{ backgroundColor: "lightgray" }}>
                                        <div style={{ align: "center" }}>
                                            <DateRangePicker
                                                startDate={period.startDate}
                                                startDateId="tata-start-date"
                                                endDate={period.endDate}
                                                endDateId="tata-end-date"
                                                onDatesChange={({ startDate, endDate }) => onDatesChange(index, { startDate, endDate })}
                                                focusedInput={focusedInput[index]}
                                                onFocusChange={(focusedInput) => onFocusChange(index, focusedInput)}
                                                displayFormat="DD/MM/yy"
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlID="formGridState">
                                            <Form.Label><h5>Nightly</h5></Form.Label>
                                            <Form.Control id="nightly" type="number" placeholder="Enter Price For One Night" name="nightly" value={period.nightly} onChange={event => handlePeriodChange(event, index)} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlID="formGridState">
                                            <Form.Label><h5>Weekends</h5></Form.Label>
                                            <Form.Control id="weekends" type="number" placeholder="Enter Price For A Single Day" name="weekends" value={period.weekends} onChange={event => handlePeriodChange(event, index)} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlID="formGridState">
                                            <Form.Label><h5>Minimum Days Of Booking</h5></Form.Label>
                                            <Form.Control id="minimumDaysOfBooking" type="number" placeholder="Minimum Stay For This Period" name="minimumBooking" value={period.minimumBooking} onChange={event => handlePeriodChange(event, index)} />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Button variant="info" style={{ width: "150px", fontSize: "12px" }} onClick={() => removePeriod(period, index)} >Remove This Custom Period</Button>
                                            {/*<Button variant="info" style={{width: "150px", fontSize: "12px"}} onClick={() => savePeriod()} >Save This Custom Period</Button>*/}
                                        </div>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    )
                })}
                <Card style={{ margin: "10px", backgroundColor: '#f7f8f9', border: "none" }}>
                    <div style={{ display: "flex" }}>
                        <Button variant="info" style={{ marginLeft: "auto", width: "150px", fontSize: "12px" }} onClick={newPeriod}>Add Custom Period</Button>
                    </div>
                </Card>
            </Col>
            <br />
            <div style={{ display: pageValid, width: '100%' }}>
                <Alert variant="danger" >
                    Unfortunately there are errors on the page!
                </Alert>
            </div>
        </div>
    )
}

export default Pricing