import axios from 'axios';

import { BASE_URL } from './GlobalDefinitions';
//import { AUTH, ORIGIN, METHODS_GET, METHODS_POST, METHODS_PUT, METHODS_DELETE, CONTENT_TYPE } from './Headers';
import { AUTH, CONTENT_TYPE, METHODS_DELETE, METHODS_GET, METHODS_POST, ORIGIN } from './Headers';

const BLOG_API_BASE_URL = "/blogs";
const BLOG_UPDATE_API_BASE_URL = "/blogsupdate";
const BLOG_ID_API_BASE_URL = "/blogsid";
const BLOG_LOCATION_API_BASE_URL = "/bloglocation";
const BLOG_DETAIL_API_BASE_URL = "/blogsdetail";
const BLOG_MEDIA_API_BASE_URL = "/blogmedia";
const BLOG_BY_USER_API_BASE_URL = "/blogsbyuser";
const BLOG_PUBLISHED_API_BASE_URL = "/blogspublishedlimited";


class BlogService {
    // get all users
    getBlogs() {
        return axios.get(BASE_URL + BLOG_API_BASE_URL, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    getBlogsDetail() {
        return axios.get(BASE_URL + BLOG_DETAIL_API_BASE_URL, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    getBlogsPublished(limit) {
        return axios.get(BASE_URL + BLOG_PUBLISHED_API_BASE_URL, {
            params: {
                Limit: limit
            },
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // create a new user
    createBlog(blog) {
        return axios.post(BASE_URL + BLOG_API_BASE_URL, blog, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }

    // update blog
    updateBlog(blog) {
        return axios.post(BASE_URL + BLOG_UPDATE_API_BASE_URL, blog, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }

    // get user by Username
    getBlogByID(id) {
        return axios.get(BASE_URL + BLOG_ID_API_BASE_URL, {
            params: {
                ID: id
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get blog by user
    getBlogsDetailByUser(uuid) {
        return axios.get(BASE_URL + BLOG_BY_USER_API_BASE_URL, {
            params: {
                CustomerID: uuid
            },
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get blog location by ID
    getBlogLocationByID(id) {
        return axios.get(BASE_URL + BLOG_LOCATION_API_BASE_URL, {
            params: {
                ID: id
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // delete blog
    deleteBlog(id) {
        return axios.delete(BASE_URL + BLOG_API_BASE_URL, {
            params: {
                ID: id
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get blog media by BlogID
    getBlogMediaByID(id) {
        return axios.get(BASE_URL + BLOG_MEDIA_API_BASE_URL, {
            params: {
                ID: id
            }
            ,
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // delete blog
    deleteBlogMediaByID(id) {
        return axios.delete(BASE_URL + BLOG_MEDIA_API_BASE_URL, {
            params: {
                ID: id
            },
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }
}

export default new BlogService()