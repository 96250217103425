import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Tabs, Tab, Button, Row, Col, Alert, Modal } from 'react-bootstrap';
import SideNav from "./../modals/SideNav";

import { Link } from 'react-router-dom';

import Information from '../items/addlisting/information';
import Pricing from '../items/addlisting/pricing';
import Location from '../items/addlisting/location';
import Features from './../items/addlisting/features';
import Media from './../items/addlisting/media';
import Bedrooms from './../items/addlisting/bedrooms';
import TermsAndConditions from '../items/addlisting/termsandconditions';
import Calendar from '../items/addlisting/calendar';

// services
import UserDetail from "./../functions/User";
import Listing from './../functions/Listing';
import Category from './../functions/Category';
import Feature from './../functions/Feature';

import { Grid } from "react-loader-spinner";

export function withRouter(Children) {
    return (props) => {
        return <Children {...props} location={props} />
    }
}

class AddNewListing extends Component {
    constructor(props) {
        super(props);

        this.inforef = React.createRef();
        this.priceref = React.createRef();
        this.mediaref = React.createRef();
        this.locationref = React.createRef();
        this.tandcref = React.createRef();

        this.state = {
            email: this.props.location.state.email,
            loggedIn: this.props.location.state.loggedIn,
            key: '0',
            tabCount: '8',
            loginType: this.props.location.state.logginType,
            description: '',
            listingType: '',
            listingOwner: null,

            // features
            categories: [],
            featuresList: [],
            selectedFeatures: [],

            // media
            primaryImage: null,
            images: [],

            // pricing
            instantBooking: false,
            nightlyRate: "Nightly",
            weekendRate: "Saturday and Sunday",

            fees: [],
            taxes: [],
            periods: [],
            rooms: [],

            blockedDates: [],

            showLoading: 'none',
            showResultMessage: 'none',
            cropArea: '',
            showTabs: 'none',
            showProfileUpdate: false,
            /*showButtons: 'none',*/
            
        }
    }

    componentDidMount() {

        this.setState({
            showLoading: 'block'
        })

        //confirm the host has added in the bank details before creating a listing
        UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {
            if (result.data) {
                //console.log(result.data);
                //check bsb and account exist or hide form and show error with link to profile
                if (!result.data.BSB || !result.data.AccountNo) {
                    this.setState({
                        showResultMessage: 'none',
                        showButtons: 'none',
                        showLoading: 'none',
                        showTabs: 'none',
                        showProfileUpdate: true
                    })
                } else {
                    this.setState({
                        showResultMessage: 'none',
                        /*showButtons: 'block',*/
                        showLoading: 'none',
                        showTabs: 'block',
                        showProfileUpdate: false
                    })
                }
            }
        });

        Category.getCategories().then((result) => {
            this.setState({categories: result.data});
        });

        Feature.getFeatures().then((result) => {
            this.setState({featuresList: result.data});
        });

    }
    /**
     * Change the tab and validate as required
     * @param {any} event
     */
    setKey = (event) => {
        //validate the current page
        //information
        if (this.state.key == 0) { //information
            //call a validate function before changing key
            if (this.inforef.current()) {
                this.saveDraftListing();
                this.setState({ key: event });
            }
        } else if (this.state.key == 1) { //pricing
            //call a validate function before changing key
            if (this.priceref.current()) {
                this.updateListingPricing();
                this.setState({ key: event });
            }
        } else if (this.state.key == 2) { //media
            //call a validate function before changing key
            //check if primary is set
            if (this.state.primaryImage) {
                this.updateListingMedia();
                this.setState({ key: event });
                this.mediaref.current(true);
            } else {
                this.mediaref.current(false);
            }
        } else if (this.state.key == 3) { //features
            //call a validate function before changing key
            this.updateListingFeatures();
            this.setState({ key: event });
        } else if (this.state.key == 4) { //location
            //call a validate function before changing key
            if (this.locationref.current()) {
                this.updateListingLocation();
                this.setState({ key: event });
            }
        } else if (this.state.key == 5) { //rooms
            //call a validate function before changing key
            this.updateListingRooms();
            this.setState({ key: event });
        } else if (this.state.key == 6) { //terms & conditions
            //call a validate function before changing key
            if (this.tandcref.current()) {
                this.updateListingTermsAndConditions();
                this.setState({ key: event });
            }
        //} else if (this.state.key == 7) { //calendar
        //    //call a validate function before changing key
        //    this.updateListingCalendar();
        //    //call to publish - this requires checking all tabs
        //    this.submitListing();

        //    //this.setState({ key: event });
        } else {
            this.setState({ key: event });
        }

    }

    hideProfileUpdate = () => this.setState({ showProfileUpdate: false });

    /**
     * Create the listing after validating all information has been entered
     * */
    saveDraftListing = () => {
        //show page loading and disable save button
        this.setState({ showLoading: 'block' });

        //check if all fields have been validated
        //if (this.inforef.current() && this.priceref.current() && this.locationref.current() && this.tandcref.current()) {
            //then process
            try {
                // information data
                const information = this.getInformation();

                // pricing data
                const pricing = this.getPricing();

                // image data
                let imageIndex = 0;

                this.state.images.forEach((element, index) => {
                    if (element.name === this.state.primaryImage.name) {
                        imageIndex = index;
                    }
                });

                this.setState({ Images: this.state.images.splice(imageIndex, 1) });

                // location data
                const location = this.getLocationData();

                const termsAndCondtionsValues = this.getTermsAndConditions();

                Listing.createDraftListing(this.state.email,
                    information, this.state.selectedFeatures,
                    this.state.primaryImage, this.state.cropArea, this.state.images,
                    pricing, this.state.fees, this.state.taxes, this.state.periods,
                    location,
                    this.state.rooms, termsAndCondtionsValues, this.state.blockedDates).then(result => {
                        //console.log('save result: ' + JSON.stringify(result));

                        var css = '';
                        var message = '';

                        //if result is good
                        if (result && result.data && typeof (result.data) != 'string' ) {

                            //console.log('save result: ' + JSON.stringify(result));

                            this.setState({ listingOwner: result.data });

                            //check tab is first or last to display correct message
                            if (this.state.key == 0) {
                                css = 'success';
                                message = 'Congratulations, your listing has been created in draft, please complete all tabs and submit for review!';
                            //} else if (this.state.key == 7) {
                            //    css = 'success';
                            //    message = 'Congratulations, your listing has been submitted for review and will be published shortly!';
                            //    this.setState({
                            //        showButtons: 'none',
                            //        showDetail: 'none'
                            //    });
                            }
                        } else {
                            css = 'danger';
                            message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';

                            this.setState({
                                showButtons: 'none',
                                showDetail: 'none'
                            });

                        }

                        this.setState({
                            showLoading: 'none',
                            showResultMessage: 'inline',
                            resultMessageClass: css,
                            resultMessage: message,
                            //showButtons: 'none',
                            //showDetail: 'none'
                        });
                    });
            } catch (error) {
                this.setState({
                    showLoading: 'none',
                    showResultMessage: 'inline',
                    resultMessageClass: 'danger',
                    resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
                });

            }
        //} else {
        //    //show error there is missing data
        //    this.setState({
        //        showLoading: 'none',
        //        showResultMessage: 'inline',
        //        resultMessageClass: 'danger',
        //        resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
        //    });
        //}

    }

    submitListing = () => {

        //alert('submit');
        this.updateListingCalendar();
        //show page loading and disable save button
        this.setState({ showLoading: 'block' });

        //check if all fields have been validated
        if (this.inforef.current() && this.priceref.current() && this.locationref.current() && this.tandcref.current()) {
            //then process
            try {
                //// information data
                //const information = this.getInformation();

                //// pricing data
                //const pricing = this.getPricing();

                //// image data
                //let imageIndex = 0;

                //this.state.images.forEach((element, index) => {
                //    if (element.name === this.state.primaryImage.name) {
                //        imageIndex = index;
                //    }
                //});

                //this.setState({ Images: this.state.images.splice(imageIndex, 1) });

                //// location data
                //const location = this.getLocationData();

                //const termsAndCondtionsValues = this.getTermsAndConditions();

                Listing.UpdateListingState(this.state.listingOwner.UUID, "draft", false).then((result) => {

                    //    reloadListings();
                    //});
                    //console.log('save result: ' + JSON.stringify(result));

                    var css = '';
                    var message = '';

                    //if result is good
                    if (result) {

                        //this.setState({ listingOwner: result.data });

                        //check tab is first or last to display correct message
                        //if (this.state.key == 0) {
                        //    css = 'success';
                        //    message = 'Congratulations, your listing has been created in draft, please complete all tabs and submit for review!';
                        //} else if (this.state.key == 7) {
                        css = 'success';
                        message = 'Congratulations, your listing has been submitted for review and will be published shortly!';
                        this.setState({
                            showButtons: 'none',
                            showDetail: 'none'
                        });
                        //}
                    } else {
                        css = 'danger';
                        message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                    }

                    this.setState({
                        showLoading: 'none',
                        showResultMessage: 'inline',
                        resultMessageClass: css,
                        resultMessage: message,
                        showButtons: 'none',
                        showDetail: 'none'
                    });
                });
            } catch (error) {
                this.setState({
                    showLoading: 'none',
                    showResultMessage: 'inline',
                    resultMessageClass: 'danger',
                    resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
                });

            }
        } else {
            //show error there is missing data
            this.setState({
                showLoading: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });
        }

    }

    updateListingInformation = () => {

        //show page loading and disable save button
        this.setState({
            showLoading: 'block',
            showLoadingMedia: 'block'
        });
        //check if all fields have been validated
        try {
            // information data
            const information = this.getInformation();

            Listing.UpdateListingInformation(this.state.listingOwner, information).then(result => {

                var css = '';
                var message = '';

                //if result is good
                if (result && !result.data.includes('failed')) {
                    css = 'success';
                    message = 'Congratulations, your listing has been created, we will review and publish soon!';
                    this.setState({
                        showLoading: 'none',
                        showLoadingMedia: 'none',
                    });
                } else {
                    css = 'danger';
                    message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                    this.setState({
                        showLoading: 'none',
                        showLoadingMedia: 'none',
                        showResultMessage: 'inline',
                        resultMessageClass: css,
                        resultMessage: message,
                        showButtons: 'none',
                        showDetail: 'none'
                    });
                }

            });
        } catch (error) {

            this.setState({
                showLoading: 'none',
                showLoadingMedia: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });

        }

    }

    updateListingPricing = () => {

        //show page loading and disable save button
        this.setState({
            showLoading: 'block',
            showLoadingMedia: 'block'
        });
        //check if all fields have been validated
        try {
            // pricing data
            const pricing = this.getPricing();

            Listing.UpdateListingPricing(this.state.listingOwner,
                pricing, this.state.fees, this.state.taxes, this.state.periods).then(result => {

                    var css = '';
                    var message = '';

                    //if result is good
                    if (result && !result.data.includes('failed')) {
                        css = 'success';
                        message = 'Congratulations, your listing has been created, we will review and publish soon!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                        });
                    } else {
                        css = 'danger';
                        message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                            showResultMessage: 'inline',
                            resultMessageClass: css,
                            resultMessage: message,
                            showButtons: 'none',
                            showDetail: 'none'
                        });
                    }

                });
        } catch (error) {

            this.setState({
                showLoading: 'none',
                showLoadingMedia: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });

        }

    }

    updateListingMedia = () => {

        //show page loading and disable save button
        this.setState({
            showLoading: 'block',
            showLoadingMedia: 'block'
        });
        //check if all fields have been validated
        try {
            //// image data
            //this.state.images.forEach((element, index) => {
            //    if (element.name === this.state.primaryImage.name) {
            //        imageIndex = index;
            //    }
            //});

            var spliceImages = this.state.images.filter(filter => filter.name !== this.state.primaryImage.name);

            this.setState({ images: spliceImages });

            Listing.UpdateListingMedia(this.state.listingOwner,
                this.state.primaryImage, this.state.cropArea, spliceImages).then(result => {

                    var css = '';
                    var message = '';

                    //if result is good
                    if (result && !result.data.includes('failed')) {
                        css = 'success';
                        message = 'Congratulations, your listing has been created, we will review and publish soon!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                        });
                    } else {
                        css = 'danger';
                        message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                            showResultMessage: 'inline',
                            resultMessageClass: css,
                            resultMessage: message,
                            showButtons: 'none',
                            showDetail: 'none'
                        });
                    }

                });
        } catch (error) {

            this.setState({
                showLoading: 'none',
                showLoadingMedia: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });

        }

    }

    updateListingFeatures = () => {

        //show page loading and disable save button
        this.setState({
            showLoading: 'block',
            showLoadingMedia: 'block'
        });
        //check if all fields have been validated
        try {

            Listing.UpdateListingFeatures(this.state.listingOwner, this.state.selectedFeatures).then(result => {

                var css = '';
                var message = '';

                //if result is good
                if (result && !result.data.includes('failed')) {
                    css = 'success';
                    message = 'Congratulations, your listing has been created, we will review and publish soon!';
                    this.setState({
                        showLoading: 'none',
                        showLoadingMedia: 'none',
                    });
                } else {
                    css = 'danger';
                    message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                    this.setState({
                        showLoading: 'none',
                        showLoadingMedia: 'none',
                        showResultMessage: 'inline',
                        resultMessageClass: css,
                        resultMessage: message,
                        showButtons: 'none',
                        showDetail: 'none'
                    });
                }

            });
        } catch (error) {

            this.setState({
                showLoading: 'none',
                showLoadingMedia: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });

        }

    }

    updateListingLocation = () => {

        //show page loading and disable save button
        this.setState({
            showLoading: 'block',
            showLoadingMedia: 'block'
        });
        //check if all fields have been validated
        try {

            // location data
            const location = this.getLocationData();

            Listing.UpdateListingLocation(this.state.listingOwner, location).then(result => {

                var css = '';
                var message = '';

                //if result is good
                if (result && !result.data.includes('failed')) {
                    css = 'success';
                    message = 'Congratulations, your listing has been created, we will review and publish soon!';
                    this.setState({
                        showLoading: 'none',
                        showLoadingMedia: 'none',
                    });
                } else {
                    css = 'danger';
                    message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                    this.setState({
                        showLoading: 'none',
                        showLoadingMedia: 'none',
                        showResultMessage: 'inline',
                        resultMessageClass: css,
                        resultMessage: message,
                        showButtons: 'none',
                        showDetail: 'none'
                    });
                }

            });
        } catch (error) {

            this.setState({
                showLoading: 'none',
                showLoadingMedia: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });

        }

    }

    updateListingRooms = () => {

        //show page loading and disable save button
        this.setState({
            showLoading: 'block',
            showLoadingMedia: 'block'
        });
        //check if all fields have been validated
        try {

            Listing.UpdateListingRooms(this.state.listingOwner, this.state.rooms).then(result => {

                var css = '';
                var message = '';

                //if result is good
                if (result && !result.data.includes('failed')) {
                    css = 'success';
                    message = 'Congratulations, your listing has been created, we will review and publish soon!';
                    this.setState({
                        showLoading: 'none',
                        showLoadingMedia: 'none',
                    });
                } else {
                    css = 'danger';
                    message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                    this.setState({
                        showLoading: 'none',
                        showLoadingMedia: 'none',
                        showResultMessage: 'inline',
                        resultMessageClass: css,
                        resultMessage: message,
                        showButtons: 'none',
                        showDetail: 'none'
                    });
                }

            });
        } catch (error) {

            this.setState({
                showLoading: 'none',
                showLoadingMedia: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });

        }

    }

    updateListingTermsAndConditions = () => {

        //show page loading and disable save button
        this.setState({
            showLoading: 'block',
            showLoadingMedia: 'block'
        });
        //check if all fields have been validated
        try {

            const termsAndCondtionsValues = this.getTermsAndConditions();

            Listing.UpdateListingTermsAndConditions(this.state.listingOwner, termsAndCondtionsValues).then(result => {

                var css = '';
                var message = '';

                //if result is good
                if (result && !result.data.includes('failed')) {
                    css = 'success';
                    message = 'Congratulations, your listing has been created, we will review and publish soon!';
                    this.setState({
                        showLoading: 'none',
                        showLoadingMedia: 'none',
                    });

                    //added after system built to accomodate deposit in T&C due to pricing being removed
                    let depositpercentage = document.getElementById('tacDeposit').value;
                    if (document.getElementById('tacDeposit').value == "") {
                        depositpercentage = "20";
                    }

                    Listing.UpdateColumnEntryValue(this.state.listingOwner.UUID, "deposit_percentage", depositpercentage).then(result => {
                        if (result && result.data && result.data.includes('listing value updated')) {
                            css = 'success';
                            message = 'Congratulations, your listing has been created, we will review and publish soon!';
                            this.setState({
                                showLoading: 'none',
                                showLoadingMedia: 'none',
                            });

                            //added after system built to accomodate security deposit (BOND) in T&C due to pricing being removed
                            let securitydepositpercentage = document.getElementById('tacSecurityDeposit').value;

                            Listing.UpdateColumnEntryValue(this.state.listingOwner.UUID, "security_deposit", securitydepositpercentage).then(result => {
                                if (result && result.data && result.data.includes('listing value updated')) {
                                    css = 'success';
                                    message = 'Congratulations, your listing has been created, we will review and publish soon!';
                                    this.setState({
                                        showLoading: 'none',
                                        showLoadingMedia: 'none',
                                    });
                                } else {
                                    css = 'danger';
                                    message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                                    this.setState({
                                        showLoading: 'none',
                                        showLoadingMedia: 'none',
                                        showResultMessage: 'inline',
                                        resultMessageClass: css,
                                        resultMessage: message,
                                        showButtons: 'none',
                                        showDetail: 'none'
                                    });
                                }
                            })
                        } else {
                            css = 'danger';
                            message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                            this.setState({
                                showLoading: 'none',
                                showLoadingMedia: 'none',
                                showResultMessage: 'inline',
                                resultMessageClass: css,
                                resultMessage: message,
                                showButtons: 'none',
                                showDetail: 'none'
                            });
                        }
                    })

                } else {
                    css = 'danger';
                    message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                    this.setState({
                        showLoading: 'none',
                        showLoadingMedia: 'none',
                        showResultMessage: 'inline',
                        resultMessageClass: css,
                        resultMessage: message,
                        showButtons: 'none',
                        showDetail: 'none'
                    });
                }

            });
        } catch (error) {

            this.setState({
                showLoading: 'none',
                showLoadingMedia: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });

        }

    }

    updateListingCalendar = () => {
        //show page loading and disable save button
        this.setState({ showLoading: 'block' });
        //check if all fields have been validated
        try {

            Listing.UpdateListingCalendar(this.state.listingOwner, this.state.blockedDates).then(result => {

                var css = '';
                var message = '';

                //if result is good
                if (result && !result.data.includes('failed')) {
                    css = 'success';
                    message = 'Congratulations, your listing has been created, we will review and publish soon!';
                    this.setState({
                        showLoading: 'none',
                    });
                } else {
                    css = 'danger';
                    message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                    this.setState({
                        showLoading: 'none',
                        showResultMessage: 'inline',
                        resultMessageClass: css,
                        resultMessage: message,
                        showButtons: 'none',
                        showDetail: 'none'
                    });
                }

            });
        } catch (error) {

            this.setState({
                showLoading: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });

        }

    }

    getInformation = () => {
        let entireplace = document.getElementById('private-room').value;
        if (document.getElementById('entire-place').checked) {
            entireplace = document.getElementById('entire-place').value;
        }
        const information = {
            Kind: entireplace,
            Title: document.getElementById('informationtitle').value,
            Description: this.state.description,
            TypeOfListing: this.state.listingType,
            NumberOfBedrooms: document.getElementById('informationnumberofbedrooms').value,
            NumberOfBeds: document.getElementById('informationnumberofbeds').value,
            NumberOfBathrooms: document.getElementById('informationnumberofbathroosm').value
        }

        return information;
    }

    getPricing = () => {

        let instantBooking = false; 
        if (document.getElementById('instantbookYes').checked) {
            instantBooking = true; 
        }

        let allowAdditionalGuests = document.getElementById('allowAdditionalGuestsFalse').value;
        if (document.getElementById('allowAdditionalGuestsTrue').checked) {
            allowAdditionalGuests = document.getElementById('allowAdditionalGuestsTrue').value;
        }

        let chargeGST = false;// document.getElementById('chargeGSTFalse').value;
        if (document.getElementById('chargeGSTTrue').checked) {
            chargeGST = true; // document.getElementById('chargeGSTTrue').value;
        }

        //let cleaningRate = document.getElementById('cleaningRateDaily').value;
        //if ( document.getElementById('cleaningRateDaily').checked) {
        //    cleaningRate = document.getElementById('cleaningRatePerStay').value;
        //}

        //let depositpercentage = document.getElementById('pricingDeposit').value;
        //let depositpercentage = "20";

        let depositpercentage = document.getElementById('tacDeposit').value;
        if (document.getElementById('tacDeposit').value == "") {
            depositpercentage = "20";
        }

        let securitydepositpercentage = document.getElementById('tacSecurityDeposit').value;
        
        //if (document.getElementById('pricingDeposit').value && document.getElementById('pricingDeposit').value != "") {
        //    depositpercentage = document.getElementById('pricingDeposit').value;
        //}


        const pricing = {
            InstantBooking: instantBooking,//this.state.instantBooking,
            Nightly: document.getElementById('pricingOneNightNightly').value,
            NightRate: this.state.nightlyRate,
            Weekend: document.getElementById('pricingOneNightWeekend').value,
            WeekendRate: this.state.weekendRate,
            WeeklyRate: document.getElementById('pricingOneNightSevenPlus').value,
            MonthlyRate: document.getElementById('pricingOneNightThirtyPlus').value,
            AllowAdditionalGuests: allowAdditionalGuests,
            AdditionalGuestCost: document.getElementById('pricingPneAdditionalGuest').value,
            StandardNumberOfGuests: document.getElementById('pricingStandardNumberOfGuests').value,
            MaximumNumberOfGuests: document.getElementById('pricingMaximumNumberOfGuests').value,
            //CleaningFee: document.getElementById('pricingCleaningFee').value,
            //CleaningRate: cleaningRate,
            SecurityDeposit: securitydepositpercentage,
            DepositPercentage: depositpercentage,
            GSTFlag: chargeGST
        };

        return pricing;
    }

    addImage = (image) => {        
        this.state.images.push(image);
    }

    updateImageArray = (arr) => {
        var tempArr = [];

        arr.map((image, index) => {
            tempArr.push(image.data);
        })

        this.setState({ images: tempArr });
    }

    deleteImage = (image) => {
        //this.state.images.push(image);
        this.setState({
            images: this.state.images.filter(filter => filter !== image)
        })

        //setListings(listings.filter(listing => listing.ListingID !== id));
    }

    onSelectImage = (image) => {
        this.setState({ primaryImage: image.data });
        console.log('primary set: ' + image.data);
    }

    onCropImage = (value) => {
        console.log('crop area here: ' + value);

        this.setState({ cropArea: value });
    }

    selectFeature = (event) => {
        if (event.target.checked) {
            this.state.selectedFeatures.push(event.target.id);
        } else {
            const newList = this.state.selectedFeatures.splice(this.state.selectedFeatures.indexOf(event.target.id), 1);
            this.setState({selectedFeatures: newList});
        }
    }

    getLocationData = () => {
        const geoLocation = document.getElementById('geoLocation').value;
        const attempt = 0;

        while ( geoLocation === null && attempt < 5) {
            geoLocation = document.getElementById('geoLocation').value;
            attempt ++;
        }

        const location = {
            Address: document.getElementById('listingAddress').value,
            Apartment: document.getElementById('listingUnit').value,
            City: document.getElementById('listingCity').value,
            State: document.getElementById('listingState').value,
            PostCode: document.getElementById('listingPostCode').value,
            Area: document.getElementById('listingArea').value,
            Country: document.getElementById('listingCountry').value,
            Location: geoLocation,
            PlaceID: document.getElementById('placeID').value
        }

        return location;
        
    }

    getTermsAndConditions = () => {
        let smoking = '';
        let pets = '';
        let party = '';
        let children = '';
        if (document.getElementById('smokingyes').checked) {
            smoking = "Yes";
        }
        else {
            smoking = "No";
        }
        if (document.getElementById('petsyes').checked) {
            pets = "Yes";
        } else {
            pets = "No";
        }
        if (document.getElementById('partyyes').checked) {
            party = "Yes";
        } else {
            party = "No";
        }
        if (document.getElementById('childrenyes').checked) {
            children = "Yes";
        } else {
            children = "No";
        }
        
        const termsAndCondtionsValues = {
            Cancellation: document.getElementById('mincancellation').value,
            MinimumNotice: document.getElementById('minimumNotice').value,
            MaxBookingWindows: document.getElementById('maximumWindow').value,
            MinimumBooking: document.getElementById('minbooking').value,
            MaximumBooking: document.getElementById('maxbooking').value,
            CheckInAfter: document.getElementById('checkInAfter').value,
            CheckOutBefore: document.getElementById('checkOutBefore').value,
            Smoking: smoking,
            Pets: pets,
            Party: party,
            Children: children,
            RegistrationNumber: document.getElementById('registrationNumber').value,
            Optional: document.getElementById('optional').value
        }

        return termsAndCondtionsValues;
    }

    setListingType = (listingType) => {
        this.setState({listingType: listingType});
    }

    setInstantBooking = (instantBooking) => {
        this.setState({instantBooking: instantBooking});
    }

    setNightlyRate = (nightlyRate) => {
        this.setState({nightlyRate: nightlyRate});
    }

    setWeekendRate = (weekendRate) => {
        this.setState({weekendRate: weekendRate});
    }

    setAdditionalFees = (fees) => {
        this.setState({fees: fees});
    }

    setAdditionalTaxes = (taxes) => {
        this.setState({taxes: taxes});
    }

    setAdditionalPeriods = (periods) => {
        this.setState({periods: periods});
    }

    changeDescription = (event) => {
        this.setState({description: event});
    }

    setRooms = (rooms) => {
        this.setState({rooms: rooms})
    }

    setBlockedDates = (dates) => {
        this.setState({ blockedDates: dates });
    }

    setImportiCalDates = (listingid, name, url) => {
        Listing.createICalEntry(listingid, name, url);
    }

    render() {

        return (
            <div style={{ paddingTop: "80px" }}>
                <div class="loading-overlay" style={{ display: this.state.showLoading }}>
                    <div class="loading-vertical">
                        <Grid
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={0}
                        />
                    </div>
                </div>
                <Row >
                    <Col className="d-none d-md-block" md="3" lg="2" xl="1">
                        <SideNav
                            loggedIn={this.props.location.state.loggedIn}
                            loginType={this.props.location.state.loginType}
                            email={this.props.location.state.email}
                            activeTab='newlisting'
                        />
                    </Col>
                    <Col md="9" lg="10" xl="11" style={{ display: this.state.showDetail }}>
                        <div style={{ width: '100%' }}>


                {/*<div className="row content">*/}
                {/*    <div className="col-md-1">*/}
                {/*        <SideNav />*/}
                {/*    </div>*/}
                {/*    <div className="col-md-11">*/}
                {/*        <div className="row">*/}
                {/*            <div style={{ width: '100%' }}>*/}
                                <h1>Add New Listing</h1>
                            <hr />
                        </div>
                        
                        <div className="row" style={{ display: this.state.showTabs }}>
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={this.state.key}
                                onSelect={(k) => this.setKey(k)}
                                style={{ background: "#f7f8f9" }}
                            >
                                <Tab eventKey="0" title="Info">
                                    <br />
                                <Information
                                    setListingType={this.setListingType}
                                    changeDescription={this.changeDescription}
                                    isValid={this.inforef}
                                />
                                    
                                </Tab>
                                <Tab eventKey="1" title="Pricing">
                                    <br />
                                    <Pricing 
                                        setInstantBooking={this.setInstantBooking}
                                        setNightlyRate = {this.setNightlyRate}
                                        setWeekendRate = {this.setWeekendRate}
                                        setFees = {this.setAdditionalFees}
                                        setTaxes = {this.setAdditionalTaxes}
                                    setPeriods={this.setAdditionalPeriods}
                                    isValid={this.priceref}
                                    />
                                </Tab>
                                <Tab eventKey="2" title="Media" >
                                    <Media 
                                    images={this.state.images}
                                    addImage={this.addImage}
                                    onSelectImage={this.onSelectImage}
                                    deleteImage={this.deleteImage}
                                    onCropImage={this.onCropImage}
                                    isValid={this.mediaref}
                                    updateImageArray={this.updateImageArray}
                                    />
                                </Tab>
                                <Tab eventKey="3" title="Features" >
                                    <Features
                                        categories={this.state.categories}
                                        featuresList={this.state.featuresList}
                                        selectFeature={this.selectFeature}
                                    />
                                </Tab>
                                <Tab eventKey="4" title="Location" >
                                    <br />
                                <Location
                                    isValid={this.locationref}
                                />
                                </Tab>
                                <Tab eventKey="5" title="Rooms" >
                                    <Bedrooms 
                                        setRoomsState={this.setRooms}
                                    />
                                </Tab>
                                <Tab eventKey="6" title="T&Cs" >
                                    <TermsAndConditions
                                        isValid={this.tandcref}
                                    />
                                    </Tab>
                                <Tab eventKey="7" title="Calendar" >
                                    <Calendar
                                    setBlockedDates={this.setBlockedDates}
                                        setImportiCalDates={this.setImportiCalDates}
                                        listingID={this.state.listingOwner ? this.state.listingOwner.UUID : null}
                                    />
                                </Tab>
                            </Tabs>
                            
                        </div>
                        <Modal show={this.state.showProfileUpdate} className="my-modal-fail">
                            <Modal.Header closeButton onClick={() => this.hideProfileUpdate()}>
                                <Modal.Title>Account Detail Missing</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                It appears we cannot find your bank account details in your profile.
                                This is required for us to be able to transfer your reservation funds to you.
                            </Modal.Body>
                            <Modal.Footer>
                                <Link style={{ width: "100%" }} to={{ pathname: "/profile", state: { email: this.props.location.state.email, loginType: this.props.location.state.loginType } }}><Button type="submit" variant="secondary" style={{ width: "100%" }}>Goto Profile</Button></Link>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>

                <Row style={{ display: this.state.showResultMessage }}>
                    <Col md={{ span: 9, offset: 3 }} lg={{ span: 10, offset: 2 }} xl={{ span: 11, offset: 1 }}>
                        <Alert variant={this.state.resultMessageClass} >
                            {this.state.resultMessage}
                        </Alert>
                    </Col>
                </Row>

                <Row style={{ display: this.state.showButtons }}>
                    <Col className="d-none d-md-block" md="3" lg="2" xl="1">
                    </Col>
                    <Col md="9" lg="10" xl="11">
                    {(this.state.key > 0) &&
                        <Button className="col-md-6" onClick={() => this.setKey((parseInt(this.state.key) + 8 - 1) % 8)} variant="secondary">Back</Button>
                    }
                    {(this.state.key == 0) &&
                        <div className="col-md-6"></div>
                    }
                        {(this.state.key != this.state.tabCount - 1 && this.state.key != 0) &&
                        <Button className="col-md-6" onClick={() => this.setKey((parseInt(this.state.key) + 1) % 8)} variant="primary">Update & Next</Button>
                        }
                        {(this.state.key != this.state.tabCount - 1 && this.state.key == 0) &&
                            <Button className="col-md-6 offset-md-6" onClick={() => this.setKey((parseInt(this.state.key) + 1) % 8)} variant="primary">Create Draft and Continue</Button>
                        }

                    {(this.state.key == (this.state.tabCount - 1)) &&
                            <Button className="col-md-6" variant="success" onClick={this.submitListing}>Submit for Review</Button>
                        }
                        </Col>
                </Row>
                <Row style={{ display: this.state.showButtons, height: "80px" }}>
                </Row>
            </div>
        )
    }
}

export default withRouter(AddNewListing)