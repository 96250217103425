import axios from 'axios';

import { BASE_URL } from './GlobalDefinitions';
import { AUTH, CONTENT_TYPE, METHODS_POST, ORIGIN } from './Headers';

const ERROR_API_BASE_URL = "/errors";


class ErrorService {

    // create a new user
    postErrorMessage(errors) {
        return axios.post(BASE_URL + ERROR_API_BASE_URL, errors, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }

}

export default new ErrorService()