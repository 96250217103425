import ReviewService from "../services/ReviewService";

const Reviews = {
    // create a guest review
    async createGuestReview(reservationid, listingid, review, parentreviewid, title, userid, rating) {
        const result = await ReviewService.createGuestReview(reservationid, listingid, review, parentreviewid, title, userid, rating);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    // create a host review
    async createHostReview(reservationid, review, userid, title, rating, guestid) {
        const result = await ReviewService.createHostReview(reservationid, review, userid, title, rating, guestid);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    // update a guest review
    async updateGuestReview(reservationid, listingid, review, parentreviewid, emailsent, reviewid, published) {
        const result = await ReviewService.updateGuestReview(reservationid, listingid, review, parentreviewid, emailsent, reviewid, published);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    // update a host review
    async updateHostReview(reservationid, review, userid, emailsent, reviewid, published) {
        const result = await ReviewService.updateHostReview(reservationid, review, userid, emailsent, reviewid, published);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    // delete a guest review
    async deleteGuestReview(value) {
        const result = await ReviewService.deleteGuestReview(value);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    // delete a host review
    async deleteHostReview(value) {
        const result = await ReviewService.deleteHostReview(value);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    // get a guest review
    async getGuestReview(key0, value0, key1, value1) {
        const result = await ReviewService.getGuestReview(key0, value0, key1, value1);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    // get a host review
    async getHostReview(key0, value0, key1, value1) {
        const result = await ReviewService.getHostReview(key0, value0, key1, value1);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    // get a number of guest reviews
    async getGuestReviews(key0, value0, key1, value1) {
        const result = await ReviewService.getGuestReviews(key0, value0, key1, value1);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    // get a number of host reviews
    async getHostReviews(key0, value0, key1, value1) {
        const result = await ReviewService.getHostReviews(key0, value0, key1, value1);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    }
}

export default Reviews