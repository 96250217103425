import React from "react";
import { Link } from "react-router-dom";

import { FaCalendarAlt, FaUser, FaMapMarkerAlt } from 'react-icons/fa';

import "./../styles/search.css";

function BlogTile (props) {

    const formatDate = (input) => {
        var date = new Date(input);
        return (('0' + (date.getDate())).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()).toString();
    }

    return (
        
        <Link to={{
            pathname: "/blog/" + props.id ,
        }}>
            <div className="search-item">
                <img className="search-img" height="240px" src={"data:image/png;base64," + props.image} width="100%" style={{ objectFit: "cover" }}></img>

                <div className="search-title"><b>{props.title}</b></div> 
                {/*<div className="blog-intro" dangerouslySetInnerHTML={{ __html: props.intro  }} />*/}
                <div className="row search-details">
                    <div className="col-sm-4 detail-padding" >
                        <FaCalendarAlt />  {formatDate(props.created)}
                    </div>
                    <div className="col-sm-4 detail-padding" >
                        <FaUser />  by: {props.author}
                    </div>
                    <div className="col-sm-4 detail-padding" >
                        <FaMapMarkerAlt />  {props.city}
                    </div>
                </div>
            </div>
        
        </Link>

        

    )
}

export default BlogTile