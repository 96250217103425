import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Button, Modal, Form } from 'react-bootstrap';

import Globals from '../functions/globals';
import User from "../functions/User";

import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { Input, Dropdown, Checkbox } from '@mobiscroll/react';
import { Grid } from "react-loader-spinner";

window.captcha = async () => {
    //e.preventDefault();

    const grecaptcha = window.grecaptcha;

    //alert("called");

    grecaptcha.ready(function () {
        grecaptcha.execute('6LfS5V8gAAAAACMFKt0haxFM6H99vacBEoN6F8w5', { action: 'submit' }).then(function (token) {
            // Add your logic to submit to your backend server here.
            document.getElementById("captchaCheck").setAttribute('data-token', token);
            document.getElementById("captchaCheck").click();
        });
    });
}

export function withRouter(Children) {
    return (props) => {

        //const match = { params: useParams() };
        //const location = { location: useLocation() };

        //return <Children {...props} match={match.params} location={location.location} />
        return <Children {...props} />
    }
}

class RegisterModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            input: {},
            errors: {},
            showErrors: {},
            regOptions: [],
            errorRegister: 'none',
            errorCaptcha: 'none',
            showForm: 'block',
            showLoading: 'none',
            showHostTC: 'none'
        }

        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentWillMount() {
        //console.log("run script");

        const script = document.createElement("script");

        script.src = "https://www.google.com/recaptcha/api.js?render=6LfS5V8gAAAAACMFKt0haxFM6H99vacBEoN6F8w5";
        script.async = true;

        document.body.appendChild(script);
    }

    captchaCheck = () => {
        //run backend script to get results of captcha
        var token = document.getElementById("captchaCheck").getAttribute('data-token');
        User.userCheckByToken(token).then(result => {

            //console.log(result.data);

            if (!result.data) {
                //user not verified
                this.setState({ errorCaptcha: 'block' });

                this.setState({
                    showForm: 'none',
                    showLoading: 'none'
                })

            } else {
                //continue on

                this.setState({ errorRegister: 'none' });

                if (this.validate()) {
                    let input = this.state.input;

                    let email = input["email"];
                    let password = input["password"];

                    User.createUser(input["email"], input["firstname"], input["lastname"], input["password"], input["regtype"]).then(result => {
                        console.log("reg: " + JSON.stringify(result));

                        //check the results and only close on success and clear
                        if (typeof result != "undefined" && !result.data.includes("problem creating user")) {

                            input = {};
                            input["firstname"] = "";
                            input["lastname"] = "";
                            input["email"] = "";
                            input["password"] = "";
                            input["passwordconfirm"] = "";
                            input["terms"] = "";
                            input["regtype"] = "";
                            this.setState({ input: input });

                            this.props.onClose(email, password) && this.props.onClose();
                        } else {
                            //else show error message to user
                            this.setState({ errorRegister: 'block' });
                            this.setState({
                                showForm: 'block',
                                showLoading: 'none'
                            })
                        }
                    });
                } else {
                    //else show error message to user
                    this.setState({ errorRegister: 'block' });
                    this.setState({
                        showForm: 'block',
                        showLoading: 'none'
                    })
                }
            }
        })
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            showForm: 'none',
            showLoading: 'block'
        })

        //check captcha
        window.captcha();

        
    }

    validate() {
        let input = this.state.input;
        let errors = {};
        let showErrors = {};
        let isValid = true;

        if (!input["firstname"]) {
            isValid = false;
            errors["firstname"] = "Please enter your first name.";
            showErrors["firstname"] = true;
        }

        if (!input["lastname"]) {
            isValid = false;
            errors["lastname"] = "Please enter your last name.";
            showErrors["lastname"] = true;
        }

        if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your email address.";
            showErrors["email"] = true;
        }

        if (typeof input["email"] !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email"] = "Please enter valid email address.";
                showErrors["email"] = true;
            }
        }

        if (!input["password"]) {
            isValid = false;
            errors["password"] = "Please enter your password.";
            showErrors["password"] = true;
        }

        if (!input["passwordconfirm"]) {
            isValid = false;
            errors["passwordconfirm"] = "Please enter your confirm password.";
            showErrors["passwordconfirm"] = true;
        }

        //console.log(document.getElementById('terms').checked);

        if (!input["terms"]) {
            //console.log("failed");
            isValid = false;
            errors["terms"] = "You must agree to the Terms and Conditions.";
            showErrors["terms"] = true;
        }

        if (!input["regtype"]) {
            //console.log("failed");
            isValid = false;
            errors["regtype"] = "Please select a registration type.";
            showErrors["regtype"] = true;
        }

        if (typeof input["password"] !== "undefined" && typeof input["passwordconfirm"] !== "undefined") {

            if (input["password"] != input["passwordconfirm"]) {
                isValid = false;
                errors["password"] = "Passwords don't match.";
                showErrors["password"] = true;
            }
        }

        this.setState({
            errors: errors,
            showErrors: showErrors
        });

        return isValid;
    }

    doHaveAccount = () => {
        this.props.doHaveAccount && this.props.doHaveAccount();
    }

    onTextChange = (event) => {

        let input = this.state.input;
        if (event.target.name == "terms") {
            input[event.target.name] = event.target.checked;
        } else if (event.target.name == "regtype") {
            input[event.target.name] = event.target.value;
            //if the value is hosting show the Host T&C
            if (event.target.value == 'hosting') {
                this.setState({ showHostTC: 'block' });
            } else {
                this.setState({ showHostTC: 'none' });
            }
        } else {
            input[event.target.name] = event.target.value;
        }
        this.setState({
            input
        });

        if (event.target.value) {
            //remoev the errors fom array state
            let errors = {};
            let showErrors = {};

            Object.entries(this.state.errors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    errors[error[0]] = error[1];
                }
            })
            Object.entries(this.state.showErrors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    showErrors[error[0]] = error[1];
                }
            })

            this.setState({
                errors: errors,
                showErrors: showErrors
            })
        }
    }

    hideRegister = (event) => {
        this.props.hideRegister && this.props.hideRegister(event);
    }

    forgotPassword = () => {
        this.props.forgotPassword && this.props.forgotPassword();
    }

    render() {
        if (!this.props.showRegister) {
            return null;
        }

        return (
            <Modal show={this.props.showRegister} onClose={event => { this.closeRegister(event); }}>
                <Modal.Header closeButton onClick={() => this.hideRegister()}>
                    <Modal.Title>Register</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form style={{display: this.state.showForm}} onSubmit={this.handleSubmit}>

                        <Input inputStyle="box" labelStyle="floating" type="email" name="email" label="Email" errorMessage={this.state.errors.email} error={this.state.showErrors.email} onChange={this.onTextChange} />
                        <Input inputStyle="box" labelStyle="floating" type="text" name="firstname" label="First Name" errorMessage={this.state.errors.firstname} error={this.state.showErrors.firstname} onChange={this.onTextChange} />
                        <Input inputStyle="box" labelStyle="floating" type="text" name="lastname" label="Last Name" errorMessage={this.state.errors.lastname} error={this.state.showErrors.lastname} onChange={this.onTextChange} />
                        <Input inputStyle="box" labelStyle="floating" name="password" type="password" label="Password" passwordToggle="true" iconshow="eye" iconhide="eye-blocked" autoComplete="new-password" errorMessage={this.state.errors.password} error={this.state.showErrors.password} onChange={this.onTextChange} />
                        <Input inputStyle="box" labelStyle="floating" name="passwordconfirm" type="password" label="Confirm Password" passwordToggle="true" iconshow="eye" iconhide="eye-blocked" errorMessage={this.state.errors.passwordconfirm} error={this.state.showErrors.passwordconfirm} onChange={this.onTextChange} />

                        <Dropdown label="Registration Type" name="regtype" error={this.state.showErrors.regtype} errorMessage={this.state.errors.regtype} onChange={this.onTextChange} >
                            <option value="">Please Select</option>
                            <option value={Globals.typeBooking}>{Globals.loginTypeBook}</option>
                            <option value={Globals.typeHosting}>{Globals.loginTypeHost}</option>
                        </Dropdown>

                        <Checkbox label="I agree with below Terms & Conditions: " color="primary" name="terms" onChange={this.onTextChange} />
                        <div style={{ paddingLeft: "50px"}} >
                            <a href="https://aussieholidaystays.com.au/termsandconditions" target="_blank">General Terms & Conditions</a> <br/>
                            <a style={{ display: this.state.showHostTC}} href="https://aussieholidaystays.com.au/hostterms" target="_blank">Host Terms & Conditions</a> <br />
                        </div>

                        <label className="mbsc-material mbsc-ltr mbsc-error-message mbsc-error-message-box mbsc-error-message-floating mbsc-error-message-has-icon-right">{this.state.errors.terms}</label>

                        <div style={{ display: this.state.errorRegister, color: "#e96852" }}>
                            Registration error: it appears this email already exists, please do <a href="#" onClick={this.forgotPassword}>Reset Password</a> to reset account or use different email
                        </div>

                        <Button id="register" type="submit" variant="secondary" style={{ width: "100%" }}>Register</Button>

                    </form>

                    <div style={{ display: this.state.errorCaptcha, color: "#e96852" }}>
                        Registration error: it appears our system has detected you may have ad blockers or running behind a VPN
                    </div>

                    <div style={{ display: this.state.showLoading, textAlign: "center" }}>
                        <div >
                            <Grid
                                color="#00BFFF"
                                height={100}
                                width={100}
                                timeout={0}
                            />
                        </div>
                    </div>

                    <Button type="submit" onClick={this.captchaCheck} id="captchaCheck" hidden >hidden</Button>
                </Modal.Body>
                <Modal.Footer>

                    <br />
                    <Form.Label className="text-center" style={{ width: "100%" }} >Do you already have an account? <a href="#" onClick={this.doHaveAccount}>Log In</a></Form.Label>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default withRouter(RegisterModal)