import UserService from "../services/UserService";

import AsyncStorage from "@react-native-async-storage/async-storage";

import Globals from "./globals";

var bcrypt = require('bcryptjs');
var salt = bcrypt.genSaltSync(10);

/**
 * global functions to act on user api actions
 */
const User = {
    /**
     * 
     * @returns get all users in the system
     */
    async getUsers() {
        const result = await UserService.getUsers();
        //console.log(result);

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },

    /**
     * log the user in and return the data from the api
     * @param {string} email 
     * @param {string} password 
     * @returns const containing the user data
     */
    async loginUser(email, password) {
        if (password !== "") {

            const result = await UserService.getUserByEmail(email);

            if (result.data.length !== 0) {

                //do a check if old "sha.." password and warn user with message
                if (result.data.Password.startsWith("sha")) {
                    return "old password"
                } else if (bcrypt.compareSync(password, result.data.Password)) {

                    //if (passwordHash.verify(password, result.data.Password) || true) {
                    //store a token for auto login and new tabs
                    await AsyncStorage.setItem("token", result.data.UUID);

                    window.sessionStorage.setItem("token", result.data.UUID);

                    localStorage.setItem("tokencleared", "false");

                    return result;
                } else {
                    return undefined;
                }
            }
        }

        return undefined;
    },

    /**
     * // find a user by email address
     * @param {string} email 
     * @returns the user
     */
    async getUserByEmail(email) {
        const result = await UserService.getUserByEmail(email);

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },

    /**
     * get user by uuid
     * @param {string} id 
     * @returns 
     */
    async getUserByID(id) {
        const result = await UserService.getUserByID(id);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    /**
     * create a new user then get the user data from the api and return the result
     * @param {string} email 
     * @param {string} firstname 
     * @param {string} lastname 
     * @param {string} password 
     * @param {string} loginType  
     */
    async createUser(email, firstname, lastname, password, loginType) {
        var hashedPassword = "";
        if (password !== "") {
            //hashedPassword = passwordHash.generate(password);
            hashedPassword = bcrypt.hashSync(password, salt);
        }

        const result = await UserService.createUser(email, firstname, lastname, hashedPassword, loginType);

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },

    /**
     * update an existing user
     * @param {string} email 
     * @param {string} firstname 
     * @param {string} lastname
     * @param {string} phone
     * @param {string} nativelanguage 
     * @param {string} otherlanguage
     * @param {string} city 
     * @param {string} state
     * @param {string} country
     * @param {string} bio 
     * @param {string} displayname
     * @param {string} image 
     * @param {string} bsb 
     * @param {string} accountno
     */
    async updateUser(email, firstname, lastname, phone, nativelanguage, otherlanguage, 
        city, state, country, bio, displayname, image, bsb, accountno) {
        //console.log(image)

        var base64 = "";

            //const reader = new FileReader();
            //reader.readAsDataURL(image)
            //const base64string = reader.result.replace('data:', '').replace(/^.+,/, '');
        if (image) {
            const encode = await Globals.convertImage(image)
            base64 = encode.replace('data:', '').replace(/^.+,/, '');
        }
            //console.log(base64)
        const result = await UserService.updateUser(email, firstname, lastname, phone, nativelanguage, otherlanguage, 
            city, state, country, bio, displayname, base64, bsb, accountno);

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },

    /**
     * update an existing account with a new password
     * @param {string} uuid 
     * @param {string} password
     * @returns 
     */
    async updatePassword(password, uuid) {
        //var hashedPassword = passwordHash.generate(password);

        var hashedPassword = bcrypt.hashSync(password, salt);

        //console.log(password)
        //console.log(hashedPassword)

        const result = await UserService.updatePassword(hashedPassword, uuid);

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },

    /**
     * mark a user account as valid
     * @param {string} uuid 
     * @returns 
     */
    async verifyAccount(uuid) {
        const result = await UserService.verifyAccount(uuid);

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },

    /**
     * delete the image assigned to the user profile both from local storage and the database
     * @param {string} email 
     */
    async deleteImage(email) {
        UserService.deleteImage(email);
    },

    /**
     * delete the user from the system
     * @param {string} email 
     * @returns 
     */
    async deleteUser(email) {
        const result = await UserService.deleteUser(email);

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },

    /**
     * verify the email account (user) exists
     * @param {string} email 
     * @returns 
     */
    async userEmailCheck(email) {
        const token = await AsyncStorage.getItem('token');
        if (token != null) {
            const result = await UserService.getUserByID(token);

            if (result.data.length !== 0) {
                if (email === result.data.Email) {
                    return true;
                }

                return false;
            }
        }

        return false;
    },

    /**
     * send a reset password email
     * @param {string} email 
     * @returns 
     */
    async resetPassword(email) {
        const result = await UserService.resetPassword(email);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    /**
     * this should be a saved session token
     * @param {string} token 
     * @returns 
     */
    async userCheckByToken(token) {
        const result = await UserService.userCheckByToken(token);

            if (result.data.length !== 0) {

                return result;
            }

        return undefined;
    },

    /**
     * re send a user welcome email
     * @param {string} userid 
     * @returns 
     */
    async resendWelcomeEmail(userid) {
        const result = await UserService.resendWelcomeEmail(userid);

            if (result.data.length !== 0) {

                return result;
            }

        return undefined;
    },  
}

export default User