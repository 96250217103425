import axios from 'axios';

import { BASE_URL } from './GlobalDefinitions';
import { AUTH, CONTENT_TYPE, METHODS_DELETE, METHODS_GET, METHODS_POST, METHODS_PUT, ORIGIN } from './Headers';

const PROMO_API_BASE_URL = "/promos";
const PROMO_UPDATE_API_BASE_URL = "/promosupdate";
const PROMO_ID_API_BASE_URL = "/promosbyid";
const PROMO_DETAIL_API_BASE_URL = "/promosdetail";
const PROMO_PUBLISHED_API_BASE_URL = "/promospublishedlimited";

class PromoService {
    // get all users
    getPromos() {
        return axios.get(BASE_URL + PROMO_API_BASE_URL, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    getPromosDetailByKey(key, param) {
        return axios.get(BASE_URL + PROMO_DETAIL_API_BASE_URL, {
            params: {
                Key: key,
                Param: param,
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    getPromosPublished(limit) {
        return axios.get(BASE_URL + PROMO_PUBLISHED_API_BASE_URL, {
            params: {
                Limit: limit
            },
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // create a new promo
    createPromo(promo) {
        return axios.post(BASE_URL + PROMO_API_BASE_URL, promo, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }

    // update promo
    updatePromo(id, promo) {
        return axios.put(BASE_URL + PROMO_API_BASE_URL, promo, {
            params: {
                Key: "UUID",
                Param: id
            },
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }

    // get user by Username
    getPromoByID(id) {
        return axios.get(BASE_URL + PROMO_ID_API_BASE_URL, {
            params: {
                ID: id
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // delete promo
    deletePromo(id) {
        return axios.delete(BASE_URL + PROMO_API_BASE_URL, {
            params: {
                Key: "UUID",
                Param: id
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    updatePromoField(uuid, key, value) {
        return axios.post(BASE_URL + PROMO_UPDATE_API_BASE_URL, null, {
            params: {
                UUID: uuid,
                Key: key,
                Param: value,
            },
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }
    
}

export default new PromoService()