import React, { Component } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Row, Col, Card } from 'react-bootstrap';

import PageContentDetail from './../functions/PageContent';
import { Grid } from "react-loader-spinner";

export function withRouter(Children) {
    return (props) => {

        const match = { params: useParams() };
        const location = { location: useLocation() };

        return <Children {...props} match={match.params} location={location.location} />
    }
}

class ContentPages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            spinner: 'none',
            contentShow: 'block',
            content: "",
            page: this.props.page
        }
    }

    componentDidMount() {

        PageContentDetail.getPage(this.state.page).then(result => {

            //console.log("Data: " + JSON.stringify(result.data));

            this.setState({ content: result.data.Content });
        })

    }

    render() {

        return (

            <div style={{ paddingTop: "80px" }}>
                <div class="container-fluid px-0">
                   
                    <Row className="justify-content-md-center px-1">
                        <div class="d-none d-md-block col-2 px-0" style={{ marginLeft: "10px" }}>

                        </div>
                        <div class="col-md-6 col-sm-12" >
                            <Card style={{ paddingTop: "10px" }}>

                                <div style={{ display: this.state.spinner, width: '100%', textAlign: 'center' }}>
                                    <Grid
                                        color="#00BFFF"
                                        height={100}
                                        width={100}
                                        timeout={0} //in milliseconds
                                    />
                                </div>

                                <Card.Body style={{ display: this.state.contentShow }}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
                                </Card.Body>
                            </Card>
                        </div>
                        <div class="d-none d-md-block col-2 px-0" style={{ marginLeft: "10px" }}>
                            
                        </div>
                        {/*<div class="col-2 px-0" >*/}

                        {/*</div>*/}
                    </Row>

                </div>


            </div>

            

        )
    }
}

export default withRouter(ContentPages)