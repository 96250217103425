import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Button, Modal, Form, InputGroup } from 'react-bootstrap';

export function withRouter(Children) {
    return (props) => {

        //const match = { params: useParams() };
        //const location = { location: useLocation() };

        //return <Children {...props} match={match.params} location={location.location} />
    }
}

class RequestToBookModal extends Component {
    closeRequestToBook = (event) => {
        this.props.onClose && this.props.onClose(event);
    }

    dontHaveAccount = () => {
        this.props.dontHaveAccount && this.props.dontHaveAccount();
    }

    onChangeUsernameOrEmail = (event) => {
        this.props.setUsernameOrEmail(event.target.value);
    }

    onChangePassword = (event) => {
        this.props.setPassword(event.target.value);
    }

    hideRequestToBook = (event) => {
        this.props.hideRequestToBook && this.props.hideRequestToBook(event);
    }

    render() {
        if (!this.props.showRequestToBook) {
            return null;
        }

        return (
            <Modal show={this.props.showRequestToBook} onClose={event => this.closeRequestToBook(event)}>
                <Modal.Header closeButton onClick={() => this.hideRequestToBook()}>
                    <Modal.Title>Request to Book Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="info" style={{width: "100%"}}>Login with Facebook</Button>
                    <br />
                    <Form.Label className="text-center" style={{width: "100%"}} >Log In</Form.Label>
                    <Form>
                        <InputGroup className="mb-3">
                                <Form.Control type="text" placeholder="Username or Email"  onChange={this.onChangeUsernameOrEmail}/>
                        </InputGroup>
                        <InputGroup className="mb-3">
                                <Form.Control type="password" placeholder="Password"  onChange={this.onChangePassword}/>
                        </InputGroup>
                        <InputGroup className="mb-3">
                                <Form.Check type="checkbox" label="Remember Me" />
                        </InputGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeRequestToBook} style={{width: "100%"}}>Log In</Button>
                    <br />
                    <Form.Label className="text-center" style={{width: "100%"}} >Don't have an account? <a href="#" onClick={this.dontHaveAccount}>Register</a></Form.Label>
                </Modal.Footer>                    
            </Modal>
        )
    }
}

export default withRouter(RequestToBookModal)