import { Input, Dropdown, RadioGroup, Radio } from '@mobiscroll/react';
import React, { Component } from 'react';
import { Alert, Button, Card, Col, Modal, Row } from 'react-bootstrap';

import SideNav from "./../modals/SideNav";

import CCFeeDetail from './../functions/CCFee';
import UserDetail from './../functions/User';

import { FaEdit, FaTrashAlt } from 'react-icons/fa';


export function withRouter(Children) {
    return (props) => {
        return <Children {...props} location={props} />
    }
}

class CCFees extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hosts: [],
            loggedIn: false,
            showAlertAddFee: 'none',
            showAddFee: 'block',
            showAlertUpdateFee: 'none',
            input: {},
            errors: {},
            showErrors: {},
            domestic: false,
            showDetails: 'none',
            showFeeID: 'none'
        }
    }
    
    //filterByEmail = (e) => {
    //    const list = this.state.defaultHostList.filter(function (host) { return host.Email.toLowerCase().includes(e.target.value.toLowerCase()); });
    //    setTimeout(function () { //Start the timer
    //        this.setState({ hosts: list });
    //    }.bind(this), 500)
    //}

    showAddFeeClick = () => {
        this.setState({
            showAddFeeModal: true,
            showAddFee: 'block',
            showAlertAddFee: 'none'
        })
    }

    hideAddFeeModal = () => {
        this.setState({
            showAddFeeModal: false,
            showAlertAddRMSFee: 'none',
        })

        //refresh list
        CCFeeDetail.getCCFees().then((feeResult) => {
            if (feeResult && feeResult.data) {
                this.setState({
                    fees: feeResult.data,
                    defaultFeesList: feeResult.data
                });
            }
        })

    }

    showUpdateFeeClick = (uuid, scheme, amount, domestic) => {
        this.setState({
            showUpdateFeeModal: true,
            showAlertUpdateFee: 'none',
            updateUUID: uuid,
            updateScheme: scheme,
            updateAmount: amount,
            updateDomestic: domestic,
            showFeeID: 'none'
        })

        let newinput = this.state.input;
        newinput["updatescheme"] = scheme;
        newinput["updateamount"] = amount;
        newinput["updatedomesticgroup"] = String(domestic);

    }

    hideUpdateFeeModal = () => {
        this.setState({
            showUpdateFeeModal: false
        })

        //refresh list
        CCFeeDetail.getCCFees().then((feeResult) => {
            if (feeResult && feeResult.data) {
                this.setState({
                    fees: feeResult.data,
                    defaultFeesList: feeResult.data
                });
            }
        })
    }

    showDeleteFeeClick = (uuid, scheme) => {
        this.setState({
            showDeleteFeeModal: true,
            deleteUUID: uuid,
            deleteScheme: scheme
        })
    }

    hideDeleteFeeModal = () => {
        this.setState({
            showDeleteFeeModal: false
        })
    }

    addFee = () => {

        //console.log("add")

        //check all fields filled
        if (this.getvalidAddFee()) {

            //console.log("valid")

            //do work to create
            let input = this.state.input;

            //check for client id before passing in
            //var clientID = "";
            //if (this.state.showFeeID == 'block') {
            //    clientID = parseInt(input["addclientid"], 10)
            //}

            CCFeeDetail.createCCFee(input["addscheme"], input["addamount"], (input["adddomesticgroup"] == "true" ? true : false)).then(result => {

                //console.log(result);

                //check the results and only close on success and clear
                if (typeof result != "undefined" && result.data.includes("new cc fee created")) {

                    input = {};
                    input["addscheme"] = "";
                    input["addamount"] = "";
                    input["adddomestic"] = "yes";

                    //clear all the fields
                    document.getElementById('addscheme').value = "";
                    document.getElementById('addamount').value = "";

                    /* Alert the copied text */
                    this.setState({
                        showAlertAddFee: 'block',
                        showAddFee: 'none',
                        input: input,
                        media: false,
                        //showDetails: 'none',
                        //showFeeID: 'none'
                    })
                }
            });

            
        }
    }

    updateFee = () => {

        //do work to update
        //check all fields filled
        if (this.getvalidUpdateFee()) {

            //do work to create
            let input = this.state.input;

            ////check for client id before passing in
            //var clientID = "";
            //if (this.state.showFeeID == 'block') {
            //    clientID = parseInt(input["addclientid"], 10)
            //}

            CCFeeDetail.updateCCFee(this.state.updateUUID, input["updatescheme"], input["updateamount"], (input["updatedomesticgroup"] == "true" ? true : false)).then(result => {

                //check the results and only close on success and clear
                if (typeof result != "undefined" && result.data.includes("cc fee updated")) {

                    /* Alert the update */
                    this.setState({
                        showAlertUpdateFee: 'block',
                        //input: input
                    })
                }
            });

        }
    }

    deleteFee = () => {
        CCFeeDetail.deleteCCFee(this.state.deleteUUID).then(result => {

            //console.log(result);

            //check the results and only close on success and clear
            if (typeof result != "undefined" && result.data.includes("cc fee deleted")) {
                //clear the UUID
                this.setState({
                    deleteUUID: "",
                    deleteScheme: ""
                })

                //close the window
                this.hideDeleteFeeModal();

                //refresh list
                CCFeeDetail.getCCFees().then((feeResult) => {
                    if (feeResult && feeResult.data) {
                        this.setState({
                            fees: feeResult.data,
                            defaultFeesList: feeResult.data
                        });
                    }
                })
            }
        });

    }

    onTextChange = (event) => {

        let newinput = this.state.input;

        //need to update the state veariables for update only
        switch (event.target.name) {
            case "updatescheme":
                this.setState({ updateScheme: event.target.value})
                break;
            case "updateamount":
                this.setState({ updateAmount: event.target.value })
                break;
            
            //case "addfee":
            //    this.setState({
            //        updatePMS: event.target.value,
            //        showDetails: 'block'
            //    })
            //    //change the displat pending the PMS chosen
            //    switch (event.target.value) {
            //        case "RMS":
            //            this.setState({
            //                showFeeID: 'block'
            //            })
            //            break;
            //        case "BEDS":
            //            this.setState({
            //                showFeeID: 'none'
            //            })
            //            break;
            //    }

            //    break;
            case "updatedomesticgroup":
                this.setState({ updateDomesticGroup: event.target.value })
                break;
        }

        newinput[event.target.name] = event.target.value;

        if (event.target.value) {
            //remoev the errors fom array state
            let newerrors = {};
            let newshowErrors = {};

            Object.entries(this.state.errors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(this.state.showErrors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newshowErrors[error[0]] = error[1];
                }
            })

            this.setState({
                errors: newerrors,
                showErrors: newshowErrors
            })
        }
    }

    getvalidAddFee = () => {
        //alert('valid');
        let input = this.state.input;
        let errors = {};
        let showErrors = {};
        let isValid = true;

        if (!input["addscheme"]) {
            isValid = false;
            errors["addscheme"] = "Please enter scheme.";
            showErrors["addscheme"] = true;
        }

        //if (typeof input["addemail"] !== "undefined") {

        //    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        //    if (!pattern.test(input["addemail"])) {
        //        isValid = false;
        //        errors["addemail"] = "Please enter valid email address.";
        //        showErrors["addemail"] = true;
        //    }
        //}

        if (!input["addamount"]) {
            isValid = false;
            errors["addamount"] = "Please enter amount.";
            showErrors["addamount"] = true;
        }

        //if (!input["addclientpassword"]) {
        //    isValid = false;
        //    errors["addclientpassword"] = "Please enter client password.";
        //    showErrors["addclientpassword"] = true;
        //}

        //if (!input["addratetype"]) {
        //    isValid = false;
        //    errors["addratetype"] = "Please enter client rate name.";
        //    showErrors["addratetype"] = true;
        //}
        
        //if (!input["addfee"]) {
        //    isValid = false;
        //    errors["addfee"] = "Please select the PMS";
        //    showErrors["addfee"] = true;
        //}

        this.setState({
            errors: errors,
            showErrors: showErrors
        });

        return isValid;
    }

    getvalidUpdateFee = () => {
        //alert('valid');
        let input = this.state.input;
        let errors = {};
        let showErrors = {};
        let isValid = true;

        if (!input["updatescheme"]) {
            isValid = false;
            errors["updatescheme"] = "Please enter scheme.";
            showErrors["updatescheme"] = true;
        }

        //if (typeof input["updateemail"] !== "undefined") {

        //    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        //    if (!pattern.test(input["updateemail"])) {
        //        isValid = false;
        //        errors["updateemail"] = "Please enter valid email address.";
        //        showErrors["updateemail"] = true;
        //    }
        //}

        if (!input["updateamount"]) {
            isValid = false;
            errors["updateamount"] = "Please enter amount.";
            showErrors["updateamount"] = true;
        }

        //if (!input["updateclientpassword"]) {
        //    isValid = false;
        //    errors["updateclientpassword"] = "Please enter client password.";
        //    showErrors["updateclientpassword"] = true;
        //}

        //if (!input["updateratetype"]) {
        //    isValid = false;
        //    errors["updateratetype"] = "Please enter client rate name.";
        //    showErrors["updateratetype"] = true;
        //}

        //if (!input["updatefee"]) {
        //    isValid = false;
        //    errors["updatefee"] = "Please select the PMS";
        //    showErrors["updatefee"] = true;
        //}

        this.setState({
            errors: errors,
            showErrors: showErrors
        });

        return isValid;
    }

    componentDidMount() {

        //get user id by email
        UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {
            if (result.data.LoginType === "admin") {
                //get list of users
                CCFeeDetail.getCCFees().then((feeResult) => {
                    if (feeResult && feeResult.data) {
                        this.setState({
                            fees: feeResult.data,
                            defaultFeesList: feeResult.data
                        });
                    }
                })
            }
        })

    }

    render() {
        return (
            <div style={{ paddingTop: "80px" }}>
                <Row>
                    <Col className="d-none d-lg-block" lg="2" xl="1">
                        <SideNav
                            loggedIn={this.props.location.state.loggedIn}
                            loginType={this.props.location.state.loginType}
                            email={this.props.location.state.email}
                            activeTab='ccfees'
                        />
                    </Col>
                    <Col md="12" lg="10" xl="11">
                        <div style={{ width: '100%' }}>
                            <h1>Credit Card Fees</h1>
                            <hr />
                        </div>

                        <Card>
                            <Card.Body style={{ paddingRight: "0px" }}>
                                <Row>
                                    <Col>
                                        {/*<Input className="mbsc-remove-margins" label="Email Search" inputStyle="box" labelStyle="floating" placeholder="Start Typing" autoComplete="off" onChange={(e) => this.filterByEmail(e)} />*/}
                                        <Button size="sm" onClick={() => this.showAddFeeClick()} >Add New Fee</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Row>
                                        <Col md="3" sm="3" xs="3"><h5>Scheme</h5></Col>
                                        <Col md="3" sm="3" xs="3"><h5>Domestic</h5></Col>
                                        <Col md="3" sm="3" xs="3"><h5>Amount</h5></Col>
                                        <Col md="3" sm="3" xs="3"><h5>Actions</h5></Col>
                                    </Row>
                                    {
                                        this.state.fees && this.state.fees.length > 0 ?
                                            this.state.fees.map((fee, index) =>
                                                <div style={{ width: '100%' }}>
                                                    <div class="d-none d-md-block">
                                                        <div>
                                                            <Row md="12" sm="12" xs="12" style={{ height: '50px', borderBottom: '1px solid #808080', paddingTop: '6px' }} >
                                                                <Col md="3" sm="3" xs="3">
                                                                    {fee.Scheme}
                                                                </Col>
                                                                <Col md="3" sm="3" xs="3">
                                                                    {fee.Domestic ? "Yes" : "No"}
                                                                </Col>
                                                                <Col md="3" sm="3" xs="3">
                                                                    {fee.Amount}
                                                                </Col>
                                                                <Col md="3" sm="3" xs="3">
                                                                    <Button variant="link" title="Update" className="icon-button" onClick={() => this.showUpdateFeeClick(fee.UUID, fee.Scheme, fee.Amount, fee.Domestic)} ><FaEdit size={28} color="lightblue" /></Button>
                                                                    <Button variant="link" title="Delete" className="icon-button" onClick={() => this.showDeleteFeeClick(fee.UUID, fee.Scheme)} ><FaTrashAlt size={28} color="lightblue" /></Button>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                    </div>
                                                </div>
                                            ) :
                                            <Row>
                                            </Row>
                                    }

                                </Row>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>

                <Modal show={this.state.showAddFeeModal} size="md" centered>
                    <Modal.Header closeButton onClick={() => this.hideAddFeeModal()}>
                        <Modal.Title>Add New Fee</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ display: this.state.showAlertAddFee, width: '100%' }}>
                            <Alert variant="success" >
                                Fee Added
                            </Alert>
                        </div>

                        <div style={{ display: this.state.showAddFee, wordWrap: 'break-word' }}>
                            <h5>Scheme</h5>
                            <Input className="mbsc-remove-margins" type="text" name="addscheme" id="addscheme" errorMessage={this.state.errors.addscheme} error={this.state.showErrors.addscheme} onChange={this.onTextChange} />

                            <h5>Amount</h5>
                            <Input className="mbsc-remove-margins" type="text" name="addamount" id="addamount" errorMessage={this.state.errors.addamount} error={this.state.showErrors.addamount} onChange={this.onTextChange} />
                                
                            <h5>Domestic</h5>
                            <Row>
                                <Col sm={12} md={6}>
                                    <RadioGroup name="adddomesticgroup">
                                        <Radio label="Yes" defaultChecked={this.state.domestic} value="true" id="adddomesticyes" onChange={this.onTextChange} />
                                    </RadioGroup>
                                </Col>
                                <Col sm={12} md={6}>
                                    <RadioGroup name="adddomesticgroup">
                                        <Radio label="No" defaultChecked={!this.state.domestic} value="false" id="adddomesticno" onChange={this.onTextChange} />
                                    </RadioGroup>
                                </Col>
                            </Row>
                        </div>
                        <hr />
                        <Button variant="success" onClick={() => this.addFee()} style={{ width: "100%" }}>Create New Fee</Button>
                        <br />
                        <br />
                        <Button variant="secondary" onClick={() => this.hideAddFeeModal()} style={{ width: "100%" }}>Close</Button>
                    </Modal.Body>
                </Modal>                

                <Modal show={this.state.showUpdateFeeModal} size="md" centered>
                    <Modal.Header closeButton onClick={() => this.hideUpdateFeeModal()}>
                        <Modal.Title>Update Fee - {this.state.updateScheme}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ display: this.state.showAlertUpdateFee, width: '100%' }}>
                            <Alert variant="success" >
                                Fee Updated
                            </Alert>
                        </div>
                        <div style={{ wordWrap: 'break-word' }}>
                            <h5>Scheme</h5>
                            <Input className="mbsc-remove-margins" value={this.state.updateScheme} type="text" name="updatescheme" id="updatescheme" errorMessage={this.state.errors.updatescheme} error={this.state.showErrors.updatescheme} onChange={this.onTextChange} />
                            
                            <h5>Amount</h5>
                            <Input className="mbsc-remove-margins" value={this.state.updateAmount} type="text" name="updateamount" id="updateamount" errorMessage={this.state.errors.updateamount} error={this.state.showErrors.updateamount} onChange={this.onTextChange} />

                            <h5>Domestic</h5>
                            <Row>
                                <Col sm={12} md={6}>
                                    <RadioGroup name="updatedomesticgroup">
                                        <Radio label="Yes" defaultChecked={this.state.updateDomestic} value="true" id="updatedomesticyes" onChange={this.onTextChange} />
                                    </RadioGroup>
                                </Col>
                                <Col sm={12} md={6}>
                                    <RadioGroup name="updatedomesticgroup">
                                        <Radio label="No" defaultChecked={!this.state.updateDomestic} value="false" id="updatedomesticno" onChange={this.onTextChange} />
                                    </RadioGroup>
                                </Col>
                            </Row>

                        </div>
                        <hr />
                        <Button variant="success" onClick={() => this.updateFee()} style={{ width: "100%" }}>Update Fee</Button>
                        <br />
                        <br />
                        <Button variant="secondary" onClick={() => this.hideUpdateFeeModal()} style={{ width: "100%" }}>Close</Button>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showDeleteFeeModal} size="md" centered>
                    <Modal.Header closeButton onClick={() => this.hideDeleteFeeModal()}>
                        <Modal.Title>Delete Fee - {this.state.deleteScheme}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ wordWrap: 'break-word' }}>
                            <h4>Page will refresh</h4>
                            You will not be able to undo this action and all fee information will be deleted
                        </div>
                        <hr />
                        <Button variant="success" onClick={() => this.deleteFee()} style={{ width: "100%" }}>Confirm Delete</Button>
                        <br />
                        <br />
                        <Button variant="secondary" onClick={() => this.hideDeleteFeeModal()} style={{ width: "100%" }}>Close</Button>
                    </Modal.Body>
                </Modal>

            </div>
        )
    }
}

export default withRouter(CCFees)