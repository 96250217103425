import React, { Component, useRef } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Tabs, Tab, Button, Row, Col, Alert } from 'react-bootstrap';
import SideNav from "./../modals/SideNav";
import Information from '../items/addblog/information';
import Location from '../items/addblog/location';
import Media from './../items/addblog/media';

// services
import Blog from './../functions/Blog';
import UserDetail from './../functions/User';

import { Grid } from "react-loader-spinner";

export function withRouter(Children) {
    return (props) => {
        return <Children {...props} location={props} />
    }
}

class AddNewBlog extends Component {
    constructor(props) {
        super(props);

        this.inforef = React.createRef();
        this.locationref = React.createRef();

        this.state = {
            //email: this.props.location.state.email,
            email: '',
            //loggedIn: this.props.location.state.loggedIn,
            loggedIn: true,
            key: '0',
            tabCount: '3',
            //loginType: this.props.location.state.logginType,
            intro: '',
            body: '',

            // media
            primaryImage: null,
            images: [],
            showLoading: 'none',
            showResultMessage: 'none'
        }
    }

    componentDidMount() {


    }

    setKey = (event) => {

        if (this.state.key == 0) {
            //call a validate function before changing key
            if (this.inforef.current()) {
                this.setState({ key: event });
            }
        } else if (this.state.key == 2) {
            //call a validate function before changing key
            if (this.locationref.current()) {
                this.setState({ key: event });
            }
        } else {
            this.setState({ key: event });
        }
    }

    saveBlog = () => {
        
        //show page loading and disable save button
        this.setState({ showLoading: 'block' });

        //check if all fields have been validated
        if (this.inforef.current() && this.locationref.current()) {
            //get user ID
            UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {
            //UserDetail.getUserByEmail("admin@aussieholidaystays.com.au").then((result) => {
                var userID = result.data.UUID;
                var primaryImage = this.state.primaryImage;

                var images = [];

                if (!this.state.primaryImage) {

                    primaryImage = this.state.images[0];

                    //remove the first image to save duplicates
                    for (var i = 1; i < this.state.images.length; i++) {
                        //images(arr => [...arr, this.state.images[i]]);
                        images.push(this.state.images[i]);
                    }
                }

                // location data
                const location = this.getLocationData();

                Blog.createBlog(
                    document.getElementById('informationtitle').value,
                    this.state.intro,
                    this.state.body,
                    userID,
                    primaryImage,
                    images,
                    location).then(result => {

                        var css = '';
                        var message = '';

                        //if result is good
                        if (result) {
                            css = 'success';
                            message = 'Congratulations, your blog has been created, we will review and publish soon!';
                        } else {
                            css = 'danger';
                            message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                        }

                        this.setState({
                            showLoading: 'none',
                            showResultMessage: 'inline',
                            resultMessageClass: css,
                            resultMessage: message,
                            showButtons: 'none',
                            showDetail: 'none'
                        });

                    });

            });

        } else {
            this.setState({ showLoading: 'none' });
        }
    }

    getInformation = () => {

        const information = {
            Title: document.getElementById('informationtitle').value,
            Introduction: this.state.intro,
            Body: this.state.body,
        }

        return information;
    }

    addImage = (image) => {
        this.state.images.push(image);
    }

    deleteImage = (image) => {
        //this.state.images.push(image);
        this.setState({
            images: this.state.images.filter(filter => filter !== image)
        })

        //setListings(listings.filter(listing => listing.ListingID !== id));
    }

    onSelectImage = (image) => {
        this.setState({ primaryImage: image.data });
    }

    getLocationData = () => {
        const geoLocation = document.getElementById('bloggeoLocation').value;
        const attempt = 0;

        while (geoLocation === null && attempt < 5) {
            geoLocation = document.getElementById('bloggeoLocation').value;
            attempt++;
        }

        const location = {
            City: document.getElementById('blogCity').value,
            Location: geoLocation,
            PlaceID: document.getElementById('blogplaceID').value,
            City: document.getElementById('blogCity').value,
            State: document.getElementById('blogState').value,
            PostCode: document.getElementById('blogPostCode').value,
            Country: document.getElementById('blogCountry').value
        }

        return location;
    }

    changeIntro = (event) => {
        this.setState({ intro: event });
    }

    changeBody = (event) => {
        this.setState({ body: event });
    }

    render() {

        return (
            <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
                <div class="loading-overlay" style={{ display: this.state.showLoading }}>
                    <div class="loading-vertical">
                        <Grid
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={0}
                        />
                    </div>
                </div>
                <Row>
                    <Col className="d-none d-md-block" md="3" lg="2" xl="1">
                        <SideNav
                            loggedIn={this.props.location.state.loggedIn}
                            loginType={this.props.location.state.loginType}
                            email={this.props.location.state.email}
                            activeTab='blogs'
                        />
                    </Col>
                    <Col md="9" lg="10" xl="11">
                        <div style={{ width: '100%' }}>
                            <h1>Add New Blog</h1>
                            <hr />
                        </div>
                        {/*<div className="row" >*/}
                        <Tabs
                            activeKey={this.state.key}
                            onSelect={(k) => this.setKey(k)}
                            style={{ background: "#f7f8f9" }}
                        >
                            <Tab eventKey="0" title="Information">
                                <br />
                                <Information
                                    changeIntro={this.changeIntro}
                                    changeBody={this.changeBody}
                                    isValid={this.inforef}
                                />

                            </Tab>
                            <Tab eventKey="1" title="Media">
                                <br />
                                <Media
                                    images={this.state.images}
                                    addImage={this.addImage}
                                    onSelectImage={this.onSelectImage}
                                    deleteImage={this.deleteImage}
                                />
                            </Tab>
                            <Tab eventKey="2" title="Location" >
                                <br />
                                <Location
                                    isValid={this.locationref}
                                />
                            </Tab>
                        </Tabs>


                    </Col>
                </Row>

                <Row style={{ display: this.state.showResultMessage }}>
                    <Col xs={{ span: 12 }} md={{ span: 9, offset: 3 }} lg={{ span: 10, offset: 2 }} xl={{ span: 11, offset: 1 }}>
                        <Alert variant={this.state.resultMessageClass} >
                            {this.state.resultMessage}
                        </Alert>
                    </Col>
                </Row>

                <Row style={{ display: this.state.showButtons }}>
                    <Col className="d-none d-sm-block" md="3" lg="2" xl="1">
                    </Col>
                    <Col className="d-none d-sm-block" md="9" lg="10" xl="11">

                        {(this.state.key > 0) &&
                            <Button className="col-sm-6" onClick={() => this.setKey((parseInt(this.state.key) + 3 - 1) % 3)} variant="secondary">Back</Button>
                        }
                        {(this.state.key == 0) &&
                            <div className="col-sm-6"></div>
                        }

                        {(this.state.key != this.state.tabCount - 1 && this.state.key != 0) &&
                            <Button className="col-sm-6" onClick={() => this.setKey((parseInt(this.state.key) + 1) % 3)} variant="primary">Next</Button>
                        }
                        {(this.state.key != this.state.tabCount - 1 && this.state.key == 0) &&
                            <Button className="col-sm-6 offset-sm-6" onClick={() => this.setKey((parseInt(this.state.key) + 1) % 3)} variant="primary">Next</Button>
                        }

                        {/*{(this.state.key != this.state.tabCount - 1) &&*/}
                        {/*    <Button className="col-md-6" onClick={() => this.setKey((parseInt(this.state.key) + 1) % 3)} variant="primary">Next</Button>*/}
                        {/*}*/}

                        {(this.state.key == (this.state.tabCount - 1)) &&
                            <Button className="col-sm-6" variant="success" onClick={this.saveBlog}>Save</Button>
                        }
                        {/*</div>*/}
                    </Col>
                    <Col className="d-block d-sm-none" xs="12">

                        {(this.state.key > 0) &&
                            <Button style={{width: "100%"}} onClick={() => this.setKey((parseInt(this.state.key) + 3 - 1) % 3)} variant="secondary">Back</Button>
                        }

                        {(this.state.key != this.state.tabCount - 1 && this.state.key != 0) &&
                            <Button style={{ width: "100%" }}  onClick={() => this.setKey((parseInt(this.state.key) + 1) % 3)} variant="primary">Next</Button>
                        }
                        {(this.state.key != this.state.tabCount - 1 && this.state.key == 0) &&
                            <Button style={{ width: "100%" }}  onClick={() => this.setKey((parseInt(this.state.key) + 1) % 3)} variant="primary">Next</Button>
                        }

                        {/*{(this.state.key != this.state.tabCount - 1) &&*/}
                        {/*    <Button className="col-md-6" onClick={() => this.setKey((parseInt(this.state.key) + 1) % 3)} variant="primary">Next</Button>*/}
                        {/*}*/}

                        {(this.state.key == (this.state.tabCount - 1)) &&
                            <Button style={{ width: "100%" }}  variant="success" onClick={this.saveBlog}>Save</Button>
                        }
                        {/*</div>*/}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withRouter(AddNewBlog)