import React, { Component } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Container, Row, Col, Card } from 'react-bootstrap';

import ImageGallery from 'react-image-gallery';
import "./../styles/image-gallery.css";

import SearchTile from './../items/search-tile.js';
import Index from './../items/index';

import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

import BlogDetail from './../functions/Blog';
import ListingDetail from './../functions/Listing';
import { Grid } from "react-loader-spinner";

const blogDetail = {
    Title: '',
    Intro: '',
    Body: ''
}

export function withRouter(Children) {
    return (props) => {

        const match = { params: useParams() };
        const location = { location: useLocation() };

        return <Children {...props} match={match} location={location.location} />
    }
}

class Blog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            blogID: '', //this.props.location.state.id
            spinner: 'none',
            blogShow: 'block',
            blog: blogDetail,
            stores: [],
            center: { lat: -27.344, lng: 133.036 },
            images: [],
            listingSummary: []
        }
    }

    componentDidMount() {

        const { id } = this.props.match.params;
        //console.log("toke, email: " + id);   // And also show me how it looks!!!

        BlogDetail.getBlogByID(id).then(result => {
            this.setState({ blog: result.data });
        })

        //get blog location
        BlogDetail.getBlogLocationByID(id).then(result => {

            //set map location
            this.setState({ center: { lat: result.data.Latitude, lng: result.data.Longitude } });
            this.setState({ stores: [{ latitude: result.data.Latitude, longitude: result.data.Longitude }] })

            //console.log("location: " + result.data.Location)

            var claenstring = result.data.Location.replace('(', '').replace(')', '');

            ListingDetail.getListingByCriteria(
                '',
                '',
                '',
                '',
                0,
                0,
                claenstring,
                "20",
                false).then(result => {

                    //get some listing to show on the side
                    //ListingDetail.getListingByLocation(result.data.Location, "20").then(result => {
                    if (result && typeof (result.data) != 'string') {
                        //only have 3 or 4 results
                        var limit = 6;
                        var listings = [];
                        result.data.map((listingSummary, index) => {
                            if (index < limit) {
                                listings.push(listingSummary);
                            }
                        })

                        //console.log(listings);

                        this.setState({ listingSummary: listings });

                    }

                })

        })

        //get blog images
        BlogDetail.getBlogMediaByID(id).then(result => {

            //this.setState({ images: [] });

            var tempImages = [];

            if (result.data && typeof (result.data) != 'string' && !result.data.includes("not found")) {
                result.data.map((image, index) => {
                    //this.state.images.push({ originalAlt: "Blog Image Gallery",  original: "data:image/png;base64," + image.ImageData, thumbnail: "data:image/png;base64," + image.ImageData, originalHeight: "600" })
                    tempImages.push({ originalAlt: "Blog Image Gallery", original: "data:image/png;base64," + image.ImageData, thumbnailAlt: "Blog Image Thumb", thumbnail: "data:image/png;base64," + image.ImageData, originalHeight: "600" })
                });
            }

            this.setState({ images: tempImages });

            //this.refs.ImageGallery.forceUpdate();

        })

    }

    

    displayMarkers = () => {
        return this.state.stores.map((store, index) => {
            return <Marker key={index} id={index} position={{
                lat: store.latitude,
                lng: store.longitude
            }}
                onClick={() => console.log("You clicked me!")} />
        })
    }

    

    render() {

        const mapStyles = {
            left: '0',
            top: '0',
            height: '100%',
            width: '100%',
            position: 'absolute'
        };

        const uluru = { lat: -25.344, lng: 131.036 };

        return (
            <div>
            <div style={{ paddingTop: "80px" }}>
                <div class="container-fluid px-0">
                   
                    <Row className="justify-content-md-center px-1">
                        {/*<div class="col-2 px-0" >*/}

                        {/*</div>*/}
                        <div class="col-md-6 col-sm-12 px-0" >
                            <Card style={{ paddingTop: "10px" }}>

                                <div style={{ display: this.state.spinner, width: '100%', textAlign: 'center' }}>
                                    <Grid
                                        color="#00BFFF"
                                        height={100}
                                        width={100}
                                        timeout={0} //in milliseconds
                                    />
                                </div>

                                <Card.Body style={{ display: this.state.blogShow }}>
                                    <Row className="justify-content-md-center">
                                        <h1>{this.state.blog.Title}</h1>
                                    </Row>
                                    <br />
                                    <Row className="justify-content-md-center">
                                        <div dangerouslySetInnerHTML={{ __html: this.state.blog.Intro }} />
                                    </Row>
                                    <br />
                                    <Row >
                                        <ImageGallery ref='ImageGallery' items={this.state.images} />
                                    </Row>
                                    <br />
                                    <Row className="justify-content-md-center">
                                        <div dangerouslySetInnerHTML={{ __html: this.state.blog.Body }} />
                                    </Row>
                                    <Row>
                                        <Col md="12" className="map-responsive" >
                                            <Map
                                                google={this.props.google}
                                                zoom={6}
                                                style={mapStyles}
                                                center={this.state.center}
                                                initialCenter={uluru}
                                                maxZoom={14}
                                            >
                                                {this.displayMarkers()}
                                            </Map>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </div>
                        <div class="d-none d-md-block col-2 px-0" style={{ marginLeft: "10px" }}>

                            {this.state.listingSummary.map((listingSummary, index) =>
                                <div className="col-sm-12">
                                    <SearchTile id={listingSummary.ListingID} title={listingSummary.Title} image={listingSummary.PrimaryImage} bedroom={listingSummary.NumberOfBedrooms} guest={listingSummary.NumberOfGuests} maxGuest={listingSummary.MaxNumberOfGuests} price={listingSummary.Price} accomodation={listingSummary.TypeOfListing} featured={listingSummary.Featured} />
                                </div>
                            )}
                            
                        </div>
                        {/*<div class="col-2 px-0" >*/}

                        {/*</div>*/}
                    </Row>

                </div>


            </div>

                <Container>
                    <Index />
                </Container>

            </div>

        )
    }
}

export default withRouter(GoogleApiWrapper({
    apiKey: 'AIzaSyCokK7P3SJHrW4MRbQjQTaJ7_VFkCkiBFc'
})(Blog));