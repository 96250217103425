import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import { Input } from '@mobiscroll/react';
import { Button, Col, Row } from 'react-bootstrap';

// services
import UserDetail from "./../functions/User";

function VerifyAccount() {

    //get the id
    const { id } = useParams();

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState({});
    const [runUserCheck, setRunUserCheck] = useState(true);

    const [showNotFound, setShowNotFound] = useState('none');
    const [showVerified, setShowVerified] = useState('none');
    const [showVerifiedError, setShowVerifiedError] = useState('none');
    const [showPassword, setShowPassword] = useState('none');
    const [showPasswordCreated, setShowPasswordCreated] = useState('none');
    const [showPasswordFailed, setShowPasswordFailed] = useState('none');

    const updatePassword = () => {

        if (getvalid()) {

            //console.log("ID: " + id);
            //console.log("Password: " + input["password"]);

            UserDetail.updatePassword(input["password"], id).then(result => {
                console.log(result);
                if (result) {
                    //password updated now verify the account
                    UserDetail.verifyAccount(id).then(verified => {
                        if (verified && typeof (verified.data) != 'string') {
                            setShowVerified('block');
                            setShowPassword('none');
                            setShowPasswordCreated('block');
                        } else {
                            setShowVerifiedError('block');
                            setShowPassword('none');
                            setShowPasswordCreated('block');
                        }
                    });
                } else {
                    //error creating password
                    setShowPassword('none');
                    setShowPasswordFailed('block');
                }
            })
        }
    }

    const onTextChange = (event) => {
        let newinput = input;

        if (event.target.name === "password") {
            newinput[event.target.name] = event.target.value;
        }

        if (event.target.name === "passwordconfirm") {
            newinput[event.target.name] = event.target.value;
        }

        setInput(newinput)

        if (event.target.value) {
            //remoev the errors fom array state
            let newerrors = {};
            let newshowErrors = {};

            Object.entries(errors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(showErrors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newshowErrors[error[0]] = error[1];
                }
            })

            setErrors(newerrors);
            setShowErrors(newshowErrors);

        }
    }

    const getvalid = () => {
        let newerrors = {};
        let newshowErrors = {};
        let isValid = true;

        if (!input["password"]) {
            isValid = false;
            newerrors["password"] = "Please enter your password.";
            newshowErrors["password"] = true;
        }

        if (!input["passwordconfirm"]) {
            isValid = false;
            newerrors["passwordconfirm"] = "Please enter your confirm password.";
            newshowErrors["passwordconfirm"] = true;
        }

        if (typeof input["password"] !== "undefined" && typeof input["passwordconfirm"] !== "undefined") {

            if (input["password"] !== input["passwordconfirm"]) {
                isValid = false;
                newerrors["password"] = "Passwords don't match.";
                newshowErrors["password"] = true;
            }
        }

        setErrors(newerrors);
        setShowErrors(newshowErrors);

        return isValid;
    }

    document.body.style.height = "100vh";
    const nav = document.getElementsByClassName("navbar");
    if (nav[0]) {
        nav[0].style.visibility = "hidden";
    }

    useEffect(() => {
        if (runUserCheck) {
            setRunUserCheck(false);
            //check if password is set or not
            UserDetail.getUserByID(id).then(result => {
                //alert(result.data);
                if (result && typeof (result.data) !== 'string') {
                    if (result.data.Password === "" || result.data.Password === "user.Password") {
                        setShowPassword('block');
                    } else {
                        //set account verified
                        UserDetail.verifyAccount(id).then(verified => {
                            if (verified && typeof (verified.data) == 'string' && verified.data == "User updated") {
                                setShowVerified('block');
                            } else {
                                setShowVerifiedError('block');
                            }
                        });
                    }
                } else {
                    //user not found
                    setShowNotFound('block');
                }
            })
        }
    });

    return (
        <div style={{ paddingTop: "30vh", width: "100%", textAlign: "center" }}>
            <div>
                <Row>
                    <Col style={{display: showNotFound}} sm="12" md={{ span: 4, offset: 4 }} >
                        User Account not found
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: showPasswordCreated }} sm="12" md={{ span: 4, offset: 4 }} >
                        Your account password has been updated
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: showPasswordFailed }} sm="12" md={{ span: 4, offset: 4 }} >
                        Account password creation failed, please try again or contact us for help
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: showVerified }} sm="12" md={{ span: 4, offset: 4 }} >
                        Your account has been verified
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: showVerifiedError }} sm="12" md={{ span: 4, offset: 4 }} >
                        Account verification failed, please try again or contact us for help
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: showPassword }} sm="12" md={{ span: 4, offset: 4 }} >
                        Please create a password for your account
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: showPassword }} sm="12" md={{ span: 4, offset: 4 }} >
                        <Input inputStyle="box" labelStyle="floating" name="password" type="password" label=" New Password" passwordToggle="true" iconShow="eye" iconHide="eye-blocked" autoComplete="new-password" errorMessage={errors.password} error={showErrors.password} onChange={onTextChange} />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: showPassword }} sm="12" md={{ span: 4, offset: 4 }}>
                        <Input inputStyle="box" labelStyle="floating" name="passwordconfirm" type="password" label="Confirm Password" passwordToggle="true" iconShow="eye" iconHide="eye-blocked" errorMessage={errors.passwordconfirm} error={showErrors.passwordconfirm} onChange={onTextChange} />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: showPassword }} sm="12" md={{ span: 4, offset: 4 }}>
                        <Button type="submit" variant="secondary" onClick={updatePassword} style={{ width: "100%" }}>Create Password & Verify</Button>
                    </Col>
                </Row>
            </div>
        </div>
        )
}

export default VerifyAccount