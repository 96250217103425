import React, { useState, useEffect } from 'react';
import { Card, Image, Row, Col, Button } from 'react-bootstrap';

import { FaTrashAlt } from 'react-icons/fa';

import './../../styles/media.css';

import Compressor from 'compressorjs';

import BlogDetail from './../../functions/Blog';

const Media = (props) => {
    const [images, addImage] = useState([]);
    const [styles, addStyle] = useState([]);

    const hiddenFileInput = React.useRef(null);

    const style_selected = {
        border: "3px solid rgb(13, 10, 2530)",
        padding: "5px"
    };

    const style_notselected = ({
        border: "none",
        padding: "5px"
    });

    const handleDragOver = (event) => {
        event.preventDefault();
    }

    const handleDrop = (event) => {
        event.preventDefault(); 
        event.stopPropagation();

        //loop through the images that were dropped
        for (var i = 0; i < event.dataTransfer.files.length; i++) {

            let imageFile = event.dataTransfer.files[i];

            // add the image to our array for tracking purposes
            //addImage(arr => [...arr, imageFile]);

            new Compressor(imageFile, {
                quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                convertSize: 400,
                success: (compressedResult) => {
                    // compressedResult has the compressed file.
                    // Use the compressed file to upload the images to your server.        
                    imageFile = compressedResult;
                },
            });

            addImage(arr => [...arr, {
                data: imageFile,
                ID: ""
            }]);

            // need to add a default style as well
            addStyle(arr => [...arr, style_notselected])

            // and pass it back to the parent
            props.addImage && props.addImage(imageFile);
        }
    }

    const changeHandler = (event) => {
        //loop through the images that were dropped
        for (var i = 0; i < event.target.files.length; i++) {

            let imageFile = event.target.files[i];

            // add the image to our array for tracking purposes
            //addImage(arr => [...arr, imageFile]);

            new Compressor(imageFile, {
                quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                convertSize: 400,
                success: (compressedResult) => {
                    // compressedResult has the compressed file.
                    // Use the compressed file to upload the images to your server.        
                    imageFile = compressedResult;
                },
            });

            addImage(arr => [...arr, {
                data: imageFile,
                ID: ""
            }]);

            // need to add a default style as well
            addStyle(arr => [...arr, style_notselected])

            // and pass it back to the parent
            props.addImage && props.addImage(imageFile);
        }
    }

    const handleCheckboxSelect = (event, index) => {
        // pass the primary back to the parent
        let imageFile = images[index];
        props.onSelectImage && props.onSelectImage(imageFile.data);

        // set the border style of all images to none
        for ( let image = 0; image < images.length; image++) {
            styles[image] = style_notselected;
        }

        // set the border style of the selected
        styles[index] = style_selected;
    }

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const deleteFile = (event) => {

        var tempImages = [];
        var imageFile = null;

        //alert(event);

        images.map((image, index) => {
            if (event != index) {
                tempImages = [...tempImages, image];
            } else {
                imageFile = image;
                //console.log("delete: " + image.ID);
            }
        })

        addImage(tempImages);

        // and pass it back to the parent
        props.deleteImage && props.deleteImage(imageFile);
        
        // and pass it back to the parent to remove
        //props.addImage && props.addImage(imageFile);


    }

    const dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    useEffect(() => {
        if (props.blog) {

            BlogDetail.getBlogMediaByID(props.blog.UUID).then(result => {

                //clear images and style
                console.log('images cleared - edit: ' + result.data);
                addImage([]);
                addStyle([]);

                if (result.data && typeof (result.data) != 'string') {
                    result.data.map((image, index) => {

                        let imageFile = "data:image/png;base64," + image.ImageData;
                        var file = dataURLtoFile(imageFile, image.UUID + '.png');
                        // add the image to our array for tracking purposes
                        addImage(arr => [...arr, {
                            data: file, //imageFile,
                            ID: "" //image.UUID
                        }]);

                        //check if primary image
                        if (image.Primary) {
                            // set the border style of the selected
                            //styles[index] = style_selected;
                            addStyle(arr => [...arr, style_selected])

                            props.onSelectImage && props.onSelectImage(file);

                        } else {
                            // need to add a default style as well
                            addStyle(arr => [...arr, style_notselected])
                        }

                        // and pass it back to the parent
                        
                        props.addImage && props.addImage(file);


                    });
                }
            })

        } else {
            //clear images and style
            //console.log('images cleared - new');
            addImage([]);
            addStyle([]);
        }

    }, [])

    return (        
        <>            
            <div className="wrapper">
                <div className="drop_zone" onDragOver={handleDragOver} onDrop={handleDrop}>
                    <p>Drag and drop image here....
                    </p>
                </div>
                <div>
                    <div style={{textAlign: 'center', width: '100%'}}>
                        <h2>OR</h2>
                    </div>

                    <Button className="col-md-12" style={{width: "100%"}} variant="secondary" onClick={handleClick}>
                        Select file(s)
                    </Button>
                    <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={changeHandler}
                        style={{ display: 'none' }}
                        accept="image/png, image/gif, image/jpeg"
                        multiple
                    />
                </div>
                <br/>
                <Card>
                    <Card.Body>
                        <Row className="mb-3">
                            <h4>Select an Image as the Primary</h4>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {images.map((image, index) =>
                                    <div>
                                        <div style={{ textAlign: 'right' }}><Button variant='outline-danger' onClick={event => deleteFile(index)} ><FaTrashAlt /></Button></div>
                                        {/*<Image key={index} src={URL.createObjectURL(image)} height="200px" onClick={event => handleCheckboxSelect(event, index)} name={image.name} style={styles[index]} />*/}
                                        <Image key={index} src={(!image.ID ? URL.createObjectURL(image.data) : image.data)} height="200px" onClick={event => handleCheckboxSelect(event, index)} name={image.name} style={styles[index]} />
                                    </div>
                                )}
                            </div>


                        </Row>
                    </Card.Body>
                </Card>
        </div>
       </>
    )
}

export default Media
