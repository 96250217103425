import MessagesService from "../services/MessagesService";

const Messages = {
     // get all messages
     async getMessages() {
        const result = await MessagesService.getMessages();

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    // get all messages summary
    async getSummaryMessages() {
        const result = await MessagesService.getSummaryMessages();

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    // get messages by id
    async getMessagesByID(key, value) {
        const result = await MessagesService.getMessagesByID(key, value);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    // get messages by id
    async getSummaryMessagesByID(key, value) {
        const result = await MessagesService.getSummaryMessagesByID(key, value);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    // create a new message
    async createMessage(senderid, receiverid, rootmessageid, parentmessageid, message, listingid, reservationid) {
        const result = await MessagesService.createMessage(senderid, receiverid, rootmessageid, parentmessageid, message, listingid, reservationid);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    }
}

export default Messages