import axios from 'axios';

import { BASE_URL } from './GlobalDefinitions';
import { AUTH, CONTENT_TYPE, METHODS_DELETE, METHODS_GET, METHODS_POST, ORIGIN } from './Headers';

const INVOICE_API_BASE_URL = "/invoices";
const INVOICE_ID_API_BASE_URL = "/invoicesid";
const INVOICE_BY_USER_ID = "/invoicesbyuserid";
const INVOICE_BY_LISTING_ID = "/invoicesbylistingid"
const INVOICE_ALL_LISTING_ID_FOR_USER_ID = "/invoicesallbyuserid";

class InvoiceService {
    // get all users
    getInvoices() {
        return axios.get(BASE_URL + INVOICE_API_BASE_URL, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // create a new user
    createInvoice(invoice) {
        return axios.post(BASE_URL + INVOICE_API_BASE_URL, invoice, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }

    // get user by Username
    getInvoiceByID(id) {
        return axios.get(BASE_URL + INVOICE_ID_API_BASE_URL, {
            params: {
                ID: id
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // delete invoice
    deleteInvoice(id) {
        return axios.delete(BASE_URL + INVOICE_API_BASE_URL, {
            params: {
                ID: id
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get invoices by user id
    getInvoiceByUserID(id) {
        return axios.get(BASE_URL + INVOICE_BY_USER_ID, {
            params: {
                UserID: id
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get invoices by listing id
    getInvoiceByListingID(id) {
        return axios.get(BASE_URL + INVOICE_BY_LISTING_ID, {
            params: {
                ListingID: id
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get all invoices for all listings that belong to this user id
    getInvoicesForAllListingIDsForUserID(id) {
        return axios.get(BASE_URL + INVOICE_ALL_LISTING_ID_FOR_USER_ID, {
            params: {
                UserID: id
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

     // get invoices by listing id
     /*updateInvoiceByID(state) {
        return axios.get(BASE_URL + INVOICE_API_BASE_URL, state, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }*/
   
}

export default new InvoiceService()