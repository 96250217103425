import axios from 'axios';

import { BASE_URL } from './GlobalDefinitions';
import { AUTH, METHODS_GET, ORIGIN } from './Headers';

const PMS_API_URL = "/pmsfees";

class PMSService {
    getPMSFees() {
        return axios.get(BASE_URL + PMS_API_URL, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }
    
}

export default new PMSService()