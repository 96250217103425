import React, { Component } from 'react';

import { Button, Modal } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import AsyncStorage from "@react-native-async-storage/async-storage";

// others
import Footer from './pages/footer';

// pages
import Error from "./pages/errorPage";
import Home from "./pages/home";

import Dashboard from "./pages/dashboard";
import Favourites from "./pages/favourites";
import Invoices from "./pages/invoices";

import Message from "./pages/message";
import Messages from "./pages/messages";

import Review from "./pages/review";
import Reviews from "./pages/reviews";

import Rating from "./pages/rating";
import Ratings from "./pages/ratings";

import MyListings from "./pages/mylistings";
import Profile from "./pages/profile";
import Reservations from "./pages/reservations";

import AddNewListing from "./pages/addNewListing";
import EditListing from "./pages/editListing";

import AddNewBlog from "./pages/addNewBlog";
import AddNewPromo from "./pages/addNewPromo";
import Admin from "./pages/admin";
import Blog from "./pages/blog";
import Blogs from "./pages/blogs";
import EditBlog from "./pages/editBlog";
import EditPromo from "./pages/editPromo";
//import ExportICal from "./pages/exportiCal";
import Invoice from "./pages/invoice";
import Listing from "./pages/listing";
import PasswordReset from "./pages/passwordReset";
//import Promo from "./pages/promo";
import Promos from "./pages/promos";
import SearchResults from "./pages/searchResults";
import Users from "./pages/users";
import PMSHosts from "./pages/pmsHosts";
import VerifyAccount from "./pages/verifyAccount";

import CCFees from "./pages/ccFees";

import ContentPages from "./pages/contentPages";
import PageContent from "./pages/pagescontent";

//reservations
import ReservationDetail from './items/reservations/detail';

// nav bars
import NavBar from "./modals/NavBar";

// pop up modals
import LoginModal from './modals/LoginModal';
import RegisterModal from "./modals/RegisterModal";
import RequestToBookModal from "./modals/RequestToBookModal";
import ResetPasswordModal from "./modals/ResetPasswordModal";

// functions
import ErrorService from "./functions/Error";
import User from "./functions/User";

import Globals from './functions/globals';

import { Grid } from "react-loader-spinner";

//import './hocs.js';

// testing
//import Testing from "./pages/testing";
export function withRouter(Children) {
    return (props) => {
        return <Children {...props} />
    }
}

class Base extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLogin: false,
            showRegister: false,
            showRequestToBook: false,
            loggedIn: false,
            toHome: false,
            showPasswordReset: false,
            showPasswordResetSent: false,
            showPasswordResetFail: false,
            showRegistrationSuccess: false,
            showVerifyEmailSent: false,
            showVerifyEmailFail: false,
            showPasswordResetRequired: false,
            errorLogin: 'none',
            invalidLogin: 'none',
            loginType: '',
            firstname: '',
            email:  '',
            dob: '',
            usernameOrEmail: '',
            password: '',
            pathname: '/',
            users: [],
            page: window.location.pathname,
            userID: '',
            showHome: false,
            showError: false
        }
    }

    error = (page, error) => {
        //console.log('not loading error: ' + error)
        this.setState({ showError: true })
        //log the error to the backend
        ErrorService.postErrorMessage(page, error).then(result => {
            //nothing to action as this is the error logging
        })
    }

    componentDidMount = async () => {
        // Activate the event listener
        this.setupBeforeUnloadListener();

        //always check if logged in and redirect to home page if not
        var token = await AsyncStorage.getItem('token');

        //check if token is null and check session storage - this is only done to allow window to be user refreshed and caught
        if (token == null && localStorage.getItem("tokencleared") == "false") {
            token = sessionStorage.getItem("token");
            if (token != null) {
                //reset the async storage for new tabs
                await AsyncStorage.setItem("token", token);
            }
        }

        this.setState({ userID: token });

        if (token != null) {
            window.sessionStorage.setItem("token", token);
            localStorage.setItem("tokencleared", "false");

            User.getUserByID(token).then(result => {
                    //if (typeof result.data != "undefined") {

                if (typeof result.data != "undefined") {

                        this.setState({ firstname: result.data.Firstname });
                        this.setState({ email: result.data.Email });
                    this.setState({ loginType: result.data.LoginType });
                    this.setState({ loggedIn: true });

                        //check if admin and page being loaded
                        if (window.location.pathname === '/pagecontent') {
                            if (result.data.LoginType !== Globals.typeAdmin) {
                                window.location.href = "/";
                            }
                        }

                    } else {

                        this.setState({ loggedIn: false });
                        this.setState({ users: [] });

                        //if home do not reload
                        if (window.location.pathname !== '/' && window.location.pathname !== '/search' && window.location.pathname.indexOf('/listing') > 0 && window.location.pathname !== '/exportical' && window.location.pathname !== '/passwordreset') {
                            window.location.href = "/";
                        }

                        if (window.location.pathname === '/pagecontent') {
                            window.location.href = "/";
                        }
                    }

                //}


            })
        } else {
            //if home do not reload
            if (window.location.pathname !== '/' && window.location.pathname !== '/search' && window.location.pathname.indexOf('/listing') > 0 && window.location.pathname !== '/exportical' && window.location.pathname !== '/passwordreset') {
                window.location.href = "/";
            }

            if (window.location.pathname === '/pagecontent') {
                window.location.href = "/";
            }

            //this function is to allow login on pages when the user is not logged in
            if (window.location.pathname.indexOf('/reservation') > -1) {
                //show login and set state to refresh reservation
                this.setState({ showLogin: true });
                this.setState({ showHome: false });
            } else {
                this.setState({ showHome: true });
            }
        }
    }
    
    showLogin = () => {
        //alert("base")
        this.setState({ showLogin: true });

    }

    hideLogin = () => {
        this.setState({ showLogin: false });
        //check if reservation and return to home
        if (window.location.pathname.indexOf('/reservation') > -1) {
            window.location.href = "/";
        }
    }
    showRegister = () => this.setState({showRegister: true});
    hideRegister = () => {
        this.setState({ showRegister: false });
        //check if reservation and return to home
        if (window.location.pathname.indexOf('/reservation') > -1) {
            window.location.href = "/";
        }
    }
    showLoginModal = () => {
        this.setState({ showLogin: true });
    }

    hideRequestToBook = () => this.setState({ showRequestToBook: false });
    hidePasswordReset = () => this.setState({ showPasswordReset: false });
    hidePasswordSuccess = () => this.setState({ showPasswordResetSent: false });
    hidePasswordFail = () => this.setState({ showPasswordResetFail: false });
    hideRegistrationSuccess = () => this.setState({ showRegistrationSuccess: false });

    hideVerifyEmailSuccess = () => this.setState({ showVerifyEmailSent: false });
    hideVerifyEmailFail = () => this.setState({ showVerifyEmailFail: false });

    hidePasswordResetRequired = () => this.setState({ showPasswordResetRequired: false });

    closeLogin = (email, password) => {
        User.loginUser(email, password).then(result => {
            if (typeof result != "undefined") {

                this.setState({ errorLogin: 'none' });
                //check if error and show modal message
                if (result === "old password") {
                    this.setState({
                        errorLogin: 'block',
                        showPasswordResetRequired: true
                    });
                } else {
                    
                    this.setState({ userID: result.data.UUID });

                    //check user is valid
                    if (result.data.Valid) {

                        //console.log(result)

                        this.setState({
                            invalidLogin: 'none',
                            showLogin: !this.state.showLogin,
                            loggedIn: true,
                            users: result.data,
                            userID: result.data.UUID,
                            firstname: result.data.Firstname,
                            email: result.data.Email,
                            loginType: result.data.LoginType
                        });
                    } else {
                        this.setState({ invalidLogin: 'block' });
                    }
                }
            } else {
                //advise user of issue logging in
                this.setState({ errorLogin: 'block' });
                this.setState({ invalidLogin: 'none' });
            }
        }).catch(error => {
            //log error and advice user
            this.setState({ errorLogin: 'block' });
            this.setState({ invalidLogin: 'none' });
        });

    }

    closeRegister = (email, password) => { 
        User.loginUser(email, password).then(result => {
            if (typeof result != "undefined") {
                this.setState({ showRegister: false });

                this.setState({ loggedIn: true });
                this.setState({ users: result.data });

                this.setState({ firstname: result.data.Firstname });
                this.setState({ email: result.data.Email });
                this.setState({ loginType: result.data.LoginType });

                this.setState({ showRegistrationSuccess: true });

                //set ID to be sent to google analitics 
                this.setState({ userID: result.data.UUID });
                this.setState({ lastname: result.data.Lastname });

            } else {
                //advise user of issue logging in
                //this.setState({ errorRegister: 'block' });
            }
        }).catch(error => {
            //log error and advice user
            //this.setState({ errorRegister: 'block' });
        });
    }

    closeRequestToBook = () => {
        this.setState({ showRequestToBook: !this.state.showRequestToBook });

        User.loginUser(this.state.usernameOrEmail, this.state.password).then(result => {
            this.setState({ loggedIn: true });
            this.setState({ users: result.data });

            this.setState({ firstname: result.data.Firstname });
            this.setState({ email: result.data.Email });
            this.setState({ loginType: result.data.LoginType });
        });

    }

    logOut = () => {
        this.setState({
            loggedIn: false,
        });

        AsyncStorage.removeItem("token");
        window.sessionStorage.setItem("token", null);
        window.sessionStorage.removeItem("token");

        localStorage.setItem("tokencleared", "true");

        //redirect to home page
        window.location.href = "/";

    }

    dontHaveAccount = () => {
        this.setState({ showLogin: false });
        this.setState({ showPasswordResetFail: false });
        this.setState({ showRegister: true});
    }

    doHaveAccount = () => {
        this.setState({ showRegister: false});
        this.setState({ showLogin: true});
    }

    forgotPassword = () => {
        this.setState({
            showLogin: false,
            showPasswordResetRequired: false,
            showPasswordReset: true
        });
    }

    forgotPasswordEmail = (email) => {
        this.setState({ showPasswordReset: false });

        User.resetPassword(email).then(result => {
            //console.log(result);

            if (typeof result != "undefined" && result.data) {
                this.setState({ showPasswordResetSent: true });
            } else {
                //advise user of issue email does not exist
                this.setState({ showPasswordResetFail: true });
            }
        }).catch(error => {
            //log error and advice user

        });

    }

    sendVerifyEmail = () => {
        this.setState({ invalidLogin: 'none' });

        this.setState({ showLogin: false });
        User.resendWelcomeEmail(this.state.userID).then(result => {
            if (typeof result != "undefined" && result.data) {
                this.setState({ showVerifyEmailSent: true });
            } else {
                //advise user of issue email does not exist
                this.setState({ showVerifyEmailFail: true });
            }
        }).catch(error => {
            //log error and advice user

        });

    }

    onChangeEmail = (email) => {
        this.setState({ email: email})
    }

    onChangeDOB = (dob) => {
        this.setState({ dob: dob})
    }

    onSelectType = (value) => {
        if ( value === Globals.typeBooking) {
            this.setState({ loginType: Globals.typeBooking});
        } else 
        if ( value === Globals.loginTypeHost) {
            this.setState({ loginType: Globals.typeHosting});
        }
    }

    onChangeUsernameOrEmail = (usernameOrEmail) => {
        this.setState({usernameOrEmail: usernameOrEmail});
    }

    onChangePassword = (password) => {
        this.setState({password: password});
    }

    // Things to do before unloading/closing the tab
    doSomethingBeforeUnload = () => {
        // Do something
        AsyncStorage.removeItem("token");
    }

    // Setup the `beforeunload` event listener
    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            //ev.preventDefault();
            //ev.stopImmediatePropagation();
            return this.doSomethingBeforeUnload();
            //this.doSomethingBeforeUnload();
            //return false;
        });
    };

    render() {                
        //let { locvalue } = this.props.params;

        if (this.state.showError) {
            return (
                <Error/>
            )
        } else if (!this.state.email && !this.state.showHome) {
            return (
                <div>
                
                <div class="loading-overlay">
                    <div class="loading-vertical">
                        <Grid
                            
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={0}
                        />
                    </div>
                </div>
                <LoginModal
                        showLogin={this.state.showLogin}
                        onClose={this.closeLogin}
                        dontHaveAccount={this.dontHaveAccount}
                        setUsernameOrEmail={this.onChangeUsernameOrEmail}
                        setPassword={this.onChangePassword}
                        hideLogin={this.hideLogin}
                        forgotPassword={this.forgotPassword}
                        errorLogin={this.state.errorLogin}
                        invalidLogin={this.state.invalidLogin}
                        sendVerifyEmail={this.sendVerifyEmail}
                    />
                    <RegisterModal
                        showRegister={this.state.showRegister}
                        onClose={this.closeRegister}
                        setEmail={this.onChangeEmail}
                        setLoginType={this.onSelectType}
                        doHaveAccount={this.doHaveAccount}
                        hideRegister={this.hideRegister}
                        forgotPassword={this.forgotPassword}
                    />
                    <RequestToBookModal
                        showRequestToBook={this.state.showRequestToBook}
                        onClose={this.closeLogin}
                        dontHaveAccount={this.dontHaveAccount}
                        setUsernameOrEmail={this.onChangeUsernameOrEmail}
                        setPassword={this.onChangePassword}
                        hideRequestToBook={this.hideRequestToBook}
                    />
                    <ResetPasswordModal
                        showPasswordReset={this.state.showPasswordReset}
                        dontHaveAccount={this.dontHaveAccount}
                        hidePasswordReset={this.hidePasswordReset}
                        forgotPasswordEmail={this.forgotPasswordEmail}
                    />

                    {/*temp modal for password updates*/}
                    <Modal show={this.state.showPasswordResetRequired} className="my-modal-fail">
                        <Modal.Header closeButton onClick={() => this.hidePasswordResetRequired()}>
                            <Modal.Title>Password Reset Required</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Due to a new security feature being added we require all users to reset their passwords. <br />
                            Please click here to reset your password? <a href="#" onClick={() => this.forgotPassword()}>Reset</a>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="secondary" onClick={() => this.hidePasswordResetRequired()} style={{ width: "100%" }}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showPasswordResetSent} className="my-modal-success">
                        <Modal.Header closeButton onClick={() => this.hidePasswordSuccess()}>
                            <Modal.Title>Email Sent</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            An email has been sent with a link to reset. <br />
                            <p style={{ fontSize: "12px" }}>*If email does not arrive in 5 minutes please check junk or send again</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="secondary" onClick={() => this.hidePasswordSuccess()} style={{ width: "100%" }}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showPasswordResetFail} className="my-modal-fail">
                        <Modal.Header closeButton onClick={() => this.hidePasswordFail()}>
                            <Modal.Title>Account Not Found</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            It appears we cannot find you in our system, please <a href="#" onClick={() => this.dontHaveAccount()}>Register Here</a> for an account!
                            <p style={{ fontSize: "12px" }}>*If you already have an account please try reset again in 5 minutes or contact us</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="secondary" onClick={() => this.hidePasswordFail()} style={{ width: "100%" }}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showVerifyEmailSent} className="my-modal-success">
                        <Modal.Header closeButton onClick={() => this.hideVerifyEmailSuccess()}>
                            <Modal.Title>Email Sent</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Email sent, please verify and try login again. <br />
                            <p style={{ fontSize: "12px" }}>*If email does not arrive in 5 minutes please check junk or send again</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="secondary" onClick={() => this.hideVerifyEmailSuccess()} style={{ width: "100%" }}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showVerifyEmailFail} className="my-modal-fail">
                        <Modal.Header closeButton onClick={() => this.hideVerifyEmailFail()}>
                            <Modal.Title>Something went wrong</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            It appears we are not able to send and email, please try again in 5 minutes.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="secondary" onClick={() => this.hideVerifyEmailFail()} style={{ width: "100%" }}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showRegistrationSuccess} className="my-modal-success">
                        <Modal.Header >
                            <Modal.Title>Successful Registration</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            An email has been sent with a link to verify your account. <br />
                            Welcome to the Aussie Holiday Stays community.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="secondary" onClick={() => this.hideRegistrationSuccess()} style={{ width: "100%" }}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div >
                )
        } else {
            return (
                <div>
                    <Router>
                        <NavBar
                            showLogin={this.showLogin}
                            showRegister={this.showRegister}
                            loggedIn={this.state.loggedIn}
                            loginType={this.state.loginType}
                            firstname={this.state.firstname}
                            email={this.state.email}
                            logout={this.logOut}
                            page={this.state.page}
                            test="something"
                        />
                        <Routes>
                            <Route exact path="/" element={<Home />}
                                logOut={this.logOut}
                            />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/favourites" element={<Favourites state={this.state} />} />
                            <Route path="/invoices" element={<Invoices state={this.state} />} />

                            <Route path="/message/:id/:resID/:listID" element={<Message loggedIn={this.state.loggedIn} loginType={this.state.loginType} email={this.state.email} state={this.state} />} />
                            <Route path="/messages" element={<Messages state={this.state} />} />

                            <Route path="/review/:id/:resID/:listID" element={<Review loggedIn={this.state.loggedIn} loginType={this.state.loginType} email={this.state.email} state={this.state} />} />
                            <Route path="/reviews" element={<Reviews state={this.state} />} />

                            <Route path="/rating/:id/:resID" element={<Rating loggedIn={this.state.loggedIn} loginType={this.state.loginType} email={this.state.email} state={this.state} />} />
                            <Route path="/ratings" element={<Ratings state={this.state} />} />

                            <Route path="/mylistings" element={<MyListings state={this.state} />} />
                            <Route path="/addnewlisting" element={<AddNewListing state={this.state} />} />
                            <Route path="/editlisting/:id" element={<EditListing loggedIn={this.state.loggedIn} loginType={this.state.loginType} email={this.state.email} state={this.state} />} />

                            <Route path="/profile" element={<Profile state={this.state} />} />
                            <Route path="/reservations" element={<Reservations state={this.state} />} />
                            <Route path="/admin" element={<Admin />} />
                            <Route path="/users" element={<Users state={this.state} />} />
                            <Route path="/hosts" element={<PMSHosts state={this.state} />} />
                            <Route path="/ccfees" element={<CCFees state={this.state} />} />
                        {/*<Route path="/search" component={SearchResults} />*/}
                            {/*<Route path="/search" render={(props) => <SearchResults userID={this.state.userID} {...props} />} />*/}
                            <Route path="/search" element={<SearchResults userID={this.state.userID} />} />

                            <Route path="/listing/:id/:checkin?/:guests?/:pets?" element={<Listing showLoginModal={this.showLoginModal} showForgotPassword={this.forgotPassword}email={this.state.email} userID={this.state.userID} />} />

                            {/*<Route path="/invoice/:id" render={(props) => <Invoice loggedIn={this.state.loggedIn} loginType={this.state.loginType} email={this.state.email} {...props} />} />*/}
                            <Route path="/invoice/:id" element={<Invoice loggedIn={this.state.loggedIn} loginType={this.state.loginType} email={this.state.email} />} />

                            {/*<Route path="/exportical/:id" component={<ExportICal />} />*/}

                            <Route path="/reservationdetail" element={<ReservationDetail />} />
                            <Route path="/reservationdetail/:id" element={<ReservationDetail loggedIn={this.state.loggedIn} loginType={this.state.loginType} email={this.state.email} error={this.error} />} />

                            <Route path="/blog/:id" element={<Blog />} />
                            <Route path="/addnewblog" element={<AddNewBlog state={this.state} />} />
                            <Route path="/editblog" element={<EditBlog state={this.state} />} />
                            <Route path="/blogs" element={<Blogs state={this.state} />} />

                        {/*<Route path="/promo/:id" component={Promo} />*/}
                            <Route path="/addnewpromo" element={<AddNewPromo state={this.state} />} />
                            <Route path="/editpromo" element={<EditPromo state={this.state} />} />
                            <Route path="/promos" element={<Promos state={this.state} />} />

                            <Route path="/passwordreset/:id" element={<PasswordReset />} />
                            <Route path="/verify/:id" element={<VerifyAccount />} />

                            <Route path="/pagecontent" element={<PageContent />} />
                        {/*<Route path="/testing" component={Testing} />*/}

                            <Route path="/about" element={<ContentPages page="About" />} />
                            <Route path="/press" element={<ContentPages page="PressPage" />} />
                            <Route path="/termsandconditions" element={<ContentPages page="TermsAndCond" />} />
                            <Route path="/privacypolicy" element={<ContentPages page="PrivacyPolicy" />} />
                            <Route path="/cancelpolicy" element={<ContentPages page="CancelPolicies" />} />
                            <Route path="/guestfaq" element={<ContentPages page="GuestFAQ" />} />
                            <Route path="/hostfaq" element={<ContentPages page="HostFAQ" />} />
                            <Route path="/hostterms" element={<ContentPages page="HostTermsAndCond" />} />
                            <Route path="/contentguidelines" element={<ContentPages page="ContentGuidelines" />} />

                        </Routes>
                        <Footer />
                    </Router>

                        {/*</div>*/}
                    <LoginModal
                        showLogin={this.state.showLogin}
                        onClose={this.closeLogin}
                        dontHaveAccount={this.dontHaveAccount}
                        setUsernameOrEmail={this.onChangeUsernameOrEmail}
                        setPassword={this.onChangePassword}
                        hideLogin={this.hideLogin}
                        forgotPassword={this.forgotPassword}
                        errorLogin={this.state.errorLogin}
                        invalidLogin={this.state.invalidLogin}
                        sendVerifyEmail={this.sendVerifyEmail}
                    />
                    <RegisterModal
                        showRegister={this.state.showRegister}
                        onClose={this.closeRegister}
                        //setUsername={this.onChangeUsername} 
                        setEmail={this.onChangeEmail}
                        //setDOB={this.onChangeDOB} 
                        setLoginType={this.onSelectType}
                        doHaveAccount={this.doHaveAccount}
                        hideRegister={this.hideRegister}
                        forgotPassword={this.forgotPassword}
                    //errorRegister={this.state.errorRegister}
                    />
                    <RequestToBookModal
                        showRequestToBook={this.state.showRequestToBook}
                        onClose={this.closeLogin}
                        dontHaveAccount={this.dontHaveAccount}
                        setUsernameOrEmail={this.onChangeUsernameOrEmail}
                        setPassword={this.onChangePassword}
                        hideRequestToBook={this.hideRequestToBook}
                    />
                    <ResetPasswordModal
                        showPasswordReset={this.state.showPasswordReset}
                        //onClose={this.closeLogin}
                        dontHaveAccount={this.dontHaveAccount}
                        //setUsernameOrEmail={this.onChangeUsernameOrEmail}
                        //setPassword={this.onChangePassword}
                        hidePasswordReset={this.hidePasswordReset}
                        forgotPasswordEmail={this.forgotPasswordEmail}
                    />

                    {/*temp modal for password updates*/}
                    <Modal show={this.state.showPasswordResetRequired} className="my-modal-fail">
                        <Modal.Header closeButton onClick={() => this.hidePasswordResetRequired()}>
                            <Modal.Title>Password Reset Required</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Due to a new security feature being added we require all users to reset their passwords. <br />
                            Please click here to reset your password? <a href="#" onClick={() => this.forgotPassword()}>Reset</a>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="secondary" onClick={() => this.hidePasswordResetRequired()} style={{ width: "100%" }}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showPasswordResetSent} className="my-modal-success">
                        <Modal.Header closeButton onClick={() => this.hidePasswordSuccess()}>
                            <Modal.Title>Email Sent</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            An email has been sent with a link to reset. <br />
                            <p style={{ fontSize: "12px" }}>*If email does not arrive in 5 minutes please check junk or send again</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="secondary" onClick={() => this.hidePasswordSuccess()} style={{ width: "100%" }}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showPasswordResetFail} className="my-modal-fail">
                        <Modal.Header closeButton onClick={() => this.hidePasswordFail()}>
                            <Modal.Title>Account Not Found</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            It appears we cannot find you in our system, please <a href="#" onClick={() => this.dontHaveAccount()}>Register Here</a> for an account!
                            <p style={{ fontSize: "12px" }}>*If you already have an account please try reset again in 5 minutes or contact us</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="secondary" onClick={() => this.hidePasswordFail()} style={{ width: "100%" }}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showVerifyEmailSent} className="my-modal-success">
                        <Modal.Header closeButton onClick={() => this.hideVerifyEmailSuccess()}>
                            <Modal.Title>Email Sent</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Email sent, please verify and try login again. <br />
                            <p style={{ fontSize: "12px" }}>*If email does not arrive in 5 minutes please check junk or send again</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="secondary" onClick={() => this.hideVerifyEmailSuccess()} style={{ width: "100%" }}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showVerifyEmailFail} className="my-modal-fail">
                        <Modal.Header closeButton onClick={() => this.hideVerifyEmailFail()}>
                            <Modal.Title>Something went wrong</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            It appears we are not able to send and email, please try again in 5 minutes.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="secondary" onClick={() => this.hideVerifyEmailFail()} style={{ width: "100%" }}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showRegistrationSuccess} className="my-modal-success">
                        {/*<Modal.Header closeButton onClick={() => this.hideRegistrationSuccess()}>*/}
                        <Modal.Header>
                            <Modal.Title>Successful Registration</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            An email has been sent with a link to verify your account. <br />
                            Welcome to the Aussie Holiday Stays community.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button id="registerclose" data-email={this.state.email} data-firstname={this.state.firstname} data-lastname={this.state.lastname} data-userid={this.state.userID} type="submit" variant="secondary" onClick={() => this.hideRegistrationSuccess()} style={{ width: "100%" }}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            )
        }
    }
}

export default Base;