import React, { Component, useRef } from 'react';
import { useParams } from "react-router-dom";
import { Tabs, Tab, Button, Row, Col, Alert } from 'react-bootstrap';
//import SideNav from "./../items/side-nav";
import SideNav from "./../modals/SideNav";

import Information from '../items/addlisting/information';
import Pricing from '../items/addlisting/pricing';
import Location from '../items/addlisting/location';
import Features from './../items/addlisting/features';
import Media from './../items/addlisting/media';
import Bedrooms from './../items/addlisting/bedrooms';
import TermsAndConditions from '../items/addlisting/termsandconditions';
import Calendar from '../items/addlisting/calendar';

// services
import UserDetail from './../functions/User';
import Listing from './../functions/Listing';
import Category from './../functions/Category';
import Feature from './../functions/Feature';

import { Grid } from "react-loader-spinner";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} location={props} />
    }
}

class EditListing extends Component {
    constructor(props) {
        super(props);

        this.inforef = React.createRef();
        this.priceref = React.createRef();
        this.mediaref = React.createRef();
        this.locationref = React.createRef();
        this.tandcref = React.createRef();
        this.bedroomsref = React.createRef();

        this.state = {
            email: '', //this.props.email,
            loggedIn: "", //this.props.loggedIn,
            key: '0',
            tabCount: '8',
            loginType: "", //this.props.logginType,
            description: '',
            listingType: '',
            listingUUID: "",
            listingObject: null,

            listingOwner: null,

            // features
            categories: [],
            featuresList: [],
            selectedFeatures: [],

            // media
            primaryImage: null,
            images: [],

            // pricing
            instantBooking: false,
            nightlyRate: "Nightly",
            weekendRate: "Saturday and Sunday",

            fees: [],
            taxes: [],
            periods: [],
            rooms: [],

            blockedDates: [],

            showLoading: 'block',
            showLoadingMedia: 'none',
            showResultMessage: 'none',
            cropArea: '',

            showAuthMessage: 'none',
            resultAuthClass: 'danger',
            authMessage: "You are not authorised to edit this listing, please login and try again!",
            showListing: 'none',

            isDraft: false,

            hasPMSAttached: false,
        }
    }

    componentDidMount() {

        const { id } = this.props.match.params;
        this.setState({ listingUUID: id });

        Listing.getListingByID(id).then((result) => {
            this.setState({
                listingObject: result.data,
                listingOwner: result.data.Owner,
                isDraft: result.data.Owner.Draft,
                hasPMSAttached: result.data.Owner.RemoteToken && result.data.Owner.RemoteToken.length > 1
            });

            var ownerID = result.data.Owner.OwnerID;

            UserDetail.getUserByEmail(this.props.email).then((userResult) => {

                //need to check admin also
                if (userResult.data.UUID != ownerID && this.props.loginType != 'admin') {
                    //do not display
                    this.setState({
                        showLoading: 'none',
                        showAuthMessage: 'block',
                        resultAuthClass: 'danger',
                        authMessage: "You are not authorised to edit this listing, please login and try again!",
                        showListing: 'none'
                    });
                } else {
                    this.setState({
                        showLoading: 'none',
                        showListing: 'inline'
                    });
                    //set the information
                    //this.setInformation(result.data.Information);

                    //set feature list
                    if (result.data.Features) {
                        result.data.Features.map((feature, index) => {
                            //console.log(feature.UUID);
                            this.state.selectedFeatures.push(feature.UUID);
                        })
                    }

                    //set facility list
                    if (result.data.Facilities) {
                        result.data.Facilities.map((feature, index) => {
                            //console.log(feature.UUID);
                            this.state.selectedFeatures.push(feature.UUID);
                        })
                    }
                    //})

                    Category.getCategories().then((categories) => {
                        this.setState({ categories: categories.data });
                    });

                    Feature.getFeatures().then((features) => {
                        this.setState({ featuresList: features.data });
                    });
                }
            })
        })

    }

    setKey = (event) => {

        ////update the listing if not media (2) or calendar (7)
        //if (this.state.key != 2 && this.state.key != 7) {
        //    this.saveListing();
        //}

        //validate the current page
        //information
        //console.log('key: ' + this.state.key);
        if (this.state.key == 0) { //information
            //call a validate function before changing key
            if (this.inforef.current()) {
                this.updateListingInformation();
                this.setState({ key: event });
            }
        } else if (this.state.key == 1) { //pricing
            //if this is a PMS connected property we skip the validation
            if (this.state.hasPMSAttached) {
                this.setState({ key: event });
            }
            //call a validate function before changing key
            else if (this.priceref.current()) {
                this.updateListingPricing();
                this.setState({ key: event });
            }
        } else if (this.state.key == 2) { //media
            //call a validate function before changing key
            //check if primary is set
            if (this.state.primaryImage) {
                //console.log("update images");

                //call save function for update images only
                this.updateListingMedia();

                this.setState({ key: event });
                this.mediaref.current(true);
            } else {
                //console.log("not set");
                this.mediaref.current(false);
            }

            //if (this.mediaref.current()) {
                //this.setState({ key: event });
            //}
        } else if (this.state.key == 3) { //features
            //call a validate function before changing key
            //if (this.locationref.current()) {
            this.updateListingFeatures();
                this.setState({ key: event });
            //}
        } else if (this.state.key == 4) { //location
            //call a validate function before changing key
            if (this.locationref.current()) {
                this.updateListingLocation();
                this.setState({ key: event });
            }
        } else if (this.state.key == 5) { //rooms
            //call a validate function before changing key
            this.updateListingRooms();
            this.setState({ key: event });
        } else if (this.state.key == 6) { //terms and conditions
            //call a validate function before changing key
            if (this.tandcref.current()) {
                this.updateListingTermsAndConditions();
                this.setState({ key: event });
            }
        } else if (this.state.key == 7) { //calendar
            //call a validate function before changing key
            this.updateListingCalendar();
            this.setState({ key: event });
        } else {
            this.setState({ key: event });
        }

    }

    saveListing = () => {

        //show page loading and disable save button
        this.setState({ showLoading: 'block' });

        //check if all fields have been validated
        if (this.inforef.current() && (!this.priceref.current || this.priceref.current()) && this.locationref.current() && this.tandcref.current()) {
            //then process

            try {
                // information data
                const information = this.getInformation();

                // pricing data
                const pricing = this.getPricing();

                // image data
                let imageIndex = 0;

                this.state.images.forEach((element, index) => {
                    if (element.name === this.state.primaryImage.name) {
                        imageIndex = index;
                    }
                });

                //let tempImages = this.state.images.splice(imageIndex, 1);
                var spliceImages = this.state.images.filter(filter => filter.name !== this.state.primaryImage.name);

                this.setState({ images: spliceImages });

                // location data
                const location = this.getLocationData();

                const termsAndCondtionsValues = this.getTermsAndConditions();
                
                Listing.UpdateListing(this.state.listingOwner,
                //Listing.UpdateListing("fail",
                    information,
                    this.state.selectedFeatures,
                    //this.state.primaryImage, this.state.cropArea, spliceImages,
                    "","","",
                    pricing, this.state.fees, this.state.taxes, this.state.periods,
                    location,
                    this.state.rooms, termsAndCondtionsValues, "").then(result => {

                        //console.log("update result: " + JSON.stringify(result));

                        var css = '';
                        var message = '';

                        //if result is good
                        if (result && !result.data.includes('failed')) {
                            css = 'success';
                            message = 'Congratulations, your listing has been created, we will review and publish soon!';
                            this.setState({
                                showLoading: 'none',
                            });
                        } else {
                            css = 'danger';
                            message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                            this.setState({
                                showLoading: 'none',
                                showResultMessage: 'inline',
                                resultMessageClass: css,
                                resultMessage: message,
                                showButtons: 'none',
                                showDetail: 'none'
                            });
                        }
                        
                    });
            } catch (error) {

                this.setState({
                    showLoading: 'none',
                    showResultMessage: 'inline',
                    resultMessageClass: 'danger',
                    resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
                });

            }
        } else {
            //show error there is missing data
            this.setState({
                showLoading: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });
        }

    }

    updateListingInformation = () => {

        //show page loading and disable save button
        this.setState({
            showLoading: 'block',
            showLoadingMedia: 'block'
        });
        //check if all fields have been validated
        try {
            // information data
            const information = this.getInformation();

            Listing.UpdateListingInformation(this.state.listingOwner, information).then(result => {

                    var css = '';
                    var message = '';

                    //if result is good
                    if (result && !result.data.includes('failed')) {
                        css = 'success';
                        message = 'Congratulations, your listing has been created, we will review and publish soon!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                        });
                    } else {
                        css = 'danger';
                        message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                            showResultMessage: 'inline',
                            resultMessageClass: css,
                            resultMessage: message,
                            showButtons: 'none',
                            showDetail: 'none'
                        });
                    }

                });
        } catch (error) {

            this.setState({
                showLoading: 'none',
                showLoadingMedia: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });

        }

    }

    updateListingPricing = () => {

        //show page loading and disable save button
        this.setState({
            showLoading: 'block',
            showLoadingMedia: 'block'
        });
        //check if all fields have been validated
        try {
            // pricing data
            const pricing = this.getPricing();

            Listing.UpdateListingPricing(this.state.listingOwner,
                pricing, this.state.fees, this.state.taxes, this.state.periods).then(result => {

                    var css = '';
                    var message = '';

                    //if result is good
                    if (result && !result.data.includes('failed')) {
                        css = 'success';
                        message = 'Congratulations, your listing has been created, we will review and publish soon!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                        });
                    } else {
                        css = 'danger';
                        message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                            showResultMessage: 'inline',
                            resultMessageClass: css,
                            resultMessage: message,
                            showButtons: 'none',
                            showDetail: 'none'
                        });
                    }

                });
        } catch (error) {

            this.setState({
                showLoading: 'none',
                showLoadingMedia: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });

        }

    }

    updateListingMedia = () => {

        //show page loading and disable save button
        this.setState({
            showLoading: 'block',
            showLoadingMedia: 'block'
        });
        //check if all fields have been validated
        try {
            //// image data
            //this.state.images.forEach((element, index) => {
            //    if (element.name === this.state.primaryImage.name) {
            //        imageIndex = index;
            //    }
            //});

            var spliceImages = this.state.images.filter(filter => filter.name !== this.state.primaryImage.name);

            this.setState({ images: spliceImages });

            Listing.UpdateListingMedia(this.state.listingOwner,
                this.state.primaryImage, this.state.cropArea, spliceImages).then(result => {

                    var css = '';
                    var message = '';

                    //if result is good
                    if (result && !result.data.includes('failed')) {
                        css = 'success';
                        message = 'Congratulations, your listing has been created, we will review and publish soon!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                        });
                    } else {
                        css = 'danger';
                        message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                            showResultMessage: 'inline',
                            resultMessageClass: css,
                            resultMessage: message,
                            showButtons: 'none',
                            showDetail: 'none'
                        });
                    }

                });
        } catch (error) {

            this.setState({
                showLoading: 'none',
                showLoadingMedia: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });

        }

    }

    updateListingFeatures = () => {

        //show page loading and disable save button
        this.setState({
            showLoading: 'block',
            showLoadingMedia: 'block'
        });
        //check if all fields have been validated
        try {

            Listing.UpdateListingFeatures(this.state.listingOwner, this.state.selectedFeatures).then(result => {

                    var css = '';
                    var message = '';

                    //if result is good
                    if (result && !result.data.includes('failed')) {
                        css = 'success';
                        message = 'Congratulations, your listing has been created, we will review and publish soon!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                        });
                    } else {
                        css = 'danger';
                        message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                            showResultMessage: 'inline',
                            resultMessageClass: css,
                            resultMessage: message,
                            showButtons: 'none',
                            showDetail: 'none'
                        });
                    }

                });
        } catch (error) {

            this.setState({
                showLoading: 'none',
                showLoadingMedia: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });

        }

    }

    updateListingLocation = () => {

        //show page loading and disable save button
        this.setState({
            showLoading: 'block',
            showLoadingMedia: 'block'
        });
        //check if all fields have been validated
        try {

            // location data
            const location = this.getLocationData();

            //before calling update check if exists and call create if missing
            if (!this.state.listingObject.Location.UUID) {
                Listing.CreateListingLocation(this.state.listingOwner, location).then(result => {
                    var css = '';
                    var message = '';

                    //if result is good
                    if (result && result.data && !result.data.includes('failed')) {
                        css = 'success';
                        message = 'Congratulations, your listing has been created, we will review and publish soon!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                        });
                    } else {
                        css = 'danger';
                        message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                            showResultMessage: 'inline',
                            resultMessageClass: css,
                            resultMessage: message,
                            showButtons: 'none',
                            showDetail: 'none'
                        });
                    }
                });

            } else {

                Listing.UpdateListingLocation(this.state.listingOwner, location).then(result => {

                    var css = '';
                    var message = '';

                    //if result is good
                    if (result && result.data && !result.data.includes('failed')) {
                        css = 'success';
                        message = 'Congratulations, your listing has been created, we will review and publish soon!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                        });
                    } else {
                        css = 'danger';
                        message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                            showResultMessage: 'inline',
                            resultMessageClass: css,
                            resultMessage: message,
                            showButtons: 'none',
                            showDetail: 'none'
                        });
                    }

                });

            }
        } catch (error) {

            this.setState({
                showLoading: 'none',
                showLoadingMedia: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });

        }

    }

    updateListingRooms = () => {

        //show page loading and disable save button
        this.setState({
            showLoading: 'block',
            showLoadingMedia: 'block'
        });
        //check if all fields have been validated
        try {

            Listing.UpdateListingRooms(this.state.listingOwner, this.state.rooms).then(result => {

                    var css = '';
                    var message = '';

                    //if result is good
                    if (result && !result.data.includes('failed')) {
                        css = 'success';
                        message = 'Congratulations, your listing has been created, we will review and publish soon!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                        });
                    } else {
                        css = 'danger';
                        message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                            showResultMessage: 'inline',
                            resultMessageClass: css,
                            resultMessage: message,
                            showButtons: 'none',
                            showDetail: 'none'
                        });
                    }

                });
        } catch (error) {

            this.setState({
                showLoading: 'none',
                showLoadingMedia: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });

        }

    }

    updateListingTermsAndConditions = () => {

        //show page loading and disable save button
        this.setState({
            showLoading: 'block',
            showLoadingMedia: 'block'
        });
        //check if all fields have been validated
        try {

            const termsAndCondtionsValues = this.getTermsAndConditions();

            Listing.UpdateListingTermsAndConditions(this.state.listingOwner, termsAndCondtionsValues).then(result => {

                    var css = '';
                    var message = '';

                    //if result is good
                    if (result && !result.data.includes('failed')) {
                        css = 'success';
                        message = 'Congratulations, your listing has been created, we will review and publish soon!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                        });

                        //added after system built to accomodate deposit in T&C due to pricing being removed
                        let depositpercentage = document.getElementById('tacDeposit').value;
                        if (document.getElementById('tacDeposit').value == "") {
                            depositpercentage = "20";
                        }

                        Listing.UpdateColumnEntryValue(this.state.listingUUID, "deposit_percentage", depositpercentage).then(result => {
                            if (result && result.data && result.data.includes('listing value updated')) {
                                css = 'success';
                                message = 'Congratulations, your listing has been created, we will review and publish soon!';
                                this.setState({
                                    showLoading: 'none',
                                    showLoadingMedia: 'none',
                                });

                                //added after system built to accomodate security deposit (BOND) in T&C due to pricing being removed
                                let securitydepositpercentage = document.getElementById('tacSecurityDeposit').value;

                                Listing.UpdateColumnEntryValue(this.state.listingUUID, "security_deposit", securitydepositpercentage).then(result => {
                                    if (result && result.data && result.data.includes('listing value updated')) {
                                        css = 'success';
                                        message = 'Congratulations, your listing has been created, we will review and publish soon!';
                                        this.setState({
                                            showLoading: 'none',
                                            showLoadingMedia: 'none',
                                        });
                                    } else {
                                        css = 'danger';
                                        message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                                        this.setState({
                                            showLoading: 'none',
                                            showLoadingMedia: 'none',
                                            showResultMessage: 'inline',
                                            resultMessageClass: css,
                                            resultMessage: message,
                                            showButtons: 'none',
                                            showDetail: 'none'
                                        });
                                    }
                                })

                            } else {
                                css = 'danger';
                                message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                                this.setState({
                                    showLoading: 'none',
                                    showLoadingMedia: 'none',
                                    showResultMessage: 'inline',
                                    resultMessageClass: css,
                                    resultMessage: message,
                                    showButtons: 'none',
                                    showDetail: 'none'
                                });
                            }
                        })

                        

                    } else {
                        css = 'danger';
                        message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                        this.setState({
                            showLoading: 'none',
                            showLoadingMedia: 'none',
                            showResultMessage: 'inline',
                            resultMessageClass: css,
                            resultMessage: message,
                            showButtons: 'none',
                            showDetail: 'none'
                        });
                    }

            });

        } catch (error) {

            this.setState({
                showLoading: 'none',
                showLoadingMedia: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });

        }

    }

    updateListingCalendar = () => {

        //show page loading and disable save button
        this.setState({ showLoading: 'block' });
        //check if all fields have been validated

        if (this.inforef.current() && (!this.priceref.current || this.priceref.current()) && this.locationref.current() && this.tandcref.current()) {
            try {

                Listing.UpdateListingCalendar(this.state.listingOwner, this.state.blockedDates).then(result => {

                    var css = '';
                    var message = '';

                    //if result is good
                    if (result && !result.data.includes('failed')) {

                        Listing.UpdateListingState(this.state.listingOwner.UUID, "draft", false).then((resultStatus) => {

                            if (resultStatus) {

                                css = 'success';
                                //chenge the message pending if draft or not
                                message = 'Congratulations, your listing has been updated!';

                                if (this.state.isDraft) {
                                    message = 'Congratulations, your listing has been created, we will review and publish soon!';
                                }

                                this.setState({
                                    showLoading: 'none',
                                    showResultMessage: 'inline',
                                    resultMessageClass: css,
                                    resultMessage: message,
                                    isDraft: false
                                });
                            } else {
                                css = 'danger';
                                message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                                this.setState({
                                    showLoading: 'none',
                                    showResultMessage: 'inline',
                                    resultMessageClass: css,
                                    resultMessage: message,
                                    showButtons: 'none',
                                    showDetail: 'none'
                                });
                            }
                        });
                    } else {
                        css = 'danger';
                        message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                        this.setState({
                            showLoading: 'none',
                            showResultMessage: 'inline',
                            resultMessageClass: css,
                            resultMessage: message,
                            showButtons: 'none',
                            showDetail: 'none'
                        });
                    }

                });
            } catch (error) {

                this.setState({
                    showLoading: 'none',
                    showResultMessage: 'inline',
                    resultMessageClass: 'danger',
                    resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
                });

            }
        } else {
            //show error there is missing data
            this.setState({
                showLoading: 'none',
                showResultMessage: 'inline',
                resultMessageClass: 'danger',
                resultMessage: 'Unfortunately there has been an error, please go back and check for missing data and try save again!'
            });
        }
    }

    getInformation = () => {
        let entireplace = document.getElementById('private-room').value;
        if (document.getElementById('entire-place').checked) {
            entireplace = document.getElementById('entire-place').value;
        }
        const information = {
            Kind: entireplace,
            Title: document.getElementById('informationtitle').value,
            Description: this.state.description,
            TypeOfListing: this.state.listingType,
            NumberOfBedrooms: document.getElementById('informationnumberofbedrooms').value,
            NumberOfBeds: document.getElementById('informationnumberofbeds').value,
            NumberOfBathrooms: document.getElementById('informationnumberofbathroosm').value
        }

        return information;
    }

    setInformation = (information) => {
        //let entireplace = document.getElementById('private-room').value;
        //if (document.getElementById('entire-place').checked) {
        //    entireplace = document.getElementById('entire-place').value;
        //}
        //const information = {
        //    Kind: entireplace,
        //document.getElementById('informationtitle').value = information.Title;
        //    Description: this.state.description,
        //    TypeOfListing: this.state.listingType,
        //    NumberOfBedrooms: document.getElementById('informationnumberofbedrooms').value,
        //    NumberOfBeds: document.getElementById('informationnumberofbeds').value,
        //    NumberOfBathrooms: document.getElementById('informationnumberofbathroosm').value
        //}

        return information;
    }

    getPricing = () => {

        let instantBooking = false; //document.getElementById('instantbookNo').value;
        if (document.getElementById('instantbookYes').checked) {
            instantBooking = true; // document.getElementById('instantbookYes').value;
        }

        let allowAdditionalGuests = document.getElementById('allowAdditionalGuestsFalse').value;
        if (document.getElementById('allowAdditionalGuestsTrue').checked) {
            allowAdditionalGuests = document.getElementById('allowAdditionalGuestsTrue').value;
        }

        let chargeGST = false;// document.getElementById('chargeGSTFalse').value;
        if (document.getElementById('chargeGSTTrue').checked) {
            chargeGST = true; // document.getElementById('chargeGSTTrue').value;
        }

        //let cleaningRate = document.getElementById('cleaningRateDaily').value;
        //if ( document.getElementById('cleaningRateDaily').checked) {
        //    cleaningRate = document.getElementById('cleaningRatePerStay').value;
        //}
        let depositpercentage = document.getElementById('tacDeposit').value;
        if (document.getElementById('tacDeposit').value == "") {
            depositpercentage = "20";
        }

        let securitydepositpercentage = document.getElementById('tacSecurityDeposit').value;

        const pricing = {
            InstantBooking: instantBooking,//this.state.instantBooking,
            Nightly: document.getElementById('pricingOneNightNightly').value,
            NightRate: this.state.nightlyRate,
            Weekend: document.getElementById('pricingOneNightWeekend').value,
            WeekendRate: this.state.weekendRate,
            WeeklyRate: document.getElementById('pricingOneNightSevenPlus').value,
            MonthlyRate: document.getElementById('pricingOneNightThirtyPlus').value,
            AllowAdditionalGuests: allowAdditionalGuests,
            AdditionalGuestCost: document.getElementById('pricingPneAdditionalGuest').value,
            StandardNumberOfGuests: document.getElementById('pricingStandardNumberOfGuests').value,
            MaximumNumberOfGuests: document.getElementById('pricingMaximumNumberOfGuests').value,
            //CleaningFee: document.getElementById('pricingCleaningFee').value,
            //CleaningRate: cleaningRate,
            SecurityDeposit: securitydepositpercentage,
            DepositPercentage: depositpercentage,
            GSTFlag: chargeGST
        };

        return pricing;
    }

    addImage = (image) => {        
        this.state.images.push(image);

        //this.setState({ images: [...this.state.images, image] });
    }

    updateImageArray = (arr) => {
        var tempArr = [];

        arr.map((image, index) => {
            tempArr.push(image.data);
        })

        this.setState({ images: tempArr });
    }

    addInitialImages = (array) => {
        //console.log("image array: " + array);

        array.forEach((element, index) => {
            //console.log("array: " + JSON.stringify(element.name));
            //this.state.images.push(element);
            this.setState({ images: [...this.state.images, element] });
        });

        //this.setState({ images: array });
    }

    deleteImage = (image) => {
        //this.state.images.push(image);

        //console.log("delete: " + JSON.stringify(image.data.name));

        //console.log("count: " + this.state.images.length);

        //this.state.images.map((img, index) => {
        //    console.log("img: " + JSON.stringify(img));
        //})

        var tempImages = this.state.images.filter(filter => filter.name !== image.data.name);

        //console.log("temp images: " + tempImages);

        //this.state.images.map((element, index) => {
        //    console.log("img: " + JSON.stringify(element.name));
        //});

        this.setState({
            images: tempImages //this.state.images.filter(filter => filter.name !== image.data.name)
        })

        //setListings(listings.filter(listing => listing.ListingID !== id));
    }

    onSelectImage = (image) => {

        this.setState({ primaryImage: image.data });
        //console.log('primary set: ' + image.data);
    }

    onCropImage = (value) => {
        //console.log('crop area here: ' + value);

        this.setState({ cropArea: value });
    }

    selectFeature = (event) => {
        if (event.target.checked) {
            this.state.selectedFeatures.push(event.target.id);
        } else {
            this.setState({
                selectedFeatures: this.state.selectedFeatures.filter(filter => filter !== event.target.id)
            })
        }
    }

    getLocationData = () => {
        const geoLocation = document.getElementById('geoLocation').value;
        const attempt = 0;

        while ( geoLocation === null && attempt < 5) {
            geoLocation = document.getElementById('geoLocation').value;
            attempt ++;
        }

        const location = {
            Address: document.getElementById('listingAddress').value,
            Apartment: document.getElementById('listingUnit').value,
            City: document.getElementById('listingCity').value,
            State: document.getElementById('listingState').value,
            PostCode: document.getElementById('listingPostCode').value,
            Area: document.getElementById('listingArea').value,
            Country: document.getElementById('listingCountry').value,
            Location: geoLocation,
            PlaceID: document.getElementById('placeID').value
        }

        return location;
        
    }

    getTermsAndConditions = () => {
        let smoking = '';
        let pets = '';
        let party = '';
        let children = '';
        if (document.getElementById('smokingyes').checked) {
            smoking = "Yes";
        }
        else {
            smoking = "No";
        }
        if (document.getElementById('petsyes').checked) {
            pets = "Yes";
        } else {
            pets = "No";
        }
        if (document.getElementById('partyyes').checked) {
            party = "Yes";
        } else {
            party = "No";
        }
        if (document.getElementById('childrenyes').checked) {
            children = "Yes";
        } else {
            children = "No";
        }
        const termsAndCondtionsValues = {
            Cancellation: document.getElementById('mincancellation').value,
            MinimumNotice: document.getElementById('minimumNotice').value,
            MaxBookingWindows: document.getElementById('maximumWindow').value,
            MinimumBooking: document.getElementById('minbooking').value,
            MaximumBooking: document.getElementById('maxbooking').value,
            CheckInAfter: document.getElementById('checkInAfter').value,
            CheckOutBefore: document.getElementById('checkOutBefore').value,
            Smoking: smoking,
            Pets: pets,
            Party: party,
            Children: children,
            RegistrationNumber: document.getElementById('registrationNumber').value,
            Optional: document.getElementById('optional').value
        }

        return termsAndCondtionsValues;
    }

    setListingType = (listingType) => {
        this.setState({listingType: listingType});
    }

    setInstantBooking = (instantBooking) => {
        this.setState({instantBooking: instantBooking});
    }

    setNightlyRate = (nightlyRate) => {
        this.setState({nightlyRate: nightlyRate});
    }

    setWeekendRate = (weekendRate) => {
        this.setState({weekendRate: weekendRate});
    }

    setAdditionalFees = (fees) => {
        this.setState({fees: fees});
    }

    setAdditionalTaxes = (taxes) => {
        this.setState({taxes: taxes});
    }

    setAdditionalPeriods = (periods) => {
        //console.log("period update: " + JSON.stringify(periods));
        this.setState({periods: periods});
    }

    changeDescription = (event) => {
        this.setState({description: event});
    }

    setRooms = (rooms) => {
        this.setState({ rooms: rooms })
        //this.bedroomsref.current.forceUpdate();
    }

    setBlockedDates = (dates) => {
        //console.log("blocked: " + JSON.stringify(dates));
        this.setState({ blockedDates: dates });
        //console.log(this.state.blockedDates);
    }

    setImportiCalDates = (listingid, name, url) => {
        Listing.createICalEntry(listingid, name, url);
    }

    render() {
        const { id } = this.props.match.params;

        if (!this.state.listingObject) {
            return <div />
        }

        return (
            <div style={{ paddingTop: "80px" }}>
                <div class="loading-overlay" style={{ display: this.state.showLoading }}>
                    <div class="loading-vertical">
                        <Grid
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={0}
                        />
                        
                    </div>
                </div>

                {/*<div style={{ display: this.state.showLoadingMedia, color: "black !important" }}>Uploading Images</div>*/}

                <div style={{ paddingTop: "180px", display: this.state.showAuthMessage, width: '100%', textAlign: "center" }}>
                    <Alert variant={this.state.resultAuthClass} >
                        {this.state.authMessage}
                    </Alert>
                </div>
                <div style={{ display: this.state.showListing }}>
                <Row >
                    <Col className="d-none d-md-block" md="3" lg="2" xl="1">
                        <SideNav
                            loggedIn={this.props.loggedIn}
                            loginType={this.props.loginType}
                            email={this.props.email}
                            activeTab='listings'
                        />
                    </Col>
                    <Col md="9" lg="10" xl="11" style={{ display: this.state.showDetail }}>
                            <div style={{ width: '100%' }}>
                                <h1>Edit Listing - <span style={{ color: '#e96852'}}>{this.state.listingObject.Information.Title}</span></h1>
                            <hr />
                            </div>
                            <Tabs
                                id="edit-tabs"
                                activeKey={this.state.key}
                                onSelect={(k) => this.setKey(k)}
                                style={{ background: "#f7f8f9" }}
                            >
                                <Tab eventKey="0" title="Info">
                                    <br />
                                <Information
                                    setListingType={this.setListingType}
                                    changeDescription={this.changeDescription}
                                    //isValid={this.informationValid}
                                    isValid={this.inforef}
                                    listingData={this.state.listingObject.Information}
                                    />
                                    
                                </Tab>
                                <Tab eventKey="1" title="Pricing">
                                    <br />
                                    {this.state.hasPMSAttached ?
                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                            <h4 style={{ color: '#e96852' }}>Your listing's pricing is controlled by your Property Management System, please log into your PMS and update from there.</h4>
                                        </div>
                                        :
                                        <Pricing
                                            setInstantBooking={this.setInstantBooking}
                                            setNightlyRate={this.setNightlyRate}
                                            setWeekendRate={this.setWeekendRate}
                                            setFees={this.setAdditionalFees}
                                            setTaxes={this.setAdditionalTaxes}
                                            setPeriods={this.setAdditionalPeriods}
                                            isValid={this.priceref}
                                            listingData={this.state.listingObject.Pricing}
                                            listingUUID={id}
                                        />
                                    }
                                </Tab>
                                <Tab eventKey="2" title="Media" >
                                    <Media 
                                    images={this.state.images}
                                    addImage={this.addImage}
                                    addInitialImages={this.addInitialImages}
                                    onSelectImage={this.onSelectImage}
                                    deleteImage={this.deleteImage}
                                    onCropImage={this.onCropImage}
                                    isValid={this.mediaref}
                                    listingUUID={id}
                                    cropArea={this.state.listingObject.CropArea}
                                    updateImageArray={this.updateImageArray}
                                    />
                                </Tab>
                                <Tab eventKey="3" title="Features" >
                                    <Features
                                    categories={this.state.categories}
                                    featuresList={this.state.featuresList}
                                    selectFeature={this.selectFeature}
                                    //selected={this.state.selectedFeatures}
                                    listingDataFeatures={this.state.listingObject.Features}
                                    listingDataFacilities={this.state.listingObject.Facilities}
                                    />
                                </Tab>
                                <Tab eventKey="4" title="Location" >
                                    <br />
                                <Location
                                    isValid={this.locationref}
                                    listingData={this.state.listingObject.Location}
                                />
                                </Tab>
                                <Tab eventKey="5" title="Rooms" >
                                <Bedrooms
                                    ref={this.bedroomsref}
                                    setRoomsState={this.setRooms}
                                    listingData={this.state.listingObject.Rooms}
                                    />
                                </Tab>
                            <Tab eventKey="6" title="T&Cs" >
                                <TermsAndConditions
                                        isValid={this.tandcref}
                                        listingData={this.state.listingObject.TermsAndConditions}
                                        depositPercentage={this.state.listingObject.Pricing.DepositPercentage}
                                        securityDepositPercentage={this.state.listingObject.Pricing.SecurityDeposit}
                                />
                                </Tab>
                                <Tab eventKey="7" title="Calendar" >
                                    <Calendar
                                    setBlockedDates={this.setBlockedDates}
                                    setImportiCalDates={this.setImportiCalDates}
                                    listingData={this.state.listingObject.Calendar}
                                    listingID={this.state.listingObject.Owner.UUID}
                                    />
                                </Tab>
                            </Tabs>
                            
                        {/*</div>*/}
                    </Col>
                </Row>

                <Row style={{ display: this.state.showResultMessage }}>
                    <Col md={{ span: 9, offset: 3 }} lg={{ span: 10, offset: 2 }} xl={{ span: 11, offset: 1 }}>
                        <Alert variant={this.state.resultMessageClass} >
                            {this.state.resultMessage}
                        </Alert>
                    </Col>
                </Row>

                <Row style={{ display: this.state.showButtons }}>
                    <Col className="d-none d-md-block" md="3" lg="2" xl="1">
                    </Col>
                    <Col md="9" lg="10" xl="11">
                    {(this.state.key > 0) &&
                                <Button style={{width: "50%"}} className="col-sm-6" onClick={() => this.setKey((parseInt(this.state.key) + 8 - 1) % 8)} variant="secondary">Back</Button>
                    }
                    {(this.state.key == 0) &&
                        <div className="col-sm-6"></div>
                    }
                        {(this.state.key != this.state.tabCount - 1 && this.state.key != 0) &&
                                <Button style={{ width: "50%" }} className="col-sm-6" onClick={() => this.setKey((parseInt(this.state.key) + 1) % 8)} variant="primary">Save & Next</Button>
                        }
                        {(this.state.key != this.state.tabCount - 1 && this.state.key == 0) &&
                                <Button style={{ width: "50%" }} className="col-sm-6 offset-sm-6" onClick={() => this.setKey((parseInt(this.state.key) + 1) % 8)} variant="primary">Save & Next</Button>
                        }

                    {(this.state.key == (this.state.tabCount - 1)) &&
                            /*<Button className="col-md-6" variant="success" onClick={this.saveListing}>Save</Button>*/
                                //check if draft to change button text
                                <Button style={{ width: "50%" }} className="col-sm-6" variant="success" onClick={this.updateListingCalendar}>{this.state.isDraft ? "Submit for Review" : "Save"}</Button>
                        }
                        </Col>
                    </Row>
                </div>
                <Row style={{ display: this.state.showButtons, height: "80px" }}>
                </Row>
            </div>
        )
    }
}

export default withRouter(EditListing)