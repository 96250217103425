import React from "react";

import "./../styles/testimonial.css";

function Testimonial(props) {
    return (
        <div className="testimonial-item">
            <div className="comment">
                {props.testimonial}
            </div>
            <div className="name">
                {props.name}
                <img width="50" height="50" style={{ borderRadius: "50%", marginTop: "-15px" }} src={props.src} alt="Testimonial Headshot" />
            </div>
        </div>
    );
}

export default Testimonial