import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from 'react-bootstrap';

import { FaChevronDown, FaChevronUp, FaTrashAlt, FaArrowRight } from 'react-icons/fa';

import StarRating from './../items/starRating';
import { Grid } from "react-loader-spinner";

import ReviewsDetail from './../functions/Reviews';

const Collapsible = (props) => {
    const [open, setOpen] = useState(false);
    const [showLoading, setShowLoading] = useState('none');
    const [showLoadingNumber, setShowLoadingNumber] = useState(true);
    const [reviews, setReviews] = useState(null);
    const [noReviews, setNoReviews] = useState(0);
    const [reviewsLoaded, setReviewsLoaded] = useState(false);
    const [reviewsReLoaded, setReviewsReLoaded] = useState(true);

    const getStatus = (owner) => {
        if (owner.Hidden == true) {
            return <span className='label label-danger'>Hidden</span>;
        }
        if (owner.Draft == true) {
            return <span className='label label-info'>Draft</span>;
        }
        if (owner.Published == true) {
            return <span className='label label-primary'>Published</span>;
        } else {
            return <span className='label label-secondary'>Pending</span>;
        }
    }

    const formatDate = (input) => {
        var date = new Date(input);
        return (('0' + (date.getDate())).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()).toString();
    }

    const toggle = () => {
        setOpen(!open);
    };

    const updateStatusReview = (title, id) => {
        ////show modal
        //setShowUpdateListingStatus(true);
        //setListingToUpdateStatusTitle(listingTitle);
        //let id = listings[index].ListingID;
        //setListingToUpdateStatus(id);
        setReviewsReLoaded(false);
        props.publishReview(title, id);
    }

    const deleteReview = (title, id) => {
        ////show modal
        //setShowDeleteListing(true);
        //setListingToDeleteTitle(listingTitle);
        //let id = listings[index].ListingID;
        //setListingToDelete(id);
        setReviewsReLoaded(false);
        props.deleteReview(title, id);

    }

    useEffect(() => {

        if (props.reloadReviews && !reviewsReLoaded) {
            //console.log('true' + props.listing.ListingID);
            ReviewsDetail.getGuestReviews("ListingID", props.listing.ListingID).then(result => {
                //console.log(result);
                if (result && result.data && result.data.length > 0 && typeof (result.data) != 'string') {
                    const sortReviews = result.data.sort((a, b) => (a.CreatedDate < b.CreatedDate) ? 1 : -1);
                    setReviews(sortReviews);
                    setNoReviews(result.data.length);
                    setShowLoadingNumber(false);
                } else {
                    //console.log('none');
                    setNoReviews("None");
                    setShowLoadingNumber(false);
                }
            })
            setReviewsReLoaded(true);
        }

        if (props.listing && !reviewsLoaded) {
            //console.log(props.listing);
            ReviewsDetail.getGuestReviews("ListingID", props.listing.ListingID).then(result => {
                //console.log(result);
                if (result && result.data && result.data.length > 0 && typeof (result.data) != 'string') {
                    const sortReviews = result.data.sort((a, b) => (a.CreatedDate < b.CreatedDate) ? 1 : -1);
                    setReviews(sortReviews);
                    setNoReviews(result.data.length);
                    setShowLoadingNumber(false);
                } else {
                    //console.log('none');
                    setNoReviews("None");
                    setShowLoadingNumber(false);
                }
            })
            setReviewsLoaded(true);
        }
    })

    return (
        <div>
            <a onClick={toggle}>
                <div style={{ width: '100%', backgroundColor: open ? "#f4f6f9" : "white" }}>
                    <Row>
                        <Col md="1" style={{textAlign: "center"}}>
                            {open ? <FaChevronUp /> : <FaChevronDown />} <br />
                            {open ? "click to close" : "click to open"}
                            
                        </Col>
                        <Col md="1">
                            {showLoadingNumber ?
                                    <div>
                                    <Grid
                                            color="#00BFFF"
                                            height={40}
                                            width={40}
                                            timeout={0}
                                        />
                                    </div>
                                :
                                noReviews
                            }
                        </Col>
                        <Col md="1">
                            <img className="search-img" height="100px" src={"data:image/png;base64," + props.listing.PrimaryImage} width="100%" style={{ objectFit: "cover" }}></img>
                        </Col>
                        <Col md="2">
                            {props.listing.Title}
                        </Col>
                        <Col md="4">
                            {props.listing.Address}
                        </Col>
                        <Col md="1">
                            {props.listing.TypeOfListing}
                        </Col>
                        <Col md="1">
                            {getStatus(props.listing.Owner)}
                        </Col>
                        <Col md="1" style={{ textAlign: "center" }}>
                            {open ? <FaChevronUp /> : <FaChevronDown />} <br />
                            {open ? "click to close" : "click to open"}
                        </Col>
                    </Row>
                </div>
            </a>
            {open &&
                <div>
                    <Row>
                        <Col xl="1" lg="2" md="2" sm="2" className="d-none d-sm-block"><h5>Created</h5></Col>
                        <Col xl="2" lg="3" md="3" sm="3" className="d-none d-sm-block"><h5>Rating</h5></Col>
                        <Col xl="2" lg="2" md="2" sm="2" className="d-none d-sm-block"><h5>Title</h5></Col>
                    <Col xl="5" lg="3" md="2" sm="2" className="d-none d-sm-block"><h5>Review</h5></Col>
                    <Col xl="1" lg="1" md="1" sm="1" className="d-none d-sm-block"><h5>Published</h5></Col>
                        <Col xl="1" lg="1" md="2" sm="2" className="d-none d-sm-block"><h5>Action</h5></Col>
                    </Row>
                    <div class="loading-overlay" style={{ display: showLoading }}>
                        <div class="loading-vertical">
                        <Grid
                                color="#00BFFF"
                                height={100}
                                width={100}
                                timeout={0}
                            />
                        </div>
                    </div>
                    {
                    reviews ?
                        reviews.map((review, index) =>
                                <Row md="12" style={{ height: '50px', borderBottom: '1px solid #808080', paddingTop: '6px' }} >
                                    <Col xl="1" lg="2" md="2" sm="2" xs="2">
                                        {formatDate(review.CreatedDate)}
                                    </Col>
                                    <Col xl="2" lg="3" md="3" sm="2" xs="2" style={{ textOverflow: 'ellipsis', maxWidth: '95%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        <StarRating
                                            rating={review.Rating}
                                        />
                                    </Col>
                                    <Col xl="2" lg="2" md="2" sm="2" xs="2" style={{ textOverflow: 'ellipsis', maxWidth: '95%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        {review.Title}
                                    </Col>
                                    <Col xl="5" lg="3" md="2" sm="2" xs="2" style={{ textOverflow: 'ellipsis', maxWidth: '95%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        {review.Review}
                                </Col>
                                <Col xl="1" lg="1" md="1" sm="1" xs="1" style={{ textOverflow: 'ellipsis', maxWidth: '95%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                    {review.Published ? "Yes" : "No"}
                                </Col>
                                    <Col xl="1" lg="1" md="2" sm="2" xs="2">
                                        <Link to={{
                                            pathname: "/review/" + review.ReviewID + "/0/0/",
                                        }}><Button>View</Button>
                                    </Link>
                                    {props.loginType === 'admin' && !review.Published ? <Button variant="link" title="Delete Review" className="icon-button" onClick={() => deleteReview(review.Title, review.ReviewID)} ><FaTrashAlt id="delete" size={28} color="lightblue" /></Button> : null}
                                    {props.loginType === 'admin' && !review.Published ? <Button variant="link" title="Publish Review" className="icon-button" onClick={() => updateStatusReview(review.Title, review.ReviewID)} ><FaArrowRight size={28} color="lightblue" /></Button> : null}
                                    </Col>
                                </Row>
                            ) :
                            <Row>
                                No Reviews Found!
                            </Row>
                    }
                </div>
            }
            <hr />



        </div>
    )
}

export default Collapsible;