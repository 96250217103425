import React from 'react';
//import { Container } from 'react-bootstrap';

import ErrorBoundary from "./components/errorBoundary";

// pages
import Base from "./components/base";
function App() {
    return (
        <ErrorBoundary>
            <Base />
        </ErrorBoundary>
    )
}
export default App
