import React, { useState } from "react";
import { useParams } from 'react-router-dom';

import { Button, Row, Col } from 'react-bootstrap';
import { Input } from '@mobiscroll/react';

// services
import UserDetail from "./../functions/User";

function PasswordReset() {

    //get the id
    const { id } = useParams();

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState({});

    const [showPassword, setShowPassword] = useState('block');
    const [showPasswordCreated, setShowPasswordCreated] = useState('none');
    const [showPasswordFailed, setShowPasswordFailed] = useState('none');

    const updatePassword = () => {

        if (getvalid()) {

            //console.log("ID: " + id);
            //console.log("Password: " + input["password"]);

            UserDetail.updatePassword(input["password"], id).then(result => {
                //console.log(JSON.stringify(result));

                if (result && result.data) {
                    //password updated now verify the account
                    setShowPassword('none');
                    setShowPasswordCreated('block');
                } else {
                    //error creating password
                    setShowPassword('none');
                    setShowPasswordFailed('block');
                }
            })
        }
    }

    const onTextChange = (event) => {
        let newinput = input;

        if (event.target.name == "password") {
            newinput[event.target.name] = event.target.value;
        }

        if (event.target.name == "passwordconfirm") {
            newinput[event.target.name] = event.target.value;
        }

        setInput(newinput)

        if (event.target.value) {
            //remoev the errors fom array state
            let newerrors = {};
            let newshowErrors = {};

            Object.entries(errors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(showErrors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newshowErrors[error[0]] = error[1];
                }
            })

            setErrors(newerrors);
            setShowErrors(newshowErrors);

        }
    }

    const getvalid = () => {
        let newerrors = {};
        let newshowErrors = {};
        let isValid = true;

        if (!input["password"]) {
            isValid = false;
            newerrors["password"] = "Please enter your password.";
            newshowErrors["password"] = true;
        }

        if (!input["passwordconfirm"]) {
            isValid = false;
            newerrors["passwordconfirm"] = "Please enter your confirm password.";
            newshowErrors["passwordconfirm"] = true;
        }

        if (typeof input["password"] !== "undefined" && typeof input["passwordconfirm"] !== "undefined") {

            if (input["password"] != input["passwordconfirm"]) {
                isValid = false;
                newerrors["password"] = "Passwords don't match.";
                newshowErrors["password"] = true;
            }
        }

        setErrors(newerrors);
        setShowErrors(newshowErrors);

        return isValid;
    }

    document.body.style.height = "100vh";
    const nav = document.getElementsByClassName("navbar");
    if (nav[0]) {
        nav[0].style.visibility = "hidden";
    }

    return (
        <div style={{ paddingTop: "30vh", width: "100%", textAlign: "center" }}>
            <div>
                <Row>
                    <Col style={{ display: showPasswordCreated }} sm="12" md={{ span: 4, offset: 4 }} >
                        Your account password has been updated
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: showPasswordFailed }} sm="12" md={{ span: 4, offset: 4 }} >
                        Account password update failed, please try again or contact us for help
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: showPassword }} sm="12" md={{ span: 4, offset: 4 }} >
                        <Input inputStyle="box" labelStyle="floating" name="password" type="password" label=" New Password" passwordToggle="true" iconShow="eye" iconHide="eye-blocked" autoComplete="new-password" errorMessage={errors.password} error={showErrors.password} onChange={onTextChange} />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: showPassword }} sm="12" md={{ span: 4, offset: 4 }}>
                        <Input inputStyle="box" labelStyle="floating" name="passwordconfirm" type="password" label="Confirm Password" passwordToggle="true" iconShow="eye" iconHide="eye-blocked" errorMessage={errors.passwordconfirm} error={showErrors.passwordconfirm} onChange={onTextChange} />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: showPassword }} sm="12" md={{ span: 4, offset: 4 }}>
                        <Button type="submit" variant="secondary" onClick={updatePassword} style={{ width: "100%" }}>Update Password</Button>
                    </Col>
                </Row>
            </div>
        </div>
        )
}

export default PasswordReset