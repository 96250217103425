import React, { Component } from 'react';
import { Link, useParams, useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";
//import createHistory from 'history/createBrowserHistory'
//import { createBrowserHistory } from 'history'
import axios from 'axios'

import { Row, Col, Card, Button, Accordion, Alert, Modal, FormControl } from 'react-bootstrap';
import { Input } from '@mobiscroll/react';
import SideNav from "./../../modals/SideNav";

import StarRating from './../../items/starRating';

import { FaInfoCircle } from 'react-icons/fa';
import { FaRegEnvelopeOpen, FaPeopleArrows } from 'react-icons/fa';
import { MdOutlineRateReview } from 'react-icons/md';

import "./../../styles/site.css";

import UserDetail from './../../functions/User';
import PricingDetail from './../../functions/Pricing';
import ReservationDetail from './../../functions/Reservation';
import ReviewsDetail from './../../functions/Reviews';
//import ListingDetail from './../../functions/Listing';

import CardModal from './../../modals/CardEntry';

import { Grid } from "react-loader-spinner";

//const createBrowserHistory = useNavigate();

export function withRouter(Children) {
    return (props) => {

        const match = { params: useParams() };
        const location = { location: useLocation() };
        const history = useNavigate();

        return <Children {...props} match={match} location={location.location} history={history} />
    }
}

class Detail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authMessage: '',
            balance: 0,
            ccFee: 0,
            clientIP: '',
            daysToPay: 0,
            depositPaid: false,
            fullyPaid: false,
            listingTitle: '',
            loggedIn: true,
            loggedInUserID: '',
            paymentRequired: 0,
            paymentToShow: 0,

            reservation: [], 
            resDetail: [],
            reservationID: '',
            resultAuthClass: '',

            showApprove: 'none',
            showBond: 'none',
            showCancel: 'none',
            showCancelModal: false,
            showCardEntry: false,
            showDecline: 'none',
            showDeclineModal: false,
            showPayment: 'none',
            showReview: 'none',
            showRating: 'none',

            disableButton: false,

            showLoading: 'none',
            showResultMessage: 'none',
            
            resultMessageClass: '',
            resultMessage: '',
            showAuthMessage: 'none',
            
            userID: '',
            
            declineReason: "",
            showDeclineReason: 'none',
            reservationShow: 'none',
            paymentStatusMessage: "",
            showPaymentMessage: false,
            withholdbonderror: '',
            withholdbonderrorshow: false,
            bondWithheld: 0,

            customerRating: 0
        }
    }

    formatDate = (input) => {
        var date = new Date(input);
        return (('0' + (date.getDate())).slice(-2) + '/' + ('0' + (date.getMonth() + 1 )).slice(-2) + '/' + date.getFullYear()).toString();
    }

/*Function will process the approval flow of a reservation and display to the user the results*/
    approve = () => {
        this.setState({
            showLoading: 'block',
            disableButton: true,
        });


        var finalPayment = false;

        //check if payment required matches the balanc
        if (Math.round(this.state.balance * 100) / 100 > Math.round(this.state.paymentRequired * 100) / 100) {
            //this would be a deposit payment
            finalPayment = false;
        } else {
            //this will be a final payment
            finalPayment = true;
        }

        ReservationDetail.updateReservationStatus(
            this.state.reservation.Reservation.ReservationID,
            'Booked',
            this.state.paymentRequired.toString(),
            "AHS Reservation - " + this.state.reservation.Reservation.ShortReservationID + " - " + this.state.reservation.Title,
            this.state.clientIP,
            finalPayment,
            "",
            ""
        ).then(result => {
            var css = '';
            var message = '';
            var status = '';

            //console.log("payment result: " + JSON.stringify(result.data));
            //console.log("payment result: " + result.data.success);

            if (result.data.success === true) {
                css = 'success';
                message = 'Congratulations, your reservation is approved and deposit paid!';
            } else {
                css = 'danger';
                message = 'Unfortunately there has been an issue processing the payment!';
                status = "The guest now has 24 hours to pay the fees due or the reservation will be cancelled"
            }

            //reset the balance based on the payment made
            ReservationDetail.getReservationObjectsByReservationID(this.state.reservation.Reservation.ReservationID).then(result => {
                this.setState({ reservation: result.data });

                this.setState({
                    showLoading: 'none',
                    disableButton: false,
                    showResultMessage: 'inline',
                    resultMessageClass: css,
                    resultMessage: message,
                    paymentStatusMessage: status,
                    resDetail: result.data.Reservation,
                    reservation: result.data,
                    showPaymentMessage: true
                });

                this.processActions(this.state.isGuest, result.data.Reservation.Status);
            })
        })
        
    }

    closeModal = () => {
        this.setState({ showDeclineModal: false });
    }

    declineModal = () => {
        this.setState({ showDeclineModal: true });
    }

    decline = () => {
        this.setState({
            showLoading: 'block',
            disableButton: true,
        });

        ReservationDetail.declineReservation(this.state.reservation.Reservation.ReservationID, document.getElementById('declineReason').value, this.state.loggedInUserID.data.UUID).then(result => {
            //process something
            var css = '';
            var message = '';

            if (result.data === "Declined") {
                css = 'success';
                message = 'Reservation has been declined!';
            } else {
                css = 'danger';
                message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
            }

            ReservationDetail.getReservationObjectsByReservationID(this.state.reservation.Reservation.ReservationID).then(result => {
                this.setState({
                    showLoading: 'none',
                    disableButton: false,
                    showResultMessage: 'inline',
                    resultMessageClass: css,
                    resultMessage: message,
                    reservation: result.data
                });

                //update the history so on refresh the page uses the latest state
                //var history = createBrowserHistory();

                //var history = useNavigate();

                //history.replace({
                //    pathname: "/reservationdetail",
                //    state: {
                //        reservation: result.data,
                //        email: this.props.email
                //    }
                //});

                this.processActions(this.state.isGuest, result.data.Reservation.Status);
                this.setState({
                    showDeclineModal: false,
                    showDeclineReason: 'block',
                    declineReason: document.getElementById('declineReason').value,
                    reservation: result.data,
                    resDetail: result.data.Reservation,
                });

            });

        })
    }


    closeCancelModal = () => {
        this.setState({ showCancelModal: false });
    }
    cancelModal = () => {
        this.setState({ showCancelModal: true });
    }
    cancel = () => {
        this.setState({
            showLoading: 'block',
            disableButton: true,
        });

        ReservationDetail.cancelReservation(this.state.reservation.Reservation.ReservationID, this.state.loggedInUserID).then(result => {
            //process something
            var css = '';
            var message = '';

            if (result.data === "Cancelled") {
                css = 'success';
                message = 'Reservation has been cancelled!';
            } else {
                css = 'danger';
                message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
            }

            ReservationDetail.getReservationObjectsByReservationID(this.state.reservation.Reservation.ReservationID).then(result => {
                this.setState({
                    showLoading: 'none',
                    disableButton: false,
                    showResultMessage: 'inline',
                    resultMessageClass: css,
                    resultMessage: message,
                    reservation: result.data
                });

                //update the history so on refresh the page uses the latest state
                //var history = createBrowserHistory();
                this.props.history('/reservationdetail',
                    {
                        state: {
                            reservation: result.data,
                            email: this.props.email
                        }
                    })

                //var history = useNavigate();

                //history.replace({
                //    pathname: "/reservationdetail",
                //    state: {
                //        reservation: result.data,
                //        email: this.props.email
                //    }
                //});

                this.processActions(this.state.isGuest, result.data.Reservation.Status);
                this.setState({
                    showCancelModal: false
                });

            });

        })
    }

    makePayment = () => {
        //show the credit card entry
        this.setState({ showCardEntry: true });
    }

    formatStatus = (input) => {
        if (typeof input != 'undefined') {
            switch (input.toLowerCase()) {
                case 'pending':
                case 'deposit paid':
                case 'payment required':
                    return 'label label-primary';
                    //break;
                case 'declined':
                    return 'label label-danger';
                    //break;
                case 'cancelled':
                    return 'label label-secondary';
                   //break;
                case 'booked':
                    return 'label label-success';
                    //break;
                default:
                    break;
            }
        }
    }

    processPayment = (props) => {
        this.setState({
            showLoading: 'block',
            disableButton: true,
        });

        var css = '';
        var message = '';

        var finalPayment = false;

        //check if payment required matches the balanc
        if (Math.round(this.state.balance * 100) / 100 > Math.round(this.state.paymentRequired * 100) / 100) {
            //this would be a deposit payment
            finalPayment = false;
        } else {
            //this will be a final payment
            finalPayment = true;
        }

        var cardFees = (Math.round(props.cardFees * 100) / 100).toString();

        ReservationDetail.updateReservationStatus(
            this.state.reservation.Reservation.ReservationID,
            'Booked',
            this.state.paymentRequired,
            "AHS Reservation - " + this.state.reservation.Reservation.ShortReservationID + " - " + this.state.reservation.Title,
            this.state.clientIP,
            finalPayment,
            props.token,
            cardFees
        ).then(result => {

            //console.log("payment result: " + JSON.stringify(result.data));

            if (result.data.success === true) {
                css = 'success';
                message = 'Thank you for your payment!';

                //reset the reservation state to update balance etc
                ReservationDetail.getReservationObjectsByReservationID(this.state.reservation.Reservation.ReservationID).then(result => {

                    //check balance for the right message to pass in
                    if (result.data.Reservation.BalanceRemaining < 1) {
                        message = 'Thank you for your payment, your reservation is now fully paid!';
                        //remove the payment button
                        this.setState({
                            showPayment: 'none'
                        });
                    } else {
                        message = 'Thank you for your payment, your reservation is now booked!';
                    }

                    this.setState({
                        reservation: result.data,
                        showLoading: 'none',
                        disableButton: false,
                        showResultMessage: 'inline',
                        resultMessageClass: css,
                        resultMessage: message,
                        showCardEntry: false,
                        showPaymentMessage: true
                    });

                    //update the history so on refresh the page uses the latest state
                    //var history = createBrowserHistory();

                    this.props.history('/reservationdetail',
                        {
                            state: {
                                reservation: result.data,
                                email: this.props.email
                            }
                        })


                    //var history = useNavigate();

                    //history.replace({
                    //    pathname: "/reservationdetail",
                    //    state: {
                    //        reservation: result.data,
                    //        email: this.props.email
                    //    }
                    //});

                    this.processActions(this.state.isGuest, result.data.Reservation.Status);


                })
            } else {
                //something went wrong with the payment
                css = 'danger';
                message = 'Unfortunately there has been an issue processing the payment!';

                this.setState({
                    showLoading: 'none',
                    disableButton: false,
                    showResultMessage: 'inline',
                    resultMessageClass: css,
                    resultMessage: message,
                    showCardEntry: false,
                    showPaymentMessage: true
                });
            }
        });

    }

    hideCardEntry = () => {
        this.setState({ showCardEntry: false });
    }

    hidePaymentMessage = () => {
        this.setState({
            showPaymentMessage: false,
        });
    }

    bondChange = (event) => {
        this.setState({
            bondWithheld: event.target.value,
            showResultMessage: 'none',
        });
    }

    withholdBond = () => {
        //this.setState({ showLoading: 'block' });
        this.setState({
            withholdbonderror: '',
            withholdbonderrorshow: false,
            showLoading: 'block',
            disableButton: true,
        })

        //validate the amount is greater than 0 and less than the total
        var amount = this.state.bondWithheld;
        if (+amount < 0 || +amount > +this.state.reservation.Reservation.SecurityDeposit) {
            this.setState({
                withholdbonderror: 'Amount must be greater than 0 and less than ' + this.state.reservation.Reservation.SecurityDeposit,
                withholdbonderrorshow: true,
                showLoading: 'none',
                disableButton: false,
            })
        } else {
            ReservationDetail.withholdBond(this.state.reservation.Reservation.ReservationID, amount).then(result => {

                //console.log('withhold result: ' + JSON.stringify(result));

                //process something
                var css = '';
                var message = '';

                if (result.data) {
                    css = 'success';
                    message = 'Bond has been withheld!';
                } else {
                    css = 'danger';
                    message = 'Unfortunately there has been an error, we have been notified and will resolve shortly!';
                }

                ReservationDetail.getReservationObjectsByReservationID(this.state.reservation.Reservation.ReservationID).then(result => {
                    this.setState({
                        showLoading: 'none',
                        disableButton: false,
                        showResultMessage: 'inline',
                        resultMessageClass: css,
                        resultMessage: message,
                        reservation: result.data
                    });

                    //update the history so on refresh the page uses the latest state
                    //var history = createBrowserHistory();

                    this.props.history('/reservationdetail',
                        {
                            state: {
                                reservation: result.data,
                                email: this.props.email
                            }
                        })

                    //var history = useNavigate();

                    //history.replace({
                    //    pathname: "/reservationdetail",
                    //    state: {
                    //        reservation: result.data,
                    //        email: this.props.email
                    //    }
                    //});

                    //this.processActions(this.state.isGuest, result.data.Reservation.Status);

                });

            })
        }
    }

    processActions(isGuest, status) {
        this.setState({
            showApprove: 'none',
            showCancel:  'none',
            showDecline: 'none',
            showPayment: 'none',
            showBond: 'none',
            showReview: 'none',
            showRating: 'none'
        });

        //check if the bond has been transfered and do not show any actions
        if (!this.state.reservation.Payment.SecurityTransferHost) {
            if (isGuest) {
                switch (status) {
                    case "Pending":
                    case "Declined":
                    case "Cancelled":
                        break;
                    case "Deposit Paid":
                        this.setState({ showCancel: 'inline' });
                        break;
                    case "Booked":
                    case "Payment":
                    case "Payment Required":
                        //check balance is great than $0
                        if (+this.state.reservation.Reservation.BalanceRemaining > 1) {
                            this.setState({
                                showPayment: 'inline'
                            });
                        }
                        var today = new Date();
                        var checkin = new Date(this.state.reservation.Reservation.ArrivalDate);
                        var checkout = new Date(this.state.reservation.Reservation.DepartureDate);
                        //if (checkout > today && checkin > today) {
                        if (checkin > today) {
                            //alert(checkin);
                            this.setState({ showCancel: 'inline' });
                        }
                        //check if post checkout for review
                        if (checkout < today) {
                            this.setState({ showReview: 'inline' });
                        }
                        break;
                    default:
                        break;
                }
            } else {
                switch (status) {
                    case "Pending":
                        this.setState({
                            showApprove: this.state.resDetail.InstantBooking ? 'none' : 'inline',
                            showDecline: 'inline'
                        });
                        break;
                    case "Declined":
                    case "Cancelled":
                        break;
                    case "Deposit Paid":
                        this.setState({ showCancel: 'inline' });
                        break;
                    case "Booked":
                        //check if post checkout for withholding bond
                        var today = new Date();
                        var checkout = new Date(this.state.reservation.Reservation.DepartureDate);
                        if (+this.state.reservation.Reservation.SecurityDeposit > 0 && !this.state.reservation.Payment.SecurityTransferGuest && !this.state.reservation.Payment.SecurityTransferHost && checkout < today) {
                            this.setState({
                                showBond: 'inline'
                            });
                        } else {
                            this.setState({ showCancel: 'inline' });
                        }
                        //check if post checkout for review
                        if (checkout < today) {
                            this.setState({ showRating: 'inline' });
                        }
                        break;
                    case "Payment Required":
                        this.setState({ showCancel: 'inline' });
                        break;
                    default:
                        break;
                }

            }
        }

        //if balance is 0 then hide balance and extra fees
        if (+this.state.reservation.Reservation.BalanceRemaining < 1) {
            this.setState({
                showBalance: 'none',
                showExtraFee: 'none',
                showDepositDue: 'none'
            })
        }
    }

    componentDidMount() {
        //console.log("fff")

        const { id } = this.props.match.params;

        if (typeof id !== 'undefined') {
            var ReservationID = id;
            //get the reservation
            ReservationDetail.getReservationObjectsByReservationID(ReservationID).then(result => {

                console.log(result.data);

                this.setState({
                    reservation: result.data,
                    resDetail: result.data.Reservation,
                    reservationID: ReservationID,
                    reservationShow: 'block',
                    bondWithheld: result.data.Reservation.BondWithheld,
                });

                //get customerID & status & ownerID to prevent any async issues
                var customerID = result.data.Reservation.CustomerID;
                var status = result.data.Reservation.Status;
                var ownerID = result.data.OwnerID;

                var loadDetail = true;
                //get the user ratings for customer
                ReviewsDetail.getHostReviews("GuestID", customerID).then(reviewResult => {
                    //loop all ratings to get average - round to whole number
                    if (reviewResult) {
                        //console.log("reviews: " + reviewResult);

                        var totalRating = 0;
                        var numberRating = 0;

                        reviewResult.data.map((review, index) => {
                            if (review.Published) {
                                totalRating = totalRating + review.Rating;
                                numberRating++;
                            }
                        })

                        if (totalRating > 0) {
                            this.setState({
                                customerRating: (totalRating / numberRating)
                            })
                        }
                    }
                })


                UserDetail.getUserByEmail(this.props.email).then((result) => {
                    //store this result as loggedinID
                    this.setState({
                        loggedInUserID: result,
                        isGuest: (result.data.UUID == customerID)
                    });

                    //need to check admin to treat as host
                    if (result.data.UUID === customerID) {
                        //guest
                        this.processActions(true, status);
                    } else if (result.data.UUID === ownerID) {
                        //owner
                        this.processActions(false, status);
                    } else if (this.props.loginType === 'admin') {
                        //admin can do owner functions
                        this.processActions(false, status);
                    } else {
                        //do not display
                        loadDetail = false;

                        this.setState({
                            showLoading: 'none',
                            disableButton: false,
                            showAuthMessage: 'block',
                            resultAuthClass: 'danger',
                            authMessage: "You are not authorised to view this reservation, please login and try again!",
                            reservationShow: 'none'
                        });
                    }



                    if (loadDetail) {

                        axios.get('https://geolocation-db.com/json/').then(result => {
                            this.setState({ clientIP: result.data.IPv4 });
                        })

                        //build pricing
                        //translate daily rate dates
                        var dailyRate = [];
                        this.state.reservation.DailyRates.map((daily, index) => {
                            dailyRate = [...dailyRate, {
                                date: new Date(daily.Date),
                                price: daily.Rate
                            }];
                        });

                        PricingDetail.buildDailyRateAccordion(dailyRate, this.state.reservation.Reservation.AmountSaved).then(result => {
                            if (result) {
                                this.setState({
                                    NightlyTotal: result[0],
                                    Nights: result[2],
                                    showAccordion: 'block'
                                });
                                this.refs.dailyrates.innerHTML = result[1];
                                this.refs.dailyratesdetail.innerHTML = result[1];
                            }
                        }).catch(error => {
                            //need to throw this to the backend
                            this.props.error("Reservation Detail", error);
                        })

                        PricingDetail.buildPricingTable(
                            //this.state.reservation.DailyRates,
                            this.state.reservation.TaxesInc,
                            this.state.reservation.TaxesEx,
                            this.state.reservation.TaxesCond,
                            this.state.reservation.TaxesArriveEx,
                            this.state.reservation.TaxesArriveCond,
                            this.state.reservation.FeesInc,
                            this.state.reservation.FeesEx,
                            this.state.reservation.FeesCond,
                            this.state.reservation.FeesArriveEx,
                            this.state.reservation.FeesArriveCond,
                            this.state.reservation.Reservation.TotalFees,
                            this.state.reservation.Reservation.Deposit,
                            this.state.reservation.Reservation.SecurityDeposit,
                            (+this.state.reservation.Reservation.BalanceRemaining > 1 ? false : true),
                            null,
                            null,
                            this.state.reservation.Reservation.TotalGST,
                            this.state.reservation.Reservation.PMSFees
                        ).then(result => {

                            if (result) {
                                this.refs.pricing.innerHTML = result;
                                this.refs.pricingdetail.innerHTML = result;
                                this.setState({ requestDisabled: false });
                            }
                            this.setState({ pricingspinner: 'none' });

                        }).catch(error => {
                            //need to throw this to the backend
                            this.props.error("Reservation Detail", error);
                        })

                        this.setState({ listingTitle: this.state.reservation.Title });

                        //If pending then Payment required within 24 hours
                        if (this.state.reservation.Reservation.Status.toLowerCase() === "pending" || this.state.reservation.Reservation.Status.toLowerCase() === "payment required") {
                            PricingDetail.formatPrice(this.state.reservation.Reservation.Deposit).then(result => {
                                this.setState({
                                    balance: this.state.reservation.Reservation.TotalFees,
                                    showBalance: 'none',
                                    showDepositDue: 'inline',
                                    showExtraFee: 'inline',
                                    paymentRequired: this.state.reservation.Reservation.Deposit,
                                    paymentToShow: result
                                });
                            });

                            //this needs to be updated - 1.5%
                            var ccFee = (this.state.reservation.Reservation.Deposit * 1.5 / 100);
                            PricingDetail.formatPrice(ccFee).then(result => {
                                this.setState({ ccFee: result });
                            });
                        } else {
                            if (+this.state.reservation.Reservation.BalanceRemaining >= 1) {
                                PricingDetail.formatPrice(this.state.reservation.Reservation.BalanceRemaining).then(result => {
                                    this.setState({
                                        balance: result,
                                        showBalance: 'inline',
                                        showDepositDue: 'none',
                                        showExtraFee: 'inline',
                                        paymentRequired: this.state.reservation.Reservation.BalanceRemaining,
                                        paymentToShow: result
                                    });
                                });
                            }
                            //this needs to be updated - 1.5%
                            var ccFee = (this.state.reservation.Reservation.TotalFees * 1.5 / 100);
                            PricingDetail.formatPrice(ccFee).then(result => {
                                this.setState({ ccFee: result });
                            });

                            if (this.state.reservation.Reservation.Status.toLowerCase() == "booked") {

                                //deposit paid - set state to show guest details to host
                                this.setState({
                                    depositPaid: true
                                })
                                //no balance remaining means fully paid and show host details
                                if (this.state.reservation.Reservation.BalanceRemaining < 1) {
                                    this.setState({
                                        fullyPaid: true
                                    })

                                }
                            }
                        }
                        this.setState({ daysToPay: this.state.reservation.Reservation.Cancellation });
                        //if the reservation is declined show reason
                        if (this.state.reservation.Reservation.Status == "Declined") {
                            this.setState({
                                showDeclineReason: 'block',
                                declineReason: this.state.reservation.Reservation.DeclineReason
                            });
                        }
                    }
                }).catch(error => {
                    //need to throw this to the backend
                    this.props.error("Reservation Detail", error);
                })
            }).catch(error => {
                //need to throw this to the backend
                this.props.error("Reservation Detail", error);
            })
        }
    }

    render() {
        return (
            <div>
                <div class="loading-overlay" style={{ display: this.state.showLoading }}>
                    <div class="loading-vertical">
                        <Grid
                        color="#00BFFF"
                        height={100}
                        width={100}
                        timeout={0} 
                        />
                    </div>
                </div>

                <Modal show={this.state.showPaymentMessage} size="lg" style={{ textAlign: "center" }}>
                    <Modal.Body>
                        <h4>{this.state.resultMessage}</h4>
                        <div>
                            {this.state.paymentStatusMessage}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-primary btn-block" onClick={() => this.hidePaymentMessage()} style={{ width: "100%" }}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <CardModal
                    showCardEntry={this.state.showCardEntry}
                    onClose={this.closeCardEntry}
                    hideCardEntry={this.hideCardEntry}
                    payable={this.state.paymentToShow}
                    processPayment={this.processPayment}
                    email={this.props.email}
                    reservationUUID={this.state.reservationID}
                />

                <div style={{ paddingTop: "180px", display: this.state.showAuthMessage, width: '100%' }}>
                    <Alert variant={this.state.resultAuthClass} >
                        {this.state.authMessage}
                    </Alert>
                </div>

                <div style={{ paddingTop: "80px", display: this.state.reservationShow }} >
                    <Row >
                        <Col className="d-none d-md-block" md="3" lg="2" xl="1">
                            <SideNav
                                loggedIn={this.props.loggedIn}
                                loginType={this.props.loginType}
                                email={this.props.email}
                                activeTab='reservations'
                            />
                        </Col>
                        <Col md="9" lg="10" xl="11" style={{ paddingRight: "0px"  }}>
                            <Row style={{ marginLeft: "0px" }}>
                                <div style={{ width: '100%' }}>
                                    <h1>Reservation</h1>
                                    <hr />
                                </div>
                            </Row>
                        <Row style={{ marginLeft: "0px" }}>
                                <Col md="9" lg="10" xl="10" style={{ paddingRight: "0px" }}>
                                    <div style={{ display: this.state.showResultMessage, width: '100%' }}>
                                        <Alert variant={ this.state.resultMessageClass } >
                                            {this.state.resultMessage }
                                        </Alert>
                                    </div>
                                    <Card>
                                        <Card.Title>
                                            <span className={this.formatStatus(this.state.resDetail.Status)}>{this.state.resDetail.Status}</span>
                                            <span style={{ float: "right" }}>
                                                <Link style={{ display: this.state.showReview}} to={{
                                                    pathname: "/review/" + "0" + "/" + this.state.reservationID + "/" + "0" + "/",
                                                }}>
                                                    <Button style={{ display: (true ? "inline" : "none") }} variant="link" title="Write Review" className="icon-button" >
                                                        <MdOutlineRateReview style={{paddingTop: "3px"}} size={38} color="lightblue" />
                                                    </Button>
                                                </Link>
                                                <Link style={{ display: this.state.showRating }} to={{
                                                    pathname: "/rating/" + "0" + "/" + this.state.reservationID + "/" + "0" + "/",
                                                }}>
                                                    <Button style={{ display: (true ? "inline" : "none") }} variant="link" title="Write Review" className="icon-button" >
                                                        <FaPeopleArrows size={28} color="lightblue" />
                                                    </Button>
                                                </Link>
                                                <Link to={{
                                                    pathname: "/message/" + "0" + "/" + this.state.reservationID + "/" + "0" + "/",
                                                }}>
                                                    <Button style={{ display: (true ? "inline" : "none") }} variant="link" title="Send Message" className="icon-button" >
                                                        <FaRegEnvelopeOpen size={28} color="lightblue" />
                                                    </Button>
                                                </Link>
                                            </span>
                                            {/*Show decline reason*/}
                                            <span className="decline-reason" style={{display: this.state.showDeclineReason}} >{this.state.declineReason} </span>
                                        </Card.Title>
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <b>Reservation ID:</b>
                                                    <br />
                                                    {this.state.resDetail.ShortReservationID}
                                                </Col>
                                                <Col>
                                                    <b>Requested Date:</b>
                                                    <br />
                                                    {this.formatDate(this.state.resDetail.CreatedDate)}
                                                </Col>
                                                <Col>
                                                    <b>From:</b>
                                                    <br/>
                                                    <Link to={{
                                                        pathname: "/listing/" + this.state.resDetail.ListingID,
                                                        state: {
                                                            id: this.state.resDetail.ListingID
                                                        }
                                                    }}>
                                                        <span>{this.state.listingTitle}</span>
                                                    </Link>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col>
                                                    <b>Details</b>
                                                </Col>
                                                <Col>
                                                    Check In: <b>{this.formatDate(this.state.resDetail.ArrivalDate)}</b><br />
                                                    Check Out: <b>{this.formatDate(this.state.resDetail.DepartureDate)}</b>
                                                </Col>
                                                <Col>
                                                    Nights: <b>{this.state.resDetail.NightsCount}</b><br />
                                                    Guests: <b>{this.state.resDetail.Guests}</b>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col>
                                                    <b>Guest Details</b>
                                                </Col>
                                                <Col>
                                                    First Name: <b>{this.state.reservation.FirstName}</b><br />
                                                    Last Name: <b>{this.state.reservation.LastName}</b><br />
                                                    Rating: <b>
                                                    {this.state.customerRating != 0 ? <StarRating
                                                        rating={this.state.customerRating}
                                                    /> : "No Ratings"}</b>
                                                </Col>
                                                <Col style={{ display: this.state.depositPaid ? "inline" : "none" }}>
                                                    Email: <b>{this.state.reservation.GuestEmail}</b><br />
                                                    Phone: <b>{this.state.reservation.GuestPhone}</b>
                                                </Col>
                                                <Col style={{ display: !this.state.depositPaid ? "inline" : "none" }}>
                                                    {/*this is blank for formatting purposes*/}
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row > {/*To be displayed only after final payment*/}
                                                <Col style={{ display: this.state.fullyPaid ? "inline" : "none" }}>
                                                    <b>Host Details</b>
                                                </Col>
                                                <Col style={{ display: this.state.fullyPaid ? "inline" : "none" }}>
                                                    First Name: <b>{this.state.reservation.OwnerFirst}</b><br />
                                                    Last Name: <b>{this.state.reservation.OwnerLast}</b>
                                                </Col>
                                                <Col style={{ display: this.state.fullyPaid ? "inline" : "none" }}>
                                                    Email: <b>{this.state.reservation.Email}</b><br />
                                                    Phone: <b>{this.state.reservation.OwnerPhone}</b>
                                                </Col>
                                            </Row>
                                            <hr style={{ display: this.state.fullyPaid ? "block" : "none" }}/>
                                            <Row>
                                                <Col md="4" lg="4" xl="4">
                                                    <b>Payment</b>
                                                </Col>
                                                <Col md="8" lg="8" xl="8">
                                                    <div class="clearfix" style={{ display: this.state.showAccordion }}>
                                                        <div class="pull-left">
                                                            <div class="payment-list-price-detail-total-price"><b>Total</b></div>
                                                            <div class="payment-list-price-detail-note">Includes taxes and fees</div>
                                                        </div>
                                                    </div>
                                                    <Accordion style={{ display: this.state.showAccordion }}>
                                                        <Accordion.Item eventKey="0">
                                                            {/*<Accordion.Header>{this.state.NightlyTotal}</Accordion.Header>*/}
                                                            <Accordion.Header><div style={{ width: '100%' }}><div style={{ width: '45%', float: 'left' }}><b>Total Nights: {this.state.Nights}</b></div><div style={{ width: '45%', textAlign: 'right', float: 'left' }}>{this.state.NightlyTotal}</div></div></Accordion.Header>
                                                            <Accordion.Body>
                                                                <div ref="dailyratesdetail"></div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                    <div ref="pricingdetail"></div>
                                                    <br />
                                                    {/*<div style={{ display: this.state.showExtraFee }}>*/}
                                                    {/*    <FaInfoCircle /> AMEX, Diners & International Cards will attract an extra fee of {this.state.ccFee}*/}
                                                    {/*</div>*/}
                                                    <br />
                                                    <br />
                                                    <div style={{ display: this.state.showBalance }}>
                                                        <FaInfoCircle /> Balance due of {this.state.balance}, {this.state.daysToPay} days before check-in date
                                                    </div>
                                                    {/*<div style={{ display: this.state.showDepositDue }}>*/}
                                                    {/*    <FaInfoCircle /> Deposit due of {this.state.balance}, within 24 hours of Reservation Approval*/}
                                                    {/*</div>*/}
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    <Row style={{ marginLeft: "0px" }}>
                                        <Col>
                                            <Button className="action-button" variant="danger" disabled={this.state.disableButton} onClick={() => this.declineModal()} style={{ width: '100%', display: this.state.showDecline }}>Decline</Button>
                                        </Col>
                                        <Col>
                                            <Button className="action-button" variant="success" disabled={this.state.disableButton} onClick={() => this.approve()} style={{ width: '100%', display: this.state.showApprove }}>Approve</Button>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginLeft: "0px" }}>
                                        <Button className="action-button" variant="primary" disabled={this.state.disableButton} onClick={() => this.makePayment()} style={{ width: '100%', display: this.state.showPayment }}>Make Payment</Button>
                                        <Button className="action-button" variant="warning" disabled={this.state.disableButton} onClick={() => this.withholdBond()} style={{ width: '100%', display: this.state.showBond }}>Withhold Bond</Button>
                                        <Button className="action-button" variant="danger" disabled={this.state.disableButton} onClick={() => this.cancelModal()} style={{ width: '100%', display: this.state.showCancel }}>Cancel</Button>
                                    </Row>

                                </Col>



                                <Col className="d-none d-md-block" md="3" lg="2" xl="2" >
                                    <div class="clearfix" style={{ display: this.state.showAccordion }}>
                                        <div class="pull-left">
                                            <div class="payment-list-price-detail-total-price"><b>Total</b></div>
                                            <div class="payment-list-price-detail-note">Includes taxes and fees</div>
                                        </div>
                                    </div>
                                    <Accordion style={{ display: this.state.showAccordion }}>
                                        <Accordion.Item eventKey="0">
                                            {/*<Accordion.Header>{this.state.NightlyTotal}</Accordion.Header>*/}
                                            <Accordion.Header><div style={{ width: '100%' }}><div style={{ width: '45%', float: 'left' }}><b>Total Nights: {this.state.Nights}</b></div><div style={{ width: '45%', textAlign: 'right', float: 'left' }}>{this.state.NightlyTotal}</div></div></Accordion.Header>
                                            <Accordion.Body>
                                                <div ref="dailyrates"></div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    <div ref="pricing"></div>
                                    <br />
                                    {/*<div style={{ display: this.state.showExtraFee }}>*/}
                                    {/*    <FaInfoCircle /> AMEX, Diners & International Cards will attract an extra fee of {this.state.ccFee}*/}
                                    {/*</div>*/}
                                    <br />
                                    <br />
                                    <div style={{ display: this.state.showBalance }}>
                                        <FaInfoCircle /> Balance due of {this.state.balance}, {this.state.daysToPay} days before check-in date
                                    </div>

                                    <div style={{ display: this.state.showBond }}>
                                        <Input className="mbsc-remove-margins" type="number" name="withholdbond" id="withholdbond" min="0" value={this.state.bondWithheld} errorMessage={this.state.withholdbonderror} error={this.state.withholdbonderrorshow} onChange={(e) => this.bondChange(e)} />
                                    </div>
                                    {/*<div style={{ display: this.state.showDepositDue }}>*/}
                                    {/*    <FaInfoCircle /> Deposit due of {this.state.balance}, within 24 hours of Reservation Approval*/}
                                    {/*</div>*/}
                                    <br />
                                    <div style={{ width: '100%' }}>
                                        <Button className="action-button" variant="success" disabled={this.state.disableButton} onClick={() => this.approve()} style={{ width: '100%', display: this.state.showApprove }}>Approve</Button>
                                        <Button className="action-button" variant="danger" disabled={this.state.disableButton} onClick={() => this.declineModal()} style={{ width: '100%', display: this.state.showDecline }}>Decline</Button>
                                        <Button className="action-button" variant="primary" disabled={this.state.disableButton} onClick={() => this.makePayment()} style={{ width: '100%', display: this.state.showPayment }}>Make Payment</Button>
                                        <Button className="action-button" variant="warning" disabled={this.state.disableButton} onClick={() => this.withholdBond()} style={{ width: '100%', display: this.state.showBond }}>Withhold Bond</Button>
                                        <Button className="action-button" variant="danger" disabled={this.state.disableButton} onClick={() => this.cancelModal()} style={{ width: '100%', display: this.state.showCancel }}>Cancel</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <Modal show={this.state.showDeclineModal} onClose={() => this.closeModal()}>
                    <Modal.Header closeButton onClick={() => this.closeModal()}>
                        <Modal.Title>Decline Reason</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormControl as="textarea" id="declineReason"/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="action-button" variant="danger" onClick={() => this.decline()} style={{ width: '100%', display: this.state.showDecline }}>Decline</Button>
                        <Button className="action-button" variant="secondary" onClick={() => this.closeModal()} style={{ width: '100%', display: this.state.showDecline }}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showCancelModal} onClose={() => this.closeCancelModal()}>
                    <Modal.Header closeButton onClick={() => this.closeCancelModal()}>
                        <Modal.Title>Confirm Reservation Cancellation</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button className="action-button" variant="danger" onClick={() => this.cancel()} style={{ width: '100%', display: this.state.showCancel }}>Cancel</Button>
                        <Button className="action-button" variant="secondary" onClick={() => this.closeCancelModal()} style={{ width: '100%', display: this.state.showCancel }}>Close</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        )
    }

}
export default withRouter(Detail)