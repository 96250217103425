import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button  } from 'react-bootstrap';
import SideNav from "./../modals/SideNav";

import UserDetail from './../functions/User';
import ReservationDetail from './../functions/Reservation';
//import ListingDetail from './../functions/Listing';

import { Grid } from "react-loader-spinner";

export function withRouter(Children) {
    return (props) => {
        return <Children {...props} location={props} />
    }
}

class Reservations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedIn: true,
            reservations: [],
            statusClass: 'label',
            showLoading: true
        }
    }

    formatDate = (input) => {
        var date = new Date(input);
        return (('0' + (date.getDate())).slice(-2) + '/' + ('0' + (date.getMonth() + 1 )).slice(-2) + '/' + date.getFullYear()).toString();
    }

    formatPrice = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    formatStatus = (input) => {
        switch (input.toLowerCase()) {
            case 'pending':
            case 'deposit paid':
            case 'payment required':
                return 'label label-primary';
                break;
            case 'declined':
                return 'label label-danger';
                break;
            case 'cancelled':
                return 'label label-secondary';
                break;
            case 'booked':
                return 'label label-success';
                break;
        }
    }

    showAction = (input) => {
        switch (input.toLowerCase()) {
            case 'pending':
            case 'deposit paid':
                return 'inline';
                break;
            case 'declined':
            case 'cancelled':
                return 'inline';
                break;
            case 'booked':
                return 'inline';
                break;
        }
    }

    componentDidMount() {

        if (this.props.location.state.loginType === 'admin') {
            ReservationDetail.getReservationObjects().then((result) => {
                if (result.data && result.data.length > 0 && typeof (result.data) != 'string') {
                    this.setState({ reservations: result.data });
                }
                this.setState({ showLoading: false });
            })
        } else {

            var reservations = [];

            //get user id by email
            UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {
                var customerID = result.data.UUID;
                //get the user reservations
                ReservationDetail.getReservationObjectsByCustomerID(customerID).then((result) => {
                    if (result.data && result.data.length > 0 && typeof (result.data) != 'string') {
                        reservations = result.data;
                    }
                    if (this.props.location.state.loginType === 'hosting') {
                        //check if host and get by owner
                        ReservationDetail.getReservationObjectsByOwnerID(customerID).then((result) => {
                            if (result.data && result.data.length > 0 && typeof (result.data) != 'string') {
                                result.data.map((reservation, index) => {
                                    reservations.push(reservation);
                                })
                                const list = reservations.sort((a, b) => (a.Reservation.CreatedDate < b.Reservation.CreatedDate) ? 1 : -1);
                                this.setState({ reservations: list });
                            }
                            this.setState({ showLoading: false });
                        })
                    } else {
                        this.setState({ showLoading: false });
                        const list = reservations.sort((a, b) => (a.Reservation.CreatedDate < b.Reservation.CreatedDate) ? 1 : -1);
                        this.setState({ reservations: list });
                    }
                })
            })
        }
    }

    sortByBookedDate = () => {
        const list = this.state.reservations.sort((a, b) => (a.Reservation.CreatedDate < b.Reservation.CreatedDate) ? 1 : -1);
        this.setState({ reservations: list });
    }

    sortByCheckinDate = () => {
        const list = this.state.reservations.sort((a, b) => (a.Reservation.ArrivalDate < b.Reservation.ArrivalDate) ? 1 : -1);
        this.setState({ reservations: list });
    }

    render() {

        if (this.state.showLoading) {
            return (
                <div class="loading-overlay" >
                    <div class="loading-vertical">
                        <Grid
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={0}
                        />
                    </div>
                </div>
            )
        } else {

            return (
                <div style={{ paddingTop: "80px" }}>
                    <Row>
                        <Col className="d-none d-lg-block" lg="2" xl="1">
                            <SideNav
                                loggedIn={this.props.location.state.loggedIn}
                                loginType={this.props.location.state.loginType}
                                email={this.props.location.state.email}
                                activeTab='reservations'
                            />
                        </Col>
                        <Col md="12" lg="10" xl="11">
                            <div style={{ width: '100%' }}>
                                <h1>Reservations</h1>
                                <hr />
                            </div>

                            <Card>
                                <Card.Body style={{ paddingRight: "0px" }}>
                                    <Row>
                                        <Col>
                                            <Button title="Sort by Booked Date" onClick={() => this.sortByBookedDate()} >Sort by Booked Date</Button>
                                            <Button title="Sort by Check-in Date" onClick={() => this.sortByCheckinDate()} >Sort by Check-in Date</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Row >
                                            <Col xl="1" lg="1" md="1" sm="1" className="d-none d-sm-block"><h5>ID</h5></Col>
                                            <Col xl="1" lg="2" md="2" sm="3" className="d-none d-sm-block"><h5>Status</h5></Col>
                                            <Col xl="1" lg="2" md="2" sm="3" className="d-none d-sm-block"><h5>Booked</h5></Col>
                                            <Col xl="2" lg="2" md="3" className="d-none d-md-block"><h5>Accomodation</h5></Col>
                                            <Col xl="1" lg="2" className="d-none d-lg-block"><h5>Check-in</h5></Col>
                                            <Col xl="1" className="d-none d-xl-block"><h5>Check-out</h5></Col>
                                            <Col xl="1" className="d-none d-xl-block"><h5>Guests</h5></Col>
                                            <Col xl="1" className="d-none d-xl-block"><h5>Pets</h5></Col>
                                            <Col xl="1" className="d-none d-xl-block"><h5>Total</h5></Col>
                                            <Col xl="1" lg="2" md="2" sm="3" className="d-none d-sm-block"><h5>Outstanding</h5></Col>
                                            <Col xl="1" lg="1" md="2" sm="2" className="d-none d-sm-block"><h5>Action</h5></Col>

                                            <Col className="d-block d-sm-none" xs="1"></Col>
                                            <Col className="d-block d-sm-none" xs="4">Status</Col>
                                            <Col className="d-block d-sm-none" xs="4">Booked</Col>
                                            <Col className="d-block d-sm-none" xs="3">Action</Col>

                                        </Row>
                                        {
                                            this.state.reservations && this.state.reservations.length > 0 ?
                                                this.state.reservations.map((reservation, index) =>
                                                    <div style={{ width: '100%' }}>
                                                        <div class="d-none d-md-block">
                                                            <Row md="12" style={{ height: '50px', borderBottom: '1px solid #808080', paddingTop: '6px' }} >
                                                                <Col xl="1" lg="1" md="1" sm="1" xs="1">
                                                                    {reservation.Reservation.ShortReservationID}
                                                                </Col>
                                                                <Col xl="1" lg="2" md="2" sm="3" xs="3">
                                                                    <span className={this.formatStatus(reservation.Reservation.Status)}>{reservation.Reservation.Status}</span>
                                                                </Col>
                                                                <Col xl="1" lg="2" md="2" sm="3" xs="3">
                                                                    {this.formatDate(reservation.Reservation.CreatedDate)}
                                                                </Col>
                                                                <Col xl="2" lg="2" md="3" className="d-none d-md-block">
                                                                    <Link to={{
                                                                        pathname: "/listing/" + reservation.Reservation.ListingID,
                                                                        state: {
                                                                            id: reservation.Reservation.ListingID
                                                                        }
                                                                    }}>
                                                                        <span>{reservation.Title}</span>
                                                                    </Link>
                                                                </Col>
                                                                <Col xl="1" lg="2" className="d-none d-lg-block">
                                                                    {this.formatDate(reservation.Reservation.ArrivalDate)}
                                                                </Col>
                                                                <Col xl="1" className="d-none d-xl-block">
                                                                    {this.formatDate(reservation.Reservation.DepartureDate)}
                                                                </Col>
                                                                <Col xl="1" className="d-none d-xl-block">
                                                                    {reservation.Reservation.Guests}
                                                                </Col>
                                                                <Col xl="1" className="d-none d-xl-block">
                                                                    {reservation.Reservation.Pets}
                                                                </Col>
                                                                <Col xl="1" className="d-none d-xl-block">
                                                                    {this.formatPrice.format(reservation.Reservation.TotalFees)}
                                                                </Col>
                                                                <Col xl="1" lg="2" md="2" sm="3" xs="3">
                                                                    {this.formatPrice.format(reservation.Reservation.BalanceRemaining)}
                                                                </Col>
                                                                <Col xl="1" lg="1" md="2" sm="2" xs="2">
                                                                    <Link style={{ display: this.showAction(reservation.Reservation.Status) }} to={{
                                                                        pathname: "/reservationdetail/" + reservation.Reservation.ReservationID,
                                                                    }}><Button>Details</Button>
                                                                    </Link>
                                                                </Col>
                                                            </Row>
                                                        </div>


                                                        <div class="d-md-none">
                                                            <Row style={{ height: '50px', paddingtop: '6px', fontSize: "13px" }} >
                                                                <Col sm="1" xs="1">
                                                                    {reservation.Reservation.ShortReservationID}
                                                                </Col>
                                                                <Col xs="4" sm="3">
                                                                    <span style={{fontSize: "11px"}} className={this.formatStatus(reservation.Reservation.Status)}>{reservation.Reservation.Status}</span>
                                                                </Col>
                                                                <Col xs="4" sm="3">
                                                                    {this.formatDate(reservation.Reservation.CreatedDate)}
                                                                </Col>
                                                                <Col sm="3" className="d-none d-sm-block">
                                                                    {this.formatPrice.format(reservation.Reservation.BalanceRemaining)}
                                                                </Col>
                                                                <Col xs="3" sm="2">
                                                                    <Link style={{ display: this.showAction(reservation.Reservation.Status) }} to={{
                                                                        pathname: "/reservationdetail/" + reservation.Reservation.ReservationID,
                                                                        //pathname: "/reservationdetail",
                                                                        //state: {
                                                                        //    reservation: reservation,
                                                                        //    email: this.props.location.state.email
                                                                        //    //    guests: props.guests,
                                                                        //    //    pets: props.pets,
                                                                        //    //    checkin: props.checkin
                                                                        //}
                                                                    }}><Button size="sm">Details</Button>
                                                                    </Link>
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ height: '40px', borderbottom: '2px solid #808080', paddingtop: '6px' }} sm="12" >
                                                                <Link to={{
                                                                    pathname: "/listing/" + reservation.Reservation.ListingID,
                                                                    state: {
                                                                        id: reservation.Reservation.ListingID
                                                                    }
                                                                }}>
                                                                    <span>{reservation.Title}</span>
                                                                </Link>
                                                                <hr />
                                                            </Row>
                                                        </div>




                                                    </div>
                                                ) :
                                                <Row>
                                                    <h4>You currently have no reservations</h4>
                                                </Row>
                                        }

                                    </Row>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </div>
            )
        }
    }
}

export default withRouter(Reservations)