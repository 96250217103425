import { Buffer } from "buffer";

// create the token for the authorisation header
const username = "aussiehs_basic_auth_username_%TGB^YHN7ujm8ik,";
const password = "!QAZ2wsx#EDC9ol.*IK<7ujm";
const token = username + ":" + password;
const hashed = Buffer.from(token).toString('base64');

// export our constants for use
export const AUTH = 'Basic ' + hashed;
export const ORIGIN = '*';
export const METHODS = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
export const METHODS_GET = 'GET,OPTIONS';
export const METHODS_POST = 'POST,OPTIONS';
export const METHODS_PUT = 'PUT,OPTIONS';
export const METHODS_DELETE = 'DELETE,OPTIONS';
export const CONTENT_TYPE = 'multipart/form-data';