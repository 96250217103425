import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { Form, Card, Col, Row, Button, Modal, Alert } from 'react-bootstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { FaSquareFull } from 'react-icons/fa';

import "./../../styles/calendar.css";
import { Datepicker, Input } from '@mobiscroll/react';

import ListingDetail from '../../functions/Listing';

import { Grid }  from "react-loader-spinner";

function getColors(bookedDates, blockedDates) {
    var colors = [];
    //cycle dates to block calendar
    if (typeof bookedDates !== 'undefined') {
        bookedDates.forEach(function (date) {
            //need to loop dates
            colors.push({ date: new Date(date.checkin), cellCssClass: 'check-in' });
            colors.push({ date: new Date(date.checkout), cellCssClass: 'check-out' });

            var start = new Date(date.checkin);
            while (start.getTime() < new Date(date.checkout).getTime()) {
                colors.push({ date: new Date(start), background: '#46c4f3' });
                start.setDate(start.getDate() + 1);
            }            
        });
    }

    if (typeof blockedDates !== 'undefined') {
        blockedDates.forEach(function (date) {
            colors.push({ date: new Date(date), background: '#ccebff' });
        });
    }

    return colors;    
};

function getDownloadLink(listingUUID) {
    //build link
    var url = "https://calendar.aussieholidaystays.com.au:8070";
    //add route
    url += "/icals?id=";
    //add listing id
    url += listingUUID;
    return url;
}

function Calendar({ setBlockedDates, setImportiCalDates, dateAdvance, bookedDates, blockedDates, listingID, listingData }) {
    // set up our defaults
    const today = new Date();
    const min = new Date();
    const max = today.setMonth(today.getMonth() + dateAdvance);

    var colors = getColors(bookedDates, blockedDates);

    var listingUUID = listingID;
    //var downloadlink = '';//getDownloadLink(listingUUID);
    const [downloadlink, setdownloadlink] = useState('');

    const [dates, setDates] = useState([]);
    const [labels, setLabels] = useState([]);
    const [datesPushed, setPushState] = useState(true);
    //const [downloaded, setDownloaded] = useState(false);
    var showiCalButtons = (typeof listingID != 'undefined' ? 'block' : 'none');
    const [showExportModal, setShowExportModal] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [iCalPushed, setiCalState] = useState(true);

    const [showCopyAlert, setShowCopyAlert] = useState('none');
    const [showSaveAlert, setShowSaveAlert] = useState('none');

    const [feedname, setFeedname] = useState('')
    const [feedURL, setFeedURL] = useState('')
    const [feeds, setFeeds] = useState([])

    const [getFeeds, setGetFeeds] = useState(false);

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState({});

    const [saveDisabled, setSaveDisabled] = useState(false);
    const [syncFeedsDisabled, setSyncFeedsDisabled] = useState(false);

    const [loadData, setLoadData] = useState(true);
    const [showLoading, setShowLoading] = useState('none');
    const [showSyncLoading, setShowSyncLoading] = useState('none');

    const buildCalendarDates = (savedDates) => {

        var buildLabels = [];
        var buildDates = [];

        savedDates.map((dateData, index) => {
            var date = new Date(dateData.Date);

            //convert the date
            var selectedDate = ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + (date.getDate())).slice(-2) + '/' + (date.getFullYear());
            var selectedUnix = new Date(selectedDate).getTime();

            //create the aria-label for page change function
            var ariaLabel = date.toLocaleString("default", { weekday: "long" }) + ', ' + date.toLocaleString("default", { month: "long" }) + ' ' + date.getDate();

            //setPushState(false);
            var elements = document.querySelectorAll('[aria-label="' + ariaLabel + '"]');
            if (elements) {
                elements.forEach(function (element) {
                    element.style.backgroundColor = '#ccebff';
                });
            }

            //check if the date is already in the date list
            var tempDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            var labelExist = buildLabels.filter(function (label) { return label.date.getTime() === tempDate.getTime(); });

            if (labelExist) {
                if (labelExist.length > 0) {
                    //check if booked and ignore else if new is booked remove old
                    if (labelExist[0].text == "Booked") {
                        //ignore
                    } else if (dateData.State == "Booked") {
                        ////remove and clean up
                        var tempLables = buildLabels.filter(function (label) { return label.date.getTime() != tempDate.getTime(); });

                        tempLables = [...tempLables, {
                            date: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
                            text: (dateData.StateBy != "Admin" ? (dateData.State == "Booked" ? dateData.State : dateData.StateBy) : ""),
                            textColor: '#1e1e1ecc'
                        }]
                        buildLabels = tempLables;

                        //var tempDates = dates.filter(function (prevDate) { return prevDate.unix != tempDate.getTime(); });
                        var tempDates = buildDates.filter(function (prevDate) { return prevDate.unix != tempDate.getTime(); });
                        //due to how the time is save we need to subtract 8:00 hours or set the date manually and not convert as above
                        let newDate = { date: selectedDate, aria: ariaLabel, month: date.getMonth(), unix: selectedUnix, state: dateData.State, stateBy: dateData.StateBy, fullDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()) }
                        tempDates = [...tempDates, newDate];

                        buildDates = tempDates;
                        buildDates.push(newDate);

                        //setDates(tempDates);
                    } else {
                        //due to how the time is save we need to subtract 8:00 hours or set the date manually and not convert as above
                        let newDate = { date: selectedDate, aria: ariaLabel, month: date.getMonth(), unix: selectedUnix, state: dateData.State, stateBy: dateData.StateBy, fullDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()) }
                        buildDates.push(newDate);
                        //setDates(prevDates => [...prevDates, newDate]);

                        buildLabels = [...buildLabels, {
                            date: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
                            text: (dateData.StateBy != "Admin" ? (dateData.State == "Booked" ? dateData.State : dateData.StateBy) : ""),
                            textColor: '#1e1e1ecc'
                        }]
                    }
                } else {
                    //due to how the time is save we need to subtract 8:00 hours or set the date manually and not convert as above
                    let newDate = { date: selectedDate, aria: ariaLabel, month: date.getMonth(), unix: selectedUnix, state: dateData.State, stateBy: dateData.StateBy, fullDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()) }
                    buildDates.push(newDate);
                        //setDates(prevDates => [...prevDates, newDate]);
                    buildLabels = [...buildLabels, {
                        date: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
                        text: (dateData.StateBy != "Admin" ? (dateData.State == "Booked" ? dateData.State : dateData.StateBy) : ""),
                        textColor: '#1e1e1ecc'
                    }]
                }
            } else {
                //due to how the time is save we need to subtract 8:00 hours or set the date manually and not convert as above
                let newDate = { date: selectedDate, aria: ariaLabel, month: date.getMonth(), unix: selectedUnix, state: dateData.State, stateBy: dateData.StateBy, fullDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()) }
                buildDates.push(newDate);
                        //setDates(prevDates => [...prevDates, newDate]);

                buildLabels = [...buildLabels, {
                    date: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
                    text: (dateData.StateBy != "Admin" ? (dateData.State == "Booked" ? dateData.State : dateData.StateBy) : ""),
                    textColor: '#1e1e1ecc'
                }]
            }
            ////due to how the time is save we need to subtract 8:00 hours or set the date manually and not convert as above
            //let newDate = { date: selectedDate, aria: ariaLabel, month: date.getMonth(), unix: selectedUnix, state: dateData.State, stateBy: dateData.StateBy, fullDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()) }
            //setDates(prevDates => [...prevDates, newDate]);
        })
        setLabels(buildLabels);
        setDates(buildDates);
        //setLoadData(false);
        return;
    }

    const getiCalFeeds = (listingID) => {
        ListingDetail.getICalEntriesByListingID(listingID).then(result => {
            var tempFeeds = [];
            if (result && result.data && result.data !== "no icals found") {
                result.data.map(function (item, i) {
                    tempFeeds.push({
                        name: item.Name,
                        url: item.URL,
                        id: item.CalendarID,
                    })
                })
                setFeeds(tempFeeds);
            }
            setShowLoading('none');
        });
        
    }

    const copyURL = () => {

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(downloadlink);

        /* Alert the copied text */
        setShowCopyAlert('block');
    }

    const showExportModalClick = () => {
        setdownloadlink(getDownloadLink(listingUUID));
        setShowExportModal(true);
    }

    const hideExportModal = () => {
        setShowExportModal(false);
        setShowCopyAlert('none');
    }

    const showImportModalClick = () => {
        setShowImportModal(true);
    }

    const hideImportModal = () => {
        setShowImportModal(false);
        setShowSaveAlert('none');
    }

    const saveFeed = () => {

        if (getvalid()) {

            setSaveDisabled(true);
            setShowLoading('block');

            ListingDetail.createICalEntry(listingUUID, feedname, feedURL).then(result => {

                //get feed name and url
                setiCalState(false);
                setShowSaveAlert('block');

                //clear the fields
                document.getElementById('feedname').value = "";
                document.getElementById('feedurl').value = "";
                setInput([]);

                setSaveDisabled(false);

                getiCalFeeds(listingUUID);
            });

        }
    }

    const syncFeeds = () => {
        setSyncFeedsDisabled(true);
        setShowSyncLoading('block');

        ListingDetail.SyncICalendars(listingUUID).then(result => {
            ListingDetail.getCalendarEntriesByListingID(listingID).then(result => {
                const tempReset = dates;

                if (result && typeof (result.data) != 'string') {
                    //clear the current dates from the calendar
                    dates.map((dateData, index) => {
                        //create the aria-label for page change function
                        var elements = document.querySelectorAll('[aria-label="' + dateData.aria + '"]');
                        if (elements) {
                            elements.forEach(function (element) {
                                element.style.backgroundColor = '#ccebcc';
                            });
                        }
                    })

                    buildCalendarDates(result.data);
                    
                    //var tempDates = [];
                    //var buildLabels = [];

                    
                    //result.data.map((dateData, index) => {

                    //    var date = new Date(dateData.Date);
                    //    //convert the date
                    //    var selectedDate = ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + (date.getDate())).slice(-2) + '/' + (date.getFullYear());
                    //    var selectedUnix = new Date(selectedDate).getTime();

                    //    //create the aria-label for page change function
                    //    var ariaLabel = date.toLocaleString("default", { weekday: "long" }) + ', ' + date.toLocaleString("default", { month: "long" }) + ' ' + date.getDate();

                    //    buildLabels = [...buildLabels, {
                    //        date: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
                    //        text: (dateData.StateBy != "Admin" ? (dateData.State == "Booked" ? dateData.State : dateData.StateBy) : ""),
                    //        textColor: '#1e1e1ecc'
                    //    }]

                    //    //due to how the time is save we need to subtract 8:00 hours or set the date manually and not convert as above
                    //    //let newDate = { date: selectedDate, aria: ariaLabel, month: date.getMonth(), unix: selectedUnix, state: dateData.State, stateBy: dateData.StateBy, fullDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()) }
                    //    //setDates(prevDates => [...prevDates, newDate]);

                    //    let newDate = { date: selectedDate, aria: ariaLabel, month: date.getMonth(), unix: selectedUnix, state: dateData.State, stateBy: dateData.StateBy }
                    //    tempDates.push(newDate);
                    //})
                    //setShowLoading('none');

                    //tempReset.map((dateData, index) => {
                    //    var elements = document.querySelectorAll('[aria-label="' + dateData.aria + '"]');
                    //    if (elements) {
                    //        elements.forEach(function (element) {
                    //            element.style.backgroundColor = 'white';
                    //        });
                    //    }
                    //})
                    //setDates(tempDates);
                    //setLabels(buildLabels);
                    setPushState(false);

                } else {
                    setShowLoading('none');
                    tempReset.map((dateData, index) => {
                        var elements = document.querySelectorAll('[aria-label="' + dateData.aria + '"]');
                        if (elements) {
                            elements.forEach(function (element) {
                                element.style.backgroundColor = 'white';
                            });
                        }
                    })
                    setDates([]);
                }

                setShowSyncLoading('none');
                setSyncFeedsDisabled(false);
            })
        });

    }

    const deleteFeed = (event) => {
        setShowSaveAlert('none');
        setShowLoading('block');
        ListingDetail.deleteICalEntry(event).then(result => {
            //check if feeds is greater than 1
            if (feeds.length > 1) {
                getiCalFeeds(listingUUID);
            } else {
                setFeeds([]);
                getiCalFeeds(listingUUID);
            }
            setShowLoading('none');
        });
    }

    const onCellClick = useCallback((event) => {

        //convert the date
        var selectedDate = ('0' + (event.date.getMonth() + 1)).slice(-2) + '/' + ('0' + (event.date.getDate())).slice(-2) + '/' + (event.date.getFullYear());// '2022-06-16T00:00';
        //get the date from selected date to remove the timezone
        var date = new Date(event.date);
        var selectedUnix = date.getTime();

        //check if date is in dates
        var objectFound = dates.find(obj => {
            return (obj.fullDate && obj.fullDate.getTime() === date.getTime());
        })

        var processSelection = true;

        if (objectFound) {
            //check the state, if Blocked then allow else dont
            if ((objectFound.stateBy == "" || objectFound.stateBy == "Admin")) {
                processSelection = true;
            } else {
                processSelection = false;
            }
        }

        if (processSelection) {
            //create the aria-label for page change function
            var ariaLabel = event.date.toLocaleString("default", { weekday: "long" }) + ', ' + event.date.toLocaleString("default", { month: "long" }) + ' ' + event.date.getDate();

            setPushState(false);

            if (event.target.style.backgroundColor === "white") {
                var elements = document.querySelectorAll('[aria-label="' + ariaLabel + '"]');
                if (elements) {
                    elements.forEach(function (element) {
                        element.style.backgroundColor = '#ccebff';
                    });
                }

                let newDate = { date: selectedDate, aria: ariaLabel, month: event.date.getMonth(), unix: event.date.getTime() }
                setDates(prevDates => [...prevDates, newDate]);
            } else if (event.target.style.backgroundColor === "") {

                var elements = document.querySelectorAll('[aria-label="' + ariaLabel + '"]');
                if (elements) {
                    elements.forEach(function (element) {
                        element.style.backgroundColor = '#ccebff';
                    });
                }

                let newDate = { date: selectedDate, aria: ariaLabel, month: event.date.getMonth(), unix: event.date.getTime() }
                setDates(prevDates => [...prevDates, newDate]);
            } else if (event.target.style.backgroundColor === "rgb(204, 235, 255)") {
                var elements = document.querySelectorAll('[aria-label="' + ariaLabel + '"]');
                if (elements) {
                    elements.forEach(function (element) {
                        element.style.backgroundColor = 'white';
                    });
                }

                let newDates = dates.filter(function (date) { return date.unix !== selectedUnix })
                setDates(newDates);
            }
        }
    });

    const onTextChange = (event) => {
        setShowSaveAlert('none');

        let newinput = input;

        if (event.target.name == "feedname") {
            //setListingType(event.target.value);
            setFeedname(event.target.value)
            newinput[event.target.name] = event.target.value;
        }

        if (event.target.name == "feedurl") {
            //setListingType(event.target.value);
            setFeedURL(event.target.value)
            newinput[event.target.name] = event.target.value;
        }

        //newinput[event.target.name] = event.target.value;

        setInput(newinput)

        if (event.target.value) {
            //remoev the errors fom array state
            let newerrors = {};
            let newshowErrors = {};

            Object.entries(errors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(showErrors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newshowErrors[error[0]] = error[1];
                }
            })

            setErrors(newerrors);
            setShowErrors(newshowErrors);

        }
    }

    const getvalid = () => {
        let newerrors = {};
        let newshowErrors = {};
        let isValid = true;

        if (!input["feedname"]) {
            isValid = false;
            newerrors["feedname"] = "Please enter a feed name";
            newshowErrors["feedname"] = true;
        }

        if (!input["feedurl"]) {
            isValid = false;
            newerrors["feedurl"] = "Please enter a feed url";
            newshowErrors["feedurl"] = true;
        }

        setErrors(newerrors);
        setShowErrors(newshowErrors);

        return isValid;
    }

    //this function is required to colour any pre selected dates on page change
    const onPageChange = useCallback((event) => {
        const sorted = dates.sort((a, b) => a.unix - b.unix);
        sorted.forEach(function (date) {

            var elements = document.querySelectorAll('[aria-label="' + date.aria + '"]');
            if (elements) {
                elements.forEach(function (element) {
                    if (!date.stateBy || date.stateBy == "" || date.stateBy == "Admin") {
                        element.style.backgroundColor = '#ccebff';
                    } else {
                        if (date.state == "Booked") {
                            element.style.backgroundColor = '#e96852';
                        } else {
                            //check if background is already set to booked and dont change
                            //if (element.style.backgroundColor != 'rgb(253, 189, 60)') {
                                element.style.backgroundColor = '#fdbd3c';
                            //} else {
                            //    //alert('found');
                            //    //console.log("found: " + JSON.stringify(date));
                            //}
                        }
                    }
                });
            }
        });

    });

    const onPageLoadingSingle = useCallback((event) => {
    });

    useEffect(() => {
        if (!datesPushed) {
            setBlockedDates(dates);
            setPushState(true);
        }

        if (!iCalPushed) {
            //clear the values
            setFeedname('');
            setFeedURL('');
            setiCalState(true);
        }

        if (!getFeeds) {
            getiCalFeeds(listingUUID);
            setGetFeeds(true);
        }

        if (listingData && loadData) {
            buildCalendarDates(listingData);
            
            setPushState(false);
            setLoadData(false);
        }
    })

    return (
        <div style={{ backgroundColor: '#f7f8f9' }}>
            <Card>
                <Card.Body>
                    <Form>
                        <Row className="mb-3" >
                            <Col sm="6">
                                <h5>Import / Export Calendar</h5>
                                <div>
                                    {/*<Button onClick={importCalendar}>import Cal</Button>*/}
                                    <Button onClick={showImportModalClick} >Import Calendar</Button>
                                    <Button onClick={showExportModalClick} >Export Calendar</Button>
                                    <Button onClick={syncFeeds} disabled={syncFeedsDisabled} >Sync Calendar</Button>
                                    <div style={{ display: showSyncLoading }}>
                                        {/*<div class="loading-vertical">*/}
                                        <Grid
                                                color="#00BFFF"
                                                height={40}
                                                width={40}
                                                timeout={0}
                                            />
                                        {/*</div>*/}
                                    </div>
                                    <br />
                                    All linked calendars will need to conform to standard ics format <br />
                                    After adding in your linked calendar please click the sync button to add to your calendar, this may take a couple of minutes
                                </div>
                            </Col>
                            <Col sm="6">
                                <div style={{ right: "50px", position: "absolute", textAlign: "right" }}>
                                    Booked: <FaSquareFull style={{ color: "#e96852"}} /> <br />
                                    Blocked by Synced Calendar: <FaSquareFull style={{ color: "#fdbd3c" }} /> <br/>
                                    User Blocked: <FaSquareFull style={{ color: "#ccebff" }} />
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3 listing">
                            <Datepicker
                                theme="material"
                                themeVariant='light'
                                display="inline"
                                controls={['calendar']}
                                select="single"
                                min={min}
                                max={max}
                                rangeHighlight={false}
                                showRangeLabels={false}
                                pages="1"
                                colors={colors}
                                onCellClick={onCellClick}
                                onPageLoaded={onPageChange}
                                onPageLoading={onPageLoadingSingle}
                                labels={labels}
                            />
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            <Modal show={showExportModal} size="md" centered>
                <Modal.Body>
                    <div style={{ display: showCopyAlert, width: '100%' }}>
                        <Alert variant="success" >
                            URL Copied to Clipboard
                        </Alert>
                    </div>
                    <div style={{ wordWrap: 'break-word' }}>
                        <h4>URL:</h4>
                        {downloadlink}
                    </div>
                    <hr />
                    <Button variant="success" onClick={copyURL} style={{ width: "100%" }}>Copy URL</Button>
                    <br />
                    <br />
                    <Button variant="secondary" onClick={hideExportModal} style={{ width: "100%" }}>Close</Button>
                </Modal.Body>
            </Modal>
            <Modal show={showImportModal} size="md" centered>
                <Modal.Header>
                    <Modal.Title>Import iCal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: showSaveAlert, width: '100%' }}>
                        <Alert variant="success" >
                            iCal Saved
                        </Alert>
                    </div>
                    {feeds.map((feed, index) =>
                        <Row className="align-items-center">
                            <Col sm='4'> {feed.name} </Col>
                            <Col sm='7' style={{ textOverflow: 'ellipsis', maxWidth: '95%', overflow: 'hidden', whiteSpace: 'nowrap' }}> {feed.url} </Col>
                            <Col sm='1'><Button variant='outline-danger' onClick={event => deleteFeed(feed.id)} ><FaTrashAlt /></Button></Col>
                        </Row>
                    )}
                    <hr />
                    <b>Feed Name</b>
                    <Input className="mbsc-remove-margins" type="text" placeholder="Enter the feed name" name="feedname" id="feedname" errorMessage={errors.feedname} error={showErrors.feedname} onChange={onTextChange} />
                    <b>Feed URL</b>
                    <Input className="mbsc-remove-margins" type="text" placeholder="Enter the feed url" name="feedurl" id="feedurl" errorMessage={errors.feedurl} error={showErrors.feedurl} onChange={onTextChange} />
                    <hr />
                    <div style={{ display: showLoading }}>
                        <div class="loading-vertical">
                            <Grid
                                color="#00BFFF"
                                height={100}
                                width={100}
                                timeout={0}
                            />
                        </div>
                    </div>
                    <Button variant="success" disabled={saveDisabled} onClick={saveFeed} style={{ width: "100%" }}>Save Feed</Button>
                    <br />
                    <br />
                    <Button variant="secondary" onClick={hideImportModal} style={{ width: "100%" }}>Close</Button>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Calendar