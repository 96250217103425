import React, { useState, useEffect } from 'react';

import Listing from './../functions/Listing';

const ListingThumb = (props) => {
    const [image, setImage] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {

        if (props.id && !loaded) {
            //load the primary image
            Listing.GetListingPrimaryImageByID(props.id).then(result => {
                if (result && typeof (result.data) != 'string') {
                    setImage(result.data.PrimaryImage);
                }
            })
            setLoaded(true);
        }
    })

    return (
        <div>
            <img className="search-img" alt="Listing Primary Image" height="100px" src={image ? "data:image/png;base64," + image : "logo.png"} width="100%" style={{ objectFit: "cover" }}></img>
        </div>
    )
}

export default ListingThumb;