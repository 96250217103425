import PromoService from "../services/PromoService";

/**
 * global functions to act on user api actions
 */
const Promo = {

    async getPromoByID(id) {
        const result = await PromoService.getPromoByID(id);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async getPromos() {
        const result = await PromoService.getPromos();

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async getPromosDetailByKey(key, param) {
        const result = await PromoService.getPromosDetailByKey(key, param);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async getPromosPublished(limit) {
        const result = await PromoService.getPromosPublished(limit);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async getPromosDetailByUser(uuid) {
        const result = await PromoService.getPromosDetailByUser(uuid);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async createPromo(userID, title, code, start, end, type, amount, hosts, users, discounts, states, listings) {

        const promo = new FormData();
        promo.append("Title", title);
        promo.append("Code", code);
        promo.append("Start", start);
        promo.append("End", end);
        promo.append("Type", type);
        promo.append("Amount", amount);

        promo.append("CreatedBy", userID);
        promo.append("Status", "Pending");

        for (const host of hosts) {
            promo.append("Hosts", JSON.stringify(host));
        }

        for (const user of users) {
            promo.append("Users", JSON.stringify(user));
        }

        for (const discount of discounts) {
            promo.append("Discounts", JSON.stringify(discount));
        }

        for (const state of states) {
            promo.append("States", JSON.stringify(state));
        }

        for (const listing of listings) {
            promo.append("Listings", JSON.stringify(listing));
        }

        // send it to the promo service
        const result = await PromoService.createPromo(promo);

        if (result.data.length !== 0) {
            return result;
        }

        return false;

    },

    async updatePromo(uuid, userID, title, code, start, end, type, amount, hosts, users, discounts, states, listings) {

        const promo = new FormData();
        promo.append("UUID", uuid);
        promo.append("Title", title);
        promo.append("Code", code);
        promo.append("Start", start);
        promo.append("End", end);
        promo.append("Type", type);
        promo.append("Amount", amount);

        promo.append("CreatedBy", userID);
        promo.append("Status", "Pending");

        for (const host of hosts) {
            promo.append("Hosts", JSON.stringify(host));
        }

        for (const user of users) {
            promo.append("Users", JSON.stringify(user));
        }

        for (const discount of discounts) {
            promo.append("Discounts", JSON.stringify(discount));
        }

        for (const state of states) {
            promo.append("States", JSON.stringify(state));
        }

        for (const listing of listings) {
            promo.append("Listings", JSON.stringify(listing));
        }

        // send it to the promo service
        const result = await PromoService.updatePromo(uuid, promo);

        if (result.data.length !== 0) {
            return result;
        }

        return false;

    },

    /**
     * delete the image assigned to the user profile both from local storage and the database
     * @param {string} id
     */
    async deletePromo(id) {
        const result = await PromoService.deletePromo(id);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async updatePromoField(uuid, key, value) {
        const result = await PromoService.updatePromoField(uuid, key, value);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    
}

export default Promo