import axios from 'axios';

import { BASE_URL } from './GlobalDefinitions';
import { AUTH, METHODS_DELETE, METHODS_GET, METHODS_POST, METHODS_PUT, ORIGIN } from './Headers';

const CCFEE_API_URL = "/ccfee";

class CreditCardFeeService {
    getCCFees() {
        return axios.get(BASE_URL + CCFEE_API_URL, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }
    
    createCCFee(scheme, amount, domestic) {
        return axios.post(BASE_URL + CCFEE_API_URL, { Scheme: scheme, Amount: amount, Domestic: domestic }, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    updateCCFee(uuid, scheme, amount, domestic) {
        return axios.put(BASE_URL + CCFEE_API_URL, { Scheme: scheme, Amount: amount, Domestic: domestic }, {
            params: {
                Key: "UUID",
                Param: uuid
            },
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    deleteCCFee(uuid) {
        return axios.delete(BASE_URL + CCFEE_API_URL, {
            params: {
                Key: "UUID",
                Param: uuid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }
}

export default new CreditCardFeeService()