import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import SideNav from "./../modals/SideNav";

export function withRouter(Children) {
    return (props) => {

        const match = { params: useParams() };
        const location = { location: useLocation() };

        return <Children {...props} match={match.params} location={location.location} />
    }
}

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <div style={{ paddingTop: "60px" }}>
                <Row>
                    <Col className="d-none d-md-block" md="3" lg="2" xl="1">
                        <SideNav
                            loggedIn={this.props.location.state.loggedIn}
                            loginType={this.props.location.state.loginType}
                            email={this.props.location.state.email}
                            activeTab='dashboard'
                        />
                    </Col>
                    <Col md="9" lg="10" xl="11">
                        <div style={{ width: '100%' }}>
                            <h1>Dashboard</h1>
                            <hr />
                        </div>

                        Content Here

                    </Col>
                </Row>
            </div>
        )
    }
}

export default withRouter(Dashboard)