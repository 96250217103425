import React, { useRef, useEffect, useState } from 'react';
import { Form, Card, Row, Col, Alert } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';

import Listing from './../../functions/Listing';

import "./../../styles/information.css"

import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { Input, Dropdown, RadioGroup, Radio } from '@mobiscroll/react';

function Information({ changeDescription, setListingType, isValid, listingData}) {
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState({});
    const [pageValid, setPageValid] = useState('none');
    const [infoDescription, setInfoDescription] = useState(true);
    const [type, setType] = useState(true);

    const [loadData, setLoadData] = useState(true);

    //const parseEditorData = (content, editor) => {

    //}

    const handleDescriptionChange = (event) => {
        changeDescription(event);
    }

    const handleListingTypeSelect = (event) => {
        setListingType(event.target.value);
    }

    const onTextChange = (event) => {

        let newinput = input;

        if (event.target.name == "bedType") {
            setListingType(event.target.value);
        }
        newinput[event.target.name] = event.target.value;

        setInput(newinput)

        if (event.target.value) {
            //remoev the errors fom array state
            let newerrors = {};
            let newshowErrors = {};

            Object.entries(errors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(showErrors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newshowErrors[error[0]] = error[1];
                }
            })

            setErrors(newerrors);
            setShowErrors(newshowErrors);

            setPageValid('none');
        }
    }

    const onTextSet = (event) => {
        let newinput = input;

        if (event.name == "bedType") {
            setListingType(event.value);
        }

        newinput[event.name] = event.value;

        setInput(newinput)

        if (event.value) {
            //remoev the errors fom array state
            let newerrors = {};
            let newshowErrors = {};

            Object.entries(errors).map((error, index) => {
                if (error[0] !== event.name) {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(showErrors).map((error, index) => {
                if (error[0] !== event.name) {
                    newshowErrors[error[0]] = error[1];
                }
            })

            setErrors(newerrors);
            setShowErrors(newshowErrors);

            setPageValid('none');
        }
    }

    const getvalid = () => {
        //alert('valid');

        //let newinput = input;
        let newerrors = {};
        let newshowErrors = {};
        let isValid = true;

        if (!input["title"]) {
            //alert('no title');
            isValid = false;
            newerrors["title"] = "Please enter title for listing";
            newshowErrors["title"] = true;
        }

        if (!input["bedType"]) {
            isValid = false;
            newerrors["bedType"] = "Please enter listing type";
            newshowErrors["bedType"] = true;
        }

        if (!input["informationnumberofbedrooms"]) {
            isValid = false;
            newerrors["informationnumberofbedrooms"] = "Please enter number of bedrooms";
            newshowErrors["informationnumberofbedrooms"] = true;
        }

        setErrors(newerrors);
        setShowErrors(newshowErrors);
        if (isValid) {
            setPageValid('none');
        } else {
            setPageValid('block');
        }

        return isValid;
    }

    useEffect(() => {

        isValid.current = getvalid;
        if (loadData && listingData) {

            document.getElementById('informationtitle').value = listingData.Title;
            document.getElementById('informationnumberofbedrooms').value = listingData.NumberOfBedrooms;
            document.getElementById('informationnumberofbeds').value = listingData.NumberOfBeds;
            document.getElementById('informationnumberofbathroosm').value = listingData.NumberOfBathrooms;
            document.getElementById('bedType').value = listingData.TypeOfListing;

            onTextSet(document.getElementById('informationtitle'));
            onTextSet(document.getElementById('bedType'));
            onTextSet(document.getElementById('informationnumberofbedrooms'));



            if (listingData.Kind == "Private Room") {
                setType(false);
            } else {
                setType(true);
            }

            setInfoDescription(listingData.Description);

            getvalid();
            setLoadData(false);
        }
        //return () => {
        //    alert('leaving');
        //}
    }, [])

    return (
        <Card style={{ margin: "10px" }}>
            
            <Card.Body>
                    <Row>
                        <Col sm={12} md={6}>
                        <Row>
                            <h4>Title*</h4>
                            <Input className="mbsc-remove-margins" type="text" name="title" id="informationtitle" errorMessage={errors.title} error={showErrors.title} onChange={onTextChange}/>
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <Row>
                            <h4>What kind of place do you want to list?</h4>

                            <RadioGroup name="group">
                                        <Radio label="Entire Place" id="entire-place" defaultChecked={type} value="Entire Place"  />
                                        <Radio label="Private Room" id="private-room" defaultChecked={!type} value="Private Room"  />
                            </RadioGroup>
                                </Row>
                                <Row>
                                    <h4>Type of Listing*</h4>

                                    <Dropdown className="mbsc-remove-margins" name="bedType" id="bedType" error={showErrors.bedType} errorMessage={errors.bedType} onChange={onTextChange} >
                                        <option value="" selected>Select Listing Type</option>
                                        <option value="Apartment">Apartment</option>
                                        <option value="Bed & Breakfast">Bed & Breakfast</option>
                                        <option value="Boat">Boat</option>
                                        <option value="Cottage/Chalet/Villa">Cottage/Chalet/Villa</option>
                                        <option value="Farm Stay">Farm Stay</option>
                                        <option value="Holiday Home">Holiday Home</option>
                                        <option value="Room">Room</option>
                                        <option value="Studio">Studio</option>
                                        <option value="Glamping">Glamping</option>
                                        <option value="Other">Other</option>
                                    </Dropdown>
                                </Row>
                            </Col>
                            <Col sm={12} md={6}>
                            
                            <Row>
                                <h4>Number of bedrooms*</h4>
                                    <Input className="mbsc-remove-margins" placeholder="Enter number of bedrooms" type="number" id="informationnumberofbedrooms" name="informationnumberofbedrooms" errorMessage={errors.informationnumberofbedrooms} error={showErrors.informationnumberofbedrooms} onChange={onTextChange}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key) || event.target.value.length > 1) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                            </Row>
                            <Row>
                                <h4>Number of beds</h4>
                                    <Input className="mbsc-remove-margins" placeholder="Enter number of beds" type="number" id="informationnumberofbeds" name="informationnumberofbeds"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key) || event.target.value.length > 1) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                            </Row>
                            <Row>
                                <h4>Number of bathrooms</h4>
                                    <Input className="mbsc-remove-margins" placeholder="Enter number of bathrooms" type="number" id="informationnumberofbathroosm" name="informationnumberofbathroosm"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key) || event.target.value.length > 1) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </Row>
                            </Col>
                        </Row>
                        </Col>
                        <Col sm={12} md={6} RowSpan={2}>
                            <Row>
                            <h4>Description</h4>
                                <Editor
                                    onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={infoDescription}
                                    init={{
                                        height: 350,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar: 'undo redo | formatselect | ' +
                                            'bold italic backcolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    id="informationdescription"
                                    onEditorChange={event => handleDescriptionChange(event)}
                                />
                            </Row>
                        </Col>
                    </Row>
                    
            </Card.Body>
            <div style={{ display: pageValid, width: '100%' }}>
                <Alert variant="danger" >
                    Unfortunately there are errors on the page!
                </Alert>
            </div>
            </Card>
        )
    }


export default Information