import React from "react";

import StarRating from './../items/starRating';

import "./../styles/review.css";

function formatDate(input) {
    var date = new Date(input);
    return (('0' + (date.getDate())).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()).toString();
}

function ReviewItem(props) {
    return (

        <div className="testimonial-item">

            <div className="review-name">
                <b>Created/Publshed: {formatDate(props.created)}</b>&nbsp;&nbsp;&nbsp;
            </div>
            {props.rating != 0 ?
                <StarRating
                    rating={props.rating}
                /> : ""}
            <div className="review-name">
                <h5>{props.title}</h5>
            </div>
            <div className="review-comment">
                {props.review}
            </div>
            {/*<div className="message-comment">*/}
            {/*    {props.message}*/}
            {/*</div>*/}
            
        </div>
    );
}

export default ReviewItem