import FavouriteService from "../services/FavouriteService";

const Favourites = {
    async getFavourites() {
        const result = await FavouriteService.getFavourites();

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getFavouritesByUser(userid) {
        const result = await FavouriteService.getFavouritesByUser(userid);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getFavouritesByListing(listingid) {
        const result = await FavouriteService.getFavouritesByListing(listingid);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getFavouritesByDate(date) {
        const result = await FavouriteService.getFavouritesByDate(date);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async createFavourite(userid, listingid) {
        const result = await FavouriteService.createFavourite(userid, listingid);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async deleteFavourite(favouriteID) {
        const result = await FavouriteService.deleteFavourite(favouriteID);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },
}

export default Favourites