import React, { Component } from "react";
import { useParams, useLocation } from "react-router-dom";
import { FaPlus } from 'react-icons/fa';
import { FaMinus } from 'react-icons/fa';

import "./../styles/search.css";

/*import '@mobiscroll/react/dist/css/mobiscroll.min.css';*/
import { Input } from '@mobiscroll/react';
import { Next } from "react-bootstrap/esm/PageItem";

export function withRouter(Children) {
    return (props) => {

        const match = { params: useParams() };
        const location = { location: useLocation() };

        return <Children {...props} match={match.params} location={location.location} />
    }
}

class GuestSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showGuest: 'none',
            adultGuests: 0,
            childGuests: 0,
            totalGuests: '',
            petsYes: 'guest-adult-minus',
            petsNo: 'guest-adult-plus',
            maxGuests: (props.maxGuests ? props.maxGuests : 20),
            pets: '',
            guestsSet: false,
            petsSet: false
        }
    }

    checkGuests = event => {
        let { value, min, max } = event.target;

        //alert("min: " + min + " max: " + max + " val: " + value + " state: " + this.state.maxGuests);

        value = Math.max(Number(min), Math.min(Number(max), Number(value)));

        //alert(value);

        this.searchGuests.value = value;
        //set the adultGuest state
        this.setState({ adultGuests: value });
        this.props.onGuestChange(value);
    };

    setShowGuest = (value) => {
        this.setState({ showGuest: value });

        //confirm the adult label matches input - this is a clean way to override the clearing of inputs
        if (value === 'block') {
            this.setState({ adultGuests: (this.searchGuests.value ? this.searchGuests.value : 0) });
        }

        if (value === 'none') {
            
            //alert(this.state.adultGuests);
            this.props.onGuestChange(this.state.adultGuests);
            if (typeof this.props.onChange === 'function') {
                //alert('here');
                this.props.onChange();
            }
        }

    }

    setAdultGuest = (e, value) => {
        var temp = (+this.state.adultGuests + +value);
        //prevent this going below zero and over max guests
        if (temp > this.state.maxGuests) {
            //set temp to max
            temp = +this.state.maxGuests;
        }

        e.preventDefault();
        if (temp < 0) {
            this.setState({ adultGuests: 0 });
            this.searchGuests.value = (0 + this.state.childGuests);

            this.props.onGuestChange(0);
        } else {
            this.setState({ adultGuests: temp });
            this.searchGuests.value = (temp + this.state.childGuests);

            this.props.onGuestChange(temp);
        }
        //this.props.onGuestChange(this.state.adultGuests);
    }

    setChildGuest = (e, value) => {
        var temp = (this.state.childGuests + value);
        e.preventDefault();
        if (temp < 0) {
            this.setState({ childGuests: 0 });
            this.searchGuests.value = (0 + this.state.adultGuests);
        } else {
            this.setState({ childGuests: temp });
            this.searchGuests.value = (temp + this.state.adultGuests);
        }
    }

    setPets = (e, value) => {
        if (e) {
            e.preventDefault();
        }

        if (value > 0) {
            this.setState({ petsYes: 'guest-adult-minus' });
            this.setState({ petsNo: 'guest-adult-plus petSelected' });
            this.props.onPetsChange('no');
        } else {
            this.setState({ petsYes: 'guest-adult-minus petSelected' });
            this.setState({ petsNo: 'guest-adult-plus ' });
            this.props.onPetsChange('yes');
        }

        
        
    }

    componentDidMount() {
        //alert('guests item: ' + this.props.pets);
        if (this.props.guests) {
            this.setState({ adultGuests: this.props.guests });
            //this.searchGuests.value = this.props.guests;
        }

        if (this.props.pets == 'no') {
            this.setPets(null, 1);
        } else if (this.props.pets == 'yes') {
            this.setPets(null, 0);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.guests && nextProps.guests != this.state.adultGuests) {
            this.setState({ adultGuests: nextProps.guests });
            this.searchGuests.value = nextProps.guests;
        }

        if (nextProps.pets) {
            if (nextProps.pets === 'no') {
                this.setState({ petsYes: 'guest-adult-minus' });
                this.setState({ petsNo: 'guest-adult-plus petSelected' });
            } else if (nextProps.pets === 'yes') {
                this.setState({ petsYes: 'guest-adult-minus petSelected' });
                this.setState({ petsNo: 'guest-adult-plus ' });
            }
        }

        if (nextProps.maxGuests && nextProps.maxGuests != this.state.maxGuests) {
            this.setState({ maxGuests: nextProps.maxGuests });
        }
    }

    componentDidUpdate() {
        //set guests
        if (this.state.adultGuests != this.props.guests && this.props.guests && !this.state.guestsSet) {
            this.setState({
                adultGuests: this.props.guests,
                guestsSet: true
            });
            this.searchGuests.value = this.props.guests;
        }
        //set pets
        if (this.state.pets != this.props.pets && !this.state.petsSet) {
            this.setState({
                pets: this.props.pets,
                petsSet: true
            });

            if (this.props.pets == 'no') {
                this.setPets(null, 1);
            } else if (this.props.pets == 'yes') {
                this.setPets(null, 0);
            }
        }
    }

    render() {
        return (
            <div onFocus={() => this.setShowGuest('block')} onBlur={() => this.setShowGuest('none')} >
                {/*<input className="guest-input" type="number" ref={inputEl => (this.searchGuests = inputEl)} placeholder="Guests"  />*/}
                <Input label="Guest" inputStyle="box" labelStyle="floating" type="number" min="0" max={this.state.maxGuests} onChange={this.checkGuests} ref={inputEl => (this.searchGuests = inputEl)} placeholder="Guests" id="searchGuests" />
                <div className="guest-search" style={{ display: this.state.showGuest }} value="guest-search" >
                    <div className="guest-row">
                        <span className="guest-adult">{ this.state.adultGuests }</span>
                        <span className="guest-label">Guests</span>
                        <button onMouseDown={(e) => this.setAdultGuest(e, -1)} className="guest-adult-minus"><FaMinus /></button>
                        <button onMouseDown={(e) => this.setAdultGuest(e, 1)} className="guest-adult-plus"><FaPlus /></button>
                    </div>
                    {/*<div className="guest-row">*/}
                    {/*    <span className="guest-child">{this.state.childGuests}</span>*/}
                    {/*    <span className="guest-label">Children</span>*/}
                    {/*    <button onMouseDown={(e) => this.setChildGuest(e, -1)} className="guest-child-minus"><FaMinus /></button>*/}
                    {/*    <button onMouseDown={(e) => this.setChildGuest(e, 1)} className="guest-child-plus"><FaPlus /></button>*/}
                    {/*</div>*/}
                    <div className="guest-row">
                        <span className="guest-label">Pets</span>
                        <button onMouseDown={(e) => this.setPets(e, -1)} className={this.state.petsYes} > Yes</button>
                        <button onMouseDown={(e) => this.setPets(e, 1)} className={this.state.petsNo} >No</button>
                    </div>
                    <div className="guest-button">
                        <button className="btn btn-info" onClick={() => this.setShowGuest('none')}>Apply</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(GuestSearch)