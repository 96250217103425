import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Button, Modal, Form } from 'react-bootstrap';

import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { Input } from '@mobiscroll/react';

export function withRouter(Children) {
    return (props) => {

        //const match = { params: useParams() };
        //const location = { location: useLocation() };

        //return <Children {...props} match={match.params} location={location.location} />
        return <Children {...props} />
    }
}

class ResetPasswordModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            input: {},
            errors: {},
            showErrors: {},
            regOptions: []
        }

        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.validate()) {
            let input = this.state.input;

            let email = input["email"];

            this.props.forgotPasswordEmail(email) && this.props.forgotPasswordEmail();
            
        }
    }

    validate() {
        let input = this.state.input;
        let errors = {};
        let showErrors = {};
        let isValid = true;

        if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your email address.";
            showErrors["email"] = true;
        }

        this.setState({
            errors: errors,
            showErrors: showErrors
        });

        return isValid;
    }

    onTextChange = (event) => {

        let input = this.state.input;
        input[event.target.name] = event.target.value;

        this.setState({
            input
        });

        if (event.target.value) {
            //remoev the errors fom array state
            let errors = {};
            let showErrors = {};

            Object.entries(this.state.errors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    errors[error[0]] = error[1];
                }
            })
            Object.entries(this.state.showErrors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    showErrors[error[0]] = error[1];
                }
            })

            this.setState({
                errors: errors,
                showErrors: showErrors
            })
        }
    }

    dontHaveAccount = () => {
        this.props.dontHaveAccount && this.props.dontHaveAccount();
    }

    hidePasswordReset = (event) => {
        this.props.hidePasswordReset && this.props.hidePasswordReset(event);
    }

    render() {
        if (!this.props.showPasswordReset) {
            return null;
        }

        return (
            <Modal show={this.props.showPasswordReset} >
                <Modal.Header closeButton onClick={() => this.hidePasswordReset()}>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={this.handleSubmit}>
                        <Input inputStyle="box" labelStyle="floating" type="email" name="email" label="Email" errorMessage={this.state.errors.email} error={this.state.showErrors.email} onChange={this.onTextChange} />
                        <Button type="submit" variant="secondary" style={{ width: "100%" }}>Send Reset Email</Button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Form.Label className="text-center" style={{width: "100%"}} >Don't have an account? <a href="#" onClick={this.dontHaveAccount}>Register</a></Form.Label>
                </Modal.Footer>                    
            </Modal>
        )
    }
}

export default withRouter(ResetPasswordModal)