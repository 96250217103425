import React, { useState } from "react";
import { FaHeart } from 'react-icons/fa';

import "./../styles/starrating.css";

const StarRating = (props) => {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);

    const ratingUpdate = (index) => {
        setRating(index);

        props.onRatingChange(index);
    }

    return (
        (!props.rating) ?
            <div className="star-rating">
                {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                        <button
                            type="button"
                            key={index}
                            className={index <= (hover || rating) ? "staron" : "staroff"}
                            onClick={() => ratingUpdate(index)}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                        >
                            {/*<span className="star">&#9733;</span>*/}
                            <FaHeart style={{ fontSize: "30px" }} />
                        </button>
                    );
                })} Click to rate
            </div>
            :
            <div className="star-rating" style={{ marginTop: "5px", marginBottom: "5px" }}>
                {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                        <button
                            type="button"
                            key={index}
                            className={index <= (props.rating || rating) ? "staron" : "staroff"}
                        >
                            <FaHeart style={{ fontSize: "30px" }} />
                        </button>
                    );
                })}
            </div>

    );
};

export default StarRating;