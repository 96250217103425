import React, { useState, useEffect } from 'react';
import { Card, Button, Col, Row } from 'react-bootstrap';

import { Input, Dropdown } from '@mobiscroll/react';
/*import Select from "react-dropdown-select";*/
//import Select from 'react-select';
//import AsyncSelect from 'react-select/async';

import BedType from './bedTypeMulti';

//import Multiselect from 'multiselect-react-dropdown';

//const options = [
//    { value: 'Single', label: 'Single' },
//    { value: 'Double', label: 'Double' },
//    { value: 'Queen', label: 'Queen' },
//    { value: 'King', label: 'King' },
//    { value: 'Bunk', label: 'Bunk' },
//    { value: 'Foldout', label: 'Foldout' },
//    { value: 'Tribunk', label: 'Tribunk' },
//    { value: 'King Single', label: 'King Single' },
//]

//const options2 = [
//    { name: 'Single', id: 'Single' },
//    { name: 'Double', id: 'Double' },
//    { name: 'Queen', id: 'Queen' },
//    { name: 'King', id: 'King' },
//    { name: 'Bunk', id: 'Bunk' },
//    { name: 'Foldout', id: 'Foldout' },
//    { name: 'Tribunk', id: 'Tribunk' },
//    { name: 'King Single', id: 'King Single' },
//]

const Bedrooms = ({ setRoomsState, listingData})  => {
    const initialRooms = [{ roomName: "", numberOfGuests: "", numberOfBeds: "", bedType: "", defaultBedType: []}]
    const [rooms, setRooms] = useState(initialRooms);
    //const [rooms, setRooms] = useState([]);

    const [loadData, setLoadData] = useState(true);
    //const [selectedOption, setSelectedOption] = useState(null);

    const removeRoom = (index) => {
        //alert(index);

        console.log(JSON.stringify(rooms));

        const list = [...rooms];
        list.splice(index, 1);

        console.log(JSON.stringify(list));

        setRooms(list);
        setRoomsState(list);


        //setRooms([{ roomName: "removed", numberOfGuests: "1", numberOfBeds: "2", bedType: "", defaultBedType: "" }]);
        //setRoomsState(rooms);

        //this.forceUpdate();
    }

    //const defaultSelect = (items) => {
    //    console.log("this: " + JSON.stringify(items));
    //    return "<Select onChange = { options => bedTypeChange(options, index)} options = { options } isMulti placeholder = 'Please Select Bed Types for Room' closeMenuOnSelect = { false} defaultValue = {room.defaultBedType} />";
    //}

    const addRoom = () => {
        //alert(rooms.length);

        setRooms([...rooms, { roomName: "", numberOfGuests: "", numberOfBeds: "", bedType: "", defaultBedType: []}]);
        setRoomsState(rooms);
    }

    //const filterColors = (inputValue) => {
    //    return options.filter((i) =>
    //        i.label.toLowerCase().includes(inputValue.toLowerCase())
    //    );
    //};

    //const promiseOptions = (inputValue) =>
    //    new Promise < Options[] > ((resolve) => {
    //        setTimeout(() => {
    //            resolve(filterColors(inputValue));
    //        }, 1000);
    //    });

    //const bedTypeChange = (options, index) => {
    //    //alert('change');

    //    //loop through objects to get values only
    //    var bedTypes = [];
    //    options.map((option, index) => {
    //        bedTypes.push(option.value);
    //    });

    //    const list = [...rooms];
    //    list[index]["bedType"] = bedTypes.toString().replace(/,/g, ", ");
    //    setRooms(list);
    //    setRoomsState(list);

    //}

    const bedTypeUpdate = (options, index) => {
        //console.log("set new value: " + index + " " + JSON.stringify(options));

        //console.log("rooms: " + JSON.stringify(rooms));

        var bedTypes = [];
        options.map((option, index) => {
            //console.log("value: " + option.value);
            bedTypes.push(option.value);
        });

        const list = [...rooms];
        list[index]["defaultBedType"] = options;
        list[index]["bedType"] = bedTypes.toString().replace(/,/g, ", ");

        //console.log("rooms: " + JSON.stringify(list));
        setRooms(list);
        setRoomsState(list);
    }

    //const multiBedChange = (selectedList, selectedItem, index) => {
    //    alert(JSON.stringify(selectedList));
    //    alert(JSON.stringify(selectedItem));
    //    alert(JSON.stringify(index));
    //}

    const handleChange = (event, index) => {
        
        const { name, value } = event.target;
        const list = [...rooms];
        list[index][name] = value;
        setRooms(list);
        setRoomsState(rooms);
    }

    useEffect(() => {

        //do a state update check based on passing in the new room set object

        if (loadData) {

            if (listingData) {
                var addRooms = [];// [{ roomName: "", numberOfGuests: "", numberOfBeds: "", bedType: "" }];
                //addRooms.push({ roomName: "", numberOfGuests: "", numberOfBeds: "", bedType: "" });
                listingData.map((room, index) => {
                    //console.log(JSON.stringify(room.BedType));
                    var bedTypes = room.BedType.split(',');

                    var defaultOptions = [];
                    bedTypes.map((bed, index) => {
                        //console.log(bed);
                        defaultOptions.push({ value: bed.replace(/ /g, ""), label: bed.replace(/ /g, "") });
                    })
                    //console.log(JSON.stringify(defaultOptions));
                    //room.BedType.map((option, index) => {
                    //    bedTypes.push(option.value);
                    //});

                    addRooms.push({ roomName: room.RoomName, numberOfGuests: room.NumberOfGuests, numberOfBeds: room.NumberOfBeds, bedType: bedTypes.toString().replace(/,/g, ", "), defaultBedType: defaultOptions });

                })
                //addRooms.push({ roomName: "", numberOfGuests: "", numberOfBeds: "", bedType: "" });
                setRooms(addRooms);

                //const list = addRooms;
                //list.splice(0, 1);
                //setRooms(list);
                                
                setRoomsState(addRooms);
                setLoadData(false);
            } 
        }
    })

    return (
        <div>
            {rooms.map((room, index) => {
                //alert("render: " + JSON.stringify(room.defaultBedType));
                //if (listingData && index == 0) {
                //    return "";
                //} else {
                    return (
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <h5>Bedroom Name</h5>
                                        <Input className="mbsc-remove-margins" type="text" id={index + ".roomName"} name="roomName" placeholder="eg. Master Bedroom" value={room.roomName} onChange={event => handleChange(event, index)} />
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <h5>Number Of Guests</h5>
                                        <Input className="mbsc-remove-margins" min="0" type="number" id="numberOfGuests" name="numberOfGuests" placeholder="Number of Guests for this Room" value={room.numberOfGuests} onChange={event => handleChange(event, index)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <h5>Number Of Beds *</h5>
                                        <Input className="mbsc-remove-margins" min="0" type="number" id="numberOfBeds" name="numberOfBeds" placeholder="Number of Beds" value={room.numberOfBeds} onChange={event => handleChange(event, index)} />
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <h5>Bed Type</h5>
                                        <BedType
                                            index={index}
                                            defaultBedType={room.defaultBedType}
                                            setBedType={bedTypeUpdate}
                                        />

                                        {/*{() => defaultSelect(room.defaultBedType)}*/}
                                        {/*<Select*/}
                                        {/*    //instanceId={room.selectID}*/}
                                        {/*    onChange={options => bedTypeChange(options, index)}*/}
                                        {/*    options={options}*/}
                                        {/*    isMulti*/}
                                        {/*    placeholder="Please Select Bed Types for Room"*/}
                                        {/*    closeMenuOnSelect={false}*/}
                                        {/*    //defaultValue={() => defaultSelect(room.defaultBedType)}*/}
                                        {/*    //defaultValue={room.defaultBedType}*/}
                                        {/*    getValue={() => room.defaultBedType}*/}
                                        {/*/>*/}
                                        {/*<AsyncSelect cacheOptions defaultOptions loadOptions={promiseOptions} />*/}
                                        {/*<Multiselect*/}
                                        {/*    id={room.selectID}*/}
                                        {/*    placeholder="Please Select Bed Types for Room"*/}
                                        {/*    onSelect={(selectedList, selectedItem) => multiBedChange(selectedList, selectedItem, index)}*/}
                                        {/*    options={options2}*/}
                                        {/*    displayValue="name"*/}
                                        {/*    selectedValues={room.defaultBedType}*/}
                                        {/*    style={{*/}
                                        {/*        chips: {*/}
                                        {/*            background: 'grey'*/}
                                        {/*        },*/}
                                        {/*        multiselectContainer: {*/}
                                        {/*            color: 'none'*/}
                                        {/*        },*/}
                                        {/*        searchBox: {*/}
                                        {/*            border: 'none',*/}
                                        {/*            'border-bottom': '1px solid blue',*/}
                                        {/*            'border-radius': '0px'*/}
                                        {/*        },*/}
                                        {/*        option: {*/}
                                        {/*            color: 'blue',*/}
                                        {/*            background: 'white',*/}
                                        {/*            width: '100%'*/}
                                        {/*        }*/}
                                        {/*    }}*/}
                                        {/*/>*/}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12}>
                                        <div style={{ display: "flex" }}>
                                            <Button variant="info" style={{ width: "150px", fontSize: "12px" }} onClick={() => removeRoom(index)} >Remove This Room</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    )
                //}
            })}
            <br />
            <div style={{display: "flex"}}>
                <Button variant="info" style={{marginLeft: "auto", width: "150px", fontSize: "12px"}} onClick={addRoom}>Add Another Room</Button>
            </div>
        </div>
    )
}

export default Bedrooms