import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from 'react-bootstrap';
import SideNav from "./../modals/SideNav";

import { FaTrashAlt, FaArrowRight } from 'react-icons/fa';

import StarRating from './../items/starRating';
import Collapsible from './../items/collapsible';
import ListingTitle from './../items/listingTitle';

import UserDetail from './../functions/User';
import ReviewsDetail from './../functions/Reviews';
import ListingsDetail from './../functions/Listing';

import DeleteReview from './../modals/DeleteReview';
import PublishReview from './../modals/PublishReview';


import { Grid } from "react-loader-spinner";

export function withRouter(Children) {
    return (props) => {
        return <Children {...props} location={props} />
    }
}

class Reviews extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false,
            listings: [],
            reviews: [],
            showLoading: true,
            isHost: false,
            showDeleteReview: false,
            showPublishReview: false,
            deleteReviewTitle: "",
            publishReviewTitle: "",
            deleteReviewID: 0,
            publishReviewID: 0,
            reload: false
        }
    }

    formatDate = (input) => {
        var date = new Date(input);
        return (('0' + (date.getDate())).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()).toString();
    }

    getStatus = (owner) => {
        if (owner.Hidden == true) {
            return <span className='label label-danger'>Hidden</span>;
        }
        if (owner.Draft == true) {
            return <span className='label label-info'>Draft</span>;
        }
        if (owner.Published == true) {
            return <span className='label label-primary'>Published</span>;
        } else {
            return <span className='label label-secondary'>Pending</span>;
        }
    }

    publishReview = (title, id) => {
        this.setState({
            showPublishReview: true,
            publishReviewTitle: title,
            publishReviewID: id
        })
    }

    publishClick = () => {
        this.setState({
            showPublishReview: false,
            showLoading: true
        })

        //to update the review we must get all the details first
        ReviewsDetail.getGuestReviews("ReviewID", this.state.publishReviewID).then(result => {
            //console.log(result.data[0]);
            ReviewsDetail.updateGuestReview(
                result.data[0].ReservationID,
                result.data[0].ListingID,
                result.data[0].Review,
                result.data[0].ParentReviewID,
                result.data[0].EmailSent,
                result.data[0].ReviewID,
                true).then(result => {
                    this.setState({
                        reload: true,
                        showLoading: false
                    })

                    //if admin then reload
                    if (this.props.location.state.loginType === 'admin') {
                        ReviewsDetail.getGuestReviews("All", "").then(result => {
                            //console.log(result);
                            if (result && result.data && result.data.length > 0 && typeof (result.data) != 'string') {
                                const sortReviews = result.data.sort((a, b) => (a.CreatedDate < b.CreatedDate) ? 1 : -1);
                                this.setState({ reviews: sortReviews });
                                this.setState({ showLoading: false });
                                this.setState({ isHost: false });
                            }
                        })

                    }
                })
        });

        
    }

    unpublishClick = () => {
        this.setState({
            showPublishReview: false,
            //reload: true
        })

        //to update the review we must get all the details first
        ReviewsDetail.getGuestReviews("ReviewID", this.state.publishReviewID).then(result => {
            console.log(result);
            ReviewsDetail.updateGuestReview(result.data[0].ReservationID, result.data[0].ListingID, result.data[0].Review, result.data[0].ParentReviewID, result.data[0].EmailSent, result.data[0].ReviewID, false);

            this.setState({
                reload: true
            })
        })
    }

    deleteReview = (title, id) => {
        this.setState({
            showDeleteReview: true,
            deleteReviewTitle: title,
            deleteReviewID: id
        })
    }

    deleteConfirmed = () => {
        this.setState({
            showDeleteReview: false,
            showLoading: true
        })

        ReviewsDetail.deleteGuestReview(this.state.deleteReviewID).then(result => {
            this.setState({
                reload: true,
                showLoading: false
            })

            //if admin then reload
            if (this.props.location.state.loginType === 'admin') {
                ReviewsDetail.getGuestReviews("All", "").then(result => {
                    //console.log(result);
                    if (result && result.data && result.data.length > 0 && typeof (result.data) != 'string') {
                        const sortReviews = result.data.sort((a, b) => (a.CreatedDate < b.CreatedDate) ? 1 : -1);
                        this.setState({ reviews: sortReviews });
                        this.setState({ showLoading: false });
                        this.setState({ isHost: false });
                    }
                })

            }
        })
    }

    hideDelete = () => {
        this.setState({
            showDeleteReview: false
        })
    }

    componentDidMount() {

        if (this.props.location.state.loginType === 'admin') {
            ReviewsDetail.getGuestReviews("All", "").then(result => {
                //console.log(result);
                if (result && result.data && result.data.length > 0 && typeof (result.data) != 'string') {
                    const sortReviews = result.data.sort((a, b) => (a.CreatedDate < b.CreatedDate) ? 1 : -1);
                    this.setState({ reviews: sortReviews });
                    this.setState({ showLoading: false });
                    this.setState({ isHost: false });
                } else {
                    this.setState({ showLoading: false });
                }
            })
            
        } else {
            UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {
                var customerID = result.data.UUID;
                //check if host or guest
                if (this.props.location.state.loginType === 'hosting') {
                    //host - get the host reviews done on guests
                    ListingsDetail.getListingByOwnerEmail(this.props.location.state.email).then(result => {
                        //setOwner(result.data.OwnerID);
                        //setListings(result.data.Listings);
                        this.setState({ listings: result.data.Listings });
                        this.setState({ showLoading: false });
                        this.setState({ isHost: true });
                        //setShowLoading('none');
                        //create a default list as this is required for filtering on text
                        //setDefaultListings(result.data.Listings);
                    });
                } else {
                    //guest - get the guest reviews done on listings
                    ReviewsDetail.getGuestReviews("UserID", customerID).then(result => {
                        //console.log(result);
                        if (result.data && result.data.length > 0 && typeof (result.data) != 'string') {
                            const sortReviews = result.data.sort((a, b) => (a.CreatedDate < b.CreatedDate) ? 1 : -1);
                            this.setState({ reviews: sortReviews });
                            this.setState({ showLoading: false });
                            this.setState({ isHost: false });
                        } else {
                            this.setState({ showLoading: false });
                        }
                    })
                }
                
            })
            
        }
    }

    render() {
        if (this.state.showLoading) {
            return (
                <div class="loading-overlay" >
                    <div class="loading-vertical">
                        <Grid
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={0}
                        />
                    </div>
                </div>
            )
        } else {

            return (
                <div style={{ paddingTop: "80px" }}>

                    <DeleteReview showDeleteReview={this.state.showDeleteReview} title={this.state.deleteReviewTitle} confirmed={this.deleteConfirmed} hide={this.hideDelete} />
                    <PublishReview showPublishReview={this.state.showPublishReview} title={this.state.publishReviewTitle} publish={this.publishClick} unpublish={this.unpublishClick} />

                    <Row>
                        <Col className="d-none d-lg-block" lg="2" xl="1">
                            <SideNav
                                loggedIn={this.props.location.state.loggedIn}
                                loginType={this.props.location.state.loginType}
                                email={this.props.location.state.email}
                                activeTab='reviews'
                            />
                        </Col>
                        <Col md="12" lg="10" xl="11">
                            <div style={{ width: '100%' }}>
                                <h1>Reviews</h1>
                                <hr />
                            </div>
                            
                            <Card>
                                <Card.Body style={{ paddingRight: "0px" }}>
                                    {this.state.isHost ?
                                        <div>
                                            <Row>
                                                <Col md="1"></Col>
                                                <Col md="1"><h5>No. Reviews</h5></Col>
                                                <Col md="1"><h5>Thumbnail</h5></Col>
                                                <Col md="2"><h5>Title</h5></Col>
                                                <Col md="4"><h5>Address</h5></Col>
                                                <Col md="1"><h5>Type</h5></Col>
                                                <Col md="1"><h5>Status</h5></Col>
                                                <Col md="1"></Col>
                                            </Row>
                                            {
                                                this.state.listings && this.state.listings.length > 0 ?
                                                    this.state.listings.map((listing, index) =>
                                                        <Collapsible listing={listing} loginType={this.props.location.state.loginType} deleteReview={this.deleteReview} publishReview={this.publishReview} reloadReviews={this.state.reload} />
                                                    ) :
                                                    <Row>
                                                        <h4>You currently have no listings</h4>
                                                    </Row>
                                            }
                                        </div>
                                        :
                                        <div>
                                            <Row>
                                                <Row >
                                                    <Col xl="1" lg="1" md="1" sm="1" className="d-none d-sm-block"><h5>Created</h5></Col>
                                                    <Col xl="1" lg="2" md="2" sm="2" className="d-none d-sm-block"><h5>Listing</h5></Col>
                                                    <Col xl="2" lg="2" md="2" sm="2" className="d-none d-sm-block"><h5>Rating</h5></Col>
                                                    <Col xl="2" lg="2" md="2" sm="2" className="d-none d-sm-block"><h5>Title</h5></Col>
                                                    <Col xl="4" lg="3" md="2" sm="2" className="d-none d-sm-block"><h5>Review</h5></Col>
                                                    <Col xl="1" lg="1" md="1" sm="1" className="d-none d-sm-block"><h5>Published</h5></Col>
                                                    <Col xl="1" lg="1" md="2" sm="2" className="d-none d-sm-block"><h5>Action</h5></Col>

                                                </Row>
                                                {
                                                    this.state.reviews && this.state.reviews.length > 0 ?
                                                        this.state.reviews.map((review, index) =>
                                                            <div style={{ width: '100%' }}>

                                                                <div >
                                                                    <Row md="12" style={{ height: '50px', borderBottom: '1px solid #808080', paddingTop: '6px' }} >
                                                                        <Col xl="1" lg="1" md="1" sm="1" xs="1">
                                                                            {this.formatDate(review.CreatedDate)}
                                                                        </Col>
                                                                        <Col xl="1" lg="2" md="2" sm="2" xs="2">
                                                                            <ListingTitle id={review.ListingID} />
                                                                        </Col>
                                                                        <Col xl="2" lg="2" md="2" sm="2" xs="2" style={{ textOverflow: 'ellipsis', maxWidth: '95%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                                            <StarRating
                                                                                rating={review.Rating}
                                                                            />
                                                                        </Col>
                                                                        <Col xl="2" lg="2" md="2" sm="2" xs="2" style={{ textOverflow: 'ellipsis', maxWidth: '95%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                                            {review.Title}
                                                                        </Col>
                                                                        <Col xl="4" lg="3" md="2" sm="2" xs="2" style={{ textOverflow: 'ellipsis', maxWidth: '95%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                                            {review.Review}
                                                                        </Col>
                                                                        <Col xl="1" lg="1" md="1" sm="1" xs="1" style={{ textOverflow: 'ellipsis', maxWidth: '95%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                                            {review.Published ? "Yes" : "No"}
                                                                        </Col>
                                                                        <Col xl="1" lg="1" md="2" sm="2" xs="2">
                                                                            <Link to={{
                                                                                pathname: "/review/" + review.ReviewID + "/0/0/",
                                                                            }}><Button>View</Button>
                                                                            </Link>
                                                                            {this.props.location.state.loginType === 'admin' && !review.Published ? <Button variant="link" title="Delete Review" className="icon-button" onClick={() => this.deleteReview(review.Title, review.ReviewID)} ><FaTrashAlt id="delete" size={28} color="lightblue" /></Button> : null}
                                                                            {this.props.location.state.loginType === 'admin' && !review.Published ? <Button variant="link" title="Publish Review" className="icon-button" onClick={() => this.publishReview(review.Title, review.ReviewID)} ><FaArrowRight size={28} color="lightblue" /></Button> : null}
                                                                        </Col>
                                                                    </Row>
                                                                </div>


                                                            </div>
                                                        ) :
                                                        <Row>
                                                            <h4>You currently have no reviews</h4>
                                                        </Row>
                                                }

                                            </Row>
                                        </div>
                                    }
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </div>
            )
        }
    }
}

export default withRouter(Reviews)