import React, { Component, useRef } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { InputGroup, Alert } from 'react-bootstrap';
import { Table, Card, Form, Row, Col, Button } from 'react-bootstrap';

// services
import UserService from "./../services/UserService";

// functions
import User from "./../functions/User";
//import UploadImage from "../../../functions/AddImage";

import SideNav from "./../modals/SideNav";

import { Input, Textarea } from '@mobiscroll/react';

import { Grid } from "react-loader-spinner";

export function withRouter(Children) {
    return (props) => {
        return <Children {...props} location={props} />
    }
}

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedIn: this.props.location.state.loggedIn,
            loginType: this.props.location.state.loginType,
            email: this.props.location.state.email,
            user: [],

            displayname: '',
            firstname: '',
            lastname: '',
            phone: '',
            nativelanguage: 'English',
            otherlanguage: 'English',
            bio: '',
            city: '',
            state: '',
            country: '',
            selectedFile: null,
            image: null,
            bsb: '',
            accountNo: '',
            firstnameError: false,
            lastnameError: false,
            phoneError: false,
            userValid: 'none',
            userSaved: 'none',
            showLoading: 'none',
            /*showAccount: 'inline'*/
        }

        this.updateUser = this.updateUser.bind(this);
        this.addImage = this.addImage.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        UserService.getUserByEmail(this.state.email).then((result) => {
            this.setState({ user: result.data });
            this.setState({ displayname: result.data.Displayname });
            this.setState({ firstname: result.data.Firstname });
            this.setState({ lastname: result.data.Lastname });
            this.setState({ phone: result.data.Phone });
            this.setState({ nativelanguage: result.data.NativeLanguage });
            this.setState({ otherlanguage: result.data.OtherLanguage });
            this.setState({ bio: result.data.Bio });
            this.setState({ city: result.data.City });
            this.setState({ state: result.data.State });
            this.setState({ country: result.data.Country });

            this.setState({ bsb: result.data.BSB });
            this.setState({ accountNo: result.data.AccountNo });

            this.setState({ image: "data:image/png;base64," + result.data.Image })

        })

        //if guest hide the bsb and account
        if (this.state.loginType == "booking") {
            this.setState({
                showAccount: 'none'
            })
        }

    }

    updateUser = () => {
        //check all required fields valid
        let isValid = true;

        if (this.state.firstname == "") {
            isValid = false;
            this.setState({
                firstnameError: true
            });
        }

        if (this.state.lastname == "") {
            isValid = false;
            this.setState({
                lastnameError: true
            });
        }

        if (this.state.phone == "") {
            isValid = false;
            this.setState({
                phoneError: true
            });
        }

        //check bsb and account if host
        if (this.state.loginType == "hosting") {
            if (this.state.bsb == "") {
                isValid = false;
                this.setState({
                    bsbError: true
                });
            }

            if (this.state.accountNo == "") {
                isValid = false;
                this.setState({
                    accountError: true
                });
            }
        }

        if (isValid) {
            this.setState({
                userValid: 'none',
                userSaved: 'none',
                showLoading: 'block'
            });
            User.updateUser(this.state.email, this.state.firstname, this.state.lastname, this.state.phone, this.state.nativelanguage, this.state.otherlanguage,
                this.state.city, this.state.state, this.state.country, this.state.bio, this.state.displayname, this.state.selectedFile, this.state.bsb, this.state.accountNo).then(result => {
                    this.setState({
                        user: result.data,
                        userSaved: 'block',
                        showLoading: 'none'
                    });
                });
        } else {
            this.setState({
                userValid: 'block',
                showLoading: 'none'
            });
        }
    }

    changeDisplayName = (event) => {
        this.setState({ displayname: event.target.value });

        if (event.target.value) {
            this.setState({
                [event.target.getAttribute('data-error')]: false
            });
        } else {
            this.setState({
                [event.target.getAttribute('data-error')]: true
            });
        }

    }
    changeFirstName = (event) => {
        this.setState({ firstname: event.target.value });

        if (event.target.value) {
            this.setState({
                [event.target.getAttribute('data-error')]: false
            });
        } else {
            this.setState({
                [event.target.getAttribute('data-error')]: true
            });
        }
    }

    changeLastName = (event) => {
        this.setState({ lastname: event.target.value });

        if (event.target.value) {
            this.setState({
                [event.target.getAttribute('data-error')]: false
            });
        } else {
            this.setState({
                [event.target.getAttribute('data-error')]: true
            });
        }
    }

    changePhone = (event) => {
        this.setState({ phone: event.target.value });

        if (event.target.value) {
            this.setState({
                [event.target.getAttribute('data-error')]: false
            });
        } else {
            this.setState({
                [event.target.getAttribute('data-error')]: true
            });
        }
    }

    changeNativeLanguage = (event) => {
        this.setState({ nativelanguage: event.target.value });
    }

    changeOtherLanguage = (event) => {
        this.setState({ otherlanguage: event.target.value });
    }

    changeBio = (event) => {
        this.setState({ bio: event.target.value });
    }

    changeDateOfBirth = (event) => {
        this.setState({ dateofbirth: event.target.value });
    }

    changeCity = (event) => {
        this.setState({ city: event.target.value });
    }

    changeState = (event) => {
        this.setState({ state: event.target.value });
    }

    changeCountry = (event) => {
        this.setState({ country: event.target.value });
    }

    changeBSB = (event) => {
        this.setState({ bsb: event.target.value });
        if (event.target.value) {
            this.setState({
                [event.target.getAttribute('data-error')]: false
            });
        } else {
            this.setState({
                [event.target.getAttribute('data-error')]: true
            });
        }
    }

    changeAccountNo = (event) => {
        this.setState({ accountNo: event.target.value });
        if (event.target.value) {
            this.setState({
                [event.target.getAttribute('data-error')]: false
            });
        } else {
            this.setState({
                [event.target.getAttribute('data-error')]: true
            });
        }
    }

    addImage() {
        this.fileInputRef.current.click();
    }

    filesAdded = (event) => {
        this.setState({ selectedFile: event.target.files[0] });
        this.setState({ image: URL.createObjectURL(event.target.files[0]) });
    }

    removeImage() {
        this.setState({ image: null });
        User.deleteImage(this.state.email);
    }



    render() {
        return (
            <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
                <div class="loading-overlay" style={{ display: this.state.showLoading }}>
                    <div class="loading-vertical">
                        <Grid
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={50}
                        />
                    </div>
                </div>
                <Row>
                    <Col className="d-none d-md-block" md="3" lg="2" xl="1">
                        <SideNav
                            loggedIn={this.props.location.state.loggedIn}
                            loginType={this.props.location.state.loginType}
                            email={this.props.location.state.email}
                            activeTab='profile'
                        />
                    </Col>
                    <Col md="9" lg="10" xl="11">
                        <div style={{ width: '100%' }}>
                            <h1>Profile</h1>
                            <hr />
                        </div>
                        <Table>
                            <tbody bg="gray">
                                <tr>
                                    <td>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>Photo</Card.Title>
                                                <Table>
                                                    <tbody>
                                                        <tr>
                                                            <td width="200px">
                                                                <img src={this.state.image} height="100px" ></img>
                                                            </td>
                                                            <td>
                                                                <InputGroup className="mb-3">
                                                                    <Form.Group as={Col} controlID="formGridState">
                                                                        <Form.Label><h5>Choose an image from your computer</h5></Form.Label>
                                                                        <div style={{ display: "flex" }}>
                                                                            <Form.Control type="file" style={{ display: "none" }} ref={this.fileInputRef} onChange={this.filesAdded} />
                                                                            <Button variant="success" style={{ marginLeft: "10px", width: "100px", fontSize: "12px" }} onClick={this.addImage}>Browse</Button>
                                                                            <Button variant="danger" style={{ marginLeft: "10px", width: "100px", fontSize: "12px" }} onClick={this.removeImage}>Delete</Button>
                                                                        </div>
                                                                    </Form.Group>
                                                                </InputGroup>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>Information</Card.Title>
                                                <Form>
                                                    <Row >
                                                        <Col xs={12}>
                                                            <h5>Display name publicly as</h5>
                                                            <Input className="mbsc-remove-margins" placeholder="Enter name to display to users" inputStyle="box" id='displayname' name="displayname" value={this.state.displayname} onChange={this.changeDisplayName} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} md={6}>
                                                            <h5>First Name *</h5>
                                                            <Input className="mbsc-remove-margins" placeholder="Enter First Name" inputStyle="box" id='firstname' name="firstname" data-error='firstnameError' errorMessage="First Name Required" error={this.state.firstnameError} value={this.state.firstname} onChange={this.changeFirstName} />
                                                        </Col>
                                                        <Col xs={12} md={6}>
                                                            <h5>Last Name *</h5>
                                                            <Input className="mbsc-remove-margins" placeholder="Enter Last Name" inputStyle="box" id='lastname' name="lastname" data-error='lastnameError' errorMessage="Last Name Required" error={this.state.lastnameError} value={this.state.lastname} onChange={this.changeLastName} />
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col xs={12} md={4}>
                                                            <h5>Phone *</h5>
                                                            <Input className="mbsc-remove-margins" type="number" placeholder="Enter Phone" inputStyle="box" id='phone' name="phone" data-error='phoneError' errorMessage="Phone Required" error={this.state.phoneError} value={this.state.phone} onChange={this.changePhone} />
                                                        </Col>
                                                        <Col xs={12} md={4}>
                                                            <h5>Native Language</h5>
                                                            <Input className="mbsc-remove-margins" placeholder="Enter Native Language" inputStyle="box" id='nativelanguage' name="nativelanguage" value={this.state.nativelanguage} onChange={this.changeNativeLanguage} />
                                                        </Col>
                                                        <Col xs={12} md={4}>
                                                            <h5>Other Language</h5>
                                                            <Input className="mbsc-remove-margins" placeholder="Enter Other Language" inputStyle="box" id='otherlanguage' name="otherlanguage" value={this.state.otherlanguage} onChange={this.changeOtherLanguage} />
                                                        </Col>
                                                    </Row>
                                                    <Row >
                                                        <Col xs={12} sm={12}>
                                                            <h5>Bio</h5>
                                                            <Textarea className="mbsc-remove-margins" placeholder="Enter Bio - multiple lines allowed" inputStyle="box" id='bio' name="bio" value={this.state.bio} onChange={this.changeBio} />
                                                        </Col>
                                                    </Row>
                                                </Form>
                                                * denotes required fields
                                            </Card.Body>
                                        </Card>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>Additional Information</Card.Title>
                                                <Form>
                                                    <Row >
                                                        <Col xs={12} md={4}>
                                                            <h5>City</h5>
                                                            <Input className="mbsc-remove-margins" placeholder="Enter City" inputStyle="box" id='city' name="city" value={this.state.city} onChange={this.changeCity} />
                                                        </Col>
                                                        <Col xs={12} md={4}>
                                                            <h5>State</h5>
                                                            <Input className="mbsc-remove-margins" placeholder="Enter State" inputStyle="box" id='state' name="state" value={this.state.state} onChange={this.changeState} />
                                                        </Col>
                                                        <Col xs={12} md={4}>
                                                            <h5>Country</h5>
                                                            <Input className="mbsc-remove-margins" placeholder="Enter Country" inputStyle="box" id='country' name="country" value={this.state.country} onChange={this.changeCountry} />
                                                        </Col>

                                                    </Row>
                                                    <Row style={{ display: this.state.showAccount }} >
                                                        <Col xs={12} md={6}>
                                                            <h5>BSB *</h5>
                                                            <Input type="number" className="mbsc-remove-margins" placeholder="Enter BSB" inputStyle="box" id='bsb' name="bsb" data-error='bsbError' errorMessage="BSB Required" error={this.state.bsbError} value={this.state.bsb} onChange={this.changeBSB} />
                                                        </Col>
                                                        <Col xs={12} md={6}>
                                                            <h5>Account *</h5>
                                                            <Input type="number" className="mbsc-remove-margins" placeholder="Enter Account" inputStyle="box" id='accountNo' name="accountNo" data-error='accountError' errorMessage="Account Required" error={this.state.accountError} value={this.state.accountNo} onChange={this.changeAccountNo} />
                                                        </Col>

                                                    </Row>
                                                </Form>
                                                * denotes required fields

                                            </Card.Body>
                                        </Card>
                                        <div style={{ display: this.state.userValid, width: '100%' }}>
                                            <Alert variant="danger" >
                                                Unfortunately there are errors on the form!
                                            </Alert>
                                        </div>
                                        <div style={{ display: this.state.userSaved, width: '100%' }}>
                                            <Alert variant="success" >
                                                Profile Updated!
                                            </Alert>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <Button style={{ width: "100%" }} variant="success" onClick={this.updateUser}>Save</Button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withRouter(Profile)