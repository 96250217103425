import PageContentService from "../services/PageContentService";

/**
 * global functions to act on user api actions
 */
const PageContent = {

    async createPage(page, content) {
        const blog = new FormData();
        blog.append("Page", page);
        blog.append("Content", content);

        // send it to the listing service
        const result = await PageContentService.createPageContent(blog);

        if (result.data.length !== 0) {
            return result;
        }

        return false;

    },

    async updatePage(pageID, page, content, status) {
        const blog = new FormData();
        blog.append("ID", pageID);
        blog.append("Page", page);
        blog.append("Content", content);
        blog.append("Status", status);

        // send it to the listing service
        const result = await PageContentService.updatePageContent(blog);

        if (result.data.length !== 0) {
            return result;
        }

        return false;

    },

    async getPage(page) {
        const result = await PageContentService.getPage(page);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async deletePage(id) {
        const result = await PageContentService.deletePageContent(id);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },
}

export default PageContent