// interface to the backend database handlers to act on the Listing database
import axios from 'axios';

import { BASE_URL } from './GlobalDefinitions';
import { AUTH, CONTENT_TYPE, METHODS_DELETE, METHODS_GET, METHODS_POST, METHODS_PUT, ORIGIN } from './Headers';

const LISTING_API_BASE_URL = "/listings";
const LISTING_DRAFT_CREATE = "/listingdraft";
//const LISTING_API_IMAGE_BASE_URL = "/listings-image";
const LISTING_SUMMARY_API_BASE_URL = "/listingsummary";
const LISTING_BY_LOCATION_BASE_URL = "/listingbylocation";
const LISTING_BY_ID_BASE_URL = "/listingbyid";
const LISTING_BY_OWNER_EMAIL_BASE_RUL = "/listingByEmail";
const LISTING_PRICING_FEES_BASE_URL = "/listingpricingfees";
const LISTING_PRICING_TAXES_BASE_URL = "/listingpricingtaxes";
const LISTING_PRICING_PERIODS_BASE_URL = "/listingpricingperiods";
const LISTING_PRICING_PERIODS_BY_ID_BASE_URL = "/listingpricingperiodsbyid";

// Listing Media URLs
const LISTING_PRIMARY_MEDIA = "/listingprimarymedia"
const LISTING_OTHER_MEDIA = "/listingmedia";
const LISTING_ALL_MEDIA = "/listingmediaall";

//Listing Update URLs
const LISTING_INFORMATION_UPDATE = "/listinginformationupdate";
const LISTING_PRICING_UPDATE = "/listingpricingupdate";
const LISTING_MEDIA_UPDATE = "/listingmediaupdate";
const LISTING_FEATURES_UPDATE = "/listingfeaturesupdate";

const LISTING_LOCATION_UPDATE = "/listinglocationupdate";
const LISTING_LOCATION_CREATE = "/listinglocationcreate";


const LISTING_ROOMS_UPDATE = "/listingroomsupdate";
const LISTING_TERMSANDCONDITIONS_UPDATE = "/listingtermsandconditionsupdate";
const LISTING_CALENDAR_UPDATE = "/listingcalendarupdate";

const LISTING_CALENDAR = "/listingcalender";
const ICALENDAR_BASE_URL = "/icalendar";
const CALENDAR_BASE_URL = "/listingcalender";
const ICALENDAR_SYNC_BASE_URL = "/syncicalendars";

const LISTING_BY_CRITERIA = "/listingbycriteria";

const LISTING_STATE = "/listingstate";
const LISTING_STATE_BY_LISTINGID = "/listingstatebylistingid";
const LISTING_STATE_BY_OWNERID = "/listingstatebyownerid";
const LISTING_STATE_BY_STATE = "/listingstatebystate";
//const UPDATE_PUBLISHED_STATE = "/listingpublished";
//const UPDATE_FEATURED_STATE = "/listingfeatured";

// Update a single column value
const LISTING_COLUMN_UPDATE = "/listingsUpdates";

// RMS Data Update
const LISTING_RMS_DATA_UPDATE = "/rmscalendarupdate";
const LISTING_BEDS_DATA_UPDATE = "/bedscalendarupdate"
const LISTING_ESCAPIA_DATA_UPDATE = "/escapiacalendarupdate"

class ListingService {
    // get all listings
    getListings() {
        return axios.get(BASE_URL + LISTING_API_BASE_URL, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }
    
    // create a new listing
    createListing(listing) {
        return axios.post(BASE_URL + LISTING_API_BASE_URL, listing, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }

    // create a new draft listing
    createDraftListing(listing) {
        return axios.post(BASE_URL + LISTING_DRAFT_CREATE, listing, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }

    // get listing summary from ListingID
    getListingSummary(listingID) {
        return axios.get(BASE_URL + LISTING_SUMMARY_API_BASE_URL, { 
            params: { 
                ListingID: listingID
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get listing by id
    getListingById(listingID) {
        return axios.get(BASE_URL + LISTING_BY_ID_BASE_URL, { 
            params: { 
                ListingID: listingID
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get listings by location
    getListingByLocation(centrePoint, radius) {
        return axios.get(BASE_URL + LISTING_BY_LOCATION_BASE_URL, { 
            params: {
                CentrePoint: centrePoint, 
                Radius: radius
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    getListingByCriteria(checkin, checkout, guests, pets, start, end, centrepoint, radius, featured) {
        //return axios.get // new base url
        // check in, checkout, guests count, pets, start count, end count, centerpoint, radius
        return axios.get(BASE_URL + LISTING_BY_CRITERIA, { 
            params: {
                Checkin: checkin, 
                Checkout: checkout, 
                Guests: guests, 
                Pets: pets, 
                Start: start, 
                End: end, 
                CentrePoint: centrepoint, 
                Radius: radius,
                Featured: featured
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get all listings owned by owner email
    getListingByOwnerEmail(email) {
        return axios.get(BASE_URL + LISTING_BY_OWNER_EMAIL_BASE_RUL, { 
            params: { 
                Email: email
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // delete a listing by its id
    deleteListingByID(listingid) {
        return axios.delete(BASE_URL + LISTING_API_BASE_URL, { 
            params: { 
                ListingID: listingid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get all listing
    deleteListings() {
        return axios.delete(BASE_URL + LISTING_API_BASE_URL, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get listing pricing fees from ListingID
    getListingPricingFees(listingID) {
        return axios.get(BASE_URL + LISTING_PRICING_FEES_BASE_URL, {
            params: {
                ListingID: listingID
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get listing pricing taxes from ListingID
    getListingPricingTaxes(listingID) {
        return axios.get(BASE_URL + LISTING_PRICING_TAXES_BASE_URL, {
            params: {
                ListingID: listingID
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get listing pricing periods from ListingID
    getListingPricingPeriods(listingID, startDate, endDate) {
        return axios.get(BASE_URL + LISTING_PRICING_PERIODS_BASE_URL, {
            params: {
                ListingID: listingID, 
                StartDate: startDate, 
                EndDate: endDate
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get listing pricing periods by id
    getListingPricingPeriodsByID(listingID) {
        return axios.get(BASE_URL + LISTING_PRICING_PERIODS_BY_ID_BASE_URL, {
            params: {
                ListingID: listingID
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // send an ical url and name to the backend
    createICalEntry(icalentry) {
        //return axios.post(BASE_URL + ICALENDAR_BASE_URL, null, {
        //    params: { 
        //        ICalEntry: icalentry
        //    },
        //    headers: {
        //        'Authorization' : AUTH,
        //        'Access-Control-Allow-Methods': METHODS_POST,
        //        'Access-Control-Allow-Origin': ORIGIN
        //    }
        //});

        return axios.post(BASE_URL + ICALENDAR_BASE_URL, icalentry, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });

    }

    getICalEntriesByListingID(listingID) {
        return axios.get(BASE_URL + ICALENDAR_BASE_URL, {
            params: {
                ListingID: listingID
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    getCalendarEntriesByListingID(listingID) {
        return axios.get(BASE_URL + CALENDAR_BASE_URL, {
            params: {
                ListingID: listingID
            },
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    deleteICalEntry(calendarID) {

        return axios.delete(BASE_URL + ICALENDAR_BASE_URL, { 
            params: { 
                CalendarID: calendarID 
            } ,
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    getICalentriesByListingID(listingID) {

        return axios.get(BASE_URL + LISTING_CALENDAR, { 
            params: { 
                ListingID: listingID 
            } ,
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    UpdateListing(listing) {
        return axios.put(BASE_URL + LISTING_API_BASE_URL, listing, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    UpdateListingInformation(listing) {
        return axios.put(BASE_URL + LISTING_INFORMATION_UPDATE, listing, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    UpdateListingPricing(listing) {
        return axios.put(BASE_URL + LISTING_PRICING_UPDATE, listing, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    UpdateListingMedia(listing) {
        return axios.put(BASE_URL + LISTING_MEDIA_UPDATE, listing, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    UpdateListingFeatures(listing) {
        return axios.put(BASE_URL + LISTING_FEATURES_UPDATE, listing, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    UpdateListingLocation(listing) {
        return axios.put(BASE_URL + LISTING_LOCATION_UPDATE, listing, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    CreateListingLocation(listing) {
        return axios.post(BASE_URL + LISTING_LOCATION_CREATE, listing, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }

    UpdateListingRooms(listing) {
        return axios.put(BASE_URL + LISTING_ROOMS_UPDATE, listing, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    UpdateListingTermsAndConditions(listing) {
        return axios.put(BASE_URL + LISTING_TERMSANDCONDITIONS_UPDATE, listing, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    UpdateListingCalendar(listing) {
        return axios.put(BASE_URL + LISTING_CALENDAR_UPDATE, listing, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    ////////////// listing media //////////////

    GetListingPrimaryImageByID(listingid) {
        return axios.get(BASE_URL + LISTING_PRIMARY_MEDIA, {
            params: {
                ListingID: listingid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    GetListingOtherImagesByID(listingid) {
        return axios.get(BASE_URL + LISTING_OTHER_MEDIA, {
            params: {
            ListingID: listingid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    GetListingAllImagesByID(listingid) {
        return axios.get(BASE_URL + LISTING_ALL_MEDIA, {
            params: {
                ListingID: listingid
            },
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    ////////////// states //////////////
    GetListingStates() {
        return axios.get(BASE_URL + LISTING_STATE, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    GetListingStateByListingID(listingid) {
        return axios.get(BASE_URL + LISTING_STATE_BY_LISTINGID, {
            params: {
                ListingID: listingid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    GetListingStateByOwnerID(ownerid) {
        return axios.get(BASE_URL + LISTING_STATE_BY_OWNERID, {
            params: {
                OwnerID: ownerid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    GetListingStateByState(state, status) {
        return axios.get(BASE_URL + LISTING_STATE_BY_STATE, {
            params: {
                State: state,
                Status: status
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        })
    }

    UpdateListingState(listingState) {
        return axios.put(BASE_URL + LISTING_STATE, listingState, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    SyncICalendars(listingid) {
        return axios.get(BASE_URL + ICALENDAR_SYNC_BASE_URL, {
            params: {
                ListingID: listingid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        })
    }

    UpdateColumnEntryValue(listingid, name, value) {
        return axios.post(BASE_URL + LISTING_COLUMN_UPDATE, {"listingid": listingid, "name": name, "value": value}, 
        {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // sync rms data
    UpdateRMSData(listingid, dateFrom, dateTo) {
        return axios.post(BASE_URL + LISTING_RMS_DATA_UPDATE, {"listingid": listingid, "dateFrom": dateFrom, "dateTo": dateTo}, { 
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    UpdateBEDSData(listingid, dateFrom, dateTo) {
        return axios.post(BASE_URL + LISTING_BEDS_DATA_UPDATE, {"listingid": listingid, "dateFrom": dateFrom, "dateTo": dateTo}, { 
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    UpdateESCAPIAData(listingid, dateFrom, dateTo) {
        return axios.post(BASE_URL + LISTING_ESCAPIA_DATA_UPDATE, { "listingid": listingid, "dateFrom": dateFrom, "dateTo": dateTo }, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }
    
}

export default new ListingService()