import axios from 'axios';

import { BASE_URL } from './GlobalDefinitions';
import { AUTH, CONTENT_TYPE, METHODS_DELETE, METHODS_GET, METHODS_POST, METHODS_PUT, ORIGIN } from './Headers';

const PMS_API_URL = "/pmshost";

class PMSHostService {
    getPMSHosts() {
        return axios.get(BASE_URL + PMS_API_URL, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }
    
    createPMSHost(email, clientid, clientpassword, ratetype, pms, media) {
        if (pms === "RMS") {
            return axios.post(BASE_URL + PMS_API_URL, { Email: email, Client_ID: clientid, Client_Password: clientpassword, Rate_Type: ratetype, PMS: pms, Media: media }, {
             
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
        } else if (pms === "BEDS") {
            return axios.post(BASE_URL + PMS_API_URL, { Email: email, Code: clientpassword, Rate_Type: ratetype, PMS: pms}, {
                headers: {
                    'Authorization' : AUTH,
                    'Access-Control-Allow-Methods': METHODS_POST,
                    'Access-Control-Allow-Origin': ORIGIN
                }
            });
        } else if (pms === "ESCAPIA") {
            return axios.post(BASE_URL + PMS_API_URL, { Email: email, Code: clientid, Client_Password: clientpassword, Rate_Type: ratetype, PMS: pms, Media: media }, {
                headers: {
                    'Authorization': AUTH,
                    'Access-Control-Allow-Methods': METHODS_POST,
                    'Access-Control-Allow-Origin': ORIGIN
                }
            });
        }
        
    }

    updatePMSHost(uuid, email, clientid, clientpassword, ratetype, pms, media) {
        if (pms === "RMS") {
            return axios.put(BASE_URL + PMS_API_URL, { Email: email, Client_ID: clientid, Client_Password: clientpassword, Rate_Type: ratetype, PMS: pms, Media: media }, {
                params: {
                    Key: "UUID",
                    Param: uuid
                },
                headers: {
                    'Authorization': AUTH,
                    'Access-Control-Allow-Methods': METHODS_PUT,
                    'Access-Control-Allow-Origin': ORIGIN
                }
            });
        } else if (pms === "BEDS") {
            return axios.put(BASE_URL + PMS_API_URL, { Email: email, Code: clientpassword, Rate_Type: ratetype, PMS: pms }, {
                params: {
                    Key: "UUID",
                    Param: uuid
                },
                headers: {
                    'Authorization': AUTH,
                    'Access-Control-Allow-Methods': METHODS_PUT,
                    'Access-Control-Allow-Origin': ORIGIN
                }
            });
        }
    }

    deletePMSHost(uuid) {
        return axios.delete(BASE_URL + PMS_API_URL, {
            params: {
                Key: "UUID",
                Param: uuid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }
}

export default new PMSHostService()