import React from 'react';

import { Button, Modal, Form, InputGroup } from 'react-bootstrap';

function EditListing (props, {hideEditListing}) {
    return (
        <div>
            <Modal show={props.showEditListing}>
                dddd
        </Modal>
        </div>
    )
}

export default EditListing