import React, { Component } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Image, Row, Col, Card, Button } from 'react-bootstrap';
import SideNav from "./../modals/SideNav";

import MessageItem from './../items/messageitem'

import MessagesDetail from './../functions/Messages';
import ReservationDetail from './../functions/Reservation';
import ListingDetail from './../functions/Listing';
import UserDetail from './../functions/User';

import { Grid } from "react-loader-spinner";

import { Textarea } from '@mobiscroll/react';

export function withRouter(Children) {
    return (props) => {

        const match = { params: useParams() };
        const location = { location: useLocation() };

        return <Children {...props} match={match} location={location.location} />
    }
}

class Message extends Component {
    constructor(props) {
        super(props);

        this.state = {
            messageID: 0, //this.props.location.state.id
            rootMessageID: null,
            parentMessageID: null,
            senderID: 0,
            receiverID: 0,
            reservationID: null,
            listingID: null,
            showLoading: true,
            message: "",
            messages: [],
            //loggedID: "",
            loggedImage: "",
            receiverImage: "",
            senderName: "",
            receiverName: "",
            displayBody: "block",
            displayNotFound: "none",
        }
    }

    updateMessage = (e) => {
        this.setState({ message: e.target.value });
    }

    sendMessage = () => {
        if (this.state.message != "") {

            this.setState({ showLoading: true });

            MessagesDetail.createMessage(this.state.senderID, this.state.receiverID, this.state.rootMessageID, this.state.parentMessageID, this.state.message, this.state.listingID, this.state.reservationID).then(result => {

                //reload the messages
                //load the message chain
                MessagesDetail.getMessagesByID("root_message_id", this.state.messageID).then(result => {
                    if (result.data && result.data.length > 0 && typeof (result.data) != 'string') {
                        const sortMessage = result.data.sort((a, b) => (a.Date < b.Date) ? 1 : -1);
                        this.setState({
                            messages: sortMessage,
                        });
                    }
                    this.setState({ showLoading: false });
                });

                //clear the text
                this.setState({ message: "" });

            })
        }
    }

    componentDidMount() {

        const { id, resID, listID } = this.props.match.params;

        this.setState({
            messageID: id,
            reservationID: resID,
            listingID: listID
        })

        //get the user ID
        var senderID = 0; //this is used later to find the receiver
        UserDetail.getUserByEmail(this.props.email).then((result) => {

            this.setState({
                senderID: result.data.UUID,
                senderName: result.data.Firstname,
                loggedImage: "data:image/png;base64," + result.data.Image
            });
            senderID = result.data.UUID;

            //get the reservation to find the reciever 
            if (resID != 0) {
                ReservationDetail.getReservationObjectsByReservationID(resID).then(result => {
                    //console.log("res det: " + JSON.stringify(result.data));

                    //check the sender is either the host or guest and set the reciever to the other
                    if (senderID != result.data.Reservation.CustomerID) {
                        //this would be the host sending to customer
                        this.setState({ receiverID: result.data.Reservation.CustomerID });
                    } else {
                        //set the receiver to the owner
                        this.setState({ receiverID: result.data.OwnerID });
                    }

                })
            }

            //get the listing to find the reciever
            if (listID != 0) {
                ListingDetail.getListingByID(listID).then(result => {
                    //console.log("list det: " + JSON.stringify(result.data));

                    this.setState({ receiverID: result.data.OwnerID });
                })
            }

            //if id is 0 then new message
            if (id == 0) {
                this.setState({ showLoading: false });
            } else {
                //load the message chain
                MessagesDetail.getMessagesByID("root_message_id", id).then(result => {
                    if (result.data && result.data.length > 0 && typeof (result.data) != 'string') {

                        //if there is no reservation or listing id then find the reciever
                        var receiverID = 0;
                        if (resID == 0 && listID == 0) {
                            if (result.data[0].SenderID == senderID) {
                                this.setState({ receiverID: result.data[0].ReceiverID });
                                receiverID = result.data[0].ReceiverID;
                            } else {
                                this.setState({ receiverID: result.data[0].SenderID });
                                receiverID = result.data[0].SenderID;
                            }
                        }

                        //get the reciever image
                        if (receiverID != 0) {
                            UserDetail.getUserByID(receiverID).then((result) => {
                                this.setState({
                                    receiverName: result.data.Firstname,
                                    receiverImage: "data:image/png;base64," + result.data.Image
                                });
                            });
                        }

                        //set the parrent ID
                        const sortMessage = result.data.sort((a, b) => (a.Date < b.Date) ? 1 : -1);
                        this.setState({
                            messages: sortMessage,
                            rootMessageID: result.data[0].RootMessageID,
                        });
                    } else {
                        this.setState({
                            showLoading: false,
                            displayBody: "none",
                            displayNotFound: "block"
                        });
                    }
                });

                this.setState({ showLoading: false });
            }
        });

    }

    render() {
        if (this.state.showLoading) {
            return (
                <div class="loading-overlay" >
                    <div class="loading-vertical">
                        <Grid
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={0}
                        />
                    </div>
                </div>
            )
        } else {

            return (
                <div style={{ paddingTop: "80px" }}>
                    <Row>
                        <Col className="d-none d-lg-block" lg="2" xl="1">
                            <SideNav
                                loggedIn={this.props.loggedIn}
                                loginType={this.props.loginType}
                                email={this.props.email}
                                activeTab='messages'
                            />
                        </Col>
                        <Col md="12" lg="10" xl="11" style={{ display: this.state.displayNotFound, color: '#e96852' }}>
                            <h1>Sorry we could not find a matching Message! </h1>
                        </Col>
                        <Col md="12" lg="10" xl="11" style={{ display: this.state.displayBody }}>
                            <div style={{ width: '100%' }}>
                                <h1>Message</h1>
                                <hr />
                            </div>

                            <Card>
                                <Card.Body style={{ paddingRight: "0px" }}>
                                    <Row>
                                        <Col>
                                            <Textarea style={{ minHeight: "200px" }} onChange={(e) => this.updateMessage(e)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button className="action-button" variant="success" onClick={() => this.sendMessage()} style={{ width: '100%' }}>Send Message</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {/*List of previous messages*/}
                                            {
                                                this.state.messages && this.state.messages.length > 0 ?
                                                    this.state.messages.map((message, index) =>
                                                        <div style={{ width: '100%' }}>
                                                            <MessageItem src={this.state.senderID == message.SenderID && this.state.loggedImage != "" ? this.state.loggedImage :
                                                                this.state.receiverID == message.SenderID && this.state.receiverImage != "" ? this.state.receiverImage : "/logo.png"}
                                                                message={message.Message}
                                                                date={message.Date}
                                                                name={this.state.senderID == message.SenderID ? this.state.senderName : this.state.receiverName} />
                                                        </div>
                                                    ) : ""
                                            }
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        }

    }
}

export default withRouter(Message)