import React from "react";
import { Row, Col } from 'react-bootstrap';

function Index() {
    return (
        <Row style={{ textAlign: "left" }}>
            <Col sm="12" md="3" className="home-col">
                <img
                    alt=""
                    src="../logo.png"
                    width="250"
                    height="250"
                    className="d-inline-block align-top"
                />
                <br />
                We believe online booking platforms should be FAIR for both owners and guests and CARE about the community they are operating in. We LOVE Australia and can't wait to help you create lifelong memories on your next holiday.
                <br />
            </Col>
            <Col sm="12" md="3" className="home-col">
                <h5>Information</h5>
                <a href="\termsandconditions" >Terms and Conditions</a> <br />
                <a href="\hostterms" >Host Terms and Conditions</a> <br />
                <a href="\privacypolicy" >Privacy Policy</a> <br />
                <a href="\cancelpolicy" >Cancellation Policies</a> <br />
                <a href="\contentguidelines" >Content Guidelines</a> <br />
            </Col>
            <Col sm="12" md="3" className="home-col">
                <h5>Aussie Holiday Stays</h5>
                <a href="\about" >About</a> <br />
                <a href="\press" >Press Page</a> <br />
                <a href="\blogs" >Blogs</a> <br />
                <a href="\guestfaq" >Guest Frequently Asked Questions</a> <br />
                <a href="\hostfaq" >Host Frequently Asked Questions</a> <br />
            </Col>
            <Col sm="12" md="3" className="home-col">
                <h5>Contact Us</h5>
                <a href="mailto:admin@aussieholidaystays.com.au" >admin@aussieholidaystays.com.au</a> <br />
                <a href="\contactus" >Contact us</a> <br />
            </Col>
        </Row>
    )
}

export default Index