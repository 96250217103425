import axios from 'axios';

import { BASE_URL } from './GlobalDefinitions';
import { AUTH, ORIGIN, METHODS_GET, METHODS_POST, METHODS_DELETE, CONTENT_TYPE } from './Headers';

const PAGECONTENT_API_BASE_URL = "/pagecontents";
const PAGECONTENT_UPDATE_API_BASE_URL = "/pagecontentsupdate";

class PageContentService {
    // get all users
    getPageContents() {
        return axios.get(BASE_URL + PAGECONTENT_API_BASE_URL, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    //getPageContentsPublished(limit) {
    //    return axios.get(BASE_URL + PAGECONTENT_PUBLISHED_API_BASE_URL, {
    //        params: {
    //            Limit: limit
    //        },
    //        headers: {
    //            'Authorization': AUTH,
    //            'Access-Control-Allow-Methods': METHODS_GET,
    //            'Access-Control-Allow-Origin': ORIGIN
    //        }
    //    });
    //}

    // create a new page
    createPageContent(pagecontent) {
        return axios.post(BASE_URL + PAGECONTENT_API_BASE_URL, pagecontent, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }

    // update pagecontent
    updatePageContent(pagecontent) {
        return axios.post(BASE_URL + PAGECONTENT_UPDATE_API_BASE_URL, pagecontent, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }

    // get user by Username
    getPage(page) {
        return axios.get(BASE_URL + PAGECONTENT_API_BASE_URL, {
            params: {
                Page: page
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // delete pagecontent
    deletePageContent(id) {
        return axios.delete(BASE_URL + PAGECONTENT_API_BASE_URL, {
            params: {
                ID: id
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }
}

export default new PageContentService()