import React, { useState, useEffect } from 'react';

import Listing from './../functions/Listing';

const ListingTitle = (props) => {
    const [title, setTitle] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {

        if (props.id && !loaded) {
            Listing.getListingByID(props.id).then(result => {
                //console.log(JSON.stringify(result.data))
                //console.log(result.data.Information.Title)
                if (result && result.data && result.data.Information) {
                    setTitle(result.data.Information.Title)
                }
            });
            setLoaded(true);
        }
    })

    return (
        <div>
            {title}
        </div>
    )
}

export default ListingTitle;