import React from 'react';

import { Button, Modal } from 'react-bootstrap';

function ShowHideListing({ listing, showShowHideListing, confirmed, hide, current}) {

    return (
        <div>
            <Modal show={showShowHideListing} style={{textAlign: "center"}}>
                <br/>
                <h3>Are you sure you want to {current} listing: {listing} </h3>
                <br/>
                <Button variant="outline-danger" onClick={() => confirmed()}>SHOW</Button>
                <br />
                <Button variant="outline-dark" onClick={() => hide()}>HIDE</Button>
                <br/>
        </Modal>
        </div>
    )
}

export default ShowHideListing