import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Container } from 'react-bootstrap';

export function withRouter(Children) {
    return (props) => {

        const match = { params: useParams() };
        const location = { location: useLocation() };

        return <Children {...props} match={match.params} location={location.location} />
    }
}

class Admin extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <Container>
                Admin page
            </Container>
        )
    }
}

export default withRouter(Admin)