import React, { useState, useEffect } from "react";
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { FaSearch } from 'react-icons/fa';

import "./../styles/site.css";
import "./../styles/calendar.css";

import { Container, InputGroup, Row, Col } from 'react-bootstrap';
import SearchTile from './../items/search-tile.js';
import TrendingTile from './../items/trending-tile';
import BlogTile from './../items/blogTile';
import Testimonial from './../items/testimonial';
import Index from './../items/index';

import SearchModal from './../modals/SearchModal';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import GuestSearch from "../items/guestSearch";

import BlogDetail from './../functions/Blog';
import ListingDetail from './../functions/Listing';

import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { Datepicker, Input, Button, setOptions } from '@mobiscroll/react';


setOptions({
    theme: 'material',
    themeVariant: 'light'
});

const today = new Date();

function Home(props) {

    const [locid, setLocID] = useState(0);
    const [locvalue, setLocValue] = useState(0);
    const [checkin, setCheckin] = useState('');
    const [guests, setGuests] = useState(0);
    const [pets, setPets] = useState('');
    const [blogs, setBlogs] = useState([]);
    const [listingFeatured, setListingFeatured] = useState([]);

    const [showSearch, setShowSearch] = useState(false);
    const [runAutocomplete, setRunAutocomplete] = useState(true);
    const [runBlogs, setRunBlogs] = useState(true);
    const [runListings, setRunListings] = useState(true);

    const [locationError, setLocationError] = useState(false);

    const [databaseError, setDatabaseError] = useState(false);

    const onChange = useCallback((e) => {
        setCheckin(e.value);
        return false;
    });

    const onCellClick = useCallback((e) => {
        return false;
    });

    const callUpdate = () => {
        setLocID(document.getElementById('citysearch').getAttribute('data-geo'));
        setLocValue(document.getElementById('citysearch').value);
        setLocationError(false);
    };

    window.googleCall = () => {
        const google = window.google;
        let autocomplete;

        autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('citysearch'),
            {
                types: ['(cities)'],
                componentRestrictions: { 'country': ['AU'] },
                fields: ['place_id', 'geometry', 'name']
            });

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();

            if (!place.geometry) return;

            document.getElementById('citysearch').setAttribute('data-geo', place.geometry.location);

            callUpdate();
        });
    }

    const renderAutoComplete = () => {
        setLocID(0);
        setLocValue(0);
        //let autocomplete;
        const script = document.createElement("script");

        script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCokK7P3SJHrW4MRbQjQTaJ7_VFkCkiBFc&libraries=places&callback=googleCall";
        script.async = true;

        document.body.appendChild(script);

    };

    const searchClick = (e) => {
        if (locid) {
            setLocationError(false);
        } else {
            setLocationError(true);
            e.preventDefault();
        }
    };

    const onGuestChange = (value) => {
        setGuests(value);
        return false;
    };

    const onPetChange = useCallback((value) => {
        setPets(value);
        return false;
    });


    const hideSearchModal = () => {
        setShowSearch(false);
        return false;
    };

    const showSearchModal = () => {
        setShowSearch(true);
        return false;
    };

    const min = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2) + 'T00:00';
    const max = (today.getFullYear() + 2) + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2) + 'T00:00';

    useEffect(() => {
        if (runBlogs) {
            setRunBlogs(false);

            BlogDetail.getBlogsPublished(4).then(result => {
                if (typeof result.data === 'string' || result.data instanceof String) {
                    //setRunBlogs(false);
                } else {
                    //console.log(result.data)
                    
                    setBlogs(result.data);
                    
                }
            }).catch(error => {
                //need to throw this to the backend
                //console.log('Blogs Errorff: ' + error)
                //props.error(error);
                //throw(error)
                //throw new Error("some")
            })
        }

        if (runListings) {
            setRunListings(false);
            ListingDetail.getListingByCriteria(
                "",
                "",
                0,
                'no',
                0,
                8,
                "-25.587068, 122.301816",
                "3200",
                true).then(result => {
                    if (result && typeof (result.data) != 'string') {
                        setListingFeatured(result.data);
                        //setRunListings(false);
                    } else {
                        //setRunListings(false);
                    }
                }).catch(error => {
                    //need to throw this to the backend
                    //console.log('Get Listingg Home Page Error: ' + error)
                    //show error to user
                    setDatabaseError(true);

                })
        }

        if (runAutocomplete) {
            setRunAutocomplete(false);
            renderAutoComplete();
        }
    })

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        //mobileFirst: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ],
        autoplay: true,
        pauseOnHover: true
    };

    return (
        <div>
            <div id="home" style={{ display: (databaseError ? "none" : "block") }} >
                <div>
                    <div className="search-div  d-none d-md-block" >
                        <h3 style={{ color: "white" }}>AUSSIE HOLIDAY STAYS - Book your Australian Holiday Accommodation for Less, Today!</h3>
                        <div className="row justify-content-center">
                            {/*<div className="d-none d-xl-block col-xl-2"> </div>*/}
                            <div className="col-md-12 col-xl-8 ">
                                <InputGroup className="mb-3" >
                                    <div className="col-md-4 " >
                                        {/*<Input label="Location" onBlur={() => setLoc()} inputStyle="box" labelStyle="floating" placeholder="Enter a location" id="citysearch" onClick={window["initAutocomplete"]} autocomplete="off" />*/}
                                        <Input className="homesearch" label="Location" inputStyle="box" onClick={renderAutoComplete} labelStyle="floating" placeholder="Enter a location" id="citysearch" autoComplete="off" errorMessage="Location must be selected from list!" error={locationError} />
                                    </div>
                                    <div className="col-md-5 ">
                                        <div className="mbsc-form-group">
                                            <Datepicker
                                                display="anchored"
                                                controls={['calendar']}
                                                select="range"
                                                theme="material"
                                                dateFormat="DD/MM/YYYY"
                                                themeVariant='light'
                                                min={min}
                                                max={max}
                                                rangeHighlight={true}
                                                showRangeLabels={true}
                                                onChange={onChange}
                                                onCellClick={onCellClick}
                                                pages="2"
                                                inputProps={{
                                                    label: 'Booking Dates',
                                                    labelStyle: 'floating',
                                                    inputStyle: 'box',
                                                    placeholder: 'Please Select...'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2 " >
                                        <GuestSearch onGuestChange={onGuestChange} onPetsChange={onPetChange} />
                                    </div>
                                    <div className="col-md-1" style={{ paddingTop: "16px", paddingLeft: "9px", paddingRight: "21px" }}>
                                        <Link onClick={searchClick}
                                            to={{
                                            pathname: "/search"}}
                                            state={ {
                                                locid: locid,
                                                locvalue: locvalue,
                                                //locid: (document.getElementById('cityloc').value),
                                                //locvalue: document.getElementById('citysearch').value,
                                                checkin: checkin,
                                                checkout: '',
                                                guests: guests,
                                                pets: pets
                                            
                                        }}>
                                            <Button style={{ width: "100%" }} color="primary" >Search</Button>
                                        </Link>


                                    </div>
                                </InputGroup>
                            </div>
                            {/*<div className="d-none d-xl-block col-xl-2"> </div>*/}
                        </div>
                    </div>

                    {/*Search Mobile*/}
                    <div className="search-div  d-block d-md-none" >
                        <h4 style={{ color: "white" }}>AUSSIE HOLIDAY STAYS - Book your Australian Holiday Accommodation for Less, Today!</h4>
                        <Button onClick={() => showSearchModal()} className="search-mobile">Where do you want to visit? &nbsp;&nbsp;&nbsp;  <FaSearch /></Button>
                    </div>

                    <SearchModal
                        showSearch={showSearch}
                        hideSearch={() => hideSearchModal()}
                    />
                    <img
                        className="d-none d-sm-block"
                        src="/images/Sugar-Loaf.jpg"
                        alt="Aussie Holiday Stays"
                        height="400px"
                        object-fit="cover"
                        style={{ minWidth: "100%" }}
                    />
                    <img
                        className="d-block d-sm-none"
                        src="/images/Sugar-Loaf.jpg"
                        alt="Aussie Holiday Homes"
                        height="350px"
                        object-fit="none"
                        style={{ maxWidth: "100%" }}
                    />
                    {/*    </Carousel.Item>*/}
                    {/*</Carousel>*/}

                    {/*Why AHS*/}
                    <Container>
                        <Row style={{ textAlign: "center" }}>
                            <Col xs="12" md={{ span: 6, offset: 3 }}>
                                <h2>Why Book with Us?</h2>
                                Good old fashioned customer service, never went out of style and we are bringing this back to online booking platforms. Our values of Integrity, Sustainability and Community inform everything we do. Here are some other ways we make your experience better:
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "center" }}>
                            <Col xs="12" md="4" className="home-col">
                                <img width="100" height="100" src="/images/heart-250x250.png" alt="Australian holiday homes" />
                                <br />
                                <h5>Platform with heart that cares! We pride ourselves on being fair to both owners and guests.</h5>
                                {/*<a href="#">Learn More...</a>*/}
                            </Col>
                            <Col xs="12" md="4" className="home-col">
                                <img width="100" height="100" src="/images/wallet-250x250.png" alt="Australian short term rental" />
                                <br />
                                <h5>Save money with our lower commission rates and keep the booking money in Australia!</h5>
                                {/*<a href="#">Learn More...</a>*/}
                            </Col>
                            <Col xs="12" md="4" className="home-col">
                                <img width="100" height="100" src="/images/wa-250x250.png" alt="Australian vacation rental" />
                                <br />
                                <h5>Being a local Australian business we care about Australian communities and promote sustainable tourism!</h5>
                                {/*<a href="#">Learn More...</a>*/}
                            </Col>
                            <Col xs="12" md={{ span: 4, offset: 4 }} className="home-col">
                                Had enough of soulless international booking platforms? Want to know more about who we are and why we started Aussie Holiday Stays?<br />
                                <a href="https://aussieholidaystays.com.au/about"> <Button style={{ backgroundColor: '#fdbd3c'}}>Our Story</Button></a>
                            </Col>
                        </Row>
                    </Container>

                    {/*Featured Accommodation*/}
                    <Container>
                        <Row style={{ textAlign: "center" }}>
                            <Col sm="12" md={{ span: 8, offset: 2 }}>
                                <h2>Our Featured Australian Holiday Accomodation</h2>
                                Hand-picked selection of quality places to stay
                            </Col>
                        </Row>
                        <div className="row" style={{ height: "400px" }}>
                            {/*{*/}
                            {/*    listingFeatured.length > 0 ?*/}

                            <Slider {...settings}>

                                {listingFeatured.map((listing, index) =>
                                    <div>
                                        <SearchTile id={listing.ListingID} title={listing.Title} image={listing.PrimaryImage} bedroom={listing.NumberOfBedrooms} guest={listing.NumberOfGuests} showFavourite={false} maxGuest={listing.MaxNumberOfGuests} price={listing.Price} accomodation={listing.TypeOfListing} />
                                    </div>
                                )}
                                    </Slider>
                        </div>
                    </Container>

                </div>


                <Container>
                    <Row style={{ textAlign: "center" }}>
                        <h2>Aussie Holiday Stays - A local online booking platform for Australian accommodation.</h2>
                        <br />
                        <br /><br />
                        <iframe className="iframe" src="https://www.youtube.com/embed/gRkeV8_98ho" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </Row>
                </Container>

                {/*Blogs*/}
                <Container>
                    <div className="row" style={{ textAlign: "center" }}>
                        {
                            blogs.length > 0 ?
                                <div style={{ width: '100%' }}>
                                    <h2>Blogs</h2>
                                </div>
                                : ""}
                        {
                            blogs ?
                                blogs.map((blog, index) =>
                                    <div className="col-sm-12 col-lg-6">
                                        <BlogTile
                                            id={blog.UUID}
                                            title={blog.Title}
                                            intro={blog.Intro}
                                            created={blog.CreatedDate}
                                            author={blog.CreatedBy}
                                            city={(blog.Location ? blog.Location.City : "")}
                                            image={blog.PrimaryImage}
                                        />
                                    </div>
                                ) : ""}

                    </div>
                </Container>
                {/*Trending Destinations*/}
                {/*<Container>*/}
                {/*    <Row style={{ textAlign: "center" }}>*/}
                {/*        <Col sm="12" md={{ span: 6, offset: 3 }}>*/}
                {/*            <h2>Trending Destinations</h2>*/}
                {/*            Explore our selection of the best places around Australia*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*    <Row style={{ textAlign: "center" }}>*/}
                {/*        <Col sm="6" md="4" className="home-col">*/}
                {/*            <TrendingTile title="Augusta" url="www.google.com" img="https://www.wahomestay.com.au/wp-content/uploads/2019/12/Fllutterbys-Back-decking-555x360.jpg" />*/}
                {/*        </Col>*/}
                {/*        <Col sm="6" md="4" className="home-col">*/}
                {/*            <TrendingTile title="Busselton" url="www.some.com" img="https://www.wahomestay.com.au/wp-content/uploads/2019/12/instances_0211ff63e162e3aac41603ed2538ddbe_5c105f145fcda-555x360.jpeg" />*/}
                {/*        </Col>*/}
                {/*        <Col sm="6" md="4" className="home-col">*/}
                {/*            <TrendingTile title="Mandurah" url="www.test.com" img="https://www.wahomestay.com.au/wp-content/uploads/2019/07/x_large_4eb738ad5d0367a9723e1d5ec5b2a1a5-555x360.jpeg" />*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</Container>*/}
                {/*Accomodation types*/}
                {/*<Container>*/}
                {/*    <Row style={{ textAlign: "center" }}>*/}
                {/*        <Col sm="12" md={{ span: 6, offset: 3 }}>*/}
                {/*            <h2>What type of accommodation will best suit your holiday needs?</h2>*/}
                {/*            Do you want the local charm of a Bed & Breakfast, the space and freedom that West Australian holiday homes afford or a smaller self-contained option? Check out our range of short term accommodation options and find your perfect ``home away from home``*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*    <Row style={{ textAlign: "center" }}>*/}
                {/*        <Col sm="6" md="4" className="home-col">*/}
                {/*            <TrendingTile title="Apartment" url="www.google.com" img="https://www.wahomestay.com.au/wp-content/uploads/2019/06/x_large_9dc1c214196cfa5b79f37ed674b8ec91-1-360x360.jpeg" />*/}
                {/*        </Col>*/}
                {/*        <Col sm="6" md="4" className="home-col">*/}
                {/*            <TrendingTile title="Farmhouse" url="www.some.com" img="https://www.wahomestay.com.au/wp-content/uploads/2019/06/x_large_b22f9822621e2366178020633078f885-2-360x360.jpeg" />*/}
                {/*        </Col>*/}
                {/*        <Col sm="6" md="4" className="home-col">*/}
                {/*            <TrendingTile title="Studio" url="www.test.com" img="https://www.wahomestay.com.au/wp-content/uploads/2019/06/x_large_75b0e38ce1663725f4dde28597206a16-1-360x360.jpeg" />*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</Container>*/}
                {/*Host Comment*/}
                <Container>
                    <Row style={{ textAlign: "center" }}>
                        <Col sm="12" md={{ span: 8, offset: 2 }}>
                            <h2>Hear From Our Australian Holiday Accommodation Hosts</h2>
                            We want our platform to be fair, give the hosts their money straight away and help them provide the best holiday experience for our guests.
                        </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col sm="12" md="4" className="home-col">
                            <Testimonial src="images/Sarah_Chittering_Heights.jpg" testimonial="We have one of Western Australia's busiest holiday rentals - Chittering Heights.  We use a few booking sites but recommend Aussie Holidays stays for their total commitment to service and attention.  Amanda is passionate and dedicated.  She has amazing attention to detail and is quick to help with any booking issues.  It has been such a pleasure to have experienced her journey so far and we wish her tons of success.  Also just a quick note, Aussie Holiday Stays are providing a real local option for operators who can get dismayed with the global platforms and lack of service offered." name="Sarah, Chittering Heights " />
                        </Col>
                        <Col sm="12" md="4" className="home-col">
                            <Testimonial src="images/Danielle_Dunsbourgh_Holiday.jpg" testimonial="Dunsborough Holiday Homes is delighted to be working alongside Amanda and the team at Aussie Holiday Stays; a like-minded business that supports local tourism and believes in a fair price promise." name="Danielle, Dunsborough Holiday Homes " />
                        </Col>
                        <Col sm="12" md="4" className="home-col">
                            <Testimonial src="images/John_Ryall_Exclusive.jpeg" testimonial="Easy to engage with, flexible and reliable � what more could you want from your channel partner.  A team that looks after the property owner/ property manager and the guest � very refreshing!" name="John, Exclusive Escapes " />
                        </Col>
                    </Row>
                </Container>
                {/*Site Index*/}
                <div style={{ backgroundColor: "white" }}>
                    <Container>
                        <Index />
                    </Container>
                </div>
                </div>

            <div style={{ display: (databaseError ? "block" : "none" ) }} >
                <div class="error-background">
                    <div class="ground"></div>
                </div>
                <div class="error-container">
                    <div class="left-section">
                        <div class="inner-content">
                            <h1 class="error-heading">Sorry We Are Having Trouble</h1>
                            <p class="subheading">Looks like our database is having some maintenance done.</p>
                            <p class="subheading">Please come back soon and try again.</p>
                        </div>
                    </div>
                    <div class="right-section">
                        <svg class="svgimg" xmlns="http://www.w3.org/2000/svg" viewBox="51.5 -15.288 385 505.565">
                            <g class="bench-legs">
                                <path d="M202.778,391.666h11.111v98.611h-11.111V391.666z M370.833,390.277h11.111v100h-11.111V390.277z M183.333,456.944h11.111
          v33.333h-11.111V456.944z M393.056,456.944h11.111v33.333h-11.111V456.944z" />
                            </g>
                            <g class="top-bench">
                                <path d="M396.527,397.917c0,1.534-1.243,2.777-2.777,2.777H190.972c-1.534,0-2.778-1.243-2.778-2.777v-8.333
          c0-1.535,1.244-2.778,2.778-2.778H393.75c1.534,0,2.777,1.243,2.777,2.778V397.917z M400.694,414.583
          c0,1.534-1.243,2.778-2.777,2.778H188.194c-1.534,0-2.778-1.244-2.778-2.778v-8.333c0-1.534,1.244-2.777,2.778-2.777h209.723
          c1.534,0,2.777,1.243,2.777,2.777V414.583z M403.473,431.25c0,1.534-1.244,2.777-2.778,2.777H184.028
          c-1.534,0-2.778-1.243-2.778-2.777v-8.333c0-1.534,1.244-2.778,2.778-2.778h216.667c1.534,0,2.778,1.244,2.778,2.778V431.25z"
                                />
                            </g>
                            <g class="bottom-bench">
                                <path d="M417.361,459.027c0,0.769-1.244,1.39-2.778,1.39H170.139c-1.533,0-2.777-0.621-2.777-1.39v-4.86
          c0-0.769,1.244-0.694,2.777-0.694h244.444c1.534,0,2.778-0.074,2.778,0.694V459.027z" />
                                <path d="M185.417,443.75H400c0,0,18.143,9.721,17.361,10.417l-250-0.696C167.303,451.65,185.417,443.75,185.417,443.75z" />
                            </g>
                            <g id="lamp">
                                <path class="lamp-details" d="M125.694,421.997c0,1.257-0.73,3.697-1.633,3.697H113.44c-0.903,0-1.633-2.44-1.633-3.697V84.917
          c0-1.257,0.73-2.278,1.633-2.278h10.621c0.903,0,1.633,1.02,1.633,2.278V421.997z"
                                />
                                <path class="lamp-accent" d="M128.472,93.75c0,1.534-1.244,2.778-2.778,2.778h-13.889c-1.534,0-2.778-1.244-2.778-2.778V79.861
          c0-1.534,1.244-2.778,2.778-2.778h13.889c1.534,0,2.778,1.244,2.778,2.778V93.75z" />

                                <circle class="lamp-light" cx="119.676" cy="44.22" r="40.51" />
                                <path class="lamp-details" d="M149.306,71.528c0,3.242-13.37,13.889-29.861,13.889S89.583,75.232,89.583,71.528c0-4.166,13.369-13.889,29.861-13.889
          S149.306,67.362,149.306,71.528z"/>
                                <radialGradient class="light-gradient" id="SVGID_1_" cx="119.676" cy="44.22" r="65" gradientUnits="userSpaceOnUse">
                                    <stop offset="0%" style={{ stopColor: "#FFFFFF", stopOpacity: 1 }} />
                                    <stop offset="50%" style={{ stopColor: "#EDEDED", stopOpacity: 0.5 }}>
                                        <animate attributeName="stop-opacity" values="0.0; 0.5; 0.0" dur="5000ms" repeatCount="indefinite"></animate>
                                    </stop>
                                    <stop offset="100%" style={{ stopColor: "#EDEDED", stopOpacity: 0 }} />
                                </radialGradient>
                                <circle class="lamp-light__glow" fill="url(#SVGID_1_)" cx="119.676" cy="44.22" r="65" />
                                <path class="lamp-bottom" d="M135.417,487.781c0,1.378-1.244,2.496-2.778,2.496H106.25c-1.534,0-2.778-1.118-2.778-2.496v-74.869
          c0-1.378,1.244-2.495,2.778-2.495h26.389c1.534,0,2.778,1.117,2.778,2.495V487.781z" />
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home

//const MapWrapper = props => (
//    <Map className="map" google={props.google} visible={false}>
//        <Home {...props} />
//    </Map>
//);

//export default MapWrapper;