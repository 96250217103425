import React, { useRef, useState, useEffect } from 'react';
import { Form, Alert } from 'react-bootstrap';

import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { Input, Dropdown, RadioGroup, Radio } from '@mobiscroll/react';

function Location({ isValid, listingData }) {
    const editorRef = useRef(null);
    const google = window.google;

    const mapStyles = {
        left: '0',
        top: '0',
        height: '400px',
        width: '100%',
        position: 'relative'
    };

    const [uluru, setUluru] = useState({ lat: -25.344, lng: 131.036 });
    const [center, setCenter] = useState({ lat: -25.344, lng: 131.036 });

    const [runAutocomplete, setRunAutocomplete] = useState(true);

    const [loadData, setLoadData] = useState(true);

    const [location, setLocation] = useState(listingData ? { lat: listingData.Latitude, lng: listingData.Longitude } : { lat: -25.344, lng: 131.036 });

    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState({});
    const [pageValid, setPageValid] = useState('none');

    const onTextChange = (event) => {

        let newinput = input;

        newinput[event.target.name] = event.target.value;
        setInput(newinput)

        if (event.target.value) {
            //remoev the errors fom array state
            let newerrors = {};
            let newshowErrors = {};

            Object.entries(errors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(showErrors).map((error, index) => {
                if (error[0] !== event.target.name) {
                    newshowErrors[error[0]] = error[1];
                }
            })

            setErrors(newerrors);
            setShowErrors(newshowErrors);

            setPageValid('none');
        }
    }

    const onTextSet = (name, value) => {
        let newinput = input;

        newinput[name] = value;
        setInput(newinput)

        if (value) {
            //remove the errors fom array state
            let newerrors = {};
            let newshowErrors = {};

            Object.entries(errors).map((error, index) => {
                if (error[0] !== name) {
                    newerrors[error[0]] = error[1];
                }
            })
            Object.entries(showErrors).map((error, index) => {
                if (error[0] !== name) {
                    newshowErrors[error[0]] = error[1];
                }
            })

            setErrors(newerrors);
            setShowErrors(newshowErrors);

            setPageValid('none');
        }
    }

    const getvalid = () => {

        //let newinput = input;
        let newerrors = {};
        let newshowErrors = {};
        let isValid = true;

        if (!input["listingAddress"]) {
            isValid = false;
            newerrors["listingAddress"] = "Please enter address for listing";
            newshowErrors["listingAddress"] = true;
        }

        if (!input["listingCity"] && document.getElementById('listingCity').value == "") {
            isValid = false;
            newerrors["listingCity"] = "Please enter city for listing";
            newshowErrors["listingCity"] = true;
        }

        setErrors(newerrors);
        setShowErrors(newshowErrors);
        if (isValid) {
            setPageValid('none');
        } else {
            setPageValid('block');
        }

        return isValid;
    }

    window.googleCallListing = () => {
        const google = window.google;
        let autocomplete;

        //if (loadData) {
        //alert(JSON.stringify(location));
        //    // The map, centered at Uluru

            const map = new google.maps.Map(document.getElementById("map"), {
                zoom: (listingData ? 12 : 6),
                center: location,
                maxZoom: 16
            });

        if (listingData) {
            const marker = new google.maps.Marker({
                position: location,
                map: map,
            });
        }

        //} 

        autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('listingAddress'),
            {
                types: ['address'],
                componentRestrictions: { 'country': ['AU'] },
                fields: ['place_id', 'geometry', 'name', 'address_components']
            });

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();

            if (!place.geometry) return;

            document.getElementById('listingAddress').setAttribute('data-geo', place.geometry.location);

            document.getElementById('geoLocation').value = place.geometry.location;
            document.getElementById('placeID').value = place.place_id;



            for (var i = 0; i < place.address_components.length; i++) {
                switch (String(place.address_components[i]['types'])) {
                    case "locality,political":
                        document.getElementById('listingCity').value = place.address_components[i]['short_name'];
                        break;
                    case "administrative_area_level_1,political":
                        document.getElementById('listingState').value = place.address_components[i]['short_name'];
                        break;
                    case "postal_code":
                        document.getElementById('listingPostCode').value = place.address_components[i]['short_name'];
                        break;
                    case "administrative_area_level_2,political":
                        document.getElementById('listingArea').value = place.address_components[i]['short_name'];
                        break;
                    case "country,political":
                        document.getElementById('listingCountry').value = place.address_components[i]['short_name'];
                        break;
                };
            }

            onTextSet("listingAddress", document.getElementById('listingAddress').value);
            
            const map = new google.maps.Map(document.getElementById("map"), {
                zoom: 12,
                center: place.geometry.location,
                maxZoom: 16
            });
            // The marker, positioned at Uluru
            const marker = new google.maps.Marker({
                position: place.geometry.location,
                map: map,
            });

        });
    }

    const renderAutoComplete = () => {
        const script = document.createElement("script");

        script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCokK7P3SJHrW4MRbQjQTaJ7_VFkCkiBFc&libraries=places&callback=googleCallListing";
        script.async = true;

        document.body.appendChild(script);
    };

    useEffect(() => {
        

        if (runAutocomplete) {
            setRunAutocomplete(false);
            renderAutoComplete();
        }

        if (loadData && listingData) {

            document.getElementById('listingAddress').value = listingData.Address;
            document.getElementById('listingUnit').value = listingData.Apartment;
            document.getElementById('listingCity').value = listingData.City;
            document.getElementById('listingState').value = listingData.State;
            document.getElementById('listingPostCode').value = listingData.PostCode;
            document.getElementById('listingArea').value = listingData.Area;
            document.getElementById('listingCountry').value = listingData.Country;
            document.getElementById('geoLocation').value = listingData.Location;
            document.getElementById('placeID').value = listingData.PlaceID;

            onTextSet("listingAddress", listingData.Address);

            //alert(listingData.Location);

            let location = { lat: listingData.Latitude, lng: listingData.Longitude };

            //alert(JSON.stringify( location));

            //const map = new google.maps.Map(document.getElementById("map"), {
            //    zoom: 8,
            //    center: location,
            //    maxZoom: 14
            //});
            
            //const marker = new google.maps.Marker({
            //    position: location,
            //    map: map,
            //});

            //getvalid();
            setLoadData(false);
        }

        isValid.current = getvalid;
    }, []);

    return (
        <div style={{ backgroundColor:'#f7f8f9' }}>
            <div className="row">
                <div className="col-md-8">
                    <h4>Address*</h4>
                    {/*<Form.Control id="listingAddress" required type="text" onClick={window["initAutocompleteAddress"]} placeholder="Enter the listing address" />*/}
                    {/*<Input className="mbsc-remove-margins" onClick={window["initAutocompleteAddress"]} placeholder="Enter the listing address" type="text" id="listingAddress" name="listingAddress" errorMessage={errors.listingAddress} error={showErrors.listingAddress} onChange={onTextChange} />*/}
                    <Input className="mbsc-remove-margins" placeholder="Enter the listing address" type="text" id="listingAddress" name="listingAddress" errorMessage={errors.listingAddress} error={showErrors.listingAddress} onChange={onTextChange} />
                </div>
                <div className="col-md-4">
                    <h4>Apt, Suite</h4>
                    {/*<Form.Control id="listingUnit" required type="text" placeholder="Enter Unit etc" />*/}
                    <Input className="mbsc-remove-margins" placeholder="Enter Unit etc" type="text" id="listingUnit" name="listingUnit" />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <h4>City*</h4>
                    {/*<Form.Control id="listingCity" required type="text" placeholder="Enter the City" />*/}
                    <Input className="mbsc-remove-margins" placeholder="Enter the City" type="text" id="listingCity" name="listingCity" errorMessage={errors.listingCity} error={showErrors.listingCity} onChange={onTextChange} />
                </div>
                <div className="col-md-4">
                    <h4>State</h4>
                    {/*<Form.Control id="listingState" required type="text" placeholder="Enter the State" />*/}
                    <Input className="mbsc-remove-margins" placeholder="Enter the State" type="text" id="listingState" name="listingState" />
                </div>
                <div className="col-md-4">
                    <h4>Post Code</h4>
                    {/*<Form.Control id="listingPostCode" required type="text" placeholder="Enter the Post Code" />*/}
                    <Input className="mbsc-remove-margins" placeholder="Enter the Post Code" type="text" id="listingPostCode" name="listingPostCode" />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h4>Area</h4>
                    {/*<Form.Control id="listingArea" required type="text" placeholder="Enter the Area" />*/}
                    <Input className="mbsc-remove-margins" placeholder="Enter the Area" type="text" id="listingArea" name="listingArea" />
                </div>
                <div className="col-md-6">
                    <h4>Country</h4>
                    {/*<Form.Control id="listingCountry" required type="text" placeholder="Enter the Country" />*/}
                    <Input className="mbsc-remove-margins" placeholder="Enter the Country" type="text" id="listingCountry" name="listingCountry" />
                </div>
                <div className="col-md-6">
                    <Form.Control id="geoLocation" type="text" readonly hidden />
                </div>
                <div className="col-md-6">
                    <Form.Control id="placeID" type="text" readonly hidden/>
                </div>
            </div>
            <div className="row">
                <div id="map"></div>
            </div>
            <div style={{ display: pageValid, width: '100%' }}>
                <Alert variant="danger" >
                    Unfortunately there are errors on the page!
                </Alert>
            </div>
        </div>
        )
    }


export default Location