import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Row, Col, Button } from 'react-bootstrap';
import { FaTrashAlt } from 'react-icons/fa';

import SideNav from "./../modals/SideNav";

import UserDetail from './../functions/User';
import FavouriteDetail from './../functions/Favourites';
import ListingDetail from './../functions/Listing';

import { Grid } from "react-loader-spinner";

export function withRouter(Children) {
    return (props) => {
        return <Children {...props} location={props} />
    }
}

class Favourites extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false,
            showLoading: 'block',
            listings: [],
            favourites: [],
            userUUID: ''
        }
    }

    deleteFavourite = (e, ListingID) => {
        e.preventDefault();
        this.setState({ showLoading: 'block' });
        //alert(ListingID);

        //loop favourites to find correct one
        this.state.favourites.map((fav, index) => {
            if (fav.ListingID == ListingID) {
                //alert(fav.UUID);
                FavouriteDetail.deleteFavourite(fav.UUID).then((result) => {
                    //reload list
                    this.loadListings(this.state.userUUID);
                })
            }
        })
        
    }

    loadListings = (userID) => {
        var listingArr = [];
        //get the user favourites
        FavouriteDetail.getFavouritesByUser(userID).then((result) => {
            if (result) {
                //console.log(result);
                this.setState({ favourites: result.data });
                result.data.map((fav, index) => {
                    //get the listing summary for each to populate table
                    ListingDetail.getListingSummary(fav.ListingID).then((result) => {
                        //console.log(result.data);
                        listingArr.push(result.data);
                        this.setState({ listings: listingArr });
                    })
                })
            }
            this.setState({ showLoading: 'none' });
        })
    }

    componentDidMount() {
        //if (this.props.location.state.loginType === 'admin') {

        //} else {

        //get user id by email
        UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {
            //var listingArr = [];
            this.setState({ userUUID: result.data.UUID });

            this.loadListings(result.data.UUID);
            ////get the user favourites
            //FavouriteDetail.getFavouritesByUser(result.data.UUID).then((result) => {
            //    if (result != "undefined") {
            //        this.setState({ favourites: result.data });
            //        result.data.map((fav, index) => {
            //            //get the listing summary for each to populate table
            //            ListingDetail.getListingSummary(fav.ListingID).then((result) => {
            //                console.log(result.data);
            //                listingArr.push(result.data);
            //                this.setState({ listings: listingArr });
            //            })
            //        })
            //    }
            //    this.setState({ showLoading: 'none' });
            //})
        })
        //}
    }

    render() {
        return (
            <div style={{ paddingTop: "80px" }}>

                <Row>
                    <Col className="d-none d-md-block" md="3" lg="2" xl="1">
                        <SideNav
                            loggedIn={this.props.location.state.loggedIn}
                            loginType={this.props.location.state.loginType}
                            email={this.props.location.state.email}
                            activeTab='favorites'
                        />
                    </Col>
                    <Col md="9" lg="10" xl="11">
                        <div style={{ width: '100%' }}>
                            <h1>Favorites</h1>
                            <hr />
                        </div>

                        <Row>
                            <Row>
                                <Col md="2"><h5>Thumbnail</h5></Col>
                                <Col md="2"><h5>Title</h5></Col>
                                <Col md="2"><h5>Type</h5></Col>
                                <Col md="1"><h5>Price</h5></Col>
                                <Col md="1"><h5>Bedrooms</h5></Col>
                                <Col md="1"><h5>Baths</h5></Col>
                                <Col md="1"><h5>Guests</h5></Col>
                                <Col md="2"><h5>Actions</h5></Col>
                            </Row>

                            <div class="loading-overlay" style={{ display: this.state.showLoading }}>
                                <div class="loading-vertical">
                                    <Grid
                                        color="#00BFFF"
                                        height={100}
                                        width={100}
                                        timeout={0}
                                    />
                                </div>
                            </div>

                            {
                                this.state.listings && this.state.listings.length > 0 ?
                                    this.state.listings.map((listing, index) =>
                                        <Row>
                                            <Col md="2">
                                                <img className="search-img" height="100px" src={"data:image/png;base64," + listing.PrimaryImage} width="100%" style={{ objectFit: "cover" }}></img>
                                            </Col>
                                            <Col md="2">
                                                {listing.Title}
                                            </Col>
                                            <Col md="2">
                                                {listing.TypeOfListing}
                                            </Col>
                                            <Col md="1">
                                                ${listing.Price}
                                            </Col>
                                            <Col md="1">
                                                {listing.NumberOfBedrooms}
                                            </Col>
                                            <Col md="1">
                                                {listing.NumberOfBathrooms}
                                            </Col>
                                            <Col md="1">
                                                {listing.MaximumNumberOfGuests}
                                            </Col>
                                            <Col md="2">
                                                <Button variant="link" title="Delete Listing" className="icon-button" onClick={(e) => this.deleteFavourite(e, listing.ListingID)} ><FaTrashAlt id="delete" size={28} color="lightblue" /></Button>
                                            </Col>
                                        </Row>
                                    ) :
                                    <Row>
                                        <div class="middle">
                                            <h3>No Favourites Found</h3>
                                            <hr />
                                        </div>
                                    </Row>
                            }
                        </Row>

                    </Col>
                </Row>
            </div>
        )
    }
}

export default withRouter(Favourites)