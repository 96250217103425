import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./../styles/site.css";
//import "./../hocs.js";

import { FaBed, FaUser, FaHotel } from 'react-icons/fa';

import { Row, Col, InputGroup, Container } from 'react-bootstrap';
import SearchTile from './../items/search-tile.js';

import Listing from './../functions/Listing';
import Favourites from './../functions/Favourites';
import Globals from './../functions/globals';
import { Datepicker, Input, Button, setOptions } from '@mobiscroll/react';
import GuestSearch from "../items/guestSearch";

import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import "./../styles/marker.css";

import { Grid } from "react-loader-spinner";

setOptions({
    theme: 'material',
    themeVariant: 'light'
});

//this is required for calendars
const today = new Date();
var markerArray = [];

window.googleCall = () => {
    const google = window.google;
    let autocomplete;

    autocomplete = new google.maps.places.Autocomplete(
        document.getElementById('citysearch'),
        {
            types: ['(cities)'],
            componentRestrictions: { 'country': ['AU'] },
            fields: ['place_id', 'geometry', 'name']
        });

    autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();

        if (!place.geometry) return;

        document.getElementById('citysearch').setAttribute('data-geo', place.geometry.location);

    });
}

const renderAutoComplete = () => {
    const script = document.createElement("script");
    window.googleCall();
    document.body.appendChild(script);
};

export function withRouter(Children) {
    return (props) => {

        //const match = { params: useParams() };
        const location = { location: useLocation() };
        //const navigate = { navigate: useNavigate() };
        const history = useNavigate();

        //return <Children {...props} match={match} location={location.location} />
        return <Children {...props} location={location.location} history={history} />
    }
}

class SearchResults extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            listingSummary: [],
            PrimaryImage: [],
            locid: '',
            locvalue: '',
            checkin: '',
            guests: '',
            pets: '',
            center: { lat: -27.344, lng: 133.036 },// props.locid,
            stores: [],
            mapZoom: 5,
            spinner: 'inline',
            noResult: 'none',
            userlist: "",
            userID: '',
            favourites: [],
            showFavourite: false,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            primaryImages: [],
            mapImage: "logo.png",
            markers: [],
        };

        for (let i = 0; i < 1000; i++) {
            this["myRef" + i] = React.createRef();
        }

    }

    //called by the map to display all the markers
    displayMarkers = () => {
        markerArray = [];
        const google = window.google;

        const svgMarker = {
            path: "m73.617 32.051h-47.23c-0.34766 0-0.62891 0.28125-0.62891 0.62891v18.895c0 0.34766 0.28125 0.62891 0.62891 0.62891h25.84l4.168 15.281c0.074219 0.27344 0.32031 0.46484 0.60547 0.46484s0.53125-0.19141 0.60938-0.46484l4.168-15.281h25.84c0.34766 0 0.62891-0.28125 0.62891-0.62891v-18.895c0-0.34766-0.28125-0.62891-0.62891-0.62891z",
            fillColor: "white",
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: "black",
            rotation: 0,
            scale: 1.28,
            anchor: new google.maps.Point(55, 72),
        };

        return this.state.stores.map((store, index) => {
            var marker = <Marker key={index} id={index} ref={this["myRef" + index]} position={{
                lat: store.latitude,
                lng: store.longitude
            }}
                title={store.title}
                listingID={store.listingID}

                rooms={store.rooms}
                guests={store.guests}
                maxGuests={store.maxGuests}
                type={store.type}

                icon={svgMarker}
                label={{
                    text: '$' + Math.round(store.price) + '/night',
                    color: "black",
                    className: "map-label"
                }}
                onClick={(props, marker, e) => {
                    //get the image data
                    var imageData = (this.state.primaryImages.filter(image => { return image.listingID === props.listingID }));
                    this.setState({
                        mapImage: (imageData && imageData.length > 0 ? imageData[0].data : ""),
                        selectedPlace: props,
                        activeMarker: marker,
                        showingInfoWindow: true
                    });
                }}
            />
            //add markers to array for manual call
            markerArray.push(marker);
            return marker;

        })
    }

    mouseover = (listingID) => {
        //find the marker from the array
        var foundMarkers = markerArray.filter(marker => { return marker.ref.current.props.listingID === listingID });
        this.props.google.maps.event.trigger(foundMarkers[0].ref.current.marker, 'click');
    }

    //on date change set the checkin to pass into next page
    onChange = (e) => {
        this.setCheckin(e.value);
        return false;

    }

    setCheckin = (value) => {
        this.setState({ checkin: value });

        this.props.history('/search',
            {state: {
                locid: this.props.location.state.locid,
                locvalue: this.props.location.state.locvalue,
                checkin: value,
                guests: this.props.location.state.guests,
                pets: this.props.location.state.pets
            }
        })


    }
    setLoc = () => {
        this.setState({
            locid: document.getElementById('citysearch').getAttribute('data-geo'),
            locvalue: document.getElementById('citysearch').value
        });

        this.props.history('/search',
            {state: {
                locid: document.getElementById('citysearch').getAttribute('data-geo'),
                locvalue: document.getElementById('citysearch').value,
                checkin: this.props.location.state.checkin,
                guests: this.props.location.state.guests,
                pets: this.props.location.state.pets
            }
        })
    }
    onGuestChange = (value) => {
        this.setState({ guests: value })

        this.props.history('/search',
            {state: {
                locid: this.props.location.state.locid,
                locvalue: this.props.location.state.locvalue,
                checkin: this.props.location.state.checkin,
                guests: value,
                pets: this.props.location.state.pets
            }
        })

    }
    onPetChange = (value) => {
        this.setState({ pets: value })

        this.props.history('/search',
            {state: {
                locid: this.props.location.state.locid,
                locvalue: this.props.location.state.locvalue,
                checkin: this.props.location.state.checkin,
                guests: this.props.location.state.guests,
                pets: value
            }
        })
    }
    getGuests = () => { }

    search() {
        this.setLoc();

        this.setState({ spinner: 'inline' });
        this.setState({
            stores: [],
            listingSummary: [],
            noResult: 'none'
        });

        var checkin = '';
        var checkout = '';

        if (this.props.location.state.checkin) {
            //break the dates down
            var datestring = this.state.checkin.toString();

            var dates = datestring.split(',');
            var date1 = new Date(dates[0]);

            checkin = (date1.getFullYear() + '-' + ('0' + (date1.getMonth() + 1)).slice(-2) + '-' + ('0' + date1.getDate()).slice(-2)).toString();

            if (dates[1]) {
                var date2 = new Date(dates[1]);
                checkout = (date2.getFullYear() + '-' + ('0' + (date2.getMonth() + 1)).slice(-2) + '-' + ('0' + date2.getDate()).slice(-2)).toString();

            }
        }

        //get lat and long
        //if (this.state.locid && document.getElementById('citysearch').value != "") {
        if (document.getElementById('citysearch').getAttribute('data-geo') != "" && document.getElementById('citysearch').value != "") {
            var location = document.getElementById('citysearch').getAttribute('data-geo');
            //var claenstring = this.state.locid.replace('(', '').replace(')', '');
            var claenstring = location.replace('(', '').replace(')', '');
            var stringarray = claenstring.split(',');
            var lat = stringarray[0];
            var long = stringarray[1];

            //reset the map to centre on the selected location
            this.setState({ center: { lat: lat, lng: long } });
            this.setState({ mapZoom: 8 });

            Listing.getListingByCriteria(
                checkin,
                checkout,
                (this.state.guests ? this.state.guests : 0),
                (this.state.pets ? this.state.pets : 'no'),
                0,
                0,
                claenstring,
                "20",
                false).then(result => {
                    if (result && typeof (result.data) != 'string' && result.data.length > 9) {

                        this.setState({ listingSummary: result.data });

                        //loop the result
                        result.data.map((listingSummary, index) => {
                            this.setState({
                                stores: [...this.state.stores, {
                                    latitude: listingSummary.Latitude,
                                    longitude: listingSummary.Longitude,
                                    title: listingSummary.Title,
                                    listingID: listingSummary.ListingID,
                                    rooms: listingSummary.NumberOfBedrooms,
                                    guests: listingSummary.NumberOfGuests,
                                    type: listingSummary.TypeOfListing,
                                    price: listingSummary.Price,
                                    maxGuests: listingSummary.MaxNumberOfGuests
                                }]
                            });
                        });
                        //this.displayMarkers();
                        //hide the spinner
                        this.setState({ spinner: 'none' });

                        //load primary images into store for use on map
                        result.data.map((listingSummary, index) => {
                            Listing.GetListingPrimaryImageByID(listingSummary.ListingID).then(result => {
                                if (result && typeof (result.data) != 'string') {
                                    this.setState({ primaryImages: [...this.state.primaryImages, { listingID: listingSummary.ListingID, data: result.data.PrimaryImage }] })
                                }
                            })
                        });

                    } else {
                        Listing.getListingByCriteria(
                            checkin,
                            checkout,
                            (this.state.guests ? this.state.guests : 0),
                            (this.state.pets ? this.state.pets : 'no'),
                            0,
                            0,
                            claenstring,
                            "50",
                            false).then(result => {
                                if (result && typeof (result.data) != 'string') {
                                    this.setState({ listingSummary: result.data });

                                    //loop the result
                                    result.data.map((listingSummary, index) => {
                                        this.setState({
                                            stores: [...this.state.stores, {
                                                latitude: listingSummary.Latitude,
                                                longitude: listingSummary.Longitude,
                                                title: listingSummary.Title,
                                                listingID: listingSummary.ListingID,
                                                rooms: listingSummary.NumberOfBedrooms,
                                                guests: listingSummary.NumberOfGuests,
                                                type: listingSummary.TypeOfListing,
                                                price: listingSummary.Price,
                                                maxGuests: listingSummary.MaxNumberOfGuests
                                            }]
                                        });
                                    });
                                    //this.displayMarkers();
                                    //hide the spinner
                                    this.setState({ spinner: 'none' });

                                    //load primary images into store for use on map
                                    result.data.map((listingSummary, index) => {
                                        Listing.GetListingPrimaryImageByID(listingSummary.ListingID).then(result => {
                                            if (result && typeof (result.data) != 'string') {
                                                this.setState({ primaryImages: [...this.state.primaryImages, { listingID: listingSummary.ListingID, data: result.data.PrimaryImage }] })
                                            }
                                        })
                                    });

                                } else {
                                    //no results found
                                    this.setState({ noResult: 'inline' });
                                    this.setState({ listingSummary: [] });
                                    //hide the spinner
                                    this.setState({ spinner: 'none' });
                                }



                            });
                    }
                });
        } else {
            Listing.getListingByCriteria(
                checkin,
                checkout,
                (this.state.guests ? this.state.guests : 0),
                (this.state.pets ? this.state.pets : 'no'),
                0,
                0,
                "",
                "20",
                false).then(result => {
                    if (result && typeof (result.data) != 'string') {
                        this.setState({ listingSummary: result.data });

                        //loop the result
                        result.data.map((listingSummary, index) => {
                            this.setState({
                                stores: [...this.state.stores, {
                                    latitude: listingSummary.Latitude,
                                    longitude: listingSummary.Longitude,
                                    title: listingSummary.Title,
                                    listingID: listingSummary.ListingID,
                                    rooms: listingSummary.NumberOfBedrooms,
                                    guests: listingSummary.NumberOfGuests,
                                    type: listingSummary.TypeOfListing,
                                    price: listingSummary.Price,
                                    maxGuests: listingSummary.MaxNumberOfGuests
                                }]
                            });
                        });
                        //this.displayMarkers();

                        //load primary images into store for use on map
                        result.data.map((listingSummary, index) => {
                            Listing.GetListingPrimaryImageByID(listingSummary.ListingID).then(result => {
                                if (result && typeof (result.data) != 'string') {
                                    this.setState({ primaryImages: [...this.state.primaryImages, { listingID: listingSummary.ListingID, data: result.data.PrimaryImage }] })
                                }
                            })
                        });

                    } else {
                        //no results found
                        this.setState({ noResult: 'inline' });
                        this.setState({ listingSummary: [] });
                    }

                    //hide the spinner
                    this.setState({ spinner: 'none' });

                });

            ////hide the spinner
            //this.setState({ spinner: 'none' });
            ////no results found
            //this.setState({ noResult: 'inline' });
        }


    }

    componentDidMount() {
        this.pageLoaded();
    }

    pageLoaded() {

        if (this.props.userID) {
            Favourites.getFavouritesByUser(this.props.userID).then((result) => {
                if (result && result.data) {
                    var favArr = [];
                    result.data.map((fav, index) => {
                        favArr.push(fav);
                    })

                    this.setState({ favourites: favArr });
                }
            });
            this.setState({ userID: this.props.userID });
            this.setState({ showFavourite: true });
        }
        this.setState({ spinner: 'inline' });
        if (this.props.location) {
            if (this.props.location.state && this.props.location.state.locvalue) {
                document.getElementById('citysearch').value = this.props.location.state.locvalue;
                document.getElementById('citysearch').setAttribute('data-geo', this.props.location.state.locid);
            }
            if (this.state.checkin == '' && this.props.location.state && this.props.location.state.locvalue) {
                this.setState({ checkin: this.props.location.state.checkin });
            }
            if (this.props.location.state && this.props.location.state.guests) {
                this.setState({ guests: this.props.location.state.guests });
            }
            if (this.props.location.state && this.props.location.state.pets) {
                this.setState({ pets: this.props.location.state.pets });
            }
        }
        //checkin and checkout preperation
        var checkin = '';
        var checkout = '';

        if (this.props.location && this.props.location.state && this.props.location.state.checkin) {
            //break the dates down
            var datestring = this.props.location.state.checkin.toString();

            var dates = datestring.split(',');
            var date1 = new Date(dates[0]);
            checkin = (date1.getFullYear() + '-' + ('0' + (date1.getMonth() + 1)).slice(-2) + '-' + ('0' + date1.getDate()).slice(-2)).toString();

            if (dates[1]) {
                var date2 = new Date(dates[1]);
                checkout = (date2.getFullYear() + '-' + ('0' + (date2.getMonth() + 1)).slice(-2) + '-' + ('0' + date2.getDate()).slice(-2)).toString();

            }
        }
        //get lat and long
        if (this.props.location && this.props.location.state && this.props.location.state.locid) {
            this.setState({
                locid: this.props.location.state.locid,
                locvalue: this.props.location.state.locvalue
            });

            var claenstring = this.props.location.state.locid.replace('(', '').replace(')', '');
            var stringarray = claenstring.split(',');
            var lat = stringarray[0];
            var long = stringarray[1];

            //reset the map to centre on the selected location
            this.setState({ center: { lat: lat, lng: long } });
            this.setState({ mapZoom: 8 });

            Listing.getListingByCriteria(
                checkin,
                checkout,
                (this.props.location.state.guests ? this.props.location.state.guests : 0),
                (this.props.location.state.pets ? this.props.location.state.pets : 'no'),
                0,
                0,
                claenstring,
                "20",
                false).then(result => {
                    if (result && typeof (result.data) != 'string' && result.data.length > 9) {
                        this.setState({ listingSummary: result.data });

                        //loop the result
                        result.data.map((listingSummary, index) => {
                            this.setState({
                                stores: [...this.state.stores, {
                                    latitude: listingSummary.Latitude,
                                    longitude: listingSummary.Longitude,
                                    title: listingSummary.Title,
                                    listingID: listingSummary.ListingID,
                                    rooms: listingSummary.NumberOfBedrooms,
                                    guests: listingSummary.NumberOfGuests,
                                    type: listingSummary.TypeOfListing,
                                    price: listingSummary.Price,
                                    maxGuests: listingSummary.MaxNumberOfGuests
                                }]
                            });
                        });
                        //hide the spinner
                        this.setState({ spinner: 'none' });
                        //this.displayMarkers();

                        //load primary images into store for use on map
                        result.data.map((listingSummary, index) => {
                            Listing.GetListingPrimaryImageByID(listingSummary.ListingID).then(result => {
                                if (result && typeof (result.data) != 'string') {
                                    this.setState({ primaryImages: [...this.state.primaryImages, { listingID: listingSummary.ListingID, data: result.data.PrimaryImage } ] })
                                }
                            })
                        });
                        

                    } else {

                        Listing.getListingByCriteria(
                            checkin,
                            checkout,
                            (this.props.location.state.guests ? this.props.location.state.guests : 0),
                            (this.props.location.state.pets ? this.props.location.state.pets : 'no'),
                            0,
                            0,
                            claenstring,
                            "50",
                            false).then(result => {
                                if (result && typeof (result.data) != 'string') {
                                    this.setState({ listingSummary: result.data });

                                    //loop the result
                                    result.data.map((listingSummary, index) => {
                                        this.setState({
                                            stores: [...this.state.stores, {
                                                latitude: listingSummary.Latitude,
                                                longitude: listingSummary.Longitude,
                                                title: listingSummary.Title,
                                                listingID: listingSummary.ListingID,
                                                rooms: listingSummary.NumberOfBedrooms,
                                                guests: listingSummary.NumberOfGuests,
                                                type: listingSummary.TypeOfListing,
                                                price: listingSummary.Price,
                                                maxGuests: listingSummary.MaxNumberOfGuests
                                            }]
                                        });
                                    });
                                    //hide the spinner
                                    this.setState({ spinner: 'none' });
                                    //this.displayMarkers();

                                    //load primary images into store for use on map
                                    result.data.map((listingSummary, index) => {
                                        Listing.GetListingPrimaryImageByID(listingSummary.ListingID).then(result => {
                                            if (result && typeof (result.data) != 'string') {
                                                this.setState({ primaryImages: [...this.state.primaryImages, { listingID: listingSummary.ListingID, data: result.data.PrimaryImage }] })
                                            }
                                        })
                                    });

                                } else {
                                    //no results found
                                    this.setState({ noResult: 'inline' });
                                    //hide the spinner
                                    this.setState({ spinner: 'none' });
                                    //this.displayMarkers();
                                }


                            });
                    }
                });
        } else {
            Listing.getListingByCriteria(
                checkin,
                checkout,
                (this.props.location && this.props.location.state && this.props.location.state.guests ? this.props.location.state.guests : 0),
                (this.props.location && this.props.location.state && this.props.location.state.pets ? this.props.location.state.pets : 'no'),
                0,
                0,
                "",
                "20",
                false).then(result => {
                    if (result && typeof (result.data) != 'string') {
                        this.setState({ listingSummary: result.data });

                        //loop the result
                        result.data.map((listingSummary, index) => {
                            this.setState({
                                stores: [...this.state.stores, {
                                    latitude: listingSummary.Latitude,
                                    longitude: listingSummary.Longitude,
                                    title: listingSummary.Title,
                                    listingID: listingSummary.ListingID,
                                    rooms: listingSummary.NumberOfBedrooms,
                                    guests: listingSummary.NumberOfGuests,
                                    type: listingSummary.TypeOfListing,
                                    price: listingSummary.Price,
                                    maxGuests: listingSummary.MaxNumberOfGuests
                                }]
                            });
                        });

                        //load primary images into store for use on map
                        result.data.map((listingSummary, index) => {
                            Listing.GetListingPrimaryImageByID(listingSummary.ListingID).then(result => {
                                if (result && typeof (result.data) != 'string') {
                                    this.setState({ primaryImages: [...this.state.primaryImages, { listingID: listingSummary.ListingID, data: result.data.PrimaryImage }] })
                                }
                            })
                        });

                    } else {
                        //no results found
                        this.setState({ noResult: 'inline' });
                    }

                    //hide the spinner
                    this.setState({ spinner: 'none' });
                    //this.displayMarkers();
                });
        }

        renderAutoComplete();
    }

    render() {

        const mapStyles = {
            left: '0',
            top: '0',
            height: '100%',
            width: '100%',
            position: 'relative',

        };

        //set the search calendar to not allow out of range dates
        const min = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2) + 'T00:00';
        const max = (today.getFullYear() + 2) + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2) + 'T00:00';

        return (
            <div style={{ paddingTop: "80px" }}>

                <Container fluid='true'>
                    <Row style={{ height: "90%" }}>

                        <Col md="6" >

                            <Row style={{ backgroundColor: "white" }}>
                                <InputGroup style={{ backgroundColor: "white" }} >
                                    <div className="col-sm-12 col-lg-4" >
                                        <Input label="Location" inputStyle="box" onClick={renderAutoComplete} labelStyle="floating" placeholder="Enter a location" id="citysearch" autoComplete="off" />
                                    </div>
                                    <div className="col-sm-12 col-lg-4">
                                        <div className="mbsc-form-group">
                                            <Datepicker
                                                display="anchored"
                                                controls={['calendar']}
                                                select="range"
                                                theme="material"
                                                dateFormat="DD/MM/YYYY"
                                                themeVariant='light'
                                                min={min}
                                                max={max}
                                                //value={['2022-01-06T00:00', '2022-01-15T00:00']}
                                                value={this.state.checkin}
                                                rangeHighlight={true}
                                                showRangeLabels={true}
                                                onChange={this.onChange}
                                                pages="2"
                                                inputProps={{
                                                    label: 'Booking Dates',
                                                    labelStyle: 'floating',
                                                    inputStyle: 'box',
                                                    placeholder: 'Please Select...'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-3" >
                                        <GuestSearch onGuestChange={this.onGuestChange} onPetsChange={this.onPetChange} guests={this.state.guests} pets={this.state.pets} />
                                    </div>
                                    <div className="col-sm-12 col-lg-1" style={{ paddingTop: "16px" }}>
                                        <Button onClick={() => this.search()} color="primary">Search</Button>
                                    </div>
                                </InputGroup>

                            </Row>

                            <Row>

                                <div style={{ display: this.state.spinner, width: '100%', textAlign: 'center' }}>
                                    <Grid
                                        color="#00BFFF"
                                        height={100}
                                        width={100}
                                        timeout={0} //3 secs
                                    />
                                </div>

                                <div style={{ display: this.state.noResult, width: '100%', textAlign: 'center' }}>
                                    <h2>No Results Found, Please refine your search</h2>
                                </div>

                                {this.state.listingSummary.length > 0 ? this.state.listingSummary.map((listingSummary, index) =>
                                    <div className="col-sm-12 col-lg-4">
                                        <SearchTile id={listingSummary.ListingID} mouseover={this.mouseover} title={listingSummary.Title} image={listingSummary.PrimaryImage} bedroom={listingSummary.NumberOfBedrooms} guest={listingSummary.NumberOfGuests} maxGuest={listingSummary.MaxNumberOfGuests} price={listingSummary.Price} accomodation={listingSummary.TypeOfListing} featured={listingSummary.Featured} favourites={this.state.favourites} userID={this.state.userID} showFavourite={this.state.showFavourite} checkin={this.state.checkin} guests={this.state.guests} pets={this.state.pets} />
                                    </div>
                                ) : ""}
                            </Row>

                        </Col>

                        <Col md="6" style={{ backgroundColor: "white" }}>
                            <div className="map" >
                                <Map
                                    google={this.props.google}
                                    zoom={this.state.mapZoom}
                                    style={mapStyles}
                                    center={this.state.center}
                                    initialCenter={this.state.center}
                                    maxZoom={14}
                                    className={'map'}
                                >
                                    {this.displayMarkers()}

                                    <InfoWindow
                                        marker={this.state.activeMarker}
                                        visible={this.state.showingInfoWindow}>

                                        <a style={{ color: "black" }} href={(this.state.checkin ? ("/listing/" + this.state.selectedPlace.listingID + "/" + (this.state.checkin ? this.state.checkin : "-") + "/" + (this.state.guests ? this.state.guests : "0") + "/" + (this.state.pets ? this.state.pets : "-")) : ("/listing/" + this.state.selectedPlace.listingID))} >

                                        {/*<BrowserRouter>*/}
                                        {/*<Link to={{*/}
                                        {/*    pathname: (this.state.checkin ? ("/listing/" + this.state.selectedPlace.listingID + "/" + (this.state.checkin ? this.state.checkin : "-") + "/" + (this.state.guests ? this.state.guests : "0") + "/" + (this.state.pets ? this.state.pets : "-")) : ("/listing/" + this.state.selectedPlace.listingID)),*/}
                                        {/*    state: {*/}
                                        {/*        id: this.state.selectedPlace.listingID,*/}
                                        {/*        guests: this.state.guests,*/}
                                        {/*        pets: this.state.pets,*/}
                                        {/*        checkin: this.state.checkin*/}
                                        {/*    }*/}
                                        {/*}}>*/}

                                            <Row style={{ paddingBottom: "0px"}}>
                                                <Col md="4" style={{ backgroundColor: "white", padding: "0px" }}>
                                                    <img height="50px" width="75px" alt="markerImage"
                                                        src={this.state.mapImage ? "data:image/png;base64," + this.state.mapImage : "logo.png"}
                                                        style={{ objectFit: "cover" }}></img>
                                            
                                                </Col>
                                                <Col md="8" style={{ backgroundColor: "white", padding: "0px" }}>
                                                    <b style={{ paddingLeft: "5px", paddingBottom: "2px", fontSize: "14px" }}>{this.state.selectedPlace.title}</b>
                                                    <div className="row" style={{ fontSize: "10px" }}>
                                                        <div className="col-sm-6" >
                                                            <FaBed /> : {this.state.selectedPlace.rooms}
                                                        </div>
                                                        <div className="col-sm-6" >
                                                            <FaUser /> : {this.state.selectedPlace.maxGuests ? this.state.selectedPlace.maxGuests : this.state.selectedPlace.guests}
                                                        </div>
                                                        <div className="col-sm-12" style={{paddingTop: "3px"}} >
                                                            <FaHotel /> : {this.state.selectedPlace.type}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/*</Link>*/}
                                            {/*</BrowserRouter>*/}
                                            </a>
                                    </InfoWindow>

                                </Map>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        )
    }
}

export default withRouter(GoogleApiWrapper({
    apiKey: Globals.googleAPI
})(SearchResults));