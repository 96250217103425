import React from 'react';

import { Button, Modal } from 'react-bootstrap';

function DeleteReview({ title, showDeleteReview, confirmed, hide}) {

    return (
        <div>
            <Modal show={showDeleteReview} style={{textAlign: "center"}}>
                <br/>
                <h3>Are you sure you want to delete review: {title} </h3>
                <br/>
                <Button variant="outline-danger" onClick={() => confirmed()}>YES</Button>
                <br />
                <Button variant="outline-dark" onClick={() => hide()}>NO</Button>
                <br/>
        </Modal>
        </div>
    )
}

export default DeleteReview