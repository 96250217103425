import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import ErrorPage from "./pages/errorPage";
import ErrorService from "./functions/Error";


export function withRouter(Children) {
    return (props) => {

        const match = { params: useParams() };
        const location = { location: useLocation() };

        return <Children {...props} match={match.params} location={location.location} />
    }
}

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // log the error to loggly
        //logger.push({ error, info });

        //console.log("error boundary");

        //console.log('error boundary: ' + error)
        //console.log('not loading error: ' + JSON.stringify(info))
        ErrorService.postErrorMessage(error, info).then(result => {
            //nothing to action as this is the error logging
        })

    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorPage/>
        }

        return this.props.children;
    }
}

export default ErrorBoundary;