import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, InputGroup } from 'react-bootstrap';

import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { Datepicker, Input, Button, setOptions } from '@mobiscroll/react';

import GuestSearch from "./../items/guestSearch";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

setOptions({
    theme: 'material',
    themeVariant: 'light'
});

const today = new Date();
const min = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2) + 'T00:00';
const max = (today.getFullYear() + 2) + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2) + 'T00:00';

function SearchModal(props) {
    const [locid, setLocID] = useState(0);
    const [locvalue, setLocValue] = useState(0);
    const [checkin, setCheckin] = useState('');
    const [guests, setGuests] = useState(0);
    const [pets, setPets] = useState('');
    const [runAutocomplete, setRunAutocomplete] = useState(true);

    const [locationError, setLocationError] = useState(false);

    const onChange = useCallback((e) => {
        setCheckin(e.value);
        return false;
    });

    const callUpdateMobile = () => {
        setLocID(document.getElementById('citysearchmobile').getAttribute('data-geo'));
        setLocValue(document.getElementById('citysearchmobile').value);
        setLocationError(false);
    };

    window.googleCallMobile = () => {
        const google = window.google;
        let autocompletemobile;

        autocompletemobile = new google.maps.places.Autocomplete(
            document.getElementById('citysearchmobile'),
            {
                types: ['(cities)'],
                componentRestrictions: { 'country': ['AU'] },
                fields: ['place_id', 'geometry', 'name']
            });

        autocompletemobile.addListener('place_changed', () => {
            const place = autocompletemobile.getPlace();

            if (!place.geometry) return;

            document.getElementById('citysearchmobile').setAttribute('data-geo', place.geometry.location);
            callUpdateMobile();

        });
    }



    const renderAutoCompleteMobile = () => {
        setLocID(0);
        setLocValue(0);
        const google = window.google;

        const script = document.createElement("script");

        //script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCokK7P3SJHrW4MRbQjQTaJ7_VFkCkiBFc&libraries=places&callback=googleCallMobile";
        script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCokK7P3SJHrW4MRbQjQTaJ7_VFkCkiBFc&libraries=places";
        script.async = true;

        document.body.appendChild(script);

    };

    const searchClick = (e) => {
        if (locid) {
            setLocationError(false);
        } else {
            setLocationError(true);
            e.preventDefault();
        }
    };

    const onGuestChange = (value) => {
        setGuests(value);
        return false;
    };

    const onPetChange = useCallback((value) => {
        setPets(value);
        return false;
    });

    const hideLogin = (event) => {
        props.hideSearch && props.hideSearch(event);
    }

    useEffect(() => {
        if (runAutocomplete) {
            setRunAutocomplete(false);
            renderAutoCompleteMobile();
        }
    })

        if (!props.showSearch) {
            return null;
        }

        return (

            <Modal show={props.showSearch}>
            
                <Modal.Header closeButton onClick={hideLogin}>
                    <Modal.Title>Search</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup >
                        <div style={{ width: "100%" }} className="col-sm-12" >
                            <Input label="Location" inputStyle="box" labelStyle="floating" placeholder="Enter a location" id="citysearchmobile" onClick={window["googleCallMobile"]} autoComplete="off" errorMessage="Location must be selected from list!" error={locationError} />
                        </div>
                        <div style={{ width: "100%" }} className="col-sm-12">
                            <div className="mbsc-form-group">
                                <Datepicker
                                    display="anchored"
                                    controls={['calendar']}
                                    select="range"
                                    theme="material"
                                    dateFormat="DD/MM/YYYY"
                                    themeVariant='light'
                                    min={min}
                                    max={max}
                                    rangeHighlight={true}
                                    showRangeLabels={true}
                                    onChange={onChange}
                                    //onCellClick={this.onCellClick()}
                                    pages="2"
                                    inputProps={{
                                        label: 'Booking Dates',
                                        labelStyle: 'floating',
                                        inputStyle: 'box',
                                        placeholder: 'Please Select...'
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ width: "100%" }} className="col-xs-12" >
                            <GuestSearch onGuestChange={onGuestChange} onPetsChange={onPetChange} />
                        </div>
                        <div style={{ width: "100%" }} className="col-xs-12" style={{ paddingTop: "16px", paddingLeft: "9px", paddingRight: "21px" }}>
                            <Link onClick={searchClick}
                                to={"/search"}
                                state={{
                                    locid: locid,
                                    locvalue: locvalue,
                                    checkin: checkin,
                                    guests: guests,
                                    pets: pets
                                }}
                            >
                                <Button style={{ width: "100%" }} color="primary">Search</Button>
                            </Link>


                        </div>
                    </InputGroup>
                </Modal.Body>
            </Modal>
        )
}

export default SearchModal