import axios from 'axios';

import { BASE_URL } from './GlobalDefinitions';
import { AUTH, METHODS_DELETE, METHODS_GET, METHODS_POST, METHODS_PUT, ORIGIN } from './Headers';

const GUESTREVIEW_API_BASE_URL = "/guestreview";
const GUESTREVIEWS_API_BASE_URL = "/guestreviews";
const HOSTREVIEW_API_BASE_URL = "/hostreview";
const HOSTREVIEWS_API_BASE_URL = "/hostreviews";

class GuestService {
    // create a guest review
    createGuestReview(reservationid, listingid, review, parentreviewid, title, userid, rating) {
        return axios.post(BASE_URL + GUESTREVIEW_API_BASE_URL, {"ReservationID": reservationid, "ListingID": listingid, "Review": review, "ParentReviewID": parentreviewid, "Title": title, "UserID": userid, "Rating": rating}, { 
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // create a host review
    createHostReview(reservationid, review, userid, title, rating, guestid) {
        return axios.post(BASE_URL + HOSTREVIEW_API_BASE_URL, {"ReservationID": reservationid, "Review": review, "UserID": userid, "Title": title, "Rating": rating, "GuestID": guestid}, { 
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // update a guest review
    updateGuestReview(reservationid, listingid, review, parentreviewid, emailsent, reviewid, published) {
        return axios.put(BASE_URL + GUESTREVIEW_API_BASE_URL, {"ReservationID": reservationid, "ListingID": listingid, "Review": review, "ParentReviewID": parentreviewid, "EmailSent": emailsent, "ReviewID": reviewid, "Published": published}, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // update a host review
    updateHostReview(reservationid, review, userid, emailsent, reviewid, published) {
        return axios.put(BASE_URL + HOSTREVIEW_API_BASE_URL, {"ReservationID": reservationid, "Review": review, "UserID": userid, "EmailSent": emailsent, "ReviewID": reviewid, "Published": published}, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // delete a guest review
    deleteGuestReview(value) {
        return axios.delete(BASE_URL + GUESTREVIEW_API_BASE_URL, {
            params: {
                Value: value
            }, 
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        })
    }

    // delete a host review
    deleteHostReview(value) {
        return axios.delete(BASE_URL + HOSTREVIEW_API_BASE_URL, {
            params: {
                Value: value
            }, 
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        })
    }

    // get a guest review
    getGuestReview(key0, value0, key1, value1) {
        return axios.get(BASE_URL + GUESTREVIEW_API_BASE_URL, {
            params: {
                Key0: key0,
                Value0: value0,
                Key1: key1,
                Value1: value1
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        })
    }

    // get a host review
    getHostReview(key0, value0, key1, value1) {
        return axios.get(BASE_URL + HOSTREVIEW_API_BASE_URL, {
            params: {
                Key0: key0,
                Value0: value0,
                Key1: key1,
                Value1: value1
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        })
    }

    // get a number of guest reviews
    getGuestReviews(key0, value0, key1, value1) {
        return axios.get(BASE_URL + GUESTREVIEWS_API_BASE_URL, {
            params: {
                Key0: key0,
                Value0: value0,
                Key1: key1,
                Value1: value1
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        })
    }

    // get a number of host reviews
    getHostReviews(key0, value0, key1, value1) {
        return axios.get(BASE_URL + HOSTREVIEWS_API_BASE_URL, {
            params: {
                Key0: key0,
                Value0: value0,
                Key1: key1,
                Value1: value1
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        })
    }
}

export default new GuestService()