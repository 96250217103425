import React, { useState, useEffect } from 'react';
import { Card, Button, Col, Row } from 'react-bootstrap';

import { Input, Dropdown } from '@mobiscroll/react';
/*import Select from "react-dropdown-select";*/
//import Select from 'react-select';
//import AsyncSelect from 'react-select/async';

//import Multiselect from 'multiselect-react-dropdown';


import { MultiSelect } from "react-multi-select-component";

const options = [
    { value: 'Single', label: 'Single' },
    { value: '2xSingle', label: '2 x Single' },
    { value: 'Double', label: 'Double' },
    { value: 'Queen', label: 'Queen' },
    { value: 'King', label: 'King' },
    { value: 'Bunk', label: 'Bunk' },
    { value: '2xBunk', label: '2 x Bunk' },
    { value: 'Foldout', label: 'Foldout' },
    { value: 'Tribunk', label: 'Tribunk' },
    { value: 'KingSingle', label: 'King Single' },
    { value: '2xKingSingle', label: '2 x King Single' },
    { value: 'Trundle', label: 'Trundle' },
]

//const options2 = [
//    { name: 'Single', id: 'Single' },
//    { name: 'Double', id: 'Double' },
//    { name: 'Queen', id: 'Queen' },
//    { name: 'King', id: 'King' },
//    { name: 'Bunk', id: 'Bunk' },
//    { name: 'Foldout', id: 'Foldout' },
//    { name: 'Tribunk', id: 'Tribunk' },
//    { name: 'King Single', id: 'King Single' },
//]


const BedTypeMulti = ({ index, defaultBedType, setBedType }) => {

    const [selected, setSelected] = useState(defaultBedType);
    const [load, setLoad] = useState(true);
    const [defaultIndex, setDefaultIndex] = useState();
    //const defaultSelect = (items) => {
    //    console.log("this: " + JSON.stringify(items));
    //    return "<Select onChange = { options => bedTypeChange(options, index)} options = { options } isMulti placeholder = 'Please Select Bed Types for Room' closeMenuOnSelect = { false} defaultValue = {room.defaultBedType} />";
    //}

    const bedTypeChange = (options) => {
        //alert(JSON.stringify(options));
        setLoad(true);
        setBedType(options, index);

        ////loop through objects to get values only
        //var bedTypes = [];
        //options.map((option, index) => {
        //    bedTypes.push(option.value);
        //});

        //const list = [...rooms];
        //list[index]["bedType"] = bedTypes.toString().replace(/,/g, ", ");
        ////setRooms(list);
        //setRoomsState(list);

    }

    //const multiBedChange = (selectedList, selectedItem, index) => {
    //    //alert(JSON.stringify(selectedList));
    //    //alert(JSON.stringify(selectedItem));
    //    //alert(JSON.stringify(index));
    //}

    useEffect(() => {
        //console.log("this: " + index + " " + JSON.stringify(defaultBedType));
        //console.log("this selected: " + index + " " + JSON.stringify(selected));
        //if (load) {
        //    //alert('changed');
        //    setSelected(defaultBedType);
        //    setLoad(false);
        //    setDefaultIndex(index);
        //}

        //if (defaultIndex != index) {
        //    console.log('removed');
        //    setDefaultIndex(index);
        //}
    })

    return (
        <div>
            {/*<Select*/}
            {/*    //instanceId={room.selectID}*/}
            {/*    onChange={options => bedTypeChange(options, index)}*/}
            {/*    options={options}*/}
            {/*    isMulti*/}
            {/*    placeholder="Please Select Bed Types for Room"*/}
            {/*    closeMenuOnSelect={false}*/}
            {/*    //defaultValue={() => defaultSelect(room.defaultBedType)}*/}
            {/*    defaultValue={defaultBedType}*/}
            {/*    //getValue={defaultBedType}*/}
            {/*/>*/}
            {/*<Multiselect*/}
            {/*    //id={index}*/}
            {/*    placeholder="Please Select Bed Types for Room"*/}
            {/*    onSelect={(selectedList, selectedItem) => multiBedChange(selectedList, selectedItem, index)}*/}
            {/*    options={options2}*/}
            {/*    displayValue="name"*/}
            {/*    selectedValues={defaultBedType}*/}
            {/*    style={{*/}
            {/*        chips: {*/}
            {/*            background: 'grey'*/}
            {/*        },*/}
            {/*        multiselectContainer: {*/}
            {/*            color: 'none'*/}
            {/*        },*/}
            {/*        searchBox: {*/}
            {/*            border: 'none',*/}
            {/*            'border-bottom': '1px solid blue',*/}
            {/*            'border-radius': '0px'*/}
            {/*        },*/}
            {/*        option: {*/}
            {/*            color: 'blue',*/}
            {/*            background: 'white',*/}
            {/*            width: '100%'*/}
            {/*        }*/}
            {/*    }}*/}
            {/*/>*/}

            {/*<pre>{JSON.stringify(selected)}</pre>*/}
            <MultiSelect
                options={options}
                value={defaultBedType ? defaultBedType : selected}
                onChange={bedTypeChange}
                labelledBy="Select"
                hasSelectAll={false}
                disableSearch={true}
            />

        </div>
    )
}

export default BedTypeMulti