import axios from 'axios';

import { BASE_URL } from './GlobalDefinitions';
import { AUTH, ORIGIN, METHODS_GET } from './Headers';

const FEATURE_API_BASE_URL = '/features';
const FEATURE_BY_CATEGORY_API_BASE_URL = 'featuresbycategory';

class FeatureService {
    // get all features
    getFeatures() {
        return axios.get(BASE_URL + FEATURE_API_BASE_URL, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    getFeaturesByCategory(categoryID) {
        return axios.get(BASE_URL + FEATURE_BY_CATEGORY_API_BASE_URL, {
            params: {
                CategoryID: categoryID
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }
}

export default new FeatureService()