import axios from 'axios';

import { BASE_URL } from './GlobalDefinitions';
import { AUTH, ORIGIN, METHODS_GET } from './Headers';

const CATEGORY_API_BASE_URL = '/categories';

class CategoryService {
    // get all the categories
    getCategories() {
        return axios.get(BASE_URL + CATEGORY_API_BASE_URL, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }
}

export default new CategoryService()