import React from 'react';

import { Button, Modal } from 'react-bootstrap';

function UpdateListingStatus({ listing, showUpdateListingStatus, publish, pending}) {

    return (
        <div>
            <Modal show={showUpdateListingStatus} style={{textAlign: "center"}}>
                <br/>
                <h3>Change listing status: {listing} </h3>
                <br/>
                <Button variant="success" onClick={() => publish()}>Publish</Button>
                <br />
                <Button variant="outline-warning" onClick={() => pending()}>Pending</Button>
                <br/>
        </Modal>
        </div>
    )
}

export default UpdateListingStatus