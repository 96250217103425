const GLobalDefinitions = {
    // definition for the base url to add to the front of backend calls
    //BASE_URL: "http://52.62.195.81:8080", // deployed definition = global ip address
    BASE_URL: "https://api.aussieholidaystays.com.au:8080",  // deployed definition - local ip address
    //BASE_URL: "", // local development

    // moved to Header.js file
    //BASIC_USENAME: "aussiehs_basic_auth_username_%TGB^YHN7ujm8ik,",
    //BASIC_PASSWORD: "!QAZ2wsx#EDC9ol.*IK<7ujm",

    //AUTH_HEADER: auth_header,
}

export default GLobalDefinitions
// definition for the base url to add to the front of backend calls
export const BASE_URL = "https://api.aussieholidaystays.com.au:8080" // deployed definition
//export const BASE_URL =  ""; // local development
