import React, { Component } from "react";
import { useParams, useLocation } from "react-router-dom";

import "./../styles/site.css";
import "./../styles/listing.css";
import "./../styles/calendar.css";
import { Row, Col, Card, Modal } from 'react-bootstrap';
import Booking from "./../items/booking.js";

import ReservationDetail from './../functions/Reservation';
import UserDetail from './../functions/User';
import Favourites from './../functions/Favourites';
import ReviewsDetail from './../functions/Reviews';

import { FaHome } from 'react-icons/fa';
import { FaUserAlt } from 'react-icons/fa';
import { FaBed } from 'react-icons/fa';
import { FaShower } from 'react-icons/fa';
import { FaAngleRight } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa';
import { FaRegHeart, FaHeart } from 'react-icons/fa';

import PricingDetail from './../functions/Pricing';
import ListingDetail from './../functions/Listing';
import Globals from './../functions/globals';
import MessagesDetail from './../functions/Messages';

import ImageGallery from 'react-image-gallery';
import "./../styles/image-gallery.css";
//import "~react-image-gallery/styles/css/image-gallery.css";

import ReviewItem from './../items/reviewitem';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import LinesEllipsis from 'react-lines-ellipsis';

import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

// pop up modals
import CardModal from './../modals/CardEntry';

import { Datepicker, Button, Textarea } from '@mobiscroll/react';
import { Grid } from "react-loader-spinner";

const today = new Date();

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export function withRouter(Children) {
    return (props) => {

        const match = { params: useParams() };
        const location = { location: useLocation() };

        return <Children {...props} match={match.params} location={location.location} />
    }
}

class Listing extends Component {
    constructor(props) {
        super(props);

        this.imagesRef = React.createRef();
        this.heroRef = React.createRef();
        this.cardModalRef = React.createRef();

        this.state = {
            listingID: "",
            userID: this.props.userID,
            spinner: 'block',
            listingShow: 'none',
            showLoading: false,
            messageLoading: 'none',
            listingSummary: [],
            min: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
            max: '2022-06-16T00:00',
            stores: [],
            center: { lat: -27.344, lng: 133.036 },
            labels: [],
            invalid: [],
            colors: [],
            listing: [],
            primaryImage: [],
            information: [],
            features: [],
            facilities: [],
            location: [],
            otherImages: [{
                original: 'http://localhost:3000/logo.png',
                thumbnail: 'http://localhost:3000/logo.png',
                originalHeight: '400',
            }],
            carouselImages: [],
            owner: [],
            pricing: [],
            rooms: [],
            termsAndConditions: [],
            dailyPrice: null,
            inctaxes: null,
            extaxes: null,
            condtaxes: null,
            extaxesArrive: null,
            condtaxesArrive: null,
            incfees: null,
            exfeesArrive: null,
            condfeesArrive: null,
            exfees: null,
            condfees: null,
            totalFee: 0,
            payable: 0,
            serviceFee: 0,
            userEmail: this.props.email,
            arrive: '',
            depart: '',
            checkin: '',
            guests: '',
            pets: 0,
            securityDeposit: null,
            intro: '',
            nightlyFormatted: '',
            cardTotalFee: 0,
            cardPayable: 0,
            reservationUUID: '',
            nights: '',
            amountSaved: 0,
            showBookingModal: false,
            showSuccess: false,
            showInstantSuccess: false,
            showFailure: false,
            heroStyle: { "x": 0, "y": 0, "width": 100, "height": 28.384231410598616 },
            fav: false,
            favUUID: "",
            instantBooking: false,
            message: "",
            reviews: [],
            maxLines: 3,
            promoCode: "",
            cardFees: 0,
            pmsFees: 0
        }
        this.renderAmenities = this.renderAmenities.bind(this);
    }

    switchFavourite = (e) => {
        e.preventDefault();

        if (this.state.fav) {
            Favourites.deleteFavourite(this.state.favUUID).then((result) => {
                this.setState({
                    fav: false
                })
            })
        } else {
            Favourites.createFavourite(this.state.userID, this.state.listingID).then((result) => {
                //reset the uuid
                Favourites.getFavouritesByUser(this.props.userID).then((result) => {
                    if (result != "undefined") {
                        result.data.map((fav, index) => {
                            if (fav.ListingID == this.state.listingID) {
                                this.setState({
                                    fav: true,
                                    favUUID: fav.UUID
                                });
                            }
                        })
                    }
                });
            })
        }
    }

    hideCardEntry = () => {
        this.setState({
            showCardEntry: false,
        });
    }

    hideSuccess = () => {
        this.setState({
            showSuccess: false,
            showInstantSuccess: false,
        });
    }

    createReservation = (props) => {
        //show loading and hide card modal
        this.setState({
            showLoading: true,
            showCardEntry: false
        });

        //split the arrive and depart
        //break the dates down
        var datestring = this.state.arrive.toString();
        var dates = datestring.split(',');
        var arriveDate = new Date(dates[0]);
        var departDate = "";
        if (dates[1]) {
            departDate = new Date(dates[1]);
        }

        var arrive = (('0' + (arriveDate.getMonth() + 1)).slice(-2) + '/' + ('0' + arriveDate.getDate()).slice(-2) + '/' + arriveDate.getFullYear()).toString();
        var depart = (('0' + (departDate.getMonth() + 1)).slice(-2) + '/' + ('0' + departDate.getDate()).slice(-2) + '/' + departDate.getFullYear()).toString();

        var today = new Date();
        var createdDate = (('0' + (today.getMonth() + 1)).slice(-2) + '/' + ('0' + today.getDate()).slice(-2) + '/' + today.getFullYear()).toString();

        var userEmail = this.props.email;
        if (typeof props.email != "undefined" && props.email != "") {
            userEmail = props.email;
        }
        //get user by email for UUID
        UserDetail.getUserByEmail(userEmail).then((result) => {

            var customerID = result.data.UUID;
            //customer token is not used here only on payment processing
            const customerToken = "";

            //format the daily rates for saving to reservation
            var dailyRates = [];
            this.state.dailyPrice.map(function (d) {
                dailyRates = [...dailyRates, {
                    Date: ('0' + (d.date.getMonth() + 1)).slice(-2) + '/' + ('0' + d.date.getDate()).slice(-2) + '/' + d.date.getFullYear(),
                    Rate: d.price
                }];
            });
            //format fees
            var incfees = [];
            this.state.incfees.map(function (d) {
                incfees = [...incfees, {
                    Fee: d.title,
                    Rate: (Math.round(d.price * 100) / 100).toString()
                }];
            });
            var exfees = [];
            this.state.exfees.map(function (d) {
                exfees = [...exfees, {
                    Fee: d.title,
                    Rate: (Math.round(d.price * 100) / 100).toString()
                }];
            });
            var condfees = [];
            this.state.condfees.map(function (d) {
                condfees = [...condfees, {
                    Fee: d.title,
                    Rate: (Math.round(d.price * 100) / 100).toString()
                }];
            });
            var exfeesArrive = [];
            this.state.exfeesArrive.map(function (d) {
                exfeesArrive = [...exfeesArrive, {
                    Fee: d.title,
                    Rate: (Math.round(d.price * 100) / 100).toString()
                }];
            });
            var condfeesArrive = [];
            this.state.condfeesArrive.map(function (d) {
                condfeesArrive = [...condfeesArrive, {
                    Fee: d.title,
                    Rate: (Math.round(d.price * 100) / 100).toString()
                }];
            });
            //format taxes
            var inctaxes = [];
            this.state.inctaxes.map(function (d) {
                inctaxes = [...inctaxes, {
                    Tax: d.title,
                    Rate: (Math.round(d.price * 100) / 100).toString()
                }];
            });
            var extaxes = [];
            this.state.extaxes.map(function (d) {
                extaxes = [...extaxes, {
                    Tax: d.title,
                    Rate: (Math.round(d.price * 100) / 100).toString()
                }];
            });
            var condtaxes = [];
            this.state.condtaxes.map(function (d) {
                condtaxes = [...condtaxes, {
                    Tax: d.title,
                    Rate: (Math.round(d.price * 100) / 100).toString()
                }];
            });
            var extaxesArrive = [];
            this.state.extaxesArrive.map(function (d) {
                extaxesArrive = [...extaxesArrive, {
                    Tax: d.title,
                    Rate: (Math.round(d.price * 100) / 100).toString()
                }];
            });
            var condtaxesArrive = [];
            this.state.condtaxesArrive.map(function (d) {
                condtaxesArrive = [...condtaxesArrive, {
                    Tax: d.title,
                    Rate: (Math.round(d.price * 100) / 100).toString()
                }];
            });

            //if card fees - need to be added to the total fees & service fee & payable
            var cardFees = 0;
            var totalFee = 0;
            var serviceFee = 0;
            var payable = 0;

            if (props.cardFees > 0) {
                totalFee = (Math.round((this.state.totalFee + props.cardFees) * 100) / 100).toString();
                serviceFee = (Math.round((this.state.serviceFee + props.cardFees) * 100) / 100).toString();
                payable = (Math.round((this.state.payable + props.cardFees) * 100) / 100).toString();
                cardFees = (Math.round(props.cardFees * 100) / 100).toString();
            } else {
                totalFee = (Math.round(this.state.totalFee * 100) / 100).toString();
                serviceFee = (Math.round(this.state.serviceFee * 100) / 100).toString();
                payable = (Math.round(this.state.payable * 100) / 100).toString();
            }

            //var totalFee = (Math.round(this.state.totalFee * 100) / 100).toString();
            var totalGST = (Math.round(this.state.totalGST * 100) / 100).toString();
            var guestGST = (Math.round(this.state.guestGST * 100) / 100).toString();
            var hostGST = (Math.round(this.state.hostGST * 100) / 100).toString();
            //var payable = (Math.round(this.state.payable * 100) / 100).toString();
            var securityDeposit = (Math.round(this.state.securityDeposit * 100) / 100).toString();
            //var serviceFee = (Math.round(this.state.serviceFee * 100) / 100).toString();
            var amountSaved = (Math.round(this.state.amountSaved * 100) / 100).toString();

            //var cardFees = (Math.round(props.cardFees * 100) / 100).toString();
            var pmsFees = (Math.round(this.state.pmsFees * 100) / 100).toString();
            //alert('process reservation: ' + props.token);

            ReservationDetail.createReservationEntry(
                this.state.listingID,
                customerID,
                securityDeposit,
                incfees,
                exfees,
                condfees,
                exfeesArrive,
                condfeesArrive,
                totalFee,
                payable,
                arrive,
                depart,
                this.state.guests.toString(),
                (this.state.pets == 'yes' ? 'Yes' : 'No'),
                dailyRates,
                inctaxes,
                extaxes,
                condtaxes,
                extaxesArrive,
                condtaxesArrive,
                customerToken,
                props.token,
                this.state.intro.toString(),
                this.state.termsAndConditions.Cancellation.toString(),
                createdDate,
                this.state.nights.toString(),
                serviceFee,
                totalFee, //at creation the whole balance is owning,
                amountSaved,
                totalGST,
                guestGST,
                hostGST,
                this.state.instantBooking,
                this.state.promoCode,
                cardFees,
                pmsFees
            ).then((result) => {
                //console.log("RESID: " + result);
                if (result && typeof result !== "undefined") {
                    this.setState({
                        reservationUUID: result.data,
                        checkin: '',
                        guests: 0,
                        pets: '',
                        showSuccess: !this.state.instantBooking ? true : false,
                        showInstantSuccess: this.state.instantBooking ? true : false,
                        showCardEntry: false,
                        showLoading: false
                    });

                    //clear the form
                    Array.from(document.querySelectorAll("input")).forEach(
                        input => (input.value = "")
                    );
                } else {
                    //show error to user
                    this.setState({
                        showFailure: true,
                        showCardEntry: false,
                        showLoading: false
                    });
                }
                //return true;
                //this.refs.bookingform.forceUpdate();
            });

        });
    }

    componentDidMount() {

        const { id, checkin, guests, pets } = this.props.match;

        var ListingID = id;
        var CheckIN = checkin;
        var Guests = guests;
        var Pets = pets;

        //check if the user has listing as a favourite
        if (this.props.userID) {
            Favourites.getFavouritesByUser(this.props.userID).then((result) => {
                //console.log(JSON.stringify(result));
                if (typeof result !== "undefined" && result.data && result.data.length > 0) {
                    result.data.map((fav, index) => {
                        if (fav.ListingID == ListingID) {
                            this.setState({
                                fav: true,
                                favUUID: fav.UUID
                            });
                        }
                    })
                }
            });
        }

        //get reviews for listing
        ReviewsDetail.getGuestReviews("ListingID", ListingID, "Published", true).then(result => {
            //console.log(result);
            if (typeof result !== "undefined" && result.data && result.data.length > 0) {
                this.setState({ reviews: result.data })
            }
        })

        ListingDetail.getListingByID(ListingID).then(result => {
            // don't know why but we seem to have to extract everything from the result
            this.setState({ primaryImage: result.data.PrimaryImage });
            this.setState({ information: result.data.Information });
            this.setState({ features: result.data.Features });
            this.setState({ facilities: result.data.Facilities });
            this.setState({ location: result.data.Location });
            this.setState({ owner: result.data.Owner });
            this.setState({ pricing: result.data.Pricing });
            this.setState({ rooms: result.data.Rooms });
            this.setState({ termsAndConditions: result.data.TermsAndConditions });

            //this.setState({ otherImages: [] });

            //console.log("pricing: " + JSON.stringify(result.data.Pricing));
            var tempImages = [];
            //ListingDetail.GetListingOtherImagesByID(ListingID).then(images => {
            ListingDetail.GetListingAllImagesByID(ListingID).then(images => {
                //console.log("images: " + JSON.stringify(images.data));
                if (typeof images.data != 'string') {
                    //add the primary image to the start
                    //this.state.otherImages.push({ original: "data:image/png;base64," + result.data.PrimaryImage, thumbnail: "data:image/png;base64," + result.data.PrimaryImage })
                    //sort the images
                    const imageSorted = images.data.sort((a, b) => (a.Sort > b.Sort) ? 1 : -1);

                    //images.data.OtherImages.map((image, index) => {
                    imageSorted.map((image, index) => {
                        //console.log("image: " + index + " : " + image.UUID + "sort: " + image.Sort);
                        //this.state.otherImages.push({ original: "data:image/png;base64," + image, thumbnail: "data:image/png;base64," + image })
                        //this.state.otherImages.push({ original: "data:image/png;base64," + image.ImageData, thumbnail: "data:image/png;base64," + image.ImageData });

                        tempImages.push({ original: "data:image/png;base64," + image.ImageData, thumbnail: "data:image/png;base64," + image.ImageData });
                    });
                    this.imagesRef.current.forceUpdate();
                    //this.heroRef.current.forceUpdate();
                } else {
                    //add the primary image to the end
                    //this.state.otherImages.push({ original: "data:image/png;base64," + result.data.PrimaryImage, thumbnail: "data:image/png;base64," + result.data.PrimaryImage });
                    //this.imagesRef.current.forceUpdate();
                    //this.heroRef.current.forceUpdate();
                    tempImages.push({ original: "data:image/png;base64," + result.data.PrimaryImage, thumbnail: "data:image/png;base64," + result.data.PrimaryImage });
                }
                this.setState({
                    carouselImages: tempImages,
                    otherImages: tempImages
                });
                this.imagesRef.current.forceUpdate();
            })

            //Get the Pricing Range
            var minRate = 0;
            var maxRate = 0;
            if (result.data.Pricing.Nightly && result.data.Pricing.Weekend) {
                if (result.data.Pricing.Nightly < result.data.Pricing.Weekend) {
                    minRate = result.data.Pricing.Nightly;
                    maxRate = result.data.Pricing.Weekend
                } else {
                    minRate = result.data.Pricing.Weekend;
                    maxRate = result.data.Pricing.Nightly
                }
            } else if (result.data.Pricing.Nightly) {
                minRate = result.data.Pricing.Nightly;
                maxRate = result.data.Pricing.Nightly
            } else if (result.data.Pricing.Weekend) {
                minRate = result.data.Pricing.Weekend;
                maxRate = result.data.Pricing.Weekend
            }

            var today = new Date();
            var max = new Date(today);

            //get the number of months advanced and add to max
            if (result.data.TermsAndConditions.MaxBookingWindows == "All") {
                max.setMonth(today.getMonth() + 24);
            } else {
                max.setMonth(today.getMonth() + +result.data.TermsAndConditions.MaxBookingWindows);
            }

            //cycle the periods to get min and max
            ListingDetail.getListingPeriodsByID(
                ListingID,
                (('0' + (today.getMonth() + 1)).slice(-2) + '/' + ('0' + today.getDate()).slice(-2) + '/' + today.getFullYear()).toString(),
                (('0' + (max.getMonth() + 1)).slice(-2) + '/' + ('0' + max.getDate()).slice(-2) + '/' + max.getFullYear()).toString()
            ).then((periods) => {

                if (periods && typeof (periods.data) != 'string') {

                    periods.data.map(function (item, i) {
                        var nightly = parseInt(item.Nightly);
                        var weekend = parseInt(item.Weekend);

                        //if weekend is $0 set to nightly
                        if (weekend == 0) {
                            weekend = nightly;
                        }

                        if (nightly != 0) {
                            //find the min and max rates
                            if (nightly < minRate) {
                                minRate = item.Nightly;
                            } else if (nightly > maxRate) {
                                maxRate = item.Nightly;
                            }
                            if (weekend < minRate) {
                                minRate = item.Weekend;
                            } else if (weekend > maxRate) {
                                maxRate = item.Weekend;
                            }
                        }
                    });

                    PricingDetail.formatPrice(minRate).then((result) => {
                        minRate = result;
                        PricingDetail.formatPrice(maxRate).then((result) => {
                            maxRate = result;
                            this.setState({ nightlyFormatted: (minRate + '-' + maxRate) });
                        });
                    });
                } else {
                    PricingDetail.formatPrice(minRate).then((result) => {
                        minRate = result;
                        PricingDetail.formatPrice(maxRate).then((result) => {
                            maxRate = result;
                            this.setState({ nightlyFormatted: (minRate + '-' + maxRate) });
                        });
                    });
                }

            });

            //set map location
            this.setState({ center: { lat: result.data.Location.Latitude, lng: result.data.Location.Longitude } });
            this.setState({ stores: [{ latitude: result.data.Location.Latitude, longitude: result.data.Location.Longitude }] })

            //hide spinner and show card
            this.setState({
                spinner: 'none',
                listingShow: 'block',
                loaded: true,
            //});

            //this.setState({
                listingID: ListingID,
                checkin: CheckIN,
                guests: Guests,
                pets: Pets,
            });

        });

        //alert('Listing email: ' + this.state.userEmail);
    }

    displayMarkers = () => {
        return this.state.stores.map((store, index) => {
            return <Marker key={index} id={index} position={{
                lat: store.latitude,
                lng: store.longitude
            }}
                //onClick={() => console.log("You clicked me!")} 
            />
        })
    }

    requestBooking = (instantBooking, dailyPrice, incTaxes, exTaxes, condTaxes, exArriveTaxes, condArriveTaxes, incFees, exFees, condFees, exArriveFees, condArriveFees, total, deposit, security, serviceFee, intro, arrive, depart, guests, pets, nights, amountSaved, totalGST, guestGST, hostGST, promoCode, pmsFees) => {

        //check if logged in - true
        //if (!this.state.userEmail) {
        //show credit card entry modal
        //alert('modal popup');
        this.setState({ showCardEntry: true });
        this.setState({
            totalFee: total,
            totalGST: totalGST,
            guestGST: guestGST,
            hostGST: hostGST,
            //cardFees: cardFees,
            pmsFees: pmsFees
        });

        this.setState({ showBookingModal: false })
        this.setState({ instantBooking: instantBooking })

        //format for card entry
        PricingDetail.formatPrice(total).then((result) => {
            this.setState({ cardTotalFee: result });
        });

        //if deposit is $0 then total fees are payable
        if (deposit > 0) {
            PricingDetail.formatPrice(deposit).then((result) => {
                this.setState({ cardPayable: result });
            });

            this.setState({ payable: deposit });
        } else { //use total fees
            PricingDetail.formatPrice(total).then((result) => {
                this.setState({ cardPayable: result });
            });
            this.setState({ payable: total });
        }

        this.setState({
            arrive: arrive,
            depart: depart,
            guests: guests,
            pets: pets,
            nights: nights,
            dailyPrice: dailyPrice,
            inctaxes: incTaxes,
            extaxes: exTaxes,
            condtaxes: condTaxes,
            extaxesArrive: exArriveTaxes,
            condtaxesArrive: condArriveTaxes,
            incfees: incFees,
            exfees: exFees,
            condfees: condFees,
            exfeesArrive: exArriveFees,
            condfeesArrive: condArriveFees,
            promoCode: (promoCode ? promoCode.Code : "")
        });

        this.setState({ securityDeposit: security });

        this.setState({ serviceFee: serviceFee });
        this.setState({ intro: intro.toString() });

        this.setState({ amountSaved: amountSaved });

    }

    clickedmore = () => {
        var lines = this.state.maxLines + 3;

        //change this to add 3 lines at a time
        this.setState({
            maxLines: lines
        })
    }

    onPageLoadingListing = () => {
        this.setState({ min: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() });

        const { id, checkin, guests, pets } = this.props.match;
        var ListingID = id;

        //get the listing for information
        ListingDetail.getListingByID(ListingID).then((result) => {
            var today = new Date();
            var max = new Date(today);

            //check the max booking window is all and update below
            if (result.data.TermsAndConditions.MaxBookingWindows == "All") {
                max.setMonth(today.getMonth() + 24);
            } else {
                max.setMonth(today.getMonth() + +result.data.TermsAndConditions.MaxBookingWindows);
            }

            this.setState({ max: max.getFullYear() + '-' + ('0' + (max.getMonth() + 1)).slice(-2) + '-' + ('0' + max.getDate()).slice(-2) + 'T00:00' });

            //get listing periods
            var periodArray = [];
            ListingDetail.getListingPeriodsByID(
                ListingID,
                (('0' + (today.getMonth() + 1)).slice(-2) + '/' + ('0' + today.getDate()).slice(-2) + '/' + today.getFullYear()).toString(),
                (('0' + (max.getMonth() + 1)).slice(-2) + '/' + ('0' + max.getDate()).slice(-2) + '/' + max.getFullYear()).toString()
            ).then((periods) => {
                if (periods && typeof (periods.data) != 'string') {
                    periods.data.map(function (item, i) {
                        var date = item.Date.toString().split('T');
                        periodArray = [...periodArray, date[0]];
                    })
                }

                const blockedDates = result.data.Calendar;
                //strip out dates only for simple matching below
                var blockedArray = [];
                blockedDates.map(function (item, i) {
                    //blockedArray = [...blockedArray, item.Date];
                    var date = new Date(item.Date);

                    blockedArray = [...blockedArray, (('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear()).toString()];
                })
                    //blockedDates.map(function (item, i) {
                    //    blockedArray = [...blockedArray, item.Date];
                    //})

                //set labels for pricing
                //build array to set state once
                var buildLabels = [];
                var buildInvalid = [];
                var buildColors = [];

                var weekend = result.data.Pricing.WeekendRate;

                for (let i = today; i < max; (i.setDate(i.getDate() + 1))) {

                    var dateCompare = (('0' + (i.getMonth() + 1)).slice(-2) + '/' + ('0' + i.getDate()).slice(-2) + '/' + i.getFullYear()).toString();
                    var prevDate = (('0' + (i.getMonth() + 1)).slice(-2) + '/' + ('0' + (i.getDate() - 1)).slice(-2) + '/' + i.getFullYear()).toString();
                    var nextDate = (('0' + (i.getMonth() + 1)).slice(-2) + '/' + ('0' + (i.getDate() + 1)).slice(-2) + '/' + i.getFullYear()).toString();

                    //Get the date formatted to compare to period
                    var periodCompare = (i.getFullYear() + '-' + ('0' + (i.getMonth() + 1)).slice(-2) + '-' + ('0' + i.getDate()).slice(-2)).toString()

                    //Reset the nightly and weekend rates each loop
                    var nightlyRate = result.data.Pricing.Nightly;
                    var weekendRate = result.data.Pricing.Weekend;

                    if (!weekendRate || weekendRate == 0) {
                        weekendRate = nightlyRate;
                    }

                    //Compare and change rates if exists
                    if (periodArray.indexOf(periodCompare) > -1) {

                        var tempNight = periods.data[periodArray.indexOf(periodCompare)].Nightly;
                        var tempWeekend = periods.data[periodArray.indexOf(periodCompare)].Weekend;

                        if (tempNight && tempNight != 0) {
                            nightlyRate = tempNight;
                        }
                        if (tempWeekend && tempWeekend != 0) {
                            weekendRate = tempWeekend;
                        }

                        if (!weekendRate) {
                            weekendRate = nightlyRate;
                        }

                    }

                    nightlyRate = formatter.format(nightlyRate);
                    weekendRate = formatter.format(weekendRate);

                    if (blockedArray.indexOf(dateCompare) > -1) {
                        buildLabels = [...buildLabels, {
                            date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                            text: 'booked',
                            textColor: '#1e1e1ecc'
                        }]

                        buildInvalid = [...buildInvalid, {
                            date: new Date(i.getFullYear(), i.getMonth(), i.getDate())
                        }]

                        if ((blockedArray.indexOf(prevDate) > -1) && (blockedArray.indexOf(nextDate) > -1)) {
                            //this is a middle date
                            buildColors = [...buildColors, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                background: '#ffbaba80',
                                cellCssClass: 'vacation-booked'
                            }, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                background: '#b2f1c080',
                                cellCssClass: 'md-book-rental-bg-off'
                            }]

                        } else if (blockedArray.indexOf(nextDate) > -1) {
                            //next exist therefore this is a first date
                            buildColors = [...buildColors, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                //date: new Date(2022, 1, 12),
                                cellCssClass: 'vacation-check-in'
                            }, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                background: '#b2f1c080',
                                cellCssClass: 'md-book-rental-bg-off'
                            }]
                        } else if (blockedArray.indexOf(prevDate) > -1) {
                            //previous exist therefore a last date
                            buildColors = [...buildColors, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                cellCssClass: 'vacation-check-out'
                            }, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                background: '#b2f1c080',
                                cellCssClass: 'md-book-rental-bg-off'
                            }]
                        }

                    } else {

                        //check what the listing has as weekends
                        //Friday = 5, Saturday = 6, Sunday = 0, 
                        if (i.getDay() == 5 && weekend.includes('Friday')) {
                            buildLabels = [...buildLabels, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                text: weekendRate,
                                textColor: '#1e1e1ecc'
                            }]
                        } else if (i.getDay() == 6 && weekend.includes('Saturday')) {
                            buildLabels = [...buildLabels, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                text: weekendRate,
                                textColor: '#1e1e1ecc'
                            }]
                        } else if (i.getDay() == 0 && weekend.includes('Sunday')) {
                            buildLabels = [...buildLabels, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                text: weekendRate,
                                textColor: '#1e1e1ecc'
                            }]
                        } else {
                            buildLabels = [...buildLabels, {
                                date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                                text: nightlyRate,
                                textColor: '#1e1e1ecc',
                                /*cellCssClass: 'calAmountCenter'*/
                            }]
                        }

                        buildColors = [...buildColors, {
                            date: new Date(i.getFullYear(), i.getMonth(), i.getDate()),
                            background: '#b2f1c080',
                            cellCssClass: 'md-book-rental-bg-off'
                        }]
                    }
                }

                this.setState({
                    labels: buildLabels,
                    invalid: buildInvalid,
                    colors: buildColors,
                    max: max.getFullYear() + '-' + ('0' + (max.getMonth() + 1)).slice(-2) + '-' + ('0' + max.getDate()).slice(-2) + 'T00:00'
                })
            })
        })
    }

    renderAmenities = () => {
        //const rows = [];
        //const col1 = [];
        //const col2 = [];
        if (this.state.features) {

            return (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {this.state.features.map((feature, index) =>
                        <div style={{ paddingRight: "30px", paddingBottom: "20px" }}>
                            <FaAngleRight /> {feature.Feature}
                        </div>
                    )}
                </div>
            );
        }



        //this.state.features.map((feature, index) => {
        //    if (index % 2 === 0) {
        //        col1.push(<Col md="4" sm="6" >
        //            <FaAngleRight /> {feature.Feature}
        //        </Col>
        //        )
        //    } else {
        //        col2.push(
        //            <Col md="4" sm="6" >
        //                <FaAngleRight /> {feature.Feature}
        //            </Col>
        //        )
        //    }
        //})
        //for (let index = 0; index < col1.length; index++) {
        //    rows.push(
        //        <Row>
        //            <Col md="4" sm="0" >
        //            </Col>
        //            {col1[index]}
        //            {col2[index]}
        //        </Row>
        //    )
        //}
        //return rows
    }

    renderFacilities = () => {
        //const rows = [];
        //const col1 = [];
        //const col2 = [];

        if (this.state.facilities) {

            return (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {this.state.facilities.map((facility, index) =>
                        <div style={{ paddingRight: "30px", paddingBottom: "20px" }}>
                            <FaAngleRight /> {facility.Feature}
                        </div>
                    )}
                </div>
            );
        }
        //if (this.state.facilities) {
        //    this.state.facilities.map((facility, index) => {
        //        if (index % 2 === 0) {
        //            col1.push(<Col md="4" sm="6" >
        //                <FaAngleRight /> {facility.Feature}
        //            </Col>
        //            )
        //        } else {
        //            col2.push(
        //                <Col md="4" sm="6" >
        //                    <FaAngleRight /> {facility.Feature}
        //                </Col>
        //            )
        //        }
        //    })
        //};

        //for (let index = 0; index < col1.length; index++) {
        //    rows.push(
        //        <Row>
        //            <Col md="4" sm="0" >
        //            </Col>
        //            {col1[index]}
        //            {col2[index]}
        //        </Row>
        //    )
        //}
        //return rows
    }

    renderBedrooms = () => {
        const rows = [];
        const col1 = [];
        const col2 = [];

        if (this.state.rooms) {
            this.state.rooms.map((room, index) => {
                //if (index % 2 === 0) {
                col1.push(
                    <Col xs="6" sm="3" style={{ textAlign: "center", color: "rgb(166, 166, 166)" }} >
                        <FaBed size="50px" style={{ paddingBottom: "5px" }} /> <br />
                        {room.RoomName} <br />
                        <strong style={{ color: "black", paddingTop: "10px" }}>{room.BedType}</strong>
                    </Col>

                )

                
                //    )
                //} else {
                //    col2.push(
                //        <Col xs="6" sm="3" >
                //            <strong>{room.RoomName}</strong> <br />
                //            {room.BedType}
                //        </Col>
                //    )
                //}
            });
            //this.setState({ showAccomodationLabel: 'block' });
        } 

        //for (let index = 0; index < col1.length; index++) {
        //    rows.push(
        //        <Row>
        //            <Col md="4" sm="0" >
        //            </Col>
        //            {col1[index]}
        //            {col2[index]}
        //        </Row>
        //    )
        //}
        return col1
    }

    renderTermsAndConditions = (key, value) => {
        const rows = [];
        const col1 = [];
        const col2 = [];
        let result = '';
        if (key === "Yes") {
            result = <Col md="4" xs="6" >
                <FaCheck /> {value} allowed:
            </Col>
        } else {
            result = <Col md="4" xs="6" >
                <FaTimes /> {value} allowed:
            </Col>
        }

        col1.push(result);
        col2.push(
            <Col md="4" xs="6" >
                <strong>{key}</strong>
            </Col>
        )

        for (let index = 0; index < col1.length; index++) {
            rows.push(
                <Row>
                    <Col md="4" xs="0" >
                    </Col>
                    {col1[index]}
                    {col2[index]}
                </Row>
            )
        }
        return rows
    }

    showLoginModal = () => {
        this.props.showLoginModal && this.props.showLoginModal();
    }

    showForgotPassword = () => {
        this.props.showForgotPassword && this.props.showForgotPassword();
    }

    showBookingModal = () => {
        //clear the reservation ID in case the user is making a second booking
        this.setState({
            showBookingModal: true,
            reservationUUID: ""
        })
    }
    hideBookingModal = (event) => {
        this.setState({
            showBookingModal: false,
            reservationUUID: ""
        })
    }

    showImagesFullScreen = () => {
        this.imagesRef.current.fullScreen()
    }


    updateMessage = (e) => {
        this.setState({ message: e.target.value });
    }

    sendMessage = () => {
        if (this.state.message != "") {
            this.setState({ messageLoading: "block" });

            MessagesDetail.createMessage(this.props.userID, this.state.owner.OwnerID, null, null, this.state.message, this.state.listingID, null).then(result => {
                this.setState({ messageLoading: "none" });
                //clear the text
                this.setState({ message: "" });
            })
        }
    }


    render() {

        const { id, checkin, guests, pets } = this.props.match;

        const mapStyles = {
            left: '0',
            top: '0',
            height: '100%',
            width: '100%',
            position: 'absolute'
        };

        const uluru = { lat: -25.344, lng: 131.036 };

        const carouselSettings = {
            showThumbs: false,
            interval: 2000,
            autoPlay: false,
            showArrows: true,
            showStatus: true,
            swipeable: true,
            emulateTouch: true
        };

        if (this.state.showLoading) {
            return (
                <div class="loading-overlay" >
                    <div class="loading-vertical">
                        <Grid
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={0}
                        />
                    </div>
                </div>
            )
        } else {
            return (

                <div style={{ paddingTop: "80px" }}>
                    <Modal show={this.state.showFailure} size="lg" style={{ textAlign: "center" }}>
                        <Modal.Body>
                            <h4>OH NO! YOUR RESERVATION REQUEST HAS NOT BEEN PROCESSED</h4>
                            <div>
                                Something has gone wrong in our system, please try again shortly.<br />
                                If the problem persists please let us know so we can help you, <a href="mailto:admin@aussieholidaystays.com.au">send email</a>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-primary btn-block" onClick={() => this.hideSuccess()} style={{ width: "100%" }}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showSuccess} size="lg" style={{ textAlign: "center" }}>
                        <Modal.Body>
                            <h4>HOORAY! YOUR RESERVATION REQUEST HAS BEEN SENT</h4>
                            <div>
                                Your host has 48 hours to accept your reservation. <br />
                                When they do accept your credit card will be debited and you will be notified.
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-primary btn-block" onClick={() => this.hideSuccess()} style={{ width: "100%" }}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showInstantSuccess} size="lg" style={{ textAlign: "center" }}>
                        <Modal.Body>
                            <h4>HOORAY! YOUR RESERVATION REQUEST HAS BEEN BOOKED</h4>
                            <div>
                                Your host has 1 hour to decline your reservation. <br />
                                If your reservation is not declined in this time your credit card will be debited and you will be notified.
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-primary btn-block" onClick={() => this.hideSuccess()} style={{ width: "100%" }}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <div class="container-fluid px-0">
                        <Row>
                            <div class="col-12 px-0" >

                                {this.state.carouselImages.length > 1 ?
                                    <Carousel

                                        infiniteLoop
                                        centerMode
                                        centerSlidePercentage={60}
                                        {...carouselSettings}
                                    >
                                        {this.state.carouselImages.map((image, index) =>
                                            <div >
                                                <img src={image.original} alt="" className="listingImageSlider" />
                                            </div>
                                        )}
                                    </Carousel> :
                                    <div style={{ width: "100%", textAlign: "center" }}>
                                        <img src={"data:image/png;base64," + this.state.primaryImage} alt="" className="listingImageSlider" style={{ width: "60%" }} />
                                    </div>
                                }

                            </div>
                            {/*Overlay Button to View all images*/}
                            <div style={{ width: "100%", textAlign: "right", height: "0px" }} >
                                <Button color="info" onClick={this.showImagesFullScreen} style={{ position: "relative", top: "-85px", right: "20px", zIndex: "99", height: "30px" }}>More Images</Button>
                            </div>

                            <div style={{ width: "100%", textAlign: "right", height: "0px" }}>
                                <FaRegHeart className="listing-favourite" style={{ color: "red", display: this.state.fav ? "none" : "inline" }} onClick={this.switchFavourite} />
                                <FaHeart className="listing-favourite" style={{ color: "red", display: this.state.fav ? "inline" : "none" }} onClick={this.switchFavourite} />
                            </div>

                            <CardModal
                                showCardEntry={this.state.showCardEntry}
                                onClose={this.closeCardEntry}
                                hideCardEntry={this.hideCardEntry}
                                totalFees={this.state.cardTotalFee}
                                payable={this.state.cardPayable}
                                createReservation={this.createReservation}
                                showLoginModal={this.showLoginModal}
                                email={this.props.email}
                                showForgotPassword={this.showForgotPassword}
                                //reservationUUID={this.state.reservationUUID}
                                ref={this.cardModalRef}
                                listingID={this.state.listingID}
                            />
                        </Row>
                        <Row className="justify-content-xl-center px-0">
                            <div class="col-xl-7 col-lg-8 col-md-7 col-sm-12 px-0" >
                                <Card style={{ paddingTop: "10px" }}>
                                    <div style={{ display: this.state.spinner, width: '100%', textAlign: 'center' }}>
                                        <Grid
                                            color="#00BFFF"
                                            height={100}
                                            width={100}
                                            timeout={0} //in milliseconds
                                        />
                                    </div>

                                    <Card.Body style={{ display: this.state.listingShow }}>
                                        <div style={{ width: '100%' }}>
                                            <h1><span style={{ color: '#e96852' }}>{this.state.information.Title}</span></h1>
                                            <hr />
                                        </div>
                                        <Row style={{ color: "rgb(166, 166, 166)" }}>
                                            <Col xs="6" sm="3" style={{ border: "1px solid rgb(219, 217, 217)", textAlign: "center" }}>
                                                <FaHome size="50px" /> <br /><br />
                                                TYPE<br /><br />
                                                <strong style={{ color: "black" }}>{this.state.information.Kind} /  {this.state.information.TypeOfListing}</strong>
                                            </Col>
                                            <Col xs="6" sm="3" style={{ border: "1px solid rgb(219, 217, 217)", textAlign: "center" }}>
                                                <FaUserAlt size="50px" /> <br /><br />
                                                SLEEPS <br /><br />
                                                <strong style={{ color: "black" }}>{this.state.pricing.MaximumNumberOfGuests} Guests</strong>
                                            </Col>
                                            <Col xs="6" sm="3" style={{ border: "1px solid rgb(219, 217, 217)", textAlign: "center" }} >
                                                <FaBed size="50px" /> <br /><br />
                                                BEDROOMS <br /><br />
                                                <strong style={{ color: "black" }}>{this.state.information.NumberOfBedrooms} Bedrooms / {this.state.information.NumberOfBeds} Beds</strong>
                                            </Col>
                                            <Col xs="6" sm="3" style={{ border: "1px solid rgb(219, 217, 217)", textAlign: "center" }} >
                                                <FaShower size="50px" /> <br /><br />
                                                BATHROOMS <br /><br />
                                                <strong style={{ color: "black" }}>{this.state.information.NumberOfBathrooms} Full</strong>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row className="justify-content-md-center">
                                            <h2>About this listing</h2>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.information.Description }} />
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col md="4" sm="12" >
                                                <h4>Details</h4>
                                            </Col>
                                            <Col md="4" sm="6" >
                                                <FaAngleRight /> Standard Number of Guests: <strong>{this.state.pricing.StandardNumberOfGuests}</strong><br />
                                                <FaAngleRight /> Maximum Guests: <strong>{this.state.pricing.MaximumNumberOfGuests}</strong><br />
                                                <FaAngleRight /> Bedrooms: <strong>{this.state.information.NumberOfBedrooms}</strong><br />
                                                <FaAngleRight /> Beds: <strong>{this.state.information.NumberOfBeds}</strong><br />
                                            </Col>
                                            <Col md="4" sm="6" >
                                                <FaAngleRight /> Bathrooms: <strong>{this.state.information.NumberOfBathrooms}</strong><br />
                                                <FaAngleRight /> Check-in After: <strong>{this.state.termsAndConditions.CheckInAfter}</strong><br />
                                                <FaAngleRight /> Check-out Before: <strong>{this.state.termsAndConditions.CheckOutBefore}</strong><br />
                                                <FaAngleRight /> Type: <strong>{this.state.information.Kind}/{this.state.information.TypeOfListing}</strong><br />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col md="4" sm="12" >
                                                <h4>Prices From</h4>
                                            </Col>
                                            <Col md="4" sm="6" >
                                                <FaAngleRight /> Nightly: <strong>{this.state.nightlyFormatted}</strong><br />
                                                <FaAngleRight /> Security Bond: <strong>${this.state.pricing.SecurityDeposit}</strong><br />
                                                {/*<FaAngleRight /> Allow Additional Guests: <strong>{(this.state.pricing.AllowAdditionalGuests == 1 ? 'Yes' : 'No')}</strong><br />*/}
                                                <FaAngleRight /> Allow Additional Guests: <strong>{(this.state.pricing.AllowAdditionalGuests)}</strong><br />
                                            </Col>
                                            <Col md="4" sm="6" >
                                                <FaAngleRight /> Minimum Number of Days: <strong>{this.state.termsAndConditions.MinimumBooking}</strong><br />
                                                <FaAngleRight /> Maximum Number of Days: <strong>{this.state.termsAndConditions.MaximumBooking}</strong><br />
                                                <FaAngleRight /> Price per Additional Guest: <strong>{this.state.pricing.AdditionalGuestCost}</strong><br />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4" >
                                            </Col>
                                            <Col md="8" >
                                                <small><FaStar /> <i>Seasonal rates and additional fees do apply please refer to booking calendar and quote for daily rates/fees</i></small>
                                            </Col>
                                        </Row>
                                        <ImageGallery ref={this.imagesRef} items={this.state.otherImages} />
                                        <br />
                                        <Row style={{ display: (this.state.rooms ? 'block' : 'none') }}>
                                            <Col xs="12" >
                                                <h4>Accommodation</h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {this.renderBedrooms()}
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col xs="12" >
                                                <h4>Amenities</h4>
                                            </Col>
                                        </Row>
                                        {this.renderAmenities()}

                                        <Row>
                                            <Col xs="12" >
                                                <h4>Facilities</h4>
                                            </Col>
                                        </Row>
                                        {this.renderFacilities()}

                                        <Row>
                                            <Col md="12" className="map-responsive" >
                                                <Map
                                                    google={this.props.google}
                                                    zoom={8}
                                                    style={mapStyles}
                                                    center={this.state.center}
                                                    initialCenter={uluru}
                                                    maxZoom={14}
                                                >
                                                    {this.displayMarkers()}
                                                </Map>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="12" xs="12">
                                                <h4>Terms & Rules</h4>
                                                {this.renderTermsAndConditions((this.state.termsAndConditions.Smoking), "Smoking")}
                                                {this.renderTermsAndConditions((this.state.termsAndConditions.Pets), "Pets")}
                                                {this.renderTermsAndConditions((this.state.termsAndConditions.Party), "Party")}
                                                {this.renderTermsAndConditions((this.state.termsAndConditions.Children), "Children")}
                                                
                                            </Col>
                                        </Row>
                                        
                                        <Row style={{ display: (this.state.termsAndConditions.Optional ? "" : "none") }}>
                                            <Col md="4" >
                                                <h4>Additional Rules Information</h4>
                                            </Col>
                                            <Col md="8" style={{ whiteSpace: 'pre-line' }}>

                                                <LinesEllipsis
                                                    text={this.state.termsAndConditions.Optional}
                                                    maxLine={this.state.maxLines}
                                                    ellipsis='... read more'
                                                    trimRight
                                                    component="div"
                                                    onClick={this.clickedmore}
                                                />

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4" >
                                                <h4>Cancellation Policy</h4>
                                            </Col>
                                            <Col md="8" >
                                                The deposit and final payment is non-refundable when paid. If there are extenuating circumstances, please contact the host. It will be at their discretion whether a refund is provided and cancellation fees may apply.
                                                <br /><br />
                                                We strongly recommend you take out insurance to mitigate your risks.
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="4" >
                                                <h4>Availability</h4>
                                            </Col>
                                            <Col md="4" >
                                                The minimum stay is <strong>{this.state.termsAndConditions.MinimumBooking} Nights</strong>
                                            </Col>
                                            <Col md="4" >
                                                The maximum stay is <strong>{this.state.termsAndConditions.MaximumBooking} Nights</strong>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="12" >
                                                <div className="mbsc-form-group" >
                                                    <Datepicker
                                                        theme="material"
                                                        themeVariant='light'
                                                        display="inline"
                                                        controls={['calendar']}
                                                        select="none"
                                                        min={this.state.min}
                                                        max={this.state.max}
                                                        rangeHighlight={false}
                                                        showRangeLabels={false}
                                                        pages={(window.innerWidth <= 768 ? "1" : "2")}
                                                        onPageLoading={this.onPageLoadingListing}
                                                        labels={this.state.labels}
                                                        invalid={this.state.invalid}
                                                        colors={this.state.colors}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12" >
                                                <div class="loading-listing-message" style={{ display: this.state.messageLoading }}>
                                                    <div class="loading-vertical">
                                                        <Grid
                                                            color="#00BFFF"
                                                            height={100}
                                                            width={100}
                                                            timeout={0}
                                                        />
                                                    </div>
                                                </div>
                                                <Row>
                                                    <Col>
                                                        <h4>Send host a message</h4>
                                                    </Col>
                                                </Row>
                                                <Row style={{ display: this.props.email == "" ? 'inline' : 'none' }}>
                                                    <Button className="btn btn-secondary btn-block" onClick={() => this.showLoginModal()} style={{ width: '100%' }}>Login</Button>
                                                </Row>
                                                <Row style={{ display: this.props.email == "" ? 'none' : 'inline' }}>
                                                    <Col>
                                                        <Textarea style={{ minHeight: "120px" }} value={this.state.message} onChange={(e) => this.updateMessage(e)} />
                                                    </Col>
                                                </Row>
                                                <Row style={{ display: this.props.email == "" ? 'none' : 'inline' }}>
                                                    <Col>
                                                        <Button disabled={this.state.messageLoading == 'block' ? true : false} className="btn btn-secondary btn-block" onClick={() => this.sendMessage()} style={{ width: '100%' }}>Send Message</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12" md="12">
                                                <h4>Reviews</h4>
                                            </Col>
                                            <Col>
                                                {
                                                    this.state.reviews && this.state.reviews.length > 0 ?
                                                        this.state.reviews.map((review, index) =>
                                                            <div style={{ width: '100%' }}>
                                                                {index > 0 ? <hr /> : ""}
                                                                <ReviewItem
                                                                    rating={review.Rating}
                                                                    title={review.Title}
                                                                    review={review.Review}
                                                                    created={review.CreatedDate}
                                                                />
                                                            </div>
                                                        ) : <strong>No reviews found for listing</strong>
                                                }
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div class="d-none d-md-block col-2 px-0" style={{ marginLeft: "10px" }}>
                                {/*<Booking reservationUUID={this.state.reservationUUID} showRequestToBook={this.requestBooking} id={this.props.location.state.id} checkin={this.props.location.state.checkin } guests={this.props.location.state.guests } pets={this.props.location.state.pets }/>*/}
                                <Booking reservationUUID={this.state.reservationUUID} showRequestToBook={this.requestBooking} id={id} checkin={this.state.checkin} guests={this.state.guests} pets={this.state.pets} userID={this.state.userID} ownerID={this.state.owner.OwnerID} listingID={this.state.listingID} state={this.state.location.State} />
                            </div>
                        </Row>
                        {/*Reinstate below once we fill in the host details*/}

                       
                    </div>

                    <div className="d-sm-block d-md-none book-button">
                        <Button onClick={this.showBookingModal} style={{ width: "100%" }} className="btn btn-secondary btn-block">book now!</Button>
                    </div>

                    <Modal show={this.state.showBookingModal} size="lg">
                        <Modal.Header closeButton onClick={() => this.hideBookingModal()}>
                            {/*<Modal.Title>Payment</Modal.Title>*/}
                        </Modal.Header>
                        <Modal.Body>
                            {/*<Booking reservationUUID={this.state.reservationUUID} showRequestToBook={this.requestBooking} id={this.props.location.state.id} checkin={this.props.location.state.checkin} guests={this.props.location.state.guests} pets={this.props.location.state.pets} />*/}
                            <Booking reservationUUID={this.state.reservationUUID} showRequestToBook={this.requestBooking} id={id} checkin={checkin} guests={guests} pets={pets} userID={this.state.userID} ownerID={this.state.owner.OwnerID} listingID={this.state.listingID} state={this.state.location.State} />
                        </Modal.Body>
                    </Modal>

                </div>
            )
        }
    }
}

export default withRouter(GoogleApiWrapper({
    apiKey: Globals.googleAPI
})(Listing));