import { Input } from '@mobiscroll/react';
import React, { Component } from 'react';
//import { useParams, useLocation } from "react-router-dom";
import { Alert, Button, Card, Col, Modal, Row } from 'react-bootstrap';
import SideNav from "./../modals/SideNav";

import UserDetail from './../functions/User';
import AsyncStorage from "@react-native-async-storage/async-storage";

import { FaUserSecret } from 'react-icons/fa';
import { FaEyeSlash } from 'react-icons/fa';
import { FaThumbsUp } from 'react-icons/fa';


export function withRouter(Children) {
    return (props) => {
        return <Children {...props} location={props} />
    }
}

class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            loggedIn: false,
            showCopyAlert: 'none',
            showCopyAlertVerify: 'none'
        }
    }

    formatStatus = (input) => {
        switch (input) {
            //case 'true':
            //    return 'label label-primary';
            //    break;
            case false:
                return 'label label-secondary';
                //break;
            case true:
                return 'label label-success';
                //break;
            default:
                return 'label label-secondary';
        }
    }

    filterByName = (e) => {
        //setShowLoading('block');

        const list = this.state.defaultUserList.filter(function (user) { return user.Firstname.toLowerCase().includes(e.target.value.toLowerCase()); });

        //setListings([]);
        setTimeout(function () { //Start the timer
            this.setState({ users: list });
            //setShowLoading('none');
        }.bind(this), 500)

    }

    showResetPasswordClick = (uuid) => {
        this.setState({
            showResetPasswordModal: true,
            showCopyAlert: 'none',
            downloadlink: "https://aussieholidaystays.com.au/passwordreset/" + uuid
        })
    }

    hideResetPasswordModal = () => {
        this.setState({
            showResetPasswordModal: false,
            showCopyAlert: 'none',
        })
    }

    showVerifyClick = (uuid) => {
        this.setState({
            showVerifyModal: true,
            showCopyAlertVerify: 'none',
            downloadlinkVerify: "https://aussieholidaystays.com.au/verify/" + uuid
        })
    }

    hideVerifyModal = () => {
        this.setState({
            showVerifyModal: false,
            showCopyAlertVerify: 'none',
        })
    }

    showImpersonateClick = (uuid) => {
        this.setState({
            showImpersonateModal: true,
            impersonateUUID: uuid
        })
    }

    hideImpersonateModal = () => {
        this.setState({
            showImpersonateModal: false
        })
    }

    copyURL = () => {
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(this.state.downloadlink);

        /* Alert the copied text */
        this.setState({
            showCopyAlert: 'block'
        })
    }

    copyURLVerify = () => {
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(this.state.downloadlinkVerify);

        /* Alert the copied text */
        this.setState({
            showCopyAlertVerify: 'block'
        })
    }

    impersonate = async () => {

        //store a token for auto login and new tabs
        await AsyncStorage.setItem("token", this.state.impersonateUUID);
        window.sessionStorage.setItem("token", this.state.impersonateUUID);
        localStorage.setItem("tokencleared", "false");

        //refresh to home page
        window.location.href = "/";

    }

    componentDidMount() {

        //get user id by email
        UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {
            if (result.data.LoginType === "admin") {
                //get list of users
                UserDetail.getUsers().then((userResult) => {
                    this.setState({
                        users: userResult.data,
                        defaultUserList: userResult.data
                    });
                })
            }
        })

    }

    render() {
        return (
            <div style={{ paddingTop: "80px" }}>
                <Row>
                    <Col className="d-none d-lg-block" lg="2" xl="1">
                        <SideNav
                            loggedIn={this.props.location.state.loggedIn}
                            loginType={this.props.location.state.loginType}
                            email={this.props.location.state.email}
                            activeTab='users'
                        />
                    </Col>
                    <Col md="12" lg="10" xl="11">
                        <div style={{ width: '100%' }}>
                            <h1>Users</h1>
                            <hr />
                        </div>

                        <Card>
                            <Card.Body style={{ paddingRight: "0px" }}>
                                <Row>
                                    <Col>
                                        <Input className="mbsc-remove-margins" label="Firstname Search" inputStyle="box" labelStyle="floating" placeholder="Start Typing" autoComplete="off" onChange={(e) => this.filterByName(e)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Row>
                                        <Col md="2" sm="3" xs="3"><h5>Status</h5></Col>
                                        <Col md="3" sm="4" xs="3"><h5>Email</h5></Col>
                                        <Col md="2" sm="2" xs="2"><h5>Firstname</h5></Col>
                                        <Col md="2" className="d-none d-md-block"><h5>Lastname</h5></Col>
                                        <Col md="3" sm="3" xs="3"><h5>Actions</h5></Col>
                                    </Row>
                                    {
                                        this.state.users && this.state.users.length > 0 ?
                                            this.state.users.map((user, index) =>
                                                <div style={{ width: '100%' }}>
                                                    <div class="d-none d-md-block">
                                                    <div>
                                                        <Row md="12" sm="12" xs="12" style={{ height: '50px', borderBottom: '1px solid #808080', paddingTop: '6px' }} >
                                                            <Col md="2" sm="3" xs="3">
                                                                    <span className={this.formatStatus(user.Valid)}>{user.Valid ? 'Valid' : 'Not Valid'}</span>
                                                            </Col>
                                                            <Col md="3" sm="4" xs="4">
                                                                {user.Email}
                                                            </Col>
                                                            <Col md="2" sm="2" xs="2">
                                                                {user.Firstname}
                                                            </Col>
                                                            <Col md="2" className="d-none d-md-block">
                                                                {user.Lastname}
                                                            </Col>
                                                                <Col md="3" sm="3" xs="3">
                                                                    {/*<Button size="sm" onClick={() => this.showResetPasswordClick(user.UUID)} >Reset Password</Button>*/}
                                                                    {/*<Button size="sm" onClick={() => this.showVerifyClick(user.UUID)} >Verify User</Button>*/}
                                                                    {/*<Button size="sm" onClick={() => this.showVerifyClick(user.UUID)} >Impersonate</Button>*/}


                                                                    <Button variant="link" title="Reset Password" className="icon-button" onClick={() => this.showResetPasswordClick(user.UUID)} ><FaEyeSlash size={28} color="lightblue" /></Button>
                                                                    <Button variant="link" title="Verify User" className="icon-button" onClick={() => this.showVerifyClick(user.UUID)} ><FaThumbsUp size={28} color="lightblue" /></Button>
                                                                    <Button variant="link" title="Impersonate" className="icon-button" onClick={() => this.showImpersonateClick(user.UUID)} ><FaUserSecret size={28} color="lightblue" /></Button>

                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    </div>
                                                </div>
                                            ) :
                                            <Row>
                                            </Row>
                                    }

                                </Row>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>

                <Modal show={this.state.showResetPasswordModal} size="md" centered>
                    <Modal.Header closeButton onClick={() => this.hideResetPasswordModal()}>
                        <Modal.Title>Reset Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ display: this.state.showCopyAlert, width: '100%' }}>
                            <Alert variant="success" >
                                URL Copied to Clipboard
                            </Alert>
                        </div>
                        <div style={{ wordWrap: 'break-word' }}>
                            <h4>URL:</h4>
                            <a href={this.state.downloadlink} target="_blank">{this.state.downloadlink}</a>
                        </div>
                        <hr />
                        <Button variant="success" onClick={() => this.copyURL()} style={{ width: "100%" }}>Copy URL</Button>
                        <br />
                        <br />
                        <Button variant="secondary" onClick={() => this.hideResetPasswordModal()} style={{ width: "100%" }}>Close</Button>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showVerifyModal} size="md" centered>
                    <Modal.Header closeButton onClick={() => this.hideVerifyModal()}>
                        <Modal.Title>Verify User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ display: this.state.showCopyAlertVerify, width: '100%' }}>
                            <Alert variant="success" >
                                URL Copied to Clipboard
                            </Alert>
                        </div>
                        <div style={{ wordWrap: 'break-word' }}>
                            <h4>URL:</h4>
                            <a href={this.state.downloadlinkVerify} target="_blank">{this.state.downloadlinkVerify}</a>
                        </div>
                        <hr />
                        <Button variant="success" onClick={() => this.copyURLVerify()} style={{ width: "100%" }}>Copy URL</Button>
                        <br />
                        <br />
                        <Button variant="secondary" onClick={() => this.hideVerifyModal()} style={{ width: "100%" }}>Close</Button>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showImpersonateModal} size="md" centered>
                    <Modal.Header closeButton onClick={() => this.hideImpersonateModal()}>
                        <Modal.Title>Impersonate User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ wordWrap: 'break-word' }}>
                            <h4>Page will refresh</h4>
                            You will be logged out as admin and be active as this user.
                            To go back to admin logout and back in.
                        </div>
                        <hr />
                        <Button variant="success" onClick={() => this.impersonate()} style={{ width: "100%" }}>Confirm Impersonate</Button>
                        <br />
                        <br />
                        <Button variant="secondary" onClick={() => this.hideImpersonateModal()} style={{ width: "100%" }}>Close</Button>
                    </Modal.Body>
                </Modal>

            </div>
        )
    }
}

export default withRouter(Users)