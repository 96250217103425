// interface to the backend database handlers to act on the Reservation database
import axios from 'axios';

import { BASE_URL } from './GlobalDefinitions';
import { AUTH, ORIGIN, METHODS_GET, METHODS_POST, METHODS_PUT, METHODS_DELETE, CONTENT_TYPE } from './Headers';

///////////// API ENDPOINTS
// Reservation object - Get
const RESERVATIONOBJECT_API_BASE_URL = "/reservationobject";
const RESERVATIONOBJECT_BY_LISTING_ID_API_BASE_URL = "/reservationobjectbylistingid";
const RESERVATIONOBJECT_BY_RESERVATION_ID_API_BASE_URL = "/reservationobjectbyreservationid";
const RESERVATIONOBJECT_BY_CUSTOMER_ID_API_BASE_URL = "/reservationobjectbycustomerid";
const RESERVATIONOBJECT_BY_OWNER_ID_API_BASE_URL = "/reservationobjectbyownerid";

// Reservation - Create/Update/Delete
const RESERVATION_API_BASE_URL = "/reservation";
const RESERVATION_BY_LISTING_ID_API_BASE_URL = "/reservationbylistingid";
const RESERVATION_BY_RESERVATION_ID_API_BASE_URL = "/reservationbyreservationid";
const RESERVATION_DECLINE_API_BASE_URL = "/reservationdecline";
const RESERVATION_CANCEL_API_BASE_URL = "/reservationcancel";

// Payment - Get/Update/Delete
const PAYMENT_API_BASE_URL = "reservationpayment";
const PAYMENT_BY_RESERVATION_ID_API_BASE_URL = "/reservationpaymentbyreservationid";
const PAYMENT_WITHHOLD_BOND = "/reservationwithholdbond";

class ReservationService {
    /////////// reservation objects
    /////////// these are a summary of the data based on all reservation tables

    // get all reservation objects
    getReservationObjects() {
        return axios.get(BASE_URL + RESERVATIONOBJECT_API_BASE_URL, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get all reservation objects based on listing id
    getReservationObjectsByListingID(listingid) {
        return axios.get(BASE_URL + RESERVATIONOBJECT_BY_LISTING_ID_API_BASE_URL, { 
            params: {
                ListingID: listingid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get all reservation objects based on reservation id
    getReservationObjectsByReservationID(reservationid) {
        return axios.get(BASE_URL + RESERVATIONOBJECT_BY_RESERVATION_ID_API_BASE_URL, { 
            params: {
                ReservationID: reservationid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get all reservation objects based on reservation id
    getReservationObjectsByCustomerID(customerid) {
        return axios.get(BASE_URL + RESERVATIONOBJECT_BY_CUSTOMER_ID_API_BASE_URL, { 
            params: {
                CustomerID: customerid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get all reservation objects based on reservation id
    getReservationObjectsByOwnerID(ownerid) {
        return axios.get(BASE_URL + RESERVATIONOBJECT_BY_OWNER_ID_API_BASE_URL, { 
            params: {
                OwnerID: ownerid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    /////////// reservation 
    /////////// these are the reservation tables only, not an amalgamation of the data

    // get all reservation objects
    getReservations() {
        return axios.get(BASE_URL + RESERVATION_API_BASE_URL, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get all reservation objects based on listing id
    getReservationsByListingID(listingid) {
        return axios.get(BASE_URL + RESERVATION_BY_LISTING_ID_API_BASE_URL, { 
            params: {
                ListingID: listingid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get all reservation objects based on reservation id
    getReservationByReservationID(reservationid) {
        return axios.get(BASE_URL + RESERVATION_BY_RESERVATION_ID_API_BASE_URL, { 
            params: {
                ReservationID: reservationid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // create a new reservation
    createReservation(reservation) {
        return axios.post(BASE_URL + RESERVATION_API_BASE_URL, null, { 
            params: {
                Reservation: reservation
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // update an existing reservation
    /*updateReservation(reservationid, reservation) {
        return axios.put(BASE_URL + RESERVATION_API_BASE_URL, null, { 
            params: {
                ReservationID: reservationid, Reservation: reservation
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }*/

    // update an existing reservation
    declineReservation(reservation) {
        return axios.put(BASE_URL + RESERVATION_DECLINE_API_BASE_URL, reservation, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // update an existing reservation
    cancelReservation(reservation) {
        return axios.put(BASE_URL + RESERVATION_CANCEL_API_BASE_URL, reservation, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // delete an existing reservation
    deleteReservation(reservationid, useractionid) {
        return axios.delete(BASE_URL + RESERVATION_API_BASE_URL, null, { 
            params: {
                ReservationID: reservationid, 
                UserActionID: useractionid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_DELETE,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    /////////// payment 
    /////////// these are the payment tables only, used for Get/Update/Delete

    // get all payment by reservation id
    getPaymentByReervationID(reservationid) {
        return axios.get(BASE_URL + PAYMENT_BY_RESERVATION_ID_API_BASE_URL, { 
            params: {
                ReservationID: reservationid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // update an existing payment record
    updatePayment(payment) {
        return axios.put(BASE_URL + PAYMENT_API_BASE_URL, payment, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }   
    
    // update the reservation status to booked
    updateReservationStatus(reservation) {
        return axios.put(BASE_URL + RESERVATION_API_BASE_URL , reservation, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    processPayment(cardToken) {
    }

    withholdBond(bond) {
        return axios.put(BASE_URL + PAYMENT_WITHHOLD_BOND, bond, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_PUT,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }
}

export default new ReservationService()