import axios from 'axios';

import { BASE_URL } from './GlobalDefinitions';
//import { AUTH, ORIGIN, METHODS_GET, METHODS_POST, METHODS_PUT, METHODS_DELETE, CONTENT_TYPE } from './Headers';
import { AUTH, CONTENT_TYPE, METHODS_GET, METHODS_POST, ORIGIN } from './Headers';

const MESSAGES_API_BASE_URL = "/messages";
const MESSAGES_SUMMARY_API_URL = "/messagessummary";
const MESSAGES_BY_ID_API_BASE_URL = "/messagesbyid";
const MESSAGES_SUMMARY_BY_ID_API_BASE_URL = "/messagessummarybyid"

class MessagesService {
    // get all messages
    getMessages() {
        return axios.get(BASE_URL + MESSAGES_API_BASE_URL, {
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    getSummaryMessages() {
        return axios.get(BASE_URL + MESSAGES_SUMMARY_API_URL, {
            headers: {
                'Authorization': AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get messages by id
    getMessagesByID(key, value) {
        return axios.get(BASE_URL + MESSAGES_BY_ID_API_BASE_URL, {
            params: {
                Key: key,
                Value: value
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // get messages by id
    getSummaryMessagesByID(key, value) {
        return axios.get(BASE_URL + MESSAGES_SUMMARY_BY_ID_API_BASE_URL, {
            params: {
                Key: key,
                Value: value
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_GET,
                'Access-Control-Allow-Origin': ORIGIN
            }
        });
    }

    // create a new message
    createMessage(senderid, receiverid, rootmessageid, parentmessageid, message, listingid, reservationid) {
        return axios.post(BASE_URL + MESSAGES_API_BASE_URL, null, {
            params: {
                SenderID: senderid,
                ReceiverID: receiverid,
                RootMessageID: rootmessageid,
                ParentMessageID: parentmessageid,
                Message: message,
                ListingID: listingid,
                ReservationID: reservationid
            },
            headers: {
                'Authorization' : AUTH,
                'Access-Control-Allow-Methods': METHODS_POST,
                'Access-Control-Allow-Origin': ORIGIN,
                "Content-Type": CONTENT_TYPE
            }
        });
    }

}

export default new MessagesService()