import React from "react";

import "./../styles/message.css";

function formatDate(input) {
    var date = new Date(input);
    return (('0' + (date.getDate())).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()).toString();
}

function MessageItem(props) {
    return (
        <div className="testimonial-item">
            <div className="message-name">
                <img width="50" height="50" style={{ borderRadius: "50%", marginBottom: "-15px" }} src={props.src} />
                &nbsp;<b>{formatDate(props.date)}</b>&nbsp;&nbsp;&nbsp;
                {props.name}
            </div>
            <div className="message-comment">
                {props.message}
            </div>
            
        </div>
    );
}

export default MessageItem