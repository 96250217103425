import BlogService from "../services/BlogService";

/**
 * global functions to act on user api actions
 */
const Blog = {

    async getBlogByID(id) {
        const result = await BlogService.getBlogByID(id);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async getBlogs() {
        const result = await BlogService.getBlogs();

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async getBlogsDetail() {
        const result = await BlogService.getBlogsDetail();

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async getBlogsPublished(limit) {
        const result = await BlogService.getBlogsPublished(limit);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async getBlogsDetailByUser(uuid) {
        const result = await BlogService.getBlogsDetailByUser(uuid);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async createBlog(title, intro, body, userID, primaryImage, otherImages, location) {
        const blog = new FormData();
        blog.append("Title", title);
        blog.append("Intro", intro);
        blog.append("Body", body);
        blog.append("CreatedBy", userID);
        blog.append("Status", "Pending");
        blog.append("PrimaryImage", primaryImage);
        for (const image of otherImages) {
            blog.append("Images", image);
        }
        blog.append("Location", JSON.stringify(location));

        // send it to the listing service
        const result = await BlogService.createBlog(blog);

        if (result.data.length !== 0) {
            return result;
        }

        return false;

    },

    async updateBlog(blogID, title, intro, body, userID, primaryImage, otherImages, location, status) {
        //console.log('update blog');

        const blog = new FormData();
        blog.append("ID", blogID);
        blog.append("Title", title);
        blog.append("Intro", intro);
        blog.append("Body", body);
        blog.append("CreatedBy", userID);
        blog.append("Status", status);
        blog.append("PrimaryImage", primaryImage);
        for (const image of otherImages) {
            blog.append("Images", image);
        }
        blog.append("Location", JSON.stringify(location));

        // send it to the listing service
        const result = await BlogService.updateBlog(blog);

        if (result.data.length !== 0) {
            return result;
        }

        return false;

    },

    /**
     * delete the image assigned to the user profile both from local storage and the database
     * @param {string} id
     */
    async deleteBlog(id) {
        const result = await BlogService.deleteBlog(id);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async getBlogLocationByID(id) {
        const result = await BlogService.getBlogLocationByID(id);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async getBlogMediaByID(id) {
        const result = await BlogService.getBlogMediaByID(id);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },

    async deleteBlogMediaByID(id) {
        const result = await BlogService.deleteBlogMediaByID(id);

        if (result.data.length !== 0) {
            return result;
        }

        return false;
    },
}

export default Blog