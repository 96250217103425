import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from 'react-bootstrap';
import SideNav from "./../modals/SideNav";

import UserDetail from './../functions/User';
import MessagesDetail from './../functions/Messages';

import { Grid } from "react-loader-spinner";

export function withRouter(Children) {
    return (props) => {
        return <Children {...props} location={props} />
    }
}

class Messages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false,
            messages: [],
            showLoading: true,
        }
    }

    formatDate = (input) => {
        var date = new Date(input);
        return (('0' + (date.getDate())).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()).toString();
    }

    componentDidMount() {

        if (this.props.location.state.loginType === 'admin') {
            MessagesDetail.getSummaryMessages().then((result) => {
                if (result.data && result.data.length > 0 && typeof (result.data) != 'string') {
                    const sortMessage = result.data.sort((a, b) => (a.Date < b.Date) ? 1 : -1);
                    this.setState({ messages: sortMessage });
                }
                this.setState({ showLoading: false });
            })
        } else {

            UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {
                var customerID = result.data.UUID;
                MessagesDetail.getSummaryMessagesByID('receiver_id', customerID).then((result) => {
                    console.log("customer: " + JSON.stringify(result.data));
                    if (result.data && result.data.length > 0 && typeof (result.data) != 'string') {
                        var tempMessage = result.data;
                        MessagesDetail.getSummaryMessagesByID('sender_id', customerID).then((result) => {
                            if (result.data && result.data.length > 0 && typeof (result.data) != 'string') {
                                //this.setState({ messages: [...this.state.messages, result.data] });
                                result.data.map((message, index) => {
                                    tempMessage.push(message);
                                })
                                const sortMessage = tempMessage.sort((a, b) => (a.Date < b.Date) ? 1 : -1);
                                this.setState({ messages: sortMessage });
                            } else { //if no sender messages
                                const sortMessage = tempMessage.sort((a, b) => (a.Date < b.Date) ? 1 : -1);
                                this.setState({ messages: sortMessage });
                            }
                            this.setState({ showLoading: false });
                        })

                    } else { //no receiver messages
                        MessagesDetail.getSummaryMessagesByID('sender_id', customerID).then((result) => {
                            if (result.data && result.data.length > 0 && typeof (result.data) != 'string') {
                                const sortMessage = result.data.sort((a, b) => (a.Date < b.Date) ? 1 : -1);
                                this.setState({ messages: sortMessage });
                            } 
                            this.setState({ showLoading: false });
                        })
                    }
                })

                
            })
            
        }
    }

    render() {
        if (this.state.showLoading) {
            return (
                <div class="loading-overlay" >
                    <div class="loading-vertical">
                        <Grid
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={0}
                        />
                    </div>
                </div>
            )
        } else {

            return (
                <div style={{ paddingTop: "80px" }}>
                    <Row>
                        <Col className="d-none d-lg-block" lg="2" xl="1">
                            <SideNav
                                loggedIn={this.props.location.state.loggedIn}
                                loginType={this.props.location.state.loginType}
                                email={this.props.location.state.email}
                                activeTab='messages'
                            />
                        </Col>
                        <Col md="12" lg="10" xl="11">
                            <div style={{ width: '100%' }}>
                                <h1>Messages</h1>
                                <hr />
                            </div>

                            <Card>
                                <Card.Body style={{ paddingRight: "0px" }}>
                                    {/*<Row>*/}
                                    {/*    <Col>*/}
                                    {/*        <Button title="Sort by Booked Date" onClick={() => this.sortByBookedDate()} >Sort by Booked Date</Button>*/}
                                    {/*        <Button title="Sort by Check-in Date" onClick={() => this.sortByCheckinDate()} >Sort by Check-in Date</Button>*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                    <Row>
                                        <Row >
                                            <Col xl="1" lg="2" md="2" sm="2" className="d-none d-sm-block"><h5>Date Sent</h5></Col>
                                            <Col xl="2" lg="2" md="2" sm="2" className="d-none d-sm-block"><h5>From</h5></Col>
                                            <Col xl="8" lg="7" md="6" sm="6" className="d-none d-sm-block"><h5>Message</h5></Col>
                                            <Col xl="1" lg="1" md="2" sm="2" className="d-none d-sm-block"><h5>Action</h5></Col>

                                        </Row>
                                        {
                                            this.state.messages && this.state.messages.length > 0 ?
                                                this.state.messages.map((message, index) =>
                                                    <div style={{ width: '100%' }}>
                                                        <div >
                                                            <Row md="12" style={{ height: '50px', borderBottom: '1px solid #808080', paddingTop: '6px' }} >
                                                                <Col xl="1" lg="2" md="2" sm="2" xs="2">
                                                                    {this.formatDate(message.Date)}
                                                                </Col>
                                                                <Col xl="2" lg="2" md="2" sm="2" xs="2" style={{ textOverflow: 'ellipsis', maxWidth: '95%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                                    {message.SenderName}
                                                                </Col>
                                                                <Col xl="8" lg="7" md="6" sm="6" xs="6" style={{ textOverflow: 'ellipsis', maxWidth: '95%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                                    {message.Message}
                                                                </Col>
                                                                <Col xl="1" lg="1" md="2" sm="2" xs="2">
                                                                    <Link to={{
                                                                        pathname: "/message/" + message.RootMessageID + "/0/0/",
                                                                    }}><Button>View</Button>
                                                                    </Link>
                                                                </Col>
                                                            </Row>
                                                        </div>


                                                    </div>
                                                ) :
                                                <Row>
                                                    <h4>You currently have no messages</h4>
                                                </Row>
                                        }

                                    </Row>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </div>
            )
        }
    }
}

export default withRouter(Messages)