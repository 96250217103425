import CategoryService from '../services/CategoryService';

const Category = {
    async getCategories() {
        const result = await CategoryService.getCategories();

        if (result.data.length !== 0) { 
            return result;           
        }

        return undefined;
    },
}

export default Category