
/*class Globals {
    loginTypeHost = 'I want to host';
    loginTypeBook = 'I want to book';
    typeBooking =  'booking';
    typeHosting = 'hosting';
    Home = "/";
    Dashboard = "/favourites";
    Favourites = "/favourites";
    Invoices = "/invoices";
    Messages = "/messages";
    MyListings = "/mylistings";
    MyListings = "/addnewlisting";
    Profile = "/profile";
    Reservations = "/reservations";
    
}

export default new Globals()

*/
/*const Globals = {
    loginTypeHost: 'I want to host';
    loginTypeBook: 'I want to book',
    typeBooking: 'booking',
    typeHosting: 'hosting',
    typeAdmin: 'admin',
    Home: "/",
    Dashboard: "/favourites",
    Favourites: "/favourites",
    Invoices: "/invoices",
    Messages: "/messages",
    MyListings: "/mylistings",
    MyListings: "/addnewlisting",
    Profile: "/profile",
    Reservations: "/reservations",
    googleAPI: "AIzaSyCokK7P3SJHrW4MRbQjQTaJ7_VFkCkiBFc",
    pinPaymentPublicKey: "pk_JpasTn2c7w8Tejqi-6un4A",
    pinPaymentAPI: "test"
}*/

class Globals {
    convertImage(file) {
        return new Promise((resolve, reject) => {
            var fileReader = new FileReader();
            if (file && file.type.match('image.*')) {
                fileReader.readAsDataURL(file);        
            }
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        });
    }

    loginTypeHost = 'I want to host';
    loginTypeBook = 'I want to book';
    typeBooking = 'booking';
    typeHosting = 'hosting';
    typeAdmin = 'admin';
    Home = "/";
    Dashboard = "/favourites";
    Favourites = "/favourites";
    Invoices = "/invoices";
    Messages = "/messages";
    MyListings = "/mylistings";
    MyListings = "/addnewlisting";
    Profile = "/profile";
    Reservations = "/reservations";
    googleAPI = "AIzaSyCokK7P3SJHrW4MRbQjQTaJ7_VFkCkiBFc";
    pinPaymentPublicKey = "pk_zrPYT5s8iKMmVzfCnoxpVg";
    pinPaymentAPI = "live";
}

export default new Globals

//export default Globals