import React, { useEffect } from 'react';
import { Table, Card, Form, Row, Col } from 'react-bootstrap';

function RenderList(props) {
    const categories = props.categories;
    const features = props.features;
    var selected = [];

    if (props.listingDataFeatures) {
        //console.log(props.listingData);
        props.listingDataFeatures.map((feature, index) => {
            selected.push(feature.UUID);
        })
    }
    if (props.listingDataFacilities) {
        //console.log(props.listingData);
        props.listingDataFacilities.map((feature, index) => {
            selected.push(feature.UUID);
        })
    }

    return categories.map(category => (
        <>
            <Card>
                <Card.Title>{category.Category}</Card.Title>
                <Card.Body>
                    <Row className="mb-3">
                        {features.map(feature => feature.CategoryID === category.UUID ?
                            <Col xs={4}>
                                <Form.Group as={Col} controlID="formGridState">
                                    <Form.Check type='checkbox' defaultChecked={selected.includes(feature.UUID)} id={feature.UUID} label={feature.Feature} onClick={props.selectFeature}/>
                                </Form.Group>
                            </Col>
                            : null
                        )}
                        
                    </Row>
                </Card.Body>
            </Card>
        </>
    ))
}

function Features(props) {

    return (

        <div style={{ paddingTop: "20px" }}>
            <div style={{ width: "80%", float: "left" }}>
                <Table>
                    <thead bg="white">
                        <tr colSpan="2">
                            <th>Add Listing</th>
                        </tr>
                    </thead>
                    <tbody bg="gray">
                        <RenderList categories={props.categories} features={props.featuresList} selectFeature={props.selectFeature} listingDataFeatures={props.listingDataFeatures} listingDataFacilities={props.listingDataFacilities} />
                    </tbody>
                </Table>
            </div>
        </div>

    )
}

export default Features