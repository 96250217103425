import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Container, Navbar, NavDropdown, Nav, Dropdown} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { FaHome, FaUserAlt, FaList, FaHeart, FaFile, FaPlusCircle, FaRegCalendarAlt, FaComments, FaSignOutAlt, FaUsers, FaPeopleArrows, FaDollarSign, FaCcMastercard  } from 'react-icons/fa';
import { MdOutlineRateReview, MdTextsms } from 'react-icons/md';

import "./../styles/nav-bar.css";

import 'bootstrap/dist/css/bootstrap.min.css';
import Globals from '../functions/globals';

export function withRouter(Children) {
    return (props) => {

        const match = { params: useParams() };
        const location = { location: useLocation() };

        return <Children {...props} match={match.params} location={location.location} />
    }
}

class NavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDropdown: false,
            transform: 0, // (window.location.pathname != "/" ? 0 : -1)

        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    showLogin = () => {
        //alert("login")

        //alert(this.props.test)

        this.props.showLogin && this.props.showLogin();
    }

    showRegister = () => {
        this.props.showRegister && this.props.showRegister();
    }

    setShowDropdown = (value) => {
        this.setState({ showDropdown: value });
    }

    setTransform = () => {
        this.setState({ transform: 0 });
    }

    menuSelected = (key) => {
        if (key == 'logout') {
            this.props.logout();
        }
    }

    handleScroll = (event) => {
        let scrollTop = event.target.scrollingElement.scrollTop,
            itemTranslate = Math.min(0, scrollTop / 3 - 10);

        //check if home page
        if (window.location.pathname != "/") {
            itemTranslate = 0;
        }

        this.setState({
            //transform: itemTranslate
        })
    }

    render() {
        let loginNav;
        let loginTypeHosting;
        let loginTypeAdmin;
        let dropdownMenu;
        let dropdownToggle;
        if (!this.props.loggedIn) {
            loginNav =  /*<Nav className="ml-auto">
                            <NavItem style={{marginRight: "50px"}}>
                                <Nav.Link onClick={this.showLogin} style={{ width: "100%"}}><h5 className="text-white">Login</h5></Nav.Link>
                            </NavItem>
                            <NavItem>
                                <Nav.Link onClick={this.showRegister}><h5 className="text-white">Register</h5></Nav.Link>
                            </NavItem>
        </Nav>*/
                <Nav style={{ marginLeft: "auto" }} >
                    <div style={{ paddingRight: "10px" }}>
                    <button style={{ paddingRight: "20px" }} className={(this.state.transform == 0 ? 'nav-white' : 'nav-home')} onClick={this.showLogin} id="nav-btn" ><h5>Login</h5></button>
                    {/*</div>*/}
                    {/*<div style={{ paddingRight: "20px", paddingLeft: "20px" }}>*/}
                        <button className={(this.state.transform == 0 ? 'nav-white' : 'nav-home')} onClick={this.showRegister} id="nav-btn"><h5>Register</h5></button>
                    </div>
                </Nav>

        } else {
            if (this.props.loginType === Globals.typeHosting || this.props.loginType === Globals.typeAdmin) {
                loginTypeHosting = <div>
                    <NavDropdown.Divider />
                    <Dropdown.Item as={Link} to={{ pathname: "/mylistings", state: { email: this.props.email, loginType: this.props.loginType } }} class="link"><FaList />&nbsp;&nbsp;&nbsp;&nbsp;My Listings</Dropdown.Item>
                    <Dropdown.Item as={Link} to={{ pathname: "/addnewlisting", state: { email: this.props.email, loginType: this.props.loginType } }} class="link"><FaPlusCircle />&nbsp;&nbsp;&nbsp;&nbsp;Add New Listing</Dropdown.Item>
                    <Dropdown.Item as={Link} to={{ pathname: "/promos", state: { email: this.props.email, loginType: this.props.loginType } }} class="link"><FaDollarSign />&nbsp;&nbsp;&nbsp;&nbsp;Promos</Dropdown.Item>
                </div>
            }
            if (this.props.loginType === Globals.typeAdmin) {
                loginTypeAdmin = <div>
                    <NavDropdown.Divider />
                    <Dropdown.Item as={Link} to={{ pathname: "/users", state: { email: this.props.email, loginType: this.props.loginType } }} class="link"><FaUsers />&nbsp;&nbsp;&nbsp;&nbsp;Users</Dropdown.Item>
                    <Dropdown.Item as={Link} to={{ pathname: "/hosts", state: { email: this.props.email, loginType: this.props.loginType } }} class="link"><FaUsers />&nbsp;&nbsp;&nbsp;&nbsp;PMS Hosts</Dropdown.Item>
                    <Dropdown.Item as={Link} to={{ pathname: "/ccfees", state: { email: this.props.email, loginType: this.props.loginType } }} class="link"><FaCcMastercard  />&nbsp;&nbsp;&nbsp;&nbsp;CC Fees</Dropdown.Item>
                    <Dropdown.Item as={Link} to={{ pathname: "/blogs", state: { email: this.props.email, loginType: this.props.loginType } }} class="link"><MdTextsms />&nbsp;&nbsp;&nbsp;&nbsp;Blogs</Dropdown.Item>
                    
                </div>;
            }
            dropdownMenu = <div style={{ position: "relative", right: "100px" }}>
                <Dropdown.Menu onClick={() => this.setTransform} show={this.state.showDropdown}>
                    {/*<Dropdown.Item><Link to={{ pathname: "/dashboard", state: { email: this.props.email, loginType: this.props.loginType } }} class="link"><FaHome />&nbsp;&nbsp;&nbsp;&nbsp;Dashboard</Link></Dropdown.Item>*/}
                    <Dropdown.Item as={Link} to={{ pathname: "/profile", state: { email: this.props.email, loginType: this.props.loginType } }} class="link" ><FaUserAlt />&nbsp;&nbsp;&nbsp;&nbsp;Profile</Dropdown.Item>
                    <Dropdown.Item as={Link} to={{ pathname: "/reservations", state: { email: this.props.email, loginType: this.props.loginType } }} class="link"><FaRegCalendarAlt />&nbsp;&nbsp;&nbsp;&nbsp;Reservations</Dropdown.Item>
                    <Dropdown.Item as={Link} to={{ pathname: "/favourites", state: { email: this.props.email, loginType: this.props.loginType } }} class="link"><FaHeart />&nbsp;&nbsp;&nbsp;&nbsp;Favourites</Dropdown.Item>
                    <Dropdown.Item as={Link} to={{ pathname: "/invoices", state: { email: this.props.email, loginType: this.props.loginType } }} class="link"><FaFile />&nbsp;&nbsp;&nbsp;&nbsp;Invoices</Dropdown.Item>
                    <Dropdown.Item as={Link} to={{ pathname: "/messages", state: { email: this.props.email, loginType: this.props.loginType } }} class="link"><FaComments />&nbsp;&nbsp;&nbsp;&nbsp;Messages</Dropdown.Item>
                    <Dropdown.Item as={Link} to={{ pathname: "/reviews", state: { email: this.props.email, loginType: this.props.loginType } }} class="link"><MdOutlineRateReview />&nbsp;&nbsp;&nbsp;&nbsp;Reviews</Dropdown.Item>
                    <Dropdown.Item as={Link} to={{ pathname: "/ratings", state: { email: this.props.email, loginType: this.props.loginType } }} class="link"><FaPeopleArrows />&nbsp;&nbsp;&nbsp;&nbsp;Ratings</Dropdown.Item>
                    {loginTypeHosting}
                    {loginTypeAdmin}
                    <NavDropdown.Divider />
                    {/*<Dropdown.Item href="/"><strong style={{ fontFamily: 'Verdana' }}><FaSignOutAlt />&nbsp;&nbsp;&nbsp;&nbsp;Log Out</strong></Dropdown.Item>*/}
                    <Dropdown.Item eventKey="logout" ><strong style={{ fontFamily: 'Verdana' }}><FaSignOutAlt />&nbsp;&nbsp;&nbsp;&nbsp;Log Out</strong></Dropdown.Item>

                </Dropdown.Menu>
            </div>;
            dropdownToggle = <div >
                <Dropdown.Toggle className={(this.state.transform == 0 ? 'nav-white' : 'nav-home')} id="dropdown-button">
                    {this.props.firstname}&nbsp;&nbsp;<FaUserAlt />
                </Dropdown.Toggle>
            </div>;
            loginNav = <div style={{ marginLeft: "auto" }}>
                <Dropdown style={{ marginRight: "10px" }} onSelect={this.menuSelected} >
                    {dropdownToggle}
                    {dropdownMenu}
                </Dropdown>
            </div>;
        }
        return (
            <Navbar expand="md" fixed="top" style={{ margin: "0", padding: "0.25rem" }} className={(this.state.transform == 0 ? 'nav-white' : 'nav-home')} >
                <Link to={{ pathname: "/", state: { email: this.props.email } }}>
                    <Navbar.Brand>
                        <img
                            alt=""
                            src="/logo.png"
                            width="75"
                            height="75"
                            className="d-inline-block align-top"
                        />{' '}
                    </Navbar.Brand>
                </Link>
                {/*<h1 style={{ fontSize: "8px", width: "10%", color: "#ffffff" }}>Aussie Holiday Stays</h1>*/}
                {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
                {/*<Navbar.Collapse>*/}
                    {loginNav}
                {/*</Navbar.Collapse>*/}
            </Navbar>

        )
    }
}

export default withRouter(NavBar)