import ReservationService from "../services/ReservationService";

const Reservation = {
    ////This function will return only nightly pricing for a range to build the calendar on a listing
    //async createReservation(listingID, guestID, arrive, depart, guests, dailyFees, taxes, fees, total, deposit, cardToken, intro, security) {
    //    //const result = await PricingService.getPricingForCalendar(listingID, start, end);
    //    const result = 'resID';

        

    //    return result;
    //},

    async getReservationObjects() {
        const result = await ReservationService.getReservationObjects();

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getReservationObjectsByListingID(listingid) {
        const result = await ReservationService.getReservationObjectsByListingID(listingid);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getReservationObjectsByReservationID(reservationid) {
        const result = await ReservationService.getReservationObjectsByReservationID(reservationid);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getReservationObjectsByCustomerID(customerid) {
        const result = await ReservationService.getReservationObjectsByCustomerID(customerid);

        //console.log("length: " + result.data);

        if (result.data != null && result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getReservationObjectsByOwnerID(ownerid) {
        const result = await ReservationService.getReservationObjectsByOwnerID(ownerid);

        //console.log("length: " + result.data);

        if (result.data != null && result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async createReservationEntry(listingID, customerID, securityDeposit,
        incfees,
        exfees,
        condfees,
        exfeesArrive,
        condfeesArrive,
        totalFees, deposit, arrivalDate, departurelDate, guests, pets, dailyRates, 
        inctaxes,
        extaxes,
        condtaxes,
        extaxesArrive,
        condtaxesArrive,
        customerToken, cardToken, introduction, cancellation, creationDate, nightsCount, serviceFee, balanceRemaining, amountSaved,
        totalGST, guestGST, hostGST,
        instantBooking, promoCode,
        cardFees, PMSFees    ) {

        const reservationParams = {
            ListingID: listingID,
            CustomerID: customerID,
            SecurityDeposit: securityDeposit,
            ReservationFeesIncluded: incfees,
            ReservationFeesExcluded: exfees,
            ReservationFeesConditional: condfees,
            ReservationFeesArriveExcluded: exfeesArrive,
            ReservationFeesArriveConditional: condfeesArrive,
            TotalFees: totalFees,
            TotalGST: totalGST,
            GuestGST: guestGST,
            HostGST: hostGST,
            Deposit: deposit, 
            ArrivalDate: arrivalDate, 
            DepartureDate: departurelDate, 
            Guests: guests, 
            Pets: pets, 
            DailyRates: dailyRates, 
            ReservationTaxesIncluded: inctaxes,
            ReservationTaxesExcluded: extaxes,
            ReservationTaxesConditional: condtaxes,
            ReservationTaxesArriveExcluded: extaxesArrive,
            ReservationTaxesArriveConditional: condtaxesArrive,
            CustomerToken: customerToken, 
            CardToken: cardToken,
            Introduction: introduction,
            Cancellation: cancellation,
            CreatedDate: creationDate,
            NightsCount : nightsCount,
            ServiceFee: serviceFee,
            BalanceRemaining: balanceRemaining,
            AmountSaved: amountSaved,
            InstantBooking: instantBooking,
            PromoCode: promoCode,
            CardFees: cardFees,
            PMSFees: PMSFees,
        }

        // send it to the reservation service
        const result = await ReservationService.createReservation(JSON.stringify(reservationParams));

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async getPaymentByReervationID(reservationID) {
        const result = await ReservationService.getPaymentByReervationID(reservationID);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async updatePayment(ReservationID, CustomerToken, CardToken, DepositTransferHost, 
        DepositTransferSelf, FinalTransferHost, FinalTransferSelf, SecurityTransferHost, 
        SecurityTransferGuest, DepositPaymentFinalised, FinalPaymentFinalised) {
            const payment = {
                ReservationID: ReservationID,
                CustomerToken: CustomerToken,
                CardToken: CardToken,
                DepositTransferHost: DepositTransferHost,
                DepositTransferSelf: DepositTransferSelf, 
                FinalTransferHost: FinalTransferHost, 
                FinalTransferSelf: FinalTransferSelf, 
                SecurityTransferHost: SecurityTransferHost, 
                SecurityTransferGuest: SecurityTransferGuest, 
                DepositPaymentFinalised: DepositPaymentFinalised, 
                FinalPaymentFinalised: FinalPaymentFinalised
        }
        
        // send it to the reservation service
        const result = await  ReservationService.updatePayment(payment);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async updateReservationStatus(ReservationID, status, amount, description, ipaddress, finalPayment, cardToken, cardFees) {
        const reservationUpdate = {
            ReservationID: ReservationID,
            Status: status,
            Amount: amount,
            Description: description,
            IPAddress: ipaddress,
            FinalPayment: finalPayment,
            CardToken: cardToken,
            CardFees: cardFees.toString(),
        }

        const result = await  ReservationService.updateReservationStatus(reservationUpdate);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async approveReservation(reservation) {
        //process the payment with pinPayment
        return reservation.Payment.CardToken;

        ReservationService.processPayment(reservation.Payment.CardToken);

        //update the reservation status to 'Deposit Paid'

    },

    async declineReservation(reservationid, declineReason, useractionid) {
        const reservationUpdate = {
            ReservationID: reservationid,
            UserActionID: useractionid,
            DeclineReason: declineReason
        }

        const result = await ReservationService.declineReservation(reservationUpdate);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async cancelReservation(reservationid, useractionid) {
        const reservationUpdte = {
            ReservationID: reservationid,
            UserActionID: useractionid
        }
        const result = await  ReservationService.cancelReservation(reservationUpdte);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },

    async paymentReceived(reservationID, amount, cardToken) {

    },

    async withholdBond(reservationid, amount) {
        const bond = {
            ReservationID: reservationid,
            Amount: amount
        }

        const result = await  ReservationService.withholdBond(bond);

        if (result.data.length !== 0) {
            return result;
        }

        return undefined;
    },
}

export default Reservation