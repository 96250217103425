import React, { useEffect, useState } from 'react';
import { Card, Image, Row, Col, Button, Modal, Alert } from 'react-bootstrap';
import Preview from './../imageSort';

import ListingDetail from './../../functions/Listing';

import './../../styles/media.css';

import Compressor from 'compressorjs';

const Media = (props) => {

    const [images, addImage] = useState([]);
    const [styles, addStyle] = useState([]);
    const [pageValid, setPageValid] = useState('none');
    const [fileLimitReached, setFileLimitReached] = useState('none');
    const [noimages, setNoImages] = useState(0);
    
    const [showCrop, setShowCrop] = useState(false);
    const [heroImage, setHeroImage] = useState(null);
    const [cropArea, setCropArea] = useState("");

    const [loadData, setLoadData] = useState(true);
    const deleteSort = (id) => {
        deleteFile(id - 1);
    }

    const updateImageArray = (arr) => {
        addImage(arr);

        props.onSelectImage && props.onSelectImage(arr[0]);

        //need to update the parent arracy in add & edit listing
        props.updateImageArray && props.updateImageArray(arr);
    }

    const getvalid = (value) => {

        //check the primary image is set and show error and invalid

        //console.log("checking media: " + value);

        //let newinput = input;
        //let newerrors = {};
        //let newshowErrors = {};
        let isValid = value;

        //if (!input["title"]) {
        //    isValid = false;
        //    newerrors["title"] = "Please enter title for listing";
        //    newshowErrors["title"] = true;
        //}

        //if (!input["bedType"]) {
        //    isValid = false;
        //    newerrors["bedType"] = "Please enter listing type";
        //    newshowErrors["bedType"] = true;
        //}

        //if (!input["informationnumberofbedrooms"]) {
        //    isValid = false;
        //    newerrors["informationnumberofbedrooms"] = "Please enter number of bedrooms";
        //    newshowErrors["informationnumberofbedrooms"] = true;
        //}

        //setErrors(newerrors);
        //setShowErrors(newshowErrors);
        if (isValid) {
            setPageValid('none');
        } else {
            setPageValid('block');
        }

        return isValid;
    }

    const hideCrop = () => {
        setShowCrop(false);
        props.onCropImage && props.onCropImage(window.localStorage.getItem('croppedArea'));
        setCropArea(window.localStorage.getItem('croppedArea'));
    }

    const saveArea = (value) => {
        setCropArea(value);
    }

    const hiddenFileInput = React.useRef(null);

    const style_selected = {
        border: "3px solid rgb(13, 10, 2530)",
        padding: "5px"
    };
    const style_notselected = ({
        border: "none",
        padding: "5px"
    });

    const handleDragOver = (event) => {
        event.preventDefault();
    }

    const handleDrop = (event) => {
        event.preventDefault(); 
        event.stopPropagation();

        //const compress = new Compress();
        setFileLimitReached('none');
        //check if 20 or less images
        if ((event.dataTransfer.files.length + noimages) < 21) {

            //loop through the images that were dropped
            for (var i = 0; i < event.dataTransfer.files.length; i++) {
                let imageFile = event.dataTransfer.files[i];

                //check image size first
                if (imageFile.size > 150000) {

                    new Compressor(imageFile, {
                        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                        maxWidth: 1920,
                        maxHeight: 1080,
                        //convertSize: 300000,
                        success: (compressedResult) => {
                            // compressedResult has the compressed file.
                            // Use the compressed file to upload the images to your server.        
                            imageFile = compressedResult;
                            // add the image to our array for tracking purposes
                            //addImage(arr => [...arr, imageFile]);
                            addImage(arr => [...arr, {
                                data: imageFile,
                                ID: ""
                            }]);
                        },
                    });
                } else {
                    // add the image to our array for tracking purposes
                    //addImage(arr => [...arr, imageFile]);
                    addImage(arr => [...arr, {
                        data: imageFile,
                        ID: ""
                    }]);
                }

                if (i == 0 && noimages == 0) {
                    props.onSelectImage && props.onSelectImage({
                        data: imageFile,
                        ID: ""
                    });
                }

                // need to add a default style as well
                addStyle(arr => [...arr, style_notselected])

                // and pass it back to the parent
                props.addImage && props.addImage(imageFile);

                //setImagesPreviewUrls(arr => [...arr, imageFile]);
            }
            setNoImages(noimages + event.dataTransfer.files.length);
        } else { //show error to user
            setFileLimitReached('block');
        }
    }

    const changeHandler = (event) => {

        setFileLimitReached('none');
        //check if 20 or less images
        if ((event.target.files.length + noimages) < 21) {
            //setNoImages(noimages + event.dataTransfer.files.length);

            //loop through the images that were dropped
            for (var i = 0; i < event.target.files.length; i++) {

                let imageFile = event.target.files[i];

                //check image size first
                if (imageFile.size > 150000) {

                    new Compressor(imageFile, {
                        //quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                        maxWidth: 1920,
                        maxHeight: 1080,
                        convertSize: 300000,
                        success: (compressedResult) => {
                            // compressedResult has the compressed file.
                            // Use the compressed file to upload the images to your server.        
                            imageFile = compressedResult;
                            // add the image to our array for tracking purposes
                            //addImage(arr => [...arr, imageFile]);
                            addImage(arr => [...arr, {
                                data: imageFile,
                                ID: ""
                            }]);
                        },
                    });
                } else {
                    addImage(arr => [...arr, {
                        data: imageFile,
                        ID: ""
                    }]);
                }
                if (i == 0 && noimages == 0) {
                    props.onSelectImage && props.onSelectImage({
                        data: imageFile,
                        ID: ""
                    });
                }

                // need to add a default style as well
                addStyle(arr => [...arr, style_notselected])

                // and pass it back to the parent
                props.addImage && props.addImage(imageFile);
            }

            setNoImages(noimages + event.target.files.length);
        } else { //show error to user
            setFileLimitReached('block');
        }
    }

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const deleteFile = (event) => {

        var tempImages = [];
        var tempStyles = [];
        var imageFile = null;
        var tempImageData = [];

        //alert(event);
        setNoImages(noimages - 1);

        images.map((image, index) => {
            if (event != index) {
                tempImages = [...tempImages, image];
                tempStyles = [...tempStyles, styles[index]];
                tempImageData = [...tempImageData, image.data];
            } else {
                imageFile = image;
                //console.log("delete: " + image.ID);
                if (heroImage == image) {
                    setHeroImage(null);
                    //setPrimarySelected(false);
                    props.onSelectImage && props.onSelectImage(null);
                }
            }
        })

        addImage(tempImages);
        addStyle(tempStyles);

        //setImagesPreviewUrls(tempImageData);

        // and pass it back to the parent
        props.deleteImage && props.deleteImage(imageFile);

        // and pass it back to the parent to remove
        //props.addImage && props.addImage(imageFile);


    }

    const dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    useEffect(() => {

        if (loadData) {
            //console.log("media loading");
            ListingDetail.GetListingAllImagesByID(props.listingUUID).then(result => {
                addImage([]);
                addStyle([]);
                var fileArray = [];
                //console.log("media: " + JSON.stringify(result.data));

                if (result && result.data && typeof (result.data) != 'string') {

                    //sort the images into the correct order
                    const imageSorted = result.data.sort((a, b) => (a.Sort > b.Sort) ? 1 : -1);

                    var imageCount = 0;

                    imageSorted.map((image, index) => {
                        //console.log("image: " + index + " : " + image.UUID + "sort: " + image.Sort);
                        imageCount++;
                        

                        let imageFile = "data:image/png;base64," + image.ImageData;

                        //let imageFileEncoded = URL.createObjectURL(imageFile);

                        //var blob = fetch(image.ImageData).then((res) => {
                        //    res.blob();
                        //    console.log("encoded: " + blob);
                        //});
                        var file = dataURLtoFile(imageFile, image.UUID + '.png');
                        //console.log(file);
                        fileArray.push(file);

                        // add the image to our array for tracking purposes
                        addImage(arr => [...arr, {
                            data: file, //imageFile,
                            ID: "", //image.UUID,
                            Sort: image.Sort
                        }]);

                        //check if primary image
                        if (image.Hero_Image) {
                            // set the border style of the selected
                            //styles[index] = style_selected;
                            addStyle(arr => [...arr, style_selected])

                            props.onSelectImage && props.onSelectImage({
                                data: file,
                                ID: "" //image.UUID
                            });

                            props.onCropImage && props.onCropImage(props.cropArea);

                        } else {
                            // need to add a default style as well
                            addStyle(arr => [...arr, style_notselected])
                        }

                        // and pass it back to the parent
                        //props.addImage && props.addImage(imageFile);
                        //props.addImage && props.addImage(file);
                        //setImagesPreviewUrls(arr => [...arr, file]);

                    });
                    setNoImages(imageCount);
                    props.addInitialImages && props.addInitialImages(fileArray);
                }
            })

            //getvalid();
            setLoadData(false);
        }

        props.isValid.current = getvalid;
    }, [images])

    return (        
        <>            
            <div className="wrapper">
                <div className="drop_zone" onDragOver={handleDragOver} onDrop={handleDrop}>
                    <p>Drag and drop image here....
                    </p>
                </div>
                <div>
                    <div style={{ textAlign: 'center', width: '100%' }}>
                        <h2>OR</h2>
                    </div>

                    <Button className="col-md-12" variant="secondary" onClick={handleClick}>
                        Select file(s)
                    </Button>
                    <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={changeHandler}
                        style={{ display: 'none' }}
                        accept="image/png, image/gif, image/jpeg"
                        multiple
                    />
                </div>
                <div style={{ display: fileLimitReached, width: '100%' }}>
                    <Alert variant="danger" >
                        Maximum file limit of 20 images
                    </Alert>
                </div>
                <br />

                <Card>
                    <Card.Body>
                        {/*<Row className="mb-3">*/}
                        {/*    <Col>*/}
                                
                        {/*    </Col>*/}
                        {/*    <Col>*/}
                        <div style={{ width: "100%", textAlign: "center" }}><b>{noimages} out of 20</b> </div>
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <Row className="mb-3">
                            <h4>Drag images around to sort</h4>
                        
                        <Preview
                                imagesPreviewUrls={images}
                                deleteImage={deleteSort}
                                updateImageArray={updateImageArray}
                        />
                        {/*<CropImage imageInput="https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000" />*/}
                        </Row>
                    </Card.Body>
                    <div style={{ display: pageValid, width: '100%' }}>
                        <Alert variant="danger" >
                            Primary Image is required to be selected and cropped!
                        </Alert>
                    </div>
                </Card>

        </div>
       </>
    )
}

export default Media
