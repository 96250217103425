import React, { useRef, useEffect, useState } from 'react';
import { Form, Card, Row, Col, Button } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';

import PageContentDetail from './../functions/PageContent';

import { Dropdown } from '@mobiscroll/react';

function PagesContent() {

    const [pageSelected, setPageSelected] = useState({});
    const [content, setContent] = useState({});
    const [initcontent, setInitContent] = useState({});
    const [existing, setExisting] = useState(false);
    const [pageID, setPageID] = useState(0);

    const handleContentChange = (event) => {
        setContent(event);

        document.getElementById("contentoutput").innerHTML = event;
    }

    const onPageSelect = (event) => {

        setPageSelected(event.target.value);

        if (event.target.value) {
            PageContentDetail.getPage(event.target.value).then(result => {
                if (result.data.Content) {
                    setExisting(true);
                    setPageID(result.data.UUID);
                    //set the wysiwyg
                    setInitContent(result.data.Content)
                    //set the output
                    document.getElementById("contentoutput").innerHTML = result.data.Content;
                } else {
                    setExisting(false);
                    setPageID(0);
                    //set the wysiwyg
                    setInitContent("")
                    //set the output
                    document.getElementById("contentoutput").innerHTML = "";
                }
            })
        }
    }

    const save = () => {

        if (pageSelected) {
            if (existing) {
                PageContentDetail.updatePage(pageID, pageSelected, content, "Updated").then(result => {
                    //console.log("result");
                    alert('updated');
                })
            } else {
                PageContentDetail.createPage(pageSelected, content).then(result => {
                    //console.log("result");
                    alert('saved');
                })
            }
        } else {
            alert('select a page');
        }
    }

    useEffect(() => {
        
    }, [])

    return (
            <Card style={{margin: "10px"}}>
                <Card.Body>                  
                <Row>
                    <h4>Select Page to Edit</h4>
                    <Dropdown label="Page" name="regtype" onChange={onPageSelect} >
                        <option value="">Please Select</option>
                        <option value="About">About</option>
                        <option value="PressPage">Press Page</option>
                        <option value="TermsAndCond">Terms and Conditions</option>
                        <option value="PrivacyPolicy">Privacy Policy</option>
                        <option value="CancelPolicies">Cancellation Policies</option>
                        <option value="GuestFAQ">Guest FAQ</option>
                        <option value="HostFAQ">Host FAQ</option>
                        <option value="HostTermsAndCond">Host Terms and Conditions</option>
                        <option value="ContentGuidelines">Content Guidelines</option>
                    </Dropdown>
                </Row>
                <Row>
                        <Col sm={6} md={6}>
                        
                        <Row>
                            <h4>Introduction</h4>
                            <Editor
                                initialValue={(initcontent)}
                                init={{
                                    height: 800,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | image | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                                id="pageContent"
                                onEditorChange={event => handleContentChange(event)}
                            />
                        </Row>
                        </Col>
                    <Col sm={6} md={6}>
                        <Row>
                            <h4>Output</h4>
                            <div id="contentoutput" />
                        </Row>
                    </Col>
                    </Row>
                <Button style={{ width: "100%" }} variant="success" onClick={save}>Save</Button>
            </Card.Body>
            
            </Card>
        )
    }


export default PagesContent