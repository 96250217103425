import React, { Component } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Nav } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import Globals from '../functions/globals';

import "./../styles/side-nav.css";

import { FaHome } from 'react-icons/fa';
import { FaUserAlt } from 'react-icons/fa';
import { FaList } from 'react-icons/fa';
import { FaPlusCircle } from 'react-icons/fa';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { FaHeart } from 'react-icons/fa';
import { FaFile } from 'react-icons/fa';
import { FaUsers } from 'react-icons/fa';
import { FaComments, FaPeopleArrows, FaDollarSign, FaCcMastercard  } from 'react-icons/fa';
import { MdOutlineRateReview, MdTextsms } from 'react-icons/md';

export function withRouter(Children) {
    return (props) => {

        const match = { params: useParams() };
        const location = { location: useLocation() };

        return <Children {...props} match={match.params} location={location.location} />
    }
}

class SideBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedIn: this.props.loggedIn,
            showLogin: this.props.showLogin,
            showRegister: this.props.showRegister,
            loginType: this.props.loginType
        }
    }

  render() {
    let loginTypeHosting;
    let loginTypeAdmin;
    if ( this.props.loginType === Globals.typeHosting || this.props.loginType === Globals.typeAdmin) {
        loginTypeHosting = <>
            <Link className={(this.props.activeTab == 'listings' ? 'activeTab nav-link' : 'nav-link')} to={{ pathname: "/mylistings", state: { email: this.props.email, loggedIn: this.props.loggedIn, loginType: this.props.loginType } }}><FaList /> My Listings</Link>
            <Link className={(this.props.activeTab == 'newlisting' ? 'activeTab nav-link' : 'nav-link')} to={{ pathname: "/addnewlisting", state: { email: this.props.email, loginType: this.props.loginType } }}><FaPlusCircle /> Add New Listing</Link>
            <Link className={(this.props.activeTab == 'promos' ? 'activeTab nav-link' : 'nav-link')} to={{ pathname: "/promos", state: { email: this.props.email, loginType: this.props.loginType } }} ><FaDollarSign /> Promos</Link>
        </>
            
     }
     if ( this.props.loginType === Globals.typeAdmin) {
         loginTypeAdmin = <>
             <Link className={(this.props.activeTab == 'users' ? 'activeTab nav-link' : 'nav-link')} to={{ pathname: "/users", state: { email: this.props.email, loginType: this.props.loginType } }} ><FaUsers /> Users</Link>
             <Link className={(this.props.activeTab == 'hosts' ? 'activeTab nav-link' : 'nav-link')} to={{ pathname: "/hosts", state: { email: this.props.email, loginType: this.props.loginType } }} ><FaUsers /> PMS Hosts</Link>
             <Link className={(this.props.activeTab == 'ccfees' ? 'activeTab nav-link' : 'nav-link')} to={{ pathname: "/ccfees", state: { email: this.props.email, loginType: this.props.loginType } }} ><FaCcMastercard  /> CC Fees</Link>
             <Link className={(this.props.activeTab == 'blogs' ? 'activeTab nav-link' : 'nav-link')} to={{ pathname: "/blogs", state: { email: this.props.email, loginType: this.props.loginType } }} ><MdTextsms /> Blogs</Link>
             
         </>    
     }
      return (
          <div>
              <Nav className="side-nav">
                  {/*<Link className={(this.props.activeTab == 'dashboard' ? 'activeTab nav-link' : 'nav-link')} to={{ pathname: "/dashboard", state: { email: this.props.email, loginType: this.props.loginType } }} ><FaHome /> Dashboard</Link>*/}
                  <Link className={(this.props.activeTab == 'profile' ? 'activeTab nav-link' : 'nav-link')} to={{ pathname: "/profile", state: { email: this.props.email, loginType: this.props.loginType } }}><FaUserAlt /> Profile</Link>
                  <Link className={(this.props.activeTab == 'reservations' ? 'activeTab nav-link' : 'nav-link')} to={{ pathname: "/reservations", state: { email: this.props.email, loginType: this.props.loginType } }} ><FaRegCalendarAlt /> Reservations</Link>
                  <Link className={(this.props.activeTab == 'favorites' ? 'activeTab nav-link' : 'nav-link')} to={{ pathname: "/favourites", state: { email: this.props.email, loginType: this.props.loginType } }} ><FaHeart /> Favourites</Link>
                  <Link className={(this.props.activeTab == 'invoices' ? 'activeTab nav-link' : 'nav-link')} to={{ pathname: "/invoices", state: { email: this.props.email, loginType: this.props.loginType } }} ><FaFile /> Invoices</Link>
                  <Link className={(this.props.activeTab == 'messages' ? 'activeTab nav-link' : 'nav-link')} to={{ pathname: "/messages", state: { email: this.props.email, loginType: this.props.loginType } }} ><FaComments /> Messages</Link>
                  <Link className={(this.props.activeTab == 'reviews' ? 'activeTab nav-link' : 'nav-link')} to={{ pathname: "/reviews", state: { email: this.props.email, loginType: this.props.loginType } }} ><MdOutlineRateReview /> Reviews</Link>
                  <Link className={(this.props.activeTab == 'ratings' ? 'activeTab nav-link' : 'nav-link')} to={{ pathname: "/ratings", state: { email: this.props.email, loginType: this.props.loginType } }} ><FaPeopleArrows /> Ratings</Link>

                {loginTypeHosting}
                {loginTypeAdmin}
            </Nav>
          </div>
      )
  }
}

export default withRouter(SideBar);