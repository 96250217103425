import React, { Component } from "react";
import { useParams, useLocation } from "react-router-dom";
import "./../styles/site.css";
import { Row, Col } from 'react-bootstrap';
import SideNav from "../modals/SideNav";

import ShowListings from '../items/mylistings/ShowListings'

export function withRouter(Children) {
    return (props) => {
        return <Children {...props} location={props} />
    }
}

class MyListings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: this.props.location.state.email,
            loggedIn: this.props.location.state.loggedIn,
            loginType: this.props.location.state.loginType,
        }
    }

    render() {
        return (
            <div style={{ paddingTop: "80px" }}>
                <Row>
                    <Col className="d-none d-lg-block" lg="2" xl="1">
                            <SideNav
                                loggedIn={this.props.location.state.loggedIn}
                                loginType={this.props.location.state.loginType}
                                email={this.props.location.state.email}
                                activeTab='listings'
                            />
                    </Col>
                    
                    <Col md="12" lg="10" xl="11">
                        <Row>
                            <h1>My Listings</h1>
                        </Row>
                        <Row>
                            <ShowListings email={this.state.email} loginType={this.state.loginType}/>
                        </Row>
                    </Col>
                </Row>
                
            </div>
        )
    }
    

    
}

export default withRouter(MyListings)