import React, { Component } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Image, Row, Col, Card, Button } from 'react-bootstrap';
import SideNav from "./../modals/SideNav";

import ReviewItem from './../items/reviewitem';

import StarRating from './../items/starRating';

import ReviewsDetail from './../functions/Reviews';
import ReservationDetail from './../functions/Reservation';
import ListingDetail from './../functions/Listing';
import UserDetail from './../functions/User';

import { Grid } from "react-loader-spinner";

import { Textarea, Input } from '@mobiscroll/react';

export function withRouter(Children) {
    return (props) => {

        const match = { params: useParams() };
        const location = { location: useLocation() };

        return <Children {...props} match={match} location={location.location} />
    }
}

class Rating extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: {},
            errorMessage: {},
            reviewID: 0, 
            rootReviewID: null,
            parentReviewID: null,
            senderID: 0,
            guestID: 0,
            receiverID: 0,
            reservationID: null,
            userID: null,
            showLoading: true,
            rating: 0,
            review: "",
            reviewTitle: "",
            loggedImage: "",
            receiverImage: "",
            senderName: "",
            receiverName: "",
            displayBody: "block",
            displayNotFound: "none",
            displaySubmitted: "none",
            displayError: "none",
            displayFound: "none",
            originalReview: null,
            response: null
        }
    }

    onRatingChange = (index) => {
        let newerrors = this.state.error;
        let newshowErrors = this.state.errorMessage;

        if (index > 0) {
            newerrors["rating"] = false;
            newshowErrors["rating"] = ""
        } else {
            newerrors["rating"] = true;
            newshowErrors["rating"] = "Please rate your guest"
        }

        this.setState({
            rating: index,
            error: newerrors,
            errorMessage: newshowErrors
        });
    }

    updateReviewTitle = (e) => {
        let newerrors = this.state.error;
        let newshowErrors = this.state.errorMessage;

        if (e.target.value) {
            newerrors["title"] = false;
            newshowErrors["title"] = ""
        } else {
            newerrors["title"] = true;
            newshowErrors["title"] = "Please add a title for your review"
        }

        this.setState({
            reviewTitle: e.target.value,
            error: newerrors,
            errorMessage: newshowErrors
        });
    }

    updateReview = (e) => {
        let newerrors = this.state.error;
        let newshowErrors = this.state.errorMessage;

        if (e.target.value) {
            newerrors["review"] = false;
            newshowErrors["review"] = ""
        } else {
            newerrors["review"] = true;
            newshowErrors["review"] = "Please add comments for your review"
        }

        this.setState({
            review: e.target.value,
            error: newerrors,
            errorMessage: newshowErrors
        });
    }

    submitReview = () => {
        //validate all fields are complete
        let newerrors = {};
        let newshowErrors = {};

        var valid = true;

        if (this.state.rating < 1 && this.state.reviewID == 0) {
            newerrors["rating"] = true;
            newshowErrors["rating"] = "Please rate your stay"
            valid = false;
        }

        if (!this.state.reviewTitle) {
            newerrors["title"] = true;
            newshowErrors["title"] = "Please add a title for your review"
            valid = false;
        }

        if (!this.state.review) {
            newerrors["review"] = true;
            newshowErrors["review"] = "Please add comments for your review"
            valid = false;
        }


        if (valid) {

            this.setState({ showLoading: true });

            ReviewsDetail.createHostReview(this.state.reservationID, this.state.review, this.state.senderID, this.state.reviewTitle, this.state.rating, this.state.guestID).then(result => {
                //console.log(result.data);
                if (result && result.data && result.data.includes("created")) {

                    //show thank you for the review
                    this.setState({
                        showLoading: false,
                        displaySubmitted: "block",
                        displayBody: "none"
                    });

                    //clear the text
                    this.setState({
                        reviewTitle: "",
                        review: "",
                        rating: 0
                    });
                } else {
                    //show error you for the review
                    this.setState({
                        showLoading: false,
                        displayError: "block",
                        displayBody: "none"
                    });
                }
            })

        } else {
            this.setState({
                error: newerrors,
                errorMessage: newshowErrors
            })
        }
    }

    componentDidMount() {

        const { id, resID } = this.props.match.params;

        this.setState({
            reviewID: id,
            reservationID: resID
        })

        //get the user ID
        var senderID = 0; //this is used later to find the receiver
        UserDetail.getUserByEmail(this.props.email).then((result) => {

            this.setState({
                senderID: result.data.UUID,
                senderName: result.data.Firstname,
                loggedImage: "data:image/png;base64," + result.data.Image
            });
            senderID = result.data.UUID;


            //get the reservation to find the reciever 
            if (resID != 0) {
                //confirm the user has not already created a review for the listing
                ReviewsDetail.getHostReview("ReservationID", resID, "UserID", senderID).then(result => {
                    //console.log("found");
                    //console.log(result);
                    //console.log("found");

                    if (result && result.data && result.data.ReviewID) {
                        //exist so hide form and display message
                        this.setState({
                            displayFound: "block",
                            displayBody: "none"
                        })
                    } else {
                        //process form
                        ReservationDetail.getReservationObjectsByReservationID(resID).then(result => {
                            if (result && result.data) {
                                //console.log(result.data);
                                //check the sender is either the host or guest and set the reciever to the other
                                if (senderID != result.data.Reservation.CustomerID || true) {
                                    //this would be the host sending to customer
                                    this.setState({ guestID: result.data.Reservation.CustomerID });
                                    //get the guest username
                                    UserDetail.getUserByID(result.data.Reservation.CustomerID).then(result => {
                                        if (result && result.data) {
                                            console.log("user: " + JSON.stringify(result.data));

                                            this.setState({ receiverName: result.data.Firstname + " " + result.data.Lastname });

                                        }

                                        //
                                    })
                                } 
                                
                            }
                        })
                    }

                })
            }

            ////get the listing to find the reciever
            //if (listID != 0) {
            //    ListingDetail.getListingByID(listID).then(result => {
            //        this.setState({ receiverID: result.data.OwnerID });
            //    })
            //}

            //if id is 0 then new message
            if (id == 0) {
                this.setState({ showLoading: false });
            } else {
                //alert("id found:" + id);

                //load the message chain
                ReviewsDetail.getHostReview("ReviewID", id).then(result => {
                    //console.log(JSON.stringify(result.data));
                //MessagesDetail.getMessagesByID("root_message_id", id).then(result => {
                    if (result.data && typeof (result.data) != 'string') {
                        //console.log(JSON.stringify(result.data[0].RootMessageID));
                        //alert("id found:" + id);
                        this.setState({
                            originalReview: result.data,
                            parentReviewID: id,
                            reviewID: id
                        });

                    }
                });

                this.setState({ showLoading: false });
            }
        });

    }

    render() {
        if (this.state.showLoading) {
            return (
                <div class="loading-overlay" >
                    <div class="loading-vertical">
                        <Grid
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={0}
                        />
                    </div>
                </div>
            )
        } else {

            return (
                <div style={{ paddingTop: "80px" }}>
                    <Row style={{ display: this.state.reviewID != 0 ? "none" : "" }}>
                        <Col className="d-none d-lg-block" lg="2" xl="1">
                            <SideNav
                                loggedIn={this.props.loggedIn}
                                loginType={this.props.loginType}
                                email={this.props.email}
                                activeTab='ratings'
                            />
                        </Col>
                        <Col md="12" lg="10" xl="11" style={{ display: this.state.displaySubmitted }}>
                            <h1>Thank you for your Review! </h1>
                        </Col>
                        <Col md="12" lg="10" xl="11" style={{ display: this.state.displayError }}>
                            <h1>Oh No! Something went wrong</h1>
                        </Col>
                        <Col md="12" lg="10" xl="11" style={{ display: this.state.displayFound }}>
                            <h1>It appears you have already submitted a review for your stay, thank you!</h1>
                        </Col>
                        <Col md="12" lg="10" xl="11" style={{ display: this.state.displayNotFound, color: '#e96852' }}>
                            <h1>Sorry we could not find a matching Review! </h1>
                        </Col>
                        <Col md="12" lg="10" xl="11" style={{ display: this.state.displayBody }}>
                            <div style={{ width: '100%' }}>
                                <h1>Write Review for:</h1>
                                <h1><span style={{ color: '#e96852' }}>{this.state.receiverName}</span></h1>
                                <hr />
                            </div>

                            <Card>
                                <Card.Body style={{ paddingRight: "0px" }}>
                                    <Row>
                                        <Col>
                                            <h5>How would you rate your guest?</h5>
                                            <StarRating onRatingChange={(e) => this.onRatingChange(e)} />
                                            <div style={{ position: "relative", top: "0px", display: this.state.error.rating ? "block" : "none" }} className={"mbsc-material mbsc-ltr mbsc-error-message mbsc-error-message-box"}>{this.state.errorMessage.rating}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h5>Title your review</h5>
                                            <Input onChange={(e) => this.updateReviewTitle(e)} placeholder="Example: Jo was a great guest!" error={this.state.error.title} errorMessage={this.state.errorMessage.title} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h5>Your review</h5>
                                            <Textarea style={{ minHeight: "200px" }} onChange={(e) => this.updateReview(e)} placeholder="Focus on the things the guest did well" error={this.state.error.review} errorMessage={this.state.errorMessage.review} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button className="action-button" variant="success" onClick={() => this.submitReview()} style={{ width: '100%' }}>Submit Review</Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row style={{ display: this.state.reviewID == 0 ? "none" : "" }}>
                        <Col className="d-none d-lg-block" lg="2" xl="1">
                            <SideNav
                                loggedIn={this.props.loggedIn}
                                loginType={this.props.loginType}
                                email={this.props.email}
                                activeTab='reviews'
                            />
                        </Col>
                        <Col md="12" lg="10" xl="11" style={{ display: this.state.displaySubmitted }}>
                            <h1>Thank you for your Response! </h1>
                        </Col>
                        <Col md="12" lg="10" xl="11" style={{ display: this.state.displayError }}>
                            <h1>Oh No! Something went wrong</h1>
                        </Col>
                        <Col md="12" lg="10" xl="11" style={{ display: this.state.displayBody }}>
                            <div style={{ width: '100%' }}>
                                <h1>Respond to Review:</h1>
                                <h1><span style={{ color: '#e96852' }}>{this.state.listingTitle}</span></h1>
                                <hr />
                            </div>

                            <Card>
                                <Card.Body style={{ paddingRight: "0px" }}>
                                    <Row>
                                        <Col>
                                            {this.state.originalReview ?
                                                <div style={{ width: '100%' }}>
                                                    <ReviewItem
                                                        rating={this.state.originalReview.Rating}
                                                        title={this.state.originalReview.Title}
                                                        review={this.state.originalReview.Review}
                                                        created={this.state.originalReview.CreatedDate}
                                                    />
                                                </div>
                                                : ""}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>

                </div>
            )
        }

    }
}

export default withRouter(Rating)