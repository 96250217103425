import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button  } from 'react-bootstrap';
import SideNav from "./../modals/SideNav";

import UserDetail from './../functions/User';
import BlogDetail from './../functions/Blog';
//import BlogService from '../services/BlogService';

export function withRouter(Children) {
    return (props) => {
        return <Children {...props} location={props} />
    }
}

class Blogs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            blogs: [],
            statusClass: 'label'
        }
    }

    formatDate = (input) => {
        var date = new Date(input);
        return (('0' + (date.getDate())).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()).toString();
    }

    formatStatus = (input) => {
        switch (input.toLowerCase()) {
            case 'pending':
                return 'label label-primary';
                break;
            case 'hidden':
            case 'cancelled':
                return 'label label-secondary';
                break;
            case 'published':
                return 'label label-success';
                break;
        }
    }

    blogLink = (input) => {
        BlogDetail.getBlogByID(input).then(result => {
            //console.log(result.data.Title);
            //return result.data.Information.Title;
            //return "something";
        })
    }

    blogLocation = (input) => {
        BlogDetail.getBlogLocationByID(input).then(result => {
            //console.log(result.data.City);
            //return result.data.Information.Title;
            return "something";
        })
    }

    componentDidMount() {

        //get user id by email
        UserDetail.getUserByEmail(this.props.location.state.email).then((result) => {
            //get the user reservations
            BlogDetail.getBlogsDetailByUser(result.data.UUID).then((result) => {
                //console.log(result.data);
                if (result && typeof (result.data) != 'string') {
                    this.setState({ blogs: result.data });
                }
            })

        })

    }

    render() {
        return (
            <div style={{ paddingTop: "80px" }}>
                <Row>
                    <Col className="d-none d-lg-block" lg="2" xl="1">
                        <SideNav
                            loggedIn={this.props.location.state.loggedIn}
                            loginType={this.props.location.state.loginType}
                            email={this.props.location.state.email}
                            activeTab='blogs'
                        />
                    </Col>
                    <Col md="12" lg="10" xl="11">
                        <div style={{ width: '100%' }}>
                            <h1>Blogs</h1>
                            <hr />
                        </div>
                        <Link to={{
                            pathname: "/addnewblog",
                            state: {
                                loginType: this.props.location.state.loginType,
                                email: this.props.location.state.email,
                                loggedIn: this.props.location.state.loggedIn
                            }
                        }}>
                            <Button>New Blog</Button>
                        </Link>
                        <Card>
                            <Card.Body style={{ paddingRight: "0px" }}>
                                <Row>
                                    <Row>
                                        <Col md="2" sm="3" xs="3"><h5>Status</h5></Col>
                                        <Col md="3" sm="4" xs="4"><h5>Title</h5></Col>
                                        <Col md="3" sm="3" xs="3"><h5>Location</h5></Col>
                                        <Col md="2" className="d-none d-md-block"><h5>Created</h5></Col>
                                        <Col md="2" sm="2" xs="2"><h5>Actions</h5></Col>
                                    </Row>
                                    {
                                        this.state.blogs && this.state.blogs.length > 0 ?
                                            this.state.blogs.map((blog, index) =>
                                                <div style={{ width: '100%' }}>
                                                    {/*<div class="d-none d-md-block">*/}
                                                    <div>
                                                        <Row md="12" sm="12" xs="12" style={{ height: '50px', borderBottom: '1px solid #808080', paddingTop: '6px' }} >
                                                            <Col md="2" sm="3" xs="3">
                                                                <span className={this.formatStatus(blog.Status)}>{blog.Status}</span>
                                                            </Col>
                                                            <Col md="3" sm="4" xs="4">
                                                                <Link to={'blog/' + blog.UUID}>
                                                                    <span>{blog.Title}{this.blogLink(blog.UUID)}</span>
                                                                </Link>
                                                                {/*<Link to={{*/}
                                                                {/*    pathname: "/blog",*/}
                                                                {/*    params: {*/}
                                                                {/*        id: blog.BlogID*/}
                                                                {/*    }*/}
                                                                {/*}}>*/}
                                                                {/*    <span>{blog.Title}{this.blogLink(blog.UUID)}</span>*/}
                                                                {/*</Link>*/}
                                                            </Col>
                                                            <Col md="3" sm="3" xs="3">
                                                                {blog.Location.City}
                                                            </Col>

                                                            <Col md="2" className="d-none d-md-block">
                                                                {this.formatDate(blog.CreatedDate)}
                                                            </Col>
                                                            <Col md="2" sm="2" xs="2">
                                                                <Link
                                                                    to={"/editblog"}
                                                                    state={{
                                                                        loginType: this.props.location.state.loginType,
                                                                        email: this.props.location.state.email,
                                                                        loggedIn: this.props.location.state.loggedIn,
                                                                        blog: blog
                                                                    }}><Button>Edit</Button>
                                                                </Link>
                                                            </Col>
                                                        </Row>
                                                    </div>


                                                    {/*<div class="d-md-none">*/}
                                                    {/*    <Row style={{ height: '50px', paddingtop: '6px' }} >*/}
                                                    {/*        <Col sm="3" xs="3">*/}
                                                    {/*            */}{/*<span className={this.formatStatus(reservation.Reservation.Status)}>{reservation.Reservation.Status}</span>*/}
                                                    {/*        </Col>*/}
                                                    {/*        <Col sm="4" xs="4">*/}
                                                    {/*            <Link to={'blog/' + blog.UUID}>*/}
                                                    {/*                <span>{blog.Title}{this.blogLink(blog.UUID)}</span>*/}
                                                    {/*            </Link>*/}
                                                    {/*        </Col>*/}
                                                    {/*        <Col sm="3" xs="3">*/}
                                                    {/*            {this.formatDate(blog.CreatedDate)}*/}
                                                    {/*        </Col>*/}
                                                    {/*        <Col sm="3" xs="3">*/}
                                                    {/*            {this.formatDate(blog.CreatedDate)}*/}
                                                    {/*        </Col>*/}

                                                    {/*    </Row>*/}
                                                    {/*    <Row style={{ height: '40px', borderbottom: '2px solid #808080', paddingtop: '6px' }} sm="12" >*/}
                                                    {/*        */}{/*<Link to={{*/}
                                                    {/*        */}{/*    pathname: "/addnewblog",*/}
                                                    {/*        */}{/*    state: {*/}
                                                    {/*        */}{/*        id: reservation.Reservation.ListingID*/}
                                                    {/*        */}{/*    }*/}
                                                    {/*        */}{/*}}>*/}
                                                    {/*        */}{/*    <span>{blog.Title}{this.listingLink(reservation.Reservation.ListingID)}</span>*/}
                                                    {/*        */}{/*</Link>*/}
                                                    {/*        <hr />*/}
                                                    {/*    </Row>*/}
                                                    {/*</div>*/}




                                                </div>
                                            ) :
                                            <Row>
                                            </Row>
                                    }

                                </Row>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </div>
        )
    }
}

export default withRouter(Blogs)